import useQueryGenericGet from "../../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_ORCHESTRATE_NOAUTH } from "../../../../serviceUrls/baseUrls";
import { O_SERVICE_BOARDS_PUBLIC_BY_UUID_GET } from "../../../../serviceUrls/serviceUrlsOrchestrate";
import useOrchestrateServiceUrlHelper from "../../../../hooks/orchestrate/useOrchestrateServiceUrlHelper";

const useGetPublicBoard = (boardUuid) => {
  const { endpointPath } = useOrchestrateServiceUrlHelper(
    O_SERVICE_BOARDS_PUBLIC_BY_UUID_GET,
    { boardUuid },
  );

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE_NOAUTH,
    endpointPath,
    false,
    [],
    {},
  );
};

export default useGetPublicBoard;
