import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import useGetCurrentUser from "../user/useGetCurrentUser";
import usePostTrackingInfo from "../usePostTrackingInfo";

const useTldrTracking = () => {
  const { data: currentUser } = useGetCurrentUser();
  const [cookies, setCookie] = useCookies(["tldr_tracking"]);
  const [tldrTrackingDict, setTldrTrackingDict] = useState(null);

  const { mutate: postTrackingInfo } = usePostTrackingInfo(
    () => {},
    () => {
      console.log("Posted tracking info.");
    },
    () => {},
  );

  useEffect(() => {
    if (cookies) {
      const tldrTrackingLocal = cookies["tldr_tracking"] || null;
      setTldrTrackingDict(tldrTrackingLocal);
    }
  }, [cookies]);

  useEffect(() => {
    if (tldrTrackingDict && Object.keys(tldrTrackingDict).length > 0) {
      if (currentUser) {
        postTrackingInfo({ ...tldrTrackingDict });

        setTldrTrackingDict(null);
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() - 10);
        setCookie("tldr_tracking", null, {
          domain: ".tldrparents.com",
          path: "/",
          secure: true,
          sameSite: "Lax",
          expires: expiryDate,
        });
      }
    }
  }, [tldrTrackingDict, currentUser, postTrackingInfo]);

  return { tldrTrackingDict };
};

export default useTldrTracking;
