import React from "react";
import LayoutChromeAuthenticated from "./layoutChromeAuthenticated";
import TldrBreadcrumbRibbon from "./tldrBreadcrumbRibbon";
import { Outlet } from "react-router-dom";
import ResponsiveCard from "../common/generic/responsiveCard";

const MeOutlet = () => {
  return (
    <LayoutChromeAuthenticated isMeMode={true}>
      <ResponsiveCard className="main-container-card">
        <TldrBreadcrumbRibbon />
        <Outlet />
      </ResponsiveCard>
    </LayoutChromeAuthenticated>
  );
};

export default MeOutlet;
