import React, { useEffect, useRef, useState } from "react";
import { Select } from "antd";
import {
  CODE_DATETIME_FORMAT,
  DISPLAY_TIME_FORMAT,
  DUMMY_DATE_STRING,
  getDayJsDatetimeFromStrings,
} from "../../../utils/dayJsUtils";
import {
  getCurrentTimeStringFlooredToHour,
  getTimesGivenIncrement,
} from "./dateTimePickerUtils";

const getOptionsForSelect = (increment) => {
  if (!increment) {
    increment = 15;
  }
  const options = [];
  const times = getTimesGivenIncrement(increment);
  for (const i in times) {
    const option = {
      label: times[i].format(DISPLAY_TIME_FORMAT),
      value: times[i].format(CODE_DATETIME_FORMAT),
    };

    options.push(option);
  }
  return options;
};

const ALL_OPTIONS = getOptionsForSelect(1);
const INCREMENTAL_OPTIONS = getOptionsForSelect(15);

const getSimpleTimes = () => {
  const simpleTimes = [];
  for (let i = 0; i < 12; i++) {
    const hour = i + 1;
    simpleTimes.push(`${hour}p`);
    simpleTimes.push(`${hour}pm`);
    simpleTimes.push(`${hour}a`);
    simpleTimes.push(`${hour}am`);
  }
  return simpleTimes;
};

const SIMPLE_TIMES = getSimpleTimes();

const isValueInIncrementalOptions = (value) => {
  for (const i in INCREMENTAL_OPTIONS) {
    if (INCREMENTAL_OPTIONS[i].value === value) {
      return true;
    }
  }
  return false;
};

const TimeSelector = ({ value, onChange, noSelectionIfNoValue, width }) => {
  if (!width) {
    width = 150;
  }

  const [allOptions, setAllOptions] = useState([]);
  const [incrementalOptions, setIncrementalOptions] = useState([]);
  const [displayOptions, setDisplayOptions] = useState([]);
  const [selectedValueAsString, setSelectedValueAsString] = useState(undefined);
  const selectRef = useRef(null);

  useEffect(() => {
    setAllOptions(ALL_OPTIONS);
    setIncrementalOptions(INCREMENTAL_OPTIONS);
  }, []);

  useEffect(() => {
    let valueToSet = value || "";
    if (!value && !noSelectionIfNoValue) {
      valueToSet = getDayJsDatetimeFromStrings(
        DUMMY_DATE_STRING,
        getCurrentTimeStringFlooredToHour(),
      ).format(CODE_DATETIME_FORMAT);
    }
    if (valueToSet) {
      valueToSet = getDayJsDatetimeFromStrings(
        DUMMY_DATE_STRING,
        valueToSet,
      ).format(CODE_DATETIME_FORMAT);
    }
    setSelectedValueAsString(valueToSet);

    if (!valueToSet || isValueInIncrementalOptions(valueToSet)) {
      setDisplayOptions(INCREMENTAL_OPTIONS);
    } else {
      setDisplayOptions(ALL_OPTIONS);
    }
  }, [value]);

  const isSearchTextInIncrementalOptions = (searchText) => {
    const cleanedSearchText = searchText
      .replace(":", "")
      .replace(" ", "")
      .toLowerCase();
    for (const i in incrementalOptions) {
      const cleanedOptionLabel = incrementalOptions[i].label
        .replace(":", "")
        .replace(" ", "");
      if (cleanedOptionLabel.startsWith(cleanedSearchText)) {
        return true;
      }
    }
  };

  const handleSearch = (searchText) => {
    if (isSearchTextInIncrementalOptions(searchText) || searchText === "") {
      setDisplayOptions(incrementalOptions);
    } else {
      setDisplayOptions(allOptions);
    }
  };

  const handleFilter = (searchText, option) => {
    const cleanedOptionLabel = option.label.replace(":", "").replace(" ", "");
    const cleanedSearchText = searchText
      .replace(":", "")
      .replace(" ", "")
      .toLowerCase();

    if (SIMPLE_TIMES.includes(cleanedSearchText)) {
      return cleanedOptionLabel.replace("00", "").startsWith(cleanedSearchText);
    }
    return cleanedOptionLabel.includes(cleanedSearchText);
  };

  const handleSelectValueChange = (newSelectedValue) => {
    setSelectedValueAsString(newSelectedValue);
    onChange(newSelectedValue.split(" ")[1]);

    if (isValueInIncrementalOptions(newSelectedValue)) {
      setDisplayOptions(incrementalOptions);
    } else {
      setDisplayOptions(allOptions);
    }
  };

  const getSelect = () => {
    return (
      <Select
        ref={selectRef}
        onBlur={() => {}}
        showSearch={true}
        value={selectedValueAsString}
        style={{ width: width }}
        onChange={handleSelectValueChange}
        onSelect={(value) => {}}
        options={displayOptions}
        onSearch={handleSearch}
        filterOption={handleFilter}
      />
    );
  };

  return <>{getSelect()}</>;
};

export default TimeSelector;
