import React from "react";
import AnchorNoPropagation from "../../common/generic/anchorNoPropagation";
import { getShortHumanizedDateTimeForEmail } from "../../../utils/dayJsUtils";
import { Typography } from "antd";

const { Text } = Typography;

const FoundInMessagesBlock = ({ messages, onClick }) => {
  const getForSingleMessage = () => {
    const subject = messages?.[0]?.subject || "[no subject]";
    return (
      <Typography.Paragraph ellipsis={{ rows: 2 }}>
        Found in{" "}
        <Text strong={true}>
          <AnchorNoPropagation onClick={onClick}>
            "{subject}"
          </AnchorNoPropagation>
        </Text>{" "}
        from {messages[0].from_address[1]} (
        {getShortHumanizedDateTimeForEmail(messages[0].timestamp)})
      </Typography.Paragraph>
    );
  };

  const getForMultipleMessages = () => {
    const subject = messages?.[0]?.subject || "[no subject]";
    return (
      <Typography.Paragraph ellipsis={{ rows: 2 }}>
        Found in{" "}
        <Text strong={true}>
          <AnchorNoPropagation onClick={onClick}>
            {messages.length} {messages.length > 1 ? "messages" : "message"}
          </AnchorNoPropagation>
        </Text>{" "}
        including <Text strong={true}>"{subject}"</Text> from{" "}
        {messages[0].from_address[1]} (
        {getShortHumanizedDateTimeForEmail(messages[0].timestamp)})
      </Typography.Paragraph>
    );
  };

  const getJsx = () => {
    return messages.length > 1
      ? getForMultipleMessages()
      : getForSingleMessage();
  };

  return getJsx();
};

export default FoundInMessagesBlock;
