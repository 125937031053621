import { useEffect, useState } from "react";
import useGetCurrentUser from "../user/useGetCurrentUser";
import { useParams } from "react-router-dom";

const useGetAndSetSelectedFamilyId = () => {
  const { data: currentUser } = useGetCurrentUser(false);
  const { familyId: initialFamilyIdFromParam } = useParams();
  const initialFamilyIdFromCookie = localStorage.getItem(
    "tldrParents.selectedFamilyId",
  );
  let initialFamilyId = initialFamilyIdFromParam;
  if (initialFamilyId === undefined) {
    initialFamilyId = initialFamilyIdFromCookie;
  }
  if (initialFamilyId) {
    initialFamilyId = parseInt(initialFamilyId);
  } else {
    initialFamilyId = 0;
  }
  const [selectedFamilyId, setSelectedFamilyId] = useState(initialFamilyId);

  useEffect(() => {
    const isFamilyIdInFamilies = (familyId) => {
      for (const i in currentUser.families) {
        const family = currentUser.families[i];
        if (family.id === familyId) {
          return true;
        }
      }
      return false;
    };

    if (currentUser) {
      if (currentUser.families.length === 0) {
        setSelectedFamilyId(0);
        localStorage.removeItem("tldrParents.selectedFamilyId");
      } else if (currentUser.families.length === 1) {
        if (initialFamilyId !== currentUser.families[0].id) {
          localStorage.setItem(
            "tldrParents.selectedFamilyId",
            currentUser.families[0].id,
          );
        }
      } else {
        if (isFamilyIdInFamilies(initialFamilyId)) {
          localStorage.setItem("tldrParents.selectedFamilyId", initialFamilyId);
        } else {
          localStorage.setItem(
            "tldrParents.selectedFamilyId",
            currentUser.families[0].id,
          );
        }
      }
    }
  }, [
    currentUser,
    setSelectedFamilyId,
    initialFamilyId,
    initialFamilyIdFromParam,
  ]);

  const setSelectedFamily = (familyId) => {
    if (familyId) {
      localStorage.setItem("tldrParents.selectedFamilyId", familyId);
      setSelectedFamilyId(familyId);
    } else {
      localStorage.removeItem("tldrParents.selectedFamilyId");
      setSelectedFamilyId(0);
    }
  };

  return {
    selectedFamilyId: selectedFamilyId,
    setSelectedFamily: setSelectedFamily,
  };
};

export default useGetAndSetSelectedFamilyId;
