import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import {
  DUMMY_TIME_STRING,
  getDayJsDatetimeFromStrings,
  toDateString,
} from "../../../utils/dayJsUtils";
import { getCurrentDateString } from "./dateTimePickerUtils";

const TldrDatePicker = ({ value, width, onChange }) => {
  if (!width) {
    width = 150;
  }
  const [selectedValueAsString, setSelectedValueAsString] = useState(undefined);

  useEffect(() => {
    if (value) {
      setSelectedValueAsString(value);
    } else {
      setSelectedValueAsString(getCurrentDateString());
    }
  }, [value]);

  const handleChange = (newValue) => {
    const valueAsStringLocal = toDateString(newValue);
    setSelectedValueAsString(valueAsStringLocal);
    onChange(valueAsStringLocal);
  };

  const getPicker = () => {
    return (
      <DatePicker
        style={{ width: width }}
        onChange={(value) => {
          handleChange(value);
        }}
        allowClear={false}
        onBlur={() => {}}
        value={getDayJsDatetimeFromStrings(
          selectedValueAsString,
          DUMMY_TIME_STRING,
        )}
        format="MMM D, YYYY"
      />
    );
  };

  return <>{getPicker()}</>;
};

export default TldrDatePicker;
