import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetOrchestrateObject from "../../../../hooks/orchestrate/useGetOrchestrateObject";
import useBatchGetArtifacts from "../../../../hooks/emailCollector/useBatchGetArtifacts";
import useUpdateOrchestrateObject from "../../../../hooks/orchestrate/useUpdateOrchestrateObject";
import {
  O_SERVICE_BOARDS_GET,
  O_SERVICE_BOARDS_UPDATE,
} from "../../../../serviceUrls/serviceUrlsOrchestrate";
import useGetEcArtifactsForSavedObjects from "../../clippingScroll/useGetEcArtifactsForSavedObjects";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";

const useBoard = () => {
  let { boardId } = useParams();
  boardId = parseInt(boardId);

  const {
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyContains,
  } = useTldrPageContext();

  const { data: board } = useGetOrchestrateObject(
    O_SERVICE_BOARDS_GET,
    boardId,
  );
  const [tiles, setTiles] = useState(undefined);
  const { data: tilesData } = useBatchGetArtifacts(tiles);
  const { ecArtifacts } = useGetEcArtifactsForSavedObjects(tiles, tilesData);
  const [isSettingPublicState, setIsSettingPublicState] = useState(false);
  const { mutate: updateBoard } = useUpdateOrchestrateObject(
    O_SERVICE_BOARDS_UPDATE,
    null,
    () => {
      setIsSettingPublicState(true);
      openLoadingMessage("updateBoard", "Updating board");
    },
    () => {
      openSuccessMessage("updateBoard", "Board updated.");
      setIsSettingPublicState(false);
      invalidateQueryWhereKeyContains("boards");
    },
    () => {
      openErrorMessage("updateBoard", "Board could not be updated.");
      setIsSettingPublicState(false);
    },
    false,
  );

  useEffect(() => {
    if (board) {
      const tilesLocal = [...board.tiles];
      setTiles(tilesLocal);
    }
  }, [board]);

  return {
    board,
    ecArtifacts,
    tiles,
    isSettingPublicState,
    updateBoard,
  };
};

export default useBoard;
