import React from "react";
import ArtifactCardWithCoverImage from "../../common/generic/artifactCardWithCoverImage";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { Tooltip } from "antd";

const ImageCard = ({
  ecObject,
  cardProperties,
  onClickViewEcObjectDetails,
  sourceMessageInfo,
  actionMenu,
}) => {
  return (
    <ArtifactCardWithCoverImage
      imageSrc={ecObject.signed_url || ecObject.final_url}
      imageSize={ecObject.size}
      alt={""}
      preview={true}
      position1={sourceMessageInfo}
      position2={
        <Tooltip title={"Image"}>
          <ImageOutlinedIcon sx={{ color: "#ff4081" }} />
        </Tooltip>
      }
      position3={actionMenu}
    />
  );
};

export default ImageCard;
