import React from "react";
import CardCenteredTextCover from "../../common/generic/cardCenteredTextCover";
import EmailAddress from "../../inboxesAndMessages/message/emailAddress";
import EmailAddresses from "../../inboxesAndMessages/emailAddresses";
import { timestampToFullDatetimeString } from "../../../utils/dayJsUtils";
import { Typography } from "antd";

const MessageCardCover = ({ ecObject, onClick, isMini }) => {
  const getHeaderAndTextContent = () => {
    return (
      <CardCenteredTextCover onClick={onClick || null}>
        <div>
          <Typography.Text strong={true} ellipsis={true}>
            {ecObject.subject}
          </Typography.Text>
        </div>
        <div>
          <Typography.Text strong={false} ellipsis={true}>
            From:{" "}
            <EmailAddress
              label={ecObject.from_address[1]}
              email={ecObject.from_address[0]}
              useStrong={true}
            />
          </Typography.Text>
        </div>

        {isMini !== true && (
          <>
            <div>
              To: <EmailAddresses addresses={ecObject.to_addresses} />
            </div>
          </>
        )}
        <div>{timestampToFullDatetimeString(ecObject.timestamp)}</div>
      </CardCenteredTextCover>
    );
  };

  return getHeaderAndTextContent();
};

export default MessageCardCover;
