import React from "react";
import { Form } from "antd";
import NotesInputFormItem from "../../../common/formComponents/notesInputFormItem";
import BoardSelector2 from "../../../boardSelector2";

const CreateTileWithBoardAssociationFormItems = () => {
  return (
    <>
      <Form.Item
        label="Select Board"
        name="board_id"
        validateTrigger={["onChange"]}
        rules={[
          {
            required: true,
            message: "Gotta have a board!",
          },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <BoardSelector2 />
      </Form.Item>
      <NotesInputFormItem />
    </>
  );
};

export default CreateTileWithBoardAssociationFormItems;
