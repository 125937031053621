import { useEffect, useState } from "react";
import useBatchGetMessages from "./useBatchGetMessages";

const useMessageCatalog = (uniqueMessageIds) => {
  const [messagesByUniqueMessageId, setMessagesByUniqueMessageId] = useState(
    {},
  );
  const [messagesIdsToGet, setMessagesIdsToGet] = useState([]);

  const { data: messagesRecentlyGotten } =
    useBatchGetMessages(messagesIdsToGet);

  useEffect(() => {
    if (uniqueMessageIds && uniqueMessageIds.length > 0) {
      const messagesSet = new Set();
      for (const i in uniqueMessageIds) {
        if (!(uniqueMessageIds[i] in messagesByUniqueMessageId)) {
          messagesSet.add(uniqueMessageIds[i]);
        }
      }
      setMessagesIdsToGet(Array.from(messagesSet));
    }
  }, [uniqueMessageIds, messagesByUniqueMessageId]);

  useEffect(() => {
    if (messagesRecentlyGotten) {
      setMessagesByUniqueMessageId((prev) => {
        const newObject = { ...prev };
        for (const i in messagesRecentlyGotten) {
          const umid = `${messagesRecentlyGotten[i].tenant_id}-${messagesRecentlyGotten[i].unique_tenancy_message_id}`;
          newObject[umid] = messagesRecentlyGotten[i];
        }
        return newObject;
      });
    }
  }, [messagesRecentlyGotten]);

  const getMessageByUniqueMessageId = (umid) => {
    return messagesByUniqueMessageId[umid] || null;
  };

  return { getMessageByUniqueMessageId };
};

export default useMessageCatalog;
