import React, { useEffect } from "react";
import useArtifactDetail from "./hooks/useArtifactDetail";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import { ALL_CLASSES_BY_CLASS_ID } from "./actionMappers/classesToActions";
import EcArtifactDetailInner from "./ecArtifactDetailInner";
import ObjectCardActionsButtons from "./cardParts/objectCardActionsButtons";
import { AppContextObjectDetailOverlay } from "./actionMappers/appContextsToActions";
import useCreateOrEditOrchestrateObject from "../../hooks/orchestrate/common/useCreateOrEditOrchestrateObject";
import OrchestrateObjectFormOverlays from "../familyOrganizer/orchestrateObjectFormOverlays";

const EcArtifactDetail = () => {
  const { artifact } = useArtifactDetail();
  const { setBreadcrumbs, clearBreadcrumbExtra } = useTldrPageContext();

  const title = ALL_CLASSES_BY_CLASS_ID[artifact?.class_id]?.objectTitle || "";

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Message Elements",
      },
      {
        label: title,
      },
    ]);
    clearBreadcrumbExtra();
  }, [setBreadcrumbs, clearBreadcrumbExtra, title]);

  const {
    eventOverlayOpen,
    closeEventOverlay,
    eventFormValues,
    eventMergeValues,
    todoOverlayOpen,
    closeTodoOverlay,
    todoFormValues,
    todoMergeValues,
    clippingOverlayOpen,
    closeClippingOverlay,
    clippingFormValues,
    clippingMergeValues,
    tileOverlayOpen,
    closeTileOverlay,
    tileFormValues,
    tileMergeValues,
    useImageOverlayOpen,
    closeUseImageOverlay,
    useImageFormValues,
    useImageMergeValues,
    onClickCardAction,
    ecObjectActedUpon,
  } = useCreateOrEditOrchestrateObject();

  const getJsx = () => {
    if (!artifact) {
      return <></>;
    }

    return (
      <>
        <OrchestrateObjectFormOverlays
          eventInitialFormValues={eventFormValues}
          eventMergeValues={eventMergeValues}
          eventOverlayOpen={eventOverlayOpen}
          onCancelEvent={() => {
            closeEventOverlay();
          }}
          onSubmitEvent={() => {
            closeEventOverlay();
          }}
          onPersistEvent={(item) => {}}
          todoInitialFormValues={todoFormValues}
          todoMergeValues={todoMergeValues}
          todoOverlayOpen={todoOverlayOpen}
          onCancelTodo={() => {
            closeTodoOverlay();
          }}
          onSubmitTodo={() => {
            closeTodoOverlay();
          }}
          onPersistTodo={(item) => {}}
          clippingInitialFormValues={clippingFormValues}
          clippingMergeValues={clippingMergeValues}
          clippingOverlayOpen={clippingOverlayOpen}
          onCancelClipping={() => {
            closeClippingOverlay();
          }}
          onSubmitClipping={() => {
            closeClippingOverlay();
          }}
          onPersistClipping={(item) => {}}
          tileInitialFormValues={tileFormValues}
          tileMergeValues={tileMergeValues}
          tileOverlayOpen={tileOverlayOpen}
          onCancelTile={() => {
            closeTileOverlay();
          }}
          onSubmitTile={() => {
            closeTileOverlay();
          }}
          onPersistTile={(item) => {}}
          useImageForBoardCoverInitialFormValues={useImageFormValues}
          useImageForBoardCoverMergeValues={useImageMergeValues}
          useImageForBoardCoverOverlayOpen={useImageOverlayOpen}
          onCancelUseImageForBoardCover={() => {
            closeUseImageOverlay();
          }}
          onSubmitUseImageForBoardCover={() => {
            closeUseImageOverlay();
          }}
          onPersistUseImageForBoardCover={(item) => {}}
          ecObjectActedUpon={ecObjectActedUpon}
        />
        <EcArtifactDetailInner
          ecObject={artifact}
          scrollToFirstHighlight={false}
          actionMenu={
            <ObjectCardActionsButtons
              ecObject={artifact}
              appContext={AppContextObjectDetailOverlay}
              onClick={(values, ecObjectLocal) => {
                onClickCardAction(values, ecObjectLocal);
              }}
              savedObject={null}
            />
          }
        />
      </>
    );
  };

  return getJsx();
};

export default EcArtifactDetail;
