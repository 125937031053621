import React from "react";
import DetailRow from "./detailPanelParts/detailRow";
import TagsDetailRow from "./detailPanelParts/tagsDetailRow";
import LinkPreview from "../../common/generic/linkPreview";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import DetailPanelSkeleton from "./detailPanelParts/detailPanelSkeleton";
import { getQueryParams } from "../../../utils/utils";

const LinkDetailPanel = ({ ecObject, actionMenu }) => {
  const linkUrl = ecObject.content_disposition
    ? ecObject.signed_url
    : ecObject.final_url;

  const imageSrc = ecObject.preview_signed_url
    ? ecObject.preview_signed_url
    : ecObject.preview_summary.image_src;

  const getJsx = () => {
    let cover;
    if (ecObject.final_url.startsWith("https://drive.google.com/file/d/")) {
      cover = (
        <div className="iframe-wrapper">
          <iframe
            src={ecObject.final_url.replace("/view", "/preview")}
            // width="640"
            // height="480"
            allow="autoplay"
          />
        </div>
      );
    } else if (ecObject.final_url.startsWith("https://www.youtube.com/watch")) {
      const embedId = getQueryParams(ecObject.final_url)["v"];
      return (
        <div className="iframe-wrapper">
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${embedId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </div>
      );
    } else {
      cover = (
        <LinkPreview
          href={linkUrl}
          title={ecObject?.preview_summary?.title}
          domain={ecObject?.preview_summary?.fqdn}
          description={ecObject?.preview_summary?.description}
          imageSrc={imageSrc}
          contentType={ecObject.link_content_type}
          coverHeight={243}
        />
      );
    }

    const position1 = (
      <TldrCardWithCoverComponent
        coverComponent={cover}
        width={"auto"}
        coverHeight={300}
        hoverable={false}
        onClick={null}
        bodyPadding={10}
      />
    );

    const position3 = (
      <>
        <DetailRow label={"URL"} value={ecObject?.final_url} copyable={true} />
        <TagsDetailRow tags={ecObject?.native_tags} />
      </>
    );

    return (
      <DetailPanelSkeleton
        position1={position1}
        position2={<>{!!actionMenu && actionMenu}</>}
        position3={position3}
      />
    );
  };

  return getJsx();
};

export default LinkDetailPanel;
