import { create } from "zustand";

const useBreadcrumbStore = create((set) => ({
  breadcrumbs: null,
  setBreadcrumbs: (newBreadcrumbs) => set({ breadcrumbs: newBreadcrumbs }),
  clearBreadcrumbs: () => set({ breadcrumbs: null }),
  breadcrumbExtra: null,
  setBreadcrumbExtra: (newBreadcrumbExtra) =>
    set({ breadcrumbExtra: newBreadcrumbExtra }),
  clearBreadcrumbExtra: () => set({ breadcrumbExtra: null }),
}));

export default useBreadcrumbStore;
