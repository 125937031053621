import React, { useState } from "react";
import useUpdateFamily from "../../../../hooks/orchestrate/common/useUpdateFamily";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";
import FamilyWorkspaceRenameForm from "../forms/familyWorkspaceRenameForm";
import { Typography } from "antd";

const { Text } = Typography;

const NameWorkspace = ({ onSuccess }) => {
  const {
    currentFamily,
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyContains,
  } = useTldrPageContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutate: updateFamilyName } = useUpdateFamily(
    () => {
      openLoadingMessage("updateFamilyName", "Saving family name");
    },
    () => {
      openSuccessMessage("updateFamilyName", "Perfect! We've got it saved");
      invalidateQueryWhereKeyContains("current-user");
    },
    () => {
      openErrorMessage("updateFamilyName", "Error saving family name");
      invalidateQueryWhereKeyContains("current-user");
    },
  );

  return (
    <div>
      <div style={{ marginBottom: 15 }}>
        <Text type="secondary">Stay calm. You can change it later!</Text>
      </div>
      <FamilyWorkspaceRenameForm
        onSubmit={(values) => {
          setIsSubmitting(true);
          updateFamilyName({ idz: currentFamily.id, name: values.name });
          onSuccess();
          setIsSubmitting(false);
        }}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default NameWorkspace;
