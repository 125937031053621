import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import AbsoluteSpin from "../common/generic/absoluteSpin";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";
import useTldrTracking from "../../hooks/orchestrate/common/useTldrTracking";
import useTrackUserVisit from "../../hooks/utils/useTrackUserVisit";

const InnerPrivateRoute = () => {
  const { data: currentUser } = useGetCurrentUser();
  const { tldrTrackingDict } = useTldrTracking();

  useTrackUserVisit();

  useEffect(() => {}, [tldrTrackingDict]);

  function getJsx() {
    if (!currentUser) {
      return <AbsoluteSpin />;
    }

    if (currentUser.is_active === true) {
      return <Outlet />;
    } else {
      return <></>;
    }
  }

  return getJsx();
};

const PrivateRoute = () => {
  const location = useLocation();
  const { isLoading: isAuth0Loading, isAuthenticated: isAuth0Authenticated } =
    useAuth0(Auth0UniversalProviderContext);

  function getJsx() {
    if (isAuth0Loading) {
      return <AbsoluteSpin />;
    }

    if (isAuth0Authenticated) {
      return <InnerPrivateRoute />;
    }

    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{getJsx()}</>;
};

export default PrivateRoute;
