import { useEffect, useState } from "react";

const useGetEcArtifactsForSavedObjects = (savedObjects, ecArtifactData) => {
  const [ecArtifacts, setEcArtifacts] = useState([]);

  useEffect(() => {
    if (savedObjects && ecArtifactData) {
      const ecArtifactsLocal = [];
      for (const i in savedObjects) {
        const savedObject = savedObjects[i];
        let ecArtifact = null;
        let savedArtifact = savedObject.artifacts[0];
        try {
          if (savedArtifact.rollup_sort_key) {
            ecArtifact =
              ecArtifactData[savedArtifact.rollup_class_id][
                savedArtifact.rollup_hash_key
              ][savedArtifact.rollup_sort_key];
          } else {
            ecArtifact =
              ecArtifactData[savedArtifact.rollup_class_id][
                savedArtifact.rollup_hash_key
              ];
          }
        } catch (e) {
          console.log(
            `Couldn't find ecObject to go with saved object. ${savedObject}`,
          );
        }
        if (ecArtifact) {
          ecArtifactsLocal.push(ecArtifact);
        } else {
          ecArtifactsLocal.push(null);
        }
      }
      setEcArtifacts(ecArtifactsLocal);
    }
  }, [savedObjects, ecArtifactData]);

  return { ecArtifacts };
};

export default useGetEcArtifactsForSavedObjects;
