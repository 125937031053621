import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import { useEffect } from "react";

const Logout = () => {
  const { logout } = useAuth0(Auth0UniversalProviderContext);

  useEffect(() => {
    logout({
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      logoutParams: { returnTo: window.location.origin },
    }).then();
  }, [logout]);

  return <></>;
};

export default Logout;
