import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../../serviceUrls/baseUrls";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../../../context/auth0UniversalProviderContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getApiClientWithBaseUrl } from "../../../../hooks/framework/apiClient";
import { O_SERVICE_TILES_CREATE } from "../../../../serviceUrls/serviceUrlsOrchestrate";
import { replacePlaceholdersInTemplate } from "../../../../serviceUrls/serviceUrlHelpers";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";

const useCreateTileWithAssociation = (
  queryKey,
  onSubmit,
  onSuccess,
  onError,
) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);
  const queryClient = useQueryClient();
  const { familyId } = useTldrPageContext();

  return useMutation({
    mutationFn: async (data) => {
      const boardId = data.board_id;
      const token = await getAccessTokenSilently();
      let endpoint = replacePlaceholdersInTemplate(O_SERVICE_TILES_CREATE, {
        boardId: boardId,
      });
      endpoint = `/families/${familyId}${endpoint}`;
      const client = getApiClientWithBaseUrl(
        BACKEND_API_BASE_URL_ORCHESTRATE,
        endpoint,
        token,
      );
      return client.post(data);
    },

    onMutate: (unsaved) => {
      if (queryKey) {
        const previousItems = queryClient.getQueryData(queryKey) || [];
        queryClient.setQueryData(queryKey, [unsaved, ...previousItems]);
        if (onSubmit) onSubmit(unsaved);
        return { previousItems };
      }

      if (onSubmit) onSubmit(unsaved);
    },

    onSuccess: (saved, submitted) => {
      if (onSuccess) onSuccess(saved, submitted);
    },

    onError: (error, unsaved, context) => {
      if (queryKey) {
        queryClient.setQueryData(queryKey, context.previousItems);
        queryClient.invalidateQueries({ queryKey: queryKey }).then();
      }
      if (onError) onError(error);
    },
  });
};

export default useCreateTileWithAssociation;
