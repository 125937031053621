import React from "react";
import { Form, Input } from "antd";

const NotesInputFormItem = () => {
  return (
    <Form.Item name="notes" label="Notes">
      <Input.TextArea rows={4} />
    </Form.Item>
  );
};

export default NotesInputFormItem;
