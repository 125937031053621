import useQueryGenericGet from "../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../serviceUrls/baseUrls";
import useOrchestrateServiceUrlHelper from "../../hooks/orchestrate/useOrchestrateServiceUrlHelper";

const useListOrchestrateObjects = (
  endpointTemplateUrl,
  enabled,
  queryParams,
) => {
  const { endpointPath } = useOrchestrateServiceUrlHelper(
    endpointTemplateUrl,
    {},
  );

  if (!queryParams) {
    queryParams = {};
  }

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    endpointPath,
    true,
    [],
    queryParams,
    enabled,
  );
};

export default useListOrchestrateObjects;
