import React from "react";
import { Button, Divider, Drawer, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { getFamilyName } from "../../utils/utils";
import NavButtonGrid from "../common/generic/navButtons/navButtonGrid";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import HandymanIcon from "@mui/icons-material/Handyman";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

const PrimaryFamilyNavDrawer = ({ open, onSelectAndNavigate, onClose }) => {
  const { familyId, currentUser } = useTldrPageContext();
  const navigate = useNavigate();

  const getNavButtonRows = () => {
    return [
      {
        sectionTitle: "Discover",
        navButtonItems: [
          {
            text: "Smart Inbox",
            icon: <EmailOutlinedIcon sx={{ fontSize: 25 }} />,
            onClick: () => {
              navigate(`/families/${familyId}/smart-inbox`);
              onSelectAndNavigate();
            },
          },
        ],
      },
      {
        navButtonItems: [
          {
            text: "Event Suggestions",
            icon: <AutoAwesomeOutlinedIcon sx={{ fontSize: 25 }} />,
            onClick: () => {
              navigate(`/families/${familyId}/event-suggestions`);
              onSelectAndNavigate();
            },
          },
          {
            text: "Link Suggestions",
            icon: <LinkOutlinedIcon sx={{ fontSize: 25 }} />,
            onClick: () => {
              navigate(`/families/${familyId}/link-suggestions`);
              onSelectAndNavigate();
            },
          },
        ],
      },
      {
        sectionTitle: "Get Stuff Done",
        navButtonItems: [
          {
            text: "Workbench",
            icon: <HandymanIcon sx={{ fontSize: 25 }} />,
            onClick: () => {
              navigate(`/families/${familyId}/workbench`);
              onSelectAndNavigate();
            },
          },
        ],
      },
      {
        navButtonItems: [
          {
            text: "To-dos",
            icon: <FormatListBulletedOutlinedIcon sx={{ fontSize: 25 }} />,
            onClick: () => {
              navigate(`/families/${familyId}/todos`);
              onSelectAndNavigate();
            },
          },
          {
            text: "Family Boards",
            icon: <DashboardOutlinedIcon sx={{ fontSize: 25 }} />,
            onClick: () => {
              navigate(`/families/${familyId}/boards`);
              onSelectAndNavigate();
            },
          },
        ],
      },
    ];
  };

  const getTakeActionButtons = () => {
    return (
      <Space direction={"vertical"}>
        <Button
          type={"link"}
          onClick={() => {
            navigate(`/families/${familyId}/key-links-files`);
            onSelectAndNavigate();
          }}
        >
          Link Gallery
        </Button>
        <Button
          type={"link"}
          onClick={() => {
            navigate(`/families/${familyId}/google-events`);
            onSelectAndNavigate();
          }}
        >
          Added to Google Calendar
        </Button>
        <Button
          type={"link"}
          onClick={() => {
            navigate(`/families/${familyId}/email-addresses`);
            onSelectAndNavigate();
          }}
        >
          Email Address List
        </Button>
        <Button
          type={"link"}
          onClick={() => {
            navigate(`/families/${familyId}/settings`);
            onSelectAndNavigate();
          }}
        >
          Family Settings
        </Button>
      </Space>
    );
  };

  return (
    <Drawer
      title={getFamilyName(familyId, currentUser)}
      placement="left"
      open={open}
      onClose={() => onClose()}
      style={{ maxWidth: "100%" }}
    >
      <NavButtonGrid
        navButtonRows={getNavButtonRows()}
        bottomSpacer={8}
        buttonHeight={90}
      />
      <Divider orientation={"left"}>More</Divider>
      {getTakeActionButtons()}
    </Drawer>
  );
};

export default PrimaryFamilyNavDrawer;
