import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";
import useBillingGet from "./useBillingGet";
import { O_SERVICE_BILLING_SUBSCRIPTIONS_MY_FAMILY } from "../../../../serviceUrls/serviceUrlsOrchestrate";
import useUpdateSubscription from "./useUpdateSubscription";
import { useState } from "react";

const DEFAULT_MESSAGE_STRINGS = [
  "Updating subscription",
  "Subscription updated",
  "Error updating subscription",
];

const CANCEL_MESSAGE_STRINGS = [
  "Cancelling subscription",
  "Subscription cancelled",
  "Error cancelling subscription",
];

const UNDO_CANCEL_MESSAGE_STRINGS = [
  "Undoing subscription cancellation",
  "Subscription cancellation undone",
  "Error Undoing subscription cancellation",
];

const useSettingsSubscription = () => {
  const {
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyContains,
  } = useTldrPageContext();

  const { data: subscription, isLoading: isLoadingSubscription } =
    useBillingGet(O_SERVICE_BILLING_SUBSCRIPTIONS_MY_FAMILY);

  const [messageStrings, setMessageStrings] = useState(DEFAULT_MESSAGE_STRINGS);

  const updateSubscriptionHook = useUpdateSubscription(
    () => {
      openLoadingMessage("updateSubscription", messageStrings[0]);
    },
    () => {
      openSuccessMessage("updateSubscription", messageStrings[1]);
      invalidateQueryWhereKeyContains("subscriptions");
    },
    () => {
      openErrorMessage("updateSubscription", messageStrings[2]);
      invalidateQueryWhereKeyContains("subscriptions");
    },
  );

  const handleUpdateSubscription = (isCancellation) => {
    if (isCancellation) {
      setMessageStrings(CANCEL_MESSAGE_STRINGS);
      updateSubscriptionHook.mutate({
        idz: subscription.id,
        cancel_at_period_end: true,
      });
    } else {
      setMessageStrings(UNDO_CANCEL_MESSAGE_STRINGS);
      updateSubscriptionHook.mutate({
        idz: subscription.id,
        cancel_at_period_end: false,
      });
    }
  };

  return { subscription, isLoadingSubscription, handleUpdateSubscription };
};

export default useSettingsSubscription;
