import React from "react";
import { ALL_CLASSES_BY_CLASS_ID } from "../actionMappers/classesToActions";

const AnyMiniCard = ({ ecObject, appContext, onClick }) => {
  const getCard = () => {
    if (ecObject) {
      const CardMiniClass =
        ALL_CLASSES_BY_CLASS_ID[ecObject.class_id].cardMiniClass;

      return (
        <CardMiniClass
          ecObject={ecObject}
          appContext={appContext}
          onClick={onClick}
        />
      );
    }
    return <></>;
  };

  return getCard();
};

export default AnyMiniCard;
