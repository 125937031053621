import useQueryGenericGet from "../framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_URL_CENTRAL } from "../../serviceUrls/baseUrls";
import { UC_SERVICE_URL_REQUESTS_REQUEST_URL } from "../../serviceUrls/serviceUrlsUrlCentral";

const useListUrlRequestsForRequestUrl = (requestUrl) => {
  return useQueryGenericGet(
    BACKEND_API_BASE_URL_URL_CENTRAL,
    UC_SERVICE_URL_REQUESTS_REQUEST_URL,
    true,
    [],
    { request_url: requestUrl },
    !!requestUrl,
  );
};

export default useListUrlRequestsForRequestUrl;
