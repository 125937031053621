import { useState } from "react";
import { MERGE_SOURCE_DATA_COMMON } from "../../../components/familyOrganizer/orchestrateObjectPrototypes";
import useTldrUniversalMutateContext from "../../utils/useTldrUniversalMutateContext";

export const EMPTY_TILE_FOR_CREATE_AND_ASSOCIATE = {
  board: { board_id: null },
  notes: "",
};

export const MERGE_VALUES_TILE_FOR_CREATE_AND_ASSOCIATE = {
  ...MERGE_SOURCE_DATA_COMMON,
  name: "",
  tile_type_id: 1,
};

const useCreateOrEditOrchestrateTile = () => {
  const { deleteTile } = useTldrUniversalMutateContext();

  const [tileOverlayOpen, setTileOverlayOpen] = useState(false);
  const [tileFormValues, setTileFormValues] = useState({});
  const [tileMergeValues, setTileMergeValues] = useState({});

  const handleCreateTileWithBoardAssociation = (values, ecObject) => {
    setTileFormValues(EMPTY_TILE_FOR_CREATE_AND_ASSOCIATE);
    setTileMergeValues({
      ...MERGE_VALUES_TILE_FOR_CREATE_AND_ASSOCIATE,
      ...values.sourceInfo,
    });
    openTileOverlay();
  };

  const handleEditTile = (values, ecObject) => {
    setTileFormValues({
      name: values.savedObject.name,
      notes: values.savedObject.notes,
    });
    setTileMergeValues({
      id: values.savedObject.id,
    });
    openTileOverlay();
  };

  const handleDeleteTileBoardAssociation = (values, ecObject) => {
    // TODO: At some point, we may have more than one board association
    deleteTile(values.savedObject.board_associations[0].id);
  };

  const openTileOverlay = () => {
    setTileOverlayOpen(true);
    document.body.classList.add("overlay-open");
  };

  const closeTileOverlay = () => {
    setTileOverlayOpen(false);
    document.body.classList.remove("overlay-open");
  };

  return {
    closeTileOverlay,
    tileOverlayOpen,
    tileFormValues,
    setTileFormValues,
    tileMergeValues,
    setTileMergeValues,
    handleCreateTileWithBoardAssociation,
    handleEditTile,
    handleDeleteTileBoardAssociation,
  };
};

export default useCreateOrEditOrchestrateTile;
