import useQueryGenericPut from "../../../../hooks/framework/useQueryGenericPut";
import { O_SERVICE_BILLING_SUBSCRIPTIONS_UPDATE } from "../../../../serviceUrls/serviceUrlsOrchestrate";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../../serviceUrls/baseUrls";
import useOrchestrateServiceUrlHelper from "../../../../hooks/orchestrate/useOrchestrateServiceUrlHelper";

const useUpdateSubscription = (onSubmit, onSuccess, onError) => {
  const { endpointPath } = useOrchestrateServiceUrlHelper(
    O_SERVICE_BILLING_SUBSCRIPTIONS_UPDATE,
    {},
  );

  return useQueryGenericPut(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    endpointPath,
    onSubmit,
    onSuccess,
    onError,
  );
};

export default useUpdateSubscription;
