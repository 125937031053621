import React from "react";
import { Skeleton, Table } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import { formatCurrency } from "../../../utils/utils";
import useBillingGet from "./hooks/useBillingGet";
import { O_SERVICE_BILLING_INVOICES_LIST } from "../../../serviceUrls/serviceUrlsOrchestrate";
import dayjs from "dayjs";
import { DISPLAY_DATE_FORMAT_LONG } from "../../../utils/dayJsUtils";

const SettingsInvoices = () => {
  const { data: invoices } = useBillingGet(O_SERVICE_BILLING_INVOICES_LIST);

  const columns = [
    {
      title: "PDF",
      key: "id",
      render: (record) => {
        return (
          <a href={record.invoice_pdf}>
            <FilePdfOutlined style={{ fontSize: 25 }} />
          </a>
        );
      },
    },
    {
      title: "Amount Due",
      key: "amount_due",
      render: (record) => {
        return formatCurrency(record.amount_due / 100);
      },
    },
    {
      title: "Amount Paid",
      key: "amount_paid",
      render: (record) => {
        return formatCurrency(record.amount_paid / 100);
      },
    },
    {
      title: "Date",
      key: "created",
      render: (record) => {
        return dayjs(record.created * 1000).format(DISPLAY_DATE_FORMAT_LONG);
      },
    },
  ];

  const getJsx = () => {
    if (!invoices) {
      return <Skeleton active />;
    } else {
      return (
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={invoices}
          pagination={false}
          bordered={false}
        />
      );
    }
  };

  return (
    <>
      <h2>Invoices</h2>
      {getJsx()}
    </>
  );
};

export default SettingsInvoices;
