import React, { useEffect } from "react";
import { Form, Input } from "antd";

const CreateFamilyForm = ({
  initialValues,
  onValuesChange,
  onFormInstanceReady,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    onFormInstanceReady(form);
  }, [form, onFormInstanceReady]);

  return (
    <Form
      layout="vertical"
      form={form}
      name={"CreateFamilyForm"}
      initialValues={initialValues}
      onValuesChange={(value) => {
        if (onValuesChange) {
          onValuesChange?.(value, form.getFieldsValue());
        }
      }}
    >
      <Form.Item
        name="name"
        label="Name for family workspace"
        rules={[{ required: true, message: "Name is required" }]}
      >
        <Input placeholder="The Smith Family" />
      </Form.Item>
    </Form>
  );
};

export default CreateFamilyForm;
