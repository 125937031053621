import React, { useEffect } from "react";
import {
  focusManager,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import tldrRoutes from "./components/routingAndAuth/tldrRoutes";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { App as AntDApp, ConfigProvider } from "antd";
import ReactGA from "react-ga4";

const queryClient = new QueryClient();

// I had to put this here because ReactQuery was not refetching data on focus after upgrading to v5.
focusManager.setEventListener((handleFocus) => {
  // Listen to visibilitychange and focus
  if (typeof window !== "undefined" && window.addEventListener) {
    window.addEventListener("visibilitychange", handleFocus, false);
    window.addEventListener("focus", handleFocus, false);
  }

  return () => {
    // Be sure to unsubscribe if a new handler is set
    window.removeEventListener("visibilitychange", handleFocus);
    window.removeEventListener("focus", handleFocus);
  };
});

// TODO: This is a little hacky... Here's what the problem was: Sometimes users would close a full-screen overlay by
//  clicking the back button. This path would bypass the usual closing route and the app would not re-enable scrolling.
// window.onpopstate = (event) => {
//   if (document.body.classList.contains("overlay-open")) {
//     document.body.classList.remove("overlay-open");
//   }
// };

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#168dff",
            colorBgLayout: "#168dff",
            colorLink: "#168dff",
            colorTextBase: "#2f2f2f",
            fontFamily: "Jost",
            // fontWeightStrong: 700,
          },
          components: {
            Spin: {
              colorPrimary: "#ffffff",
              algorithm: true,
            },
            Button: {
              borderRadius: 20,
              borderRadiusLG: 28,
              borderRadiusSM: 8,
            },
          },
        }}
      >
        <AntDApp>
          <RouterProvider router={tldrRoutes} />
          <ReactQueryDevtools />
        </AntDApp>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
