import React from "react";
import OnboardingPageFrame from "../onboardingPageFrame";
import useOnboardingPartDeux from "./hooks/useOnboardingPartDeux";
import InvitePartner from "./steps/invitePartner";
import ProcessFirstMessage from "./steps/processFirstMessage";
import NameWorkspace from "./steps/nameWorkspace";
import UsersGivenName from "../../common/commonTldr/usersGivenName";

const OnboardingPartDeux = () => {
  const { onboardingSteps, currentStep, updateUserEducationSubjectStatus } =
    useOnboardingPartDeux();

  const getCardForCurrentStep = () => {
    if (currentStep) {
      switch (currentStep) {
        case "NAME_FAMILY_WORKSPACE":
          return (
            <NameWorkspace
              onSuccess={() =>
                updateUserEducationSubjectStatus({
                  subjectId: "NAME_FAMILY_WORKSPACE",
                  status: "completed",
                })
              }
            />
          );
        case "INVITE_PARTNER":
          return (
            <InvitePartner
              onSuccess={() =>
                updateUserEducationSubjectStatus({
                  subjectId: "INVITE_PARTNER",
                  status: "completed",
                })
              }
              onSkip={() =>
                updateUserEducationSubjectStatus({
                  subjectId: "INVITE_PARTNER",
                  status: "skipped",
                })
              }
            />
          );
        case "PROCESS_FIRST_MESSAGE":
          return (
            <ProcessFirstMessage
              onDone={() => {
                updateUserEducationSubjectStatus({
                  subjectId: "PROCESS_FIRST_MESSAGE",
                  status: "skipped",
                });
              }}
            />
          );

        default:
          return <></>;
      }
    }
  };

  const getTitleForCurrentStep = () => {
    if (currentStep) {
      switch (currentStep) {
        case "NAME_FAMILY_WORKSPACE":
          return (
            <>
              🎉 <UsersGivenName fallbackGivenName={"Friend"} />, it's time to
              name your family workspace!
            </>
          );
        case "INVITE_PARTNER":
          return "🌟 Invite a partner";
        case "PROCESS_FIRST_MESSAGE":
          return "➡️ Forward your first email";
        default:
          return "";
      }
    }
  };

  const getStepNumForCurrentStep = () => {
    if (onboardingSteps && currentStep) {
      return onboardingSteps.indexOf(currentStep);
    }
    return 0;
  };

  return (
    <>
      <OnboardingPageFrame
        numSteps={onboardingSteps?.length || 0}
        currentStep={getStepNumForCurrentStep()}
        title={getTitleForCurrentStep()}
      >
        {getCardForCurrentStep()}
      </OnboardingPageFrame>
    </>
  );
};

export default OnboardingPartDeux;
