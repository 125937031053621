import React from "react";
import { Button, Descriptions, Divider, Skeleton, Tag, Typography } from "antd";
import useSettingsSubscription from "./hooks/useSettingsSubscription";
import dayjs from "dayjs";
import { DISPLAY_DATE_FORMAT_LONG } from "../../../utils/dayJsUtils";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";

const { Text } = Typography;

const SettingsSubscription = () => {
  const { currentFamily } = useTldrPageContext();
  const { subscription, isLoadingSubscription, handleUpdateSubscription } =
    useSettingsSubscription();

  const getCancelOrUndoButton = () => {
    const isCancellation = !subscription.cancel_at_period_end;
    const buttonText = isCancellation
      ? "Cancel Subscription"
      : "Undo Cancellation";

    return (
      <Button
        ghost={isCancellation}
        danger={isCancellation}
        type="primary"
        onClick={() => {
          handleUpdateSubscription(isCancellation);
        }}
      >
        {buttonText}
      </Button>
    );
  };

  const getJsx = () => {
    if (isLoadingSubscription) {
      return <Skeleton active />;
    } else {
      if (subscription && subscription?.status !== "incomplete") {
        return (
          <>
            <p>
              {currentFamily?.num_messages_processed_current_month >=
              currentFamily?.num_messages_allowed_free_tier ? (
                <Text type="danger">
                  Your family has processed{" "}
                  {currentFamily?.num_messages_processed_current_month} messages
                  this month. The standard plan entitles you to{" "}
                  {currentFamily?.num_messages_allowed_paid_tier} messages per
                  month.
                </Text>
              ) : (
                <Text type="secondary">
                  Your family has processed{" "}
                  {currentFamily?.num_messages_processed_current_month} messages
                  this month. The standard plan entitles you to{" "}
                  {currentFamily?.num_messages_allowed_paid_tier} messages per
                  month.
                </Text>
              )}
            </p>
            <Descriptions>
              <Descriptions.Item label="Status">
                <Tag
                  color={
                    subscription.status === "active" ? "success" : "default"
                  }
                >
                  {subscription.status}
                </Tag>
                {subscription.cancel_at_period_end && (
                  <Text type="danger"> (cancels at end of period)</Text>
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions>
              <Descriptions.Item label="Started">
                {dayjs(subscription.start_date * 1000).format(
                  DISPLAY_DATE_FORMAT_LONG,
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions>
              <Descriptions.Item label="Current Period">
                {dayjs(subscription.current_period_start * 1000).format(
                  DISPLAY_DATE_FORMAT_LONG,
                )}{" "}
                -{" "}
                {dayjs(subscription.current_period_end * 1000).format(
                  DISPLAY_DATE_FORMAT_LONG,
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions>
              <Descriptions.Item>{getCancelOrUndoButton()}</Descriptions.Item>
            </Descriptions>
          </>
        );
      } else {
        return (
          <>
            <p>
              <Text type="secondary">
                You are currently enjoying the free version of TLDR Parents.
              </Text>
            </p>
            <p>
              {currentFamily?.num_messages_processed_current_month >=
              currentFamily?.num_messages_allowed_free_tier ? (
                <Text type="danger">
                  Your family has processed{" "}
                  {currentFamily?.num_messages_processed_current_month} messages
                  this month. The free plan entitles you to{" "}
                  {currentFamily?.num_messages_allowed_free_tier} messages per
                  month. If you'd like to upgrade, click the button below.
                </Text>
              ) : (
                <Text type="secondary">
                  Your family has processed{" "}
                  {currentFamily?.num_messages_processed_current_month} messages
                  this month. The free plan entitles you to{" "}
                  {currentFamily?.num_messages_allowed_free_tier} messages per
                  month. If you'd like to upgrade, click the button below.
                </Text>
              )}
            </p>
          </>
        );
      }
    }
  };

  return (
    <>
      <div style={{ paddingTop: 10 }}>
        <h2>Subscription</h2>
      </div>
      {getJsx()}
      <Divider />
    </>
  );
};

export default SettingsSubscription;
