import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import useListMagicEvents from "./useListMagicEvents";
import useBatchGetMagicEvents from "./useBatchGetMagicEvents";
import {
  CODE_DATE_FORMAT,
  getDayJsDatetimeFromStringsGivenTimezones,
} from "../../../utils/dayJsUtils";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import { useQueryClient } from "@tanstack/react-query";

const PAGE_SIZE = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);

const useStateForMagicCalendar = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { currentFamily } = useTldrPageContext();
  const pageNumFromParamsAsString = searchParams.get("page");
  const [searchQueryInfo, setSearchQueryInfo] = useState(null);
  const [currentPageNumForDisplay, setCurrentPageNumForDisplay] =
    useState(null);
  const [eventsAndStartDates, setEventsAndStartDates] = useState(null);
  const [eventsList, setEventsList] = useState([]);
  const [zeroResults, setZeroResults] = useState(null);
  const [searchQueryParams, setSearchQueryParams] = useState({
    offset: 0,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (pageNumFromParamsAsString) {
      if (searchQueryInfo) {
        let pageNumFromParamsAsInt = parseInt(pageNumFromParamsAsString);
        if (pageNumFromParamsAsInt > searchQueryInfo.totalPages) {
          const searchParamsLocal = createSearchParams(searchParams);
          searchParamsLocal.delete("page");
          navigate({ search: searchParamsLocal.toString() });
        }
        if (pageNumFromParamsAsInt <= 0) {
          pageNumFromParamsAsInt = 1;
        }
        setSearchQueryParams({
          offset: (pageNumFromParamsAsInt - 1) * PAGE_SIZE,
        });
      }
    } else {
      setSearchQueryParams({
        offset: 0,
      });
    }
  }, [pageNumFromParamsAsString, searchQueryInfo, navigate, searchParams]);

  const showHidden =
    searchParams.get("show_hidden") === "true" ||
    searchParams.get("show_hidden") === "1";

  const showPast =
    searchParams.get("show_past") === "true" ||
    searchParams.get("show_past") === "1";

  useEffect(() => {
    setSearchQueryParams({
      offset: 0,
    });
  }, [showHidden, showPast]);

  const { data: magicEventSearchQueryResults } = useListMagicEvents(
    showPast,
    showHidden,
    PAGE_SIZE,
    searchQueryParams.offset,
  );

  const { data: magicEventsBySortKey, queryKey: magicEventsBySortKeyQueryKey } =
    useBatchGetMagicEvents(magicEventSearchQueryResults?.items);

  useEffect(() => {
    if (currentFamily && magicEventsBySortKey) {
      const startDateSet = new Set();
      const eventsByStartDateLocal = {};
      const eventsListLocal = [];
      for (const i in magicEventsBySortKey) {
        const eventObject = magicEventsBySortKey[i];
        const startDateString = getDayJsDatetimeFromStringsGivenTimezones(
          eventObject.start_date,
          eventObject.start_time,
          "UTC",
          currentFamily?.timezone || "UTC",
        ).format(CODE_DATE_FORMAT);

        eventsListLocal.push(eventObject);
        startDateSet.add(startDateString);
        if (eventsByStartDateLocal[startDateString]) {
          const eventList = [
            ...eventsByStartDateLocal[startDateString],
            eventObject,
          ];
          eventList.sort(
            (a, b) => a.event_start_timestamp - b.event_start_timestamp,
          );
          eventsByStartDateLocal[startDateString] = eventList;
        } else {
          eventsByStartDateLocal[startDateString] = [eventObject];
        }
      }
      const startDates = Array.from(startDateSet);
      startDates.sort();
      setEventsAndStartDates({
        startDates: startDates,
        eventsByStartDate: eventsByStartDateLocal,
      });
      setEventsList(eventsListLocal);
    }
  }, [magicEventsBySortKey, currentFamily]);

  useEffect(() => {
    if (magicEventSearchQueryResults) {
      setZeroResults(magicEventSearchQueryResults.found === 0);
    }
  }, [magicEventSearchQueryResults]);

  useEffect(() => {
    if (magicEventSearchQueryResults) {
      const pages = Math.ceil(magicEventSearchQueryResults.found / PAGE_SIZE);
      const offset = magicEventSearchQueryResults.offset;
      let currentPageNumForDisplay = offset / PAGE_SIZE + 1;

      const searchQueryInfoLocal = {
        totalPages: pages,
        currentPageNumForDisplay: currentPageNumForDisplay,
      };
      setSearchQueryInfo(searchQueryInfoLocal);
    }
  }, [magicEventSearchQueryResults]);

  useEffect(() => {
    if (pageNumFromParamsAsString) {
      const pageNumFromParamsAsInt = parseInt(pageNumFromParamsAsString);
      if (
        searchQueryInfo?.currentPageNumForDisplay === pageNumFromParamsAsInt
      ) {
        setCurrentPageNumForDisplay(searchQueryInfo?.currentPageNumForDisplay);
      }
    } else {
      setCurrentPageNumForDisplay(searchQueryInfo?.currentPageNumForDisplay);
    }
  }, [pageNumFromParamsAsString, searchQueryInfo]);

  const handleChangePage = (page) => {
    const searchParamsLocal = createSearchParams(searchParams);
    searchParamsLocal.set("page", page.toString());
    navigate({
      search: searchParamsLocal.toString(),
    });
  };

  const handleToggleHidden = () => {
    if (showHidden) {
      const searchParamsLocal = createSearchParams(searchParams);
      searchParamsLocal.delete("show_hidden");
      searchParamsLocal.delete("page");
      navigate({
        search: searchParamsLocal.toString(),
      });
    } else {
      const searchParamsLocal = createSearchParams(searchParams);
      searchParamsLocal.set("show_hidden", "true");
      searchParamsLocal.delete("page");
      navigate({
        search: searchParamsLocal.toString(),
      });
    }
  };

  const handleToggleShowPast = () => {
    const searchParamsLocal = createSearchParams(searchParams);
    if (showPast) {
      searchParamsLocal.delete("show_past");
      searchParamsLocal.delete("page");
      navigate({
        search: searchParamsLocal.toString(),
      });
    } else {
      searchParamsLocal.set("show_past", "true");
      searchParamsLocal.delete("page");
      navigate({
        search: searchParamsLocal.toString(),
      });
    }
  };

  const handleUpdateHiddenStatus = (item) => {
    if (magicEventsBySortKeyQueryKey) {
      queryClient.setQueryData(magicEventsBySortKeyQueryKey, (currentItems) => {
        const filteredItems = { ...currentItems };
        for (const key in currentItems) {
          if (key === item.event_rollup_sort_key) {
            filteredItems[key] = {
              ...filteredItems[key],
              is_hidden: item.is_hidden,
            };
          }
        }
        return filteredItems;
      });
    }
  };

  return {
    zeroResults,
    totalPages: searchQueryInfo?.totalPages,
    currentPageNumForDisplay: currentPageNumForDisplay,
    eventsAndStartDates,
    eventsList,
    pageSize: PAGE_SIZE,
    handleChangePage,
    showHidden,
    showPast,
    handleToggleHidden,
    handleToggleShowPast,
    handleUpdateHiddenStatus,
  };
};

export default useStateForMagicCalendar;
