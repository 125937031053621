import React from "react";

import AnyMiniCard from "../../ecObjectViewers/miniCards/anyMiniCard";
import { getObjectTitle } from "../../ecObjectViewers/actionMappers/classesToActions";

const LinkedArtifactRow = ({ ecObject, label }) => {
  const labelLocal = label ? label : <>{getObjectTitle(ecObject)}</>;

  return ecObject ? (
    <>
      <div style={{ marginBottom: 8 }}>{labelLocal}</div>
      <AnyMiniCard ecObject={ecObject} onClick={null} appContext={null} />
    </>
  ) : (
    <></>
  );
};

export default LinkedArtifactRow;
