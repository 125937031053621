import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import weekday from "dayjs/plugin/weekday";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(weekday);

dayjs.extend(customParseFormat);

export const CODE_DATE_FORMAT = "YYYY-MM-DD";
export const CODE_TIME_FORMAT = "HH:mm";
export const CODE_DATETIME_FORMAT = `${CODE_DATE_FORMAT} ${CODE_TIME_FORMAT}`;
export const DUMMY_DATE_STRING = dayjs().format("YYYY-MM-DD");
export const DUMMY_TIME_STRING = "00:00";
export const DISPLAY_DATE_FORMAT_LONG = "ddd, MMM D, YYYY";
export const DISPLAY_TIME_FORMAT = "h:mm a";
export const DISPLAY_DATETIME_FORMAT_LONG = `${DISPLAY_DATE_FORMAT_LONG} ${DISPLAY_TIME_FORMAT}`;
export const DISPLAY_DATETIME_WITH_SECONDS_FORMAT_LONG = `${DISPLAY_DATE_FORMAT_LONG} h:mm:ss a`;

export const getDayJsDatetimeFromStrings = (dateAsString, timeAsString) => {
  if (dateAsString && timeAsString) {
    return dayjs(`${dateAsString} ${timeAsString}`, CODE_DATETIME_FORMAT);
  } else if (dateAsString) {
    return dayjs(`${dateAsString} ${DUMMY_TIME_STRING}`, CODE_DATETIME_FORMAT);
  } else if (timeAsString) {
    return dayjs(`${DUMMY_DATE_STRING} ${timeAsString}`, CODE_DATETIME_FORMAT);
  } else {
    console.log(
      `Error combining strings (${dateAsString}, ${timeAsString}) to form datetime.`,
    );
  }
};

export const getDayJsDatetimeFromStringsGivenTimezones = (
  dateAsString,
  timeAsString,
  timezoneInterpret,
  timezoneDisplay,
) => {
  if (dateAsString && timeAsString) {
    return dayjs
      .tz(
        `${dateAsString} ${timeAsString}`,
        CODE_DATETIME_FORMAT,
        timezoneInterpret,
      )
      .tz(timezoneDisplay);
  } else if (dateAsString) {
    return dayjs(`${dateAsString} ${DUMMY_TIME_STRING}`, CODE_DATETIME_FORMAT);
  } else if (timeAsString) {
    return dayjs
      .tz(
        `${DUMMY_DATE_STRING} ${timeAsString}`,
        CODE_DATETIME_FORMAT,
        timezoneInterpret,
      )
      .tz(timezoneDisplay);
  } else {
    console.log(
      `Error combining strings (${dateAsString}, ${timeAsString}) to form datetime.`,
    );
  }
};

export const toDateString = (daysJs) => {
  return daysJs.format(CODE_DATE_FORMAT);
};

export const toTimeString = (daysJs) => {
  return daysJs.format(CODE_TIME_FORMAT);
};

export const timestampToFullDatetimeString = (ts) => {
  return dayjs(ts).format(DISPLAY_DATETIME_FORMAT_LONG);
};

export const convertWeekStringToHumanReadable = (week) => {
  const splitWeek = week.split(" ");
  let startDate = new Date(`${splitWeek[0]}T00:00:00.000Z`);
  const userTimezoneOffset = startDate.getTimezoneOffset() * 60000;
  startDate = new Date(startDate.getTime() + userTimezoneOffset);
  const year = startDate.getFullYear();
  const month = startDate.toLocaleString("en-Us", { month: "long" });
  const day = startDate.getDate();
  return `${month} ${day}, ${year}`;
};

export const getFormattedUtcDateInUsersTz = (dt, timeZone) => {
  const dt_with_tz = dayjs.tz(dt, "UTC").tz(timeZone);
  return dt_with_tz.format("ddd, MMMM D, YYYY");
};

export const getFormattedUtcDateTimeInUsersTz = (dt, timeZone) => {
  const dt_with_tz = dayjs.tz(dt, "UTC").tz(timeZone);
  return dt_with_tz.format("ddd, MMMM D, YYYY, h:mm a");
};

export const getShortHumanizedDateTimeForEmail = (dt, usersTimezone) => {
  const now = dayjs().tz(usersTimezone);
  const emailDate = dayjs.tz(dt, "UTC").tz(usersTimezone);

  if (now.isSame(emailDate, "day")) {
    return emailDate.format("h:mm a");
  }

  if (now.subtract(1, "day").isSame(emailDate, "day")) {
    return "Yesterday";
  }

  const diff = now.diff(emailDate, "days", false);
  if (diff < 7) {
    return emailDate.format("dddd");
  }

  return emailDate.format("M/D/YY");
};

export const convertServerUtcDateTimeToUsersTimeZone = (dt, timeZone) => {
  return dayjs.tz(dt, "UTC").tz(timeZone);
};

export const convertUtcDatetimeStringsToTimezoneDatetimeStrings = (
  startDateAsString,
  startTimeAsString,
  endDateAsString,
  endTimeAsString,
  familyTimezone,
) => {
  const startDatetime = getDayJsDatetimeFromStringsGivenTimezones(
    startDateAsString,
    startTimeAsString,
    "UTC",
    familyTimezone,
  );
  const endDatetime = getDayJsDatetimeFromStringsGivenTimezones(
    endDateAsString,
    endTimeAsString,
    "UTC",
    familyTimezone,
  );

  const awareStartDateAsString = startDatetime.format(CODE_DATE_FORMAT);
  let awareStartTimeAsString = startDatetime.format(CODE_TIME_FORMAT);
  const awareEndDateAsString = endDatetime.format(CODE_DATE_FORMAT);
  let awareEndTimeAsString = endDatetime.format(CODE_TIME_FORMAT);

  if (awareStartTimeAsString === "00:00" && awareEndTimeAsString === "00:00") {
    awareStartTimeAsString = null;
    awareEndTimeAsString = null;
  }

  return {
    startDate: awareStartDateAsString,
    startTime: awareStartTimeAsString,
    endDate: awareEndDateAsString,
    endTime: awareEndTimeAsString,
  };
};

export const getDateForDisplayForISO8601 = (dateInAsString) => {
  if (dateInAsString) {
    let dateForDisplay = dateInAsString.slice(0, -3) + "Z";
    return dayjs(dateForDisplay).format(DISPLAY_DATETIME_FORMAT_LONG);
  }
};
