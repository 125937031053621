import { useNavigate, useSearchParams } from "react-router-dom";

const useSearchParamPagination = (paramsWithDefaults) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  let currentPage = 1;
  if (searchParams.get("page")) {
    currentPage = parseInt(searchParams.get("page"));
  }

  let orderBy = paramsWithDefaults["order"];
  if (searchParams.get("order")) {
    orderBy = searchParams.get("order");
  }

  const changeOrderOrFilterAndNavigate = (paramName, paramValue) => {
    if (paramsWithDefaults[paramName] === paramValue) {
      searchParams.delete(paramName);
    } else {
      searchParams.set(paramName, paramValue);
    }
    searchParams.delete("page");
    navigate({
      search: searchParams.toString(),
    });
  };

  const changePage = (newPageNum) => {
    if (newPageNum === 1) {
      searchParams.delete("page");
    } else {
      searchParams.set("page", newPageNum);
    }
    navigate({
      search: searchParams.toString(),
    });
  };

  const getSearchParam = (paramName) => {
    const paramFromUrl = searchParams.get(paramName);
    if (paramFromUrl) {
      return paramFromUrl;
    }
    return paramsWithDefaults[paramName];
  };

  const getSearchParamAsInt = (paramName) => {
    const paramFromUrl = searchParams.get(paramName);
    if (paramFromUrl) {
      return parseInt(paramFromUrl);
    }
    return parseInt(paramsWithDefaults[paramName]);
  };

  const getSearchParamAsBool = (paramName) => {
    const paramFromUrl = searchParams.get(paramName);
    if (paramFromUrl) {
      return paramFromUrl === "true" || paramFromUrl === "1";
    }
    return (
      paramsWithDefaults[paramName] === "true" ||
      paramsWithDefaults[paramName] === "1"
    );
  };

  return {
    currentPage,
    orderBy,
    changeOrderOrFilterAndNavigate,
    changePage,
    getSearchParam,
    getSearchParamAsInt,
    getSearchParamAsBool,
  };
};

export default useSearchParamPagination;
