import React from "react";
import { Alert, Button } from "antd";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import { useNavigate } from "react-router-dom";

const UsageLimitAlert = () => {
  const navigate = useNavigate();
  const { currentFamily } = useTldrPageContext();

  const getMessage = () => {
    if (!currentFamily) {
      return null;
    }
    if (
      currentFamily.plan_name === "free" &&
      currentFamily.num_messages_processed_current_month >=
        currentFamily.num_messages_allowed_free_tier
    ) {
      const message = "You've hit the monthly limit for the free-tier plan.";
      const description = `Your family has processed ${currentFamily.num_messages_processed_current_month} of the allowed ${currentFamily.num_messages_allowed_free_tier} messages. Your account will reset at the beginning of the next month.`;
      return { message: message, description: description };
    }

    if (
      currentFamily.plan_name === "standard" &&
      currentFamily.num_messages_processed_current_month >=
        currentFamily.num_messages_allowed_paid_tier
    ) {
      const message = "You've hit the monthly limit for your plan.";
      const description = `Your family has processed ${currentFamily.num_messages_processed_current_month} of the allowed ${currentFamily.num_messages_allowed_paid_tier} messages. Your account will reset at the beginning of the next month.`;
      return { message: message, description: description };
    }

    return null;
  };

  const getJsx = () => {
    const alertMessaging = getMessage();
    if (alertMessaging) {
      return (
        <Alert
          showIcon={true}
          type="warning"
          message={
            <>
              {alertMessaging?.message}
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={() =>
                  navigate(
                    `/families/${currentFamily.id}/settings/subscription-billing`,
                  )
                }
              >
                Upgrade
              </Button>
            </>
          }
          description={alertMessaging?.description}
          style={{ marginBottom: 10 }}
        />
      );
    }
    return <></>;
  };

  return getJsx();
};

export default UsageLimitAlert;
