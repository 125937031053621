import React from "react";
import DetailPanelSkeleton from "./detailPanelParts/detailPanelSkeleton";
import DetailRow from "./detailPanelParts/detailRow";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import NonHttpLinkCardCover from "../cardCovers/nonHttpLinkCardCover";

const NonHttpLinkDetailPanel = ({ ecObject, actionMenu }) => {
  const getCard = () => {
    return (
      <TldrCardWithCoverComponent
        coverComponent={<NonHttpLinkCardCover ecObject={ecObject} />}
        width={"auto"}
        coverHeight={300}
        hoverable={false}
        onClick={null}
      />
    );
  };

  return (
    <DetailPanelSkeleton
      position1={getCard()}
      position2={!!actionMenu && actionMenu}
      position3={<DetailRow label={"URL"} value={ecObject?.link_url} />}
    />
  );
};

export default NonHttpLinkDetailPanel;
