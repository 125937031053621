import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";
import useQueryGenericGetWithPost from "../../framework/useQueryGenericGetWithPost";
import { O_SERVICE_BATCH_QUERY_SAVED_OBJECTS_GIVEN_EC_ARTIFACTS } from "../../../serviceUrls/serviceUrlsOrchestrate";
import useOrchestrateServiceUrlHelper from "../useOrchestrateServiceUrlHelper";

const useGetSavedObjectsForEcArtifacts = (ecArtifacts) => {
  const postData = {
    ec_artifact_ids: ecArtifacts?.map((item) => {
      return {
        rollup_class_id: item.class_id,
        rollup_hash_key: item.hash_key,
        rollup_sort_key: item.sort_key || null,
      };
    }),
  };

  const { endpointPath } = useOrchestrateServiceUrlHelper(
    O_SERVICE_BATCH_QUERY_SAVED_OBJECTS_GIVEN_EC_ARTIFACTS,
    {},
  );

  return useQueryGenericGetWithPost(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    endpointPath,
    postData,
    true,
    ecArtifacts?.length > 0 && postData,
  );
};

export default useGetSavedObjectsForEcArtifacts;
