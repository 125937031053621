import useQueryGenericPost from "../../../hooks/framework/useQueryGenericPost";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";
import { O_SERVICE_RUN_ADMIN_JOB } from "../../../serviceUrls/serviceUrlsOrchestrate";

const useRunAdminJob = (onSubmit, onSuccess, onError) => {
  return useQueryGenericPost(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_RUN_ADMIN_JOB,
    null,
    onSubmit,
    onSuccess,
    onError,
    true,
  );
};

export default useRunAdminJob;
