import React from "react";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import MiniCardTitleRow from "../miniCardParts/miniCardTitleRow";
import SummaryBulletCardCover from "../cardCovers/summaryBulletCardCover";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import useGetMessagesForArtifact from "../../../hooks/emailCollector/common/useGetMessagesForArtifact";

const SummaryBulletMiniCard = ({ ecObject, appContext, onClick }) => {
  const { messages } = useGetMessagesForArtifact(
    ecObject?.class_id,
    ecObject?.hash_key,
    ecObject?.sort_key,
  );

  const cover = (
    <SummaryBulletCardCover
      message={messages && messages.length ? messages[0] : null}
      ecObject={ecObject}
      isMini={true}
    />
  );

  return (
    <TldrCardWithCoverComponent
      coverComponent={cover}
      width={250}
      coverHeight={150}
      hoverable={false}
      onClick={onClick}
      bodyPadding={8}
    >
      <MiniCardTitleRow
        titleText={ecObject?.subject}
        tooltipText={"Topic"}
        icon={<TagOutlinedIcon sx={{ color: "#ff4081" }} />}
      />
    </TldrCardWithCoverComponent>
  );
};

export default SummaryBulletMiniCard;
