import useBatchGetArtifacts from "../../../../hooks/emailCollector/useBatchGetArtifacts";
import useListOrchestrateObjects from "../../../../hooks/orchestrate/useListOrchestrateObjects";
import { O_SERVICE_CLIPPINGS_LIST } from "../../../../serviceUrls/serviceUrlsOrchestrate";
import useGetEcArtifactsForSavedObjects from "../useGetEcArtifactsForSavedObjects";
import useRemoveDuplicatesFromClippingScroll from "./useRemoveDuplicatesFromClippingScroll";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";

const useClippingScroll = () => {
  const { openLoadingMessage, openSuccessMessage, openErrorMessage } =
    useTldrPageContext();

  const {
    data: clippings,
    isLoading: isLoadingClippings,
    queryKey,
  } = useListOrchestrateObjects(O_SERVICE_CLIPPINGS_LIST, true);
  const { data: clippingData, isLoading: isLoadingClippingData } =
    useBatchGetArtifacts(clippings);
  const { ecArtifacts } = useGetEcArtifactsForSavedObjects(
    clippings,
    clippingData,
  );

  const { mutate: removeDuplicates } = useRemoveDuplicatesFromClippingScroll(
    queryKey,
    () => {
      openLoadingMessage("removeDuplicates", "Removing duplicates");
    },
    () => {
      openSuccessMessage("removeDuplicates", "Duplicates removed");
    },
    () => {
      openErrorMessage("removeDuplicates", "Error removing duplicates");
    },
  );

  return {
    clippings,
    isLoadingClippings,
    isLoadingClippingData,
    ecArtifacts,
    removeDuplicates,
  };
};

export default useClippingScroll;
