import useQueryGenericGet from "../framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../serviceUrls/baseUrls";
import useOrchestrateServiceUrlHelper from "./useOrchestrateServiceUrlHelper";
import {
  O_SERVICE_CLIPPINGS_GET,
  O_SERVICE_GOOGLE_EVENTS_GET,
  O_SERVICE_TILES_GET,
  O_SERVICE_TODOS_GET,
} from "../../serviceUrls/serviceUrlsOrchestrate";

const getEndpointTemplateUrlForClassId = (classId) => {
  switch (classId) {
    case "todo":
      return O_SERVICE_TODOS_GET;
    case "clipping":
      return O_SERVICE_CLIPPINGS_GET;
    case "google_event":
      return O_SERVICE_GOOGLE_EVENTS_GET;
    case "tile":
      return O_SERVICE_TILES_GET;
    default:
      return null;
  }
};

const useGetAnyOrchestrateObject = (classId, objectId) => {
  const endpointTemplateUrl = getEndpointTemplateUrlForClassId(classId);

  const { endpointPath } = useOrchestrateServiceUrlHelper(endpointTemplateUrl, {
    objectId: objectId,
  });

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    endpointPath || "",
    true,
    [],
    {},
    !!endpointPath,
  );
};

export default useGetAnyOrchestrateObject;
