import useQueryGenericDelete from "../framework/useQueryGenericDelete";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../serviceUrls/baseUrls";
import useOrchestrateServiceUrlHelper from "./useOrchestrateServiceUrlHelper";

const useDeleteOrchestrateObject = (
  endpointTemplateUrl,
  queryKey,
  onSubmit,
  onSuccess,
  onError,
) => {
  const { endpointPath } = useOrchestrateServiceUrlHelper(
    endpointTemplateUrl,
    {},
  );

  return useQueryGenericDelete(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    endpointPath,
    queryKey,
    onSubmit,
    onSuccess,
    onError,
  );
};

export default useDeleteOrchestrateObject;
