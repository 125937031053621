import React from "react";
import { Card, Divider, Form, Space } from "antd";
import DatetimeRangePicker from "./common/datetimePickers/datetimeRangePicker";
import TimeSelector from "./common/datetimePickers/timeSelector";
import TimeSelectorWithPresets from "./common/datetimePickers/timeSelectorWithPresets";
import DatePickerWithPresets from "./common/datetimePickers/datePickerWithPresets";
import TldrDatePicker from "./common/datetimePickers/tldrDatePicker";
import DatetimePicker from "./common/datetimePickers/datetimePicker";
import DatetimePickerWithPresets from "./common/datetimePickers/datetimePickerWithPresets";
import {
  createDatePresets,
  createTimePresets,
} from "./common/datetimePickers/dateTimePickerUtils";

const Testing = () => {
  const [form] = Form.useForm();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Space direction={"vertical"}>
        <h1>Date/Time Range Picker</h1>
        <Card>
          <Form
            name={"form"}
            form={form}
            layout="vertical"
            initialValues={{
              datetimes: {
                date1: "2023-10-20",
                time1: "15:09",
                date2: "2023-10-20",
                time2: "16:09",
              },
            }}
            onValuesChange={(values) => {
              // console.log("parent", values);
            }}
            onFinish={(values) => {
              console.log("onFinish", values);
            }}
          >
            <Form.Item name="datetimes" label="datetimes">
              <DatetimeRangePicker
                showTime={true}
                requireTime={false}
                allowTimeChoice={true}
                datePresets1={createDatePresets(["2023-08-07"])}
                timePresets1={createTimePresets([
                  "00:59",
                  "03:31",
                  "06:15",
                  "10:14",
                  "11:43",
                  "15:32",
                  "20:50",
                  "23:01",
                ])}
                datePresets2={createDatePresets([
                  "2023-08-07",
                  "2023-08-08",
                  "2023-10-13",
                  "2024-12-30",
                ])}
                timePresets2={createTimePresets([
                  "00:59",
                  "03:31",
                  "06:15",
                  "10:14",
                  "11:43",
                  "15:32",
                  "20:50",
                  "23:01",
                ])}
              />
            </Form.Item>
          </Form>
        </Card>

        <Divider />
        <h1>Date / Time Picker with Presets</h1>
        <DatetimePickerWithPresets
          value={{ date: "2023-04-07", time: "01:03" }}
          onChange={(value) => console.log(value)}
          showTime={true}
          requireTime={false}
          allowDateChoice={true}
          allowTimeChoice={true}
          datePresets={createDatePresets([
            "2022-10-20",
            "2024-10-13",
            "2023-12-30",
            "2023-04-07",
          ])}
          timePresets={createTimePresets(["14:55", "23:01", "11:05", "16:45"])}
          editPanelOpen={false}
          onEditPanelOpenChange={(value) => console.log(value)}
          // onEditPanelOpenChange={(value) => console.log(value)}
        />

        <Divider />
        <h1>Date / Time Picker</h1>
        <DatetimePicker
          value={{ date: "2023-04-07", time: "01:03" }}
          onChange={(value) => console.log(value)}
          showTime={true}
          requireTime={false}
          allowTimeChoice={true}
        />

        <Divider />
        <h1>Date Picker with Presets</h1>
        <DatePickerWithPresets
          value={"2023-12-11"}
          width={300}
          onChange={(value) => console.log(value)}
          presets={createDatePresets([
            "2022-10-20",
            "2024-10-13",
            "2023-12-30",
            "2023-04-07",
          ])}
        />

        <Divider />
        <h1>Date Picker </h1>
        <TldrDatePicker
          value={"2023-12-11"}
          width={300}
          onChange={(value) => console.log(value)}
        />

        <Divider />
        <h1>Time Picker with Presets</h1>
        <TimeSelectorWithPresets
          value={"13:45"}
          width={300}
          onChange={(value) => console.log(value)}
          presets={createTimePresets(["14:55", "23:01", "11:05", "16:45"])}
        />

        <Divider />
        <h1>Time Picker</h1>
        <TimeSelector
          value={"12:10"}
          width={300}
          onChange={(value) => console.log(value)}
        />
      </Space>
    </div>
  );
};

export default Testing;
