import React from "react";
import { useNavigate } from "react-router-dom";
import { Tabs } from "antd";

const SecondaryTodoListMenu = ({ familyId, selectedValue }) => {
  const navigate = useNavigate();

  return (
    <Tabs
      style={{ marginBottom: 20 }}
      mode="horizontal"
      activeKey={selectedValue || "open"}
      onChange={(value) => {
        navigate(`/families/${familyId}/todos?completed=${value}`);
      }}
      items={[
        {
          label: "Open",
          key: "open",
        },
        {
          label: "Completed",
          key: "completed",
        },
      ]}
    />
  );
};

export default SecondaryTodoListMenu;
