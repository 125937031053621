import React from "react";
import UserEducationModal from "../../userEducation/userEducationModal";
import { Modal, Typography } from "antd";

const { Text } = Typography;

const GoogleEventsEducationModal = ({ forced, open, onClose }) => {
  const title = "Added to Google Calendar 📅";
  const inner = (
    <>
      <p>
        Whenever you or your partner adds something to Google Calendar through
        TLDR Parents, we keep track of it here. Entries are logged as soon as
        you click the "Open in Google Calendar" button. Even if you don't
        actually add the event, it will still be logged here.
      </p>
      <p>
        <Text strong={true}>Note:</Text> This is just a log of the events you've
        added, and we present it for your convenience. Deleting an entry from
        this log won't affect your actual Google Calendar as TLDR Parents has no
        access.
      </p>
    </>
  );

  if (!!forced) {
    return (
      <UserEducationModal title={title} subjects={["EXPLAIN_GOOGLE_EVENTS"]}>
        {inner}
      </UserEducationModal>
    );
  } else {
    return (
      <Modal
        title={title}
        open={open}
        closable={true}
        onCancel={() => onClose()}
        onOk={null}
        footer={null}
      >
        {inner}
      </Modal>
    );
  }
};

export default GoogleEventsEducationModal;
