import useQueryGenericGet from "../../framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../serviceUrls/baseUrls";
import { EC_SERVICE_MESSAGES_FOR_ARTIFACT } from "../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../useEmailCollectorServiceUrlHelper";

const useGetMessagesForArtifact = (classId, hashKey, sortKey) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_MESSAGES_FOR_ARTIFACT,
    { classId, hashKey, sortKey },
  );

  const { data: messages } = useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    {},
    !!(classId && hashKey && currentFamily),
  );

  return { messages };
};

export default useGetMessagesForArtifact;
