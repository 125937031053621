import React from "react";

const AnchorNoPropagation = ({ onClick, children }) => {
  return (
    <a
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        onClick(e);
      }}
    >
      {children}
    </a>
  );
};

export default AnchorNoPropagation;
