import { useEffect, useRef, useState } from "react";
import useGetMessageContent from "../../../../../hooks/emailCollector/messages/useGetMessageContent";
import useGetGmailMessage from "../../../../../hooks/emailCollector/messages/useGetGmailMessage";

const useSimpleMessage = (uniqueMessageId) => {
  const [htmlAsString, setHtmlAsString] = useState("");

  const { data: messageContent } = useGetMessageContent(uniqueMessageId);
  const { data: messageObject } = useGetGmailMessage(uniqueMessageId);
  const refContainer = useRef(null);

  useEffect(() => {
    if (messageContent?.body_indexed) {
      setHtmlAsString(messageContent.body_indexed);
    }
  }, [messageContent]);

  return {
    messageObject,
    messageContent,
    htmlAsString,
    refContainer,
  };
};

export default useSimpleMessage;
