import React, { useEffect, useState } from "react";
import HtmlStringToReactComponent from "../../../common/generic/htmlStringToReactComponent";
import useSimpleMessage from "./hooks/useSimpleMessage";
import MessageInfoForSimpleMessageViewer from "./messageInfoForSimpleMessageViewer";
import { Button, Col, Divider, Row, Skeleton, Space } from "antd";
import { useNavigate } from "react-router-dom";
import useTldrViewMessageContext from "../hooks/useTldrViewMessageContext";

const SimpleMessageViewer = ({
  uniqueMessageId,
  scrollToFirstHighlight,
  tlecIndexesToHighlight,
}) => {
  const navigate = useNavigate();

  const { familyId } = useTldrViewMessageContext();
  const { messageObject, htmlAsString, refContainer } =
    useSimpleMessage(uniqueMessageId);

  const [tlecIndexesToHighlightState, setTlecIndexesToHighlightState] =
    useState([]);

  useEffect(() => {
    if (tlecIndexesToHighlight) {
      const tlecIndexesToHighlightStateLocal = tlecIndexesToHighlight.filter(
        (item) => {
          return item > 1;
        },
      );
      setTlecIndexesToHighlightState(tlecIndexesToHighlightStateLocal);
    }
  }, [tlecIndexesToHighlight]);

  const applyHighlights = () => {
    const containedIndexes = [];
    tlecIndexesToHighlightState.forEach((index) => {
      const element = document.querySelector(`[tlec-index="${index}"]`);
      tlecIndexesToHighlightState.forEach((innerIndex) => {
        if (index !== innerIndex) {
          const innerElement = document.querySelector(
            `[tlec-index="${innerIndex}"]`,
          );
          if (element && element.contains(innerElement)) {
            containedIndexes.push(innerIndex);
          }
        }
      });
    });

    tlecIndexesToHighlightState.forEach((index) => {
      if (!containedIndexes.includes(index)) {
        const element = document.querySelector(`[tlec-index="${index}"]`);
        if (element) {
          element.classList.add("tldr-message-highlight");
          element.style.border = null;
          const children = element.querySelectorAll("*");
          children.forEach((child) => {
            child.classList.add("tldr-message-highlight-child");
          });
        }
      }
    });
  };

  const scrollToElementWithId = () => {
    if (refContainer.current) {
      refContainer.current
        ?.querySelector(".tldr-message-highlight")
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      <Row justify={"space-between"} gutter={[16, 16]}>
        <Col>
          <MessageInfoForSimpleMessageViewer messageObject={messageObject} />
        </Col>
        <Col align={"right"}>
          <Space direction={"vertical"}>
            {tlecIndexesToHighlightState?.length > 0 && (
              <Button
                type={"primary"}
                style={{ width: 150 }}
                onClick={() => scrollToElementWithId()}
              >
                Scroll to highlight
              </Button>
            )}
            <Button
              type={"primary"}
              style={{ width: 150 }}
              ghost={true}
              onClick={() => {
                navigate(
                  `/families/${familyId}/smart-inbox/${messageObject.tenant_id}-${messageObject.unique_tenancy_message_id}`,
                );
              }}
            >
              Go to message
            </Button>
          </Space>
        </Col>
      </Row>

      <Divider />
      {htmlAsString ? (
        <HtmlStringToReactComponent
          htmlAsString={htmlAsString}
          onComponentDidMount={() => {
            applyHighlights();
            if (scrollToFirstHighlight) {
              scrollToElementWithId();
            }
          }}
          attachments={messageObject?.attachments}
          ref={refContainer}
        />
      ) : (
        <Skeleton active={true} />
      )}
    </>
  );
};

export default SimpleMessageViewer;
