import React from "react";
import TldrCardWithCoverImage from "../../common/generic/tldrCardWithCoverImage";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import MiniCardTitleRow from "../miniCardParts/miniCardTitleRow";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { Space } from "antd";

const LinkMiniCard = ({ ecObject, appContext, onClick }) => {
  const imageSrc = ecObject?.link_preview_signed_url
    ? ecObject?.link_preview_signed_url
    : ecObject?.link_preview_summary?.image_src;

  return (
    <TldrCardWithCoverImage
      imageSrc={imageSrc}
      coverHeight={150}
      imageSize={null}
      width={250}
      alt={""}
      preview={false}
      hoverable={false}
      bodyPadding={8}
      onClick={onClick}
    >
      <MiniCardTitleRow
        titleText={ecObject?.link_preview_summary?.title}
        tooltipText={"Link + Image"}
        icon={
          <Space>
            <LinkOutlinedIcon sx={{ color: "#ff4081" }} />
            <ImageOutlinedIcon sx={{ color: "#ff4081" }} />
          </Space>
        }
      />
    </TldrCardWithCoverImage>
  );
};

export default LinkMiniCard;
