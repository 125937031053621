import { BACKEND_API_BASE_URL_URL_CENTRAL } from "../../serviceUrls/baseUrls";
import useQueryGenericGetWithPost from "../framework/useQueryGenericGetWithPost";
import { UC_SERVICE_URL_REQUESTS_REQUEST_IDS } from "../../serviceUrls/serviceUrlsUrlCentral";

const useBatchGetUrlRequests = (requestIds, enabled) => {
  return useQueryGenericGetWithPost(
    BACKEND_API_BASE_URL_URL_CENTRAL,
    UC_SERVICE_URL_REQUESTS_REQUEST_IDS,
    requestIds,
    true,
    enabled,
  );
};

export default useBatchGetUrlRequests;
