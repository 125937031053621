import React from "react";
import { Button, Typography } from "antd";

const { Text } = Typography;

const ProcessFirstMessage = ({ onDone }) => {
  //
  //
  // Forward the email to email@not.on.localhost.tldrparents.com. Your message will be processed in about a minute.
  //
  //

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <Text type="secondary">
          Find a recent email from your children's schools or extracurricular
          activities. For the best first-time TLDR Parents experience, this
          initial email should be relatively detailed, mentioning upcoming
          events and including a few links.
        </Text>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Text type="secondary">
          Forward the email to{" "}
          <Text strong={true}>
            {process.env.REACT_APP_FORWARD_EMAIL_ADDRESS}
          </Text>
          . We'll work our magic on your message and notify you via email once
          the processing is complete. Takes about a minute. Stay tuned!
        </Text>
      </div>

      <Button
        size={"large"}
        type={"primary"}
        style={{ width: "100%" }}
        onClick={() => {
          onDone();
        }}
      >
        Go to my workspace!
      </Button>
    </>
  );
};

export default ProcessFirstMessage;
