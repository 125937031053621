import React, { useEffect, useState } from "react";
import { Checkbox, Space } from "antd";
import TimeSelector from "./timeSelector";
import TldrDatePicker from "./tldrDatePicker";
import {
  getCurrentDateString,
  getCurrentTimeStringFlooredToHour,
} from "./dateTimePickerUtils";

const DatetimePicker = ({
  value,
  onChange,
  showTime,
  requireTime,
  allowTimeChoice,
}) => {
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [selectedTime, setSelectedTime] = useState(undefined);
  const [showTimeState, setShowTimeState] = useState(showTime);

  useEffect(() => {
    if (value?.date) {
      setSelectedDate(value.date);
    } else {
      setSelectedDate(getCurrentDateString());
    }
    if (value?.time) {
      setSelectedTime(value.time);
    } else {
      setSelectedTime(getCurrentTimeStringFlooredToHour());
    }
  }, [value]);

  useEffect(() => {
    setShowTimeState((showTime && allowTimeChoice) || requireTime);
  }, [showTime, requireTime, allowTimeChoice]);

  const reportOnChangeToParent = (date, time, showTime) => {
    const data = {
      date: date,
    };
    if (showTime) {
      data.time = time;
    }
    onChange?.(data);
  };

  return (
    <Space direction="vertical">
      {allowTimeChoice && !requireTime ? (
        <Checkbox
          checked={showTimeState}
          onChange={(value) => {
            reportOnChangeToParent(selectedDate, selectedTime, !showTimeState);
            setShowTimeState(!showTimeState);
          }}
        >
          All day
        </Checkbox>
      ) : (
        <></>
      )}
      <Space>
        <TldrDatePicker
          value={selectedDate}
          width={150}
          onChange={(value) => {
            reportOnChangeToParent(value, selectedTime, showTimeState);
            setSelectedDate(value);
          }}
        />
        {(showTimeState && allowTimeChoice) || requireTime ? (
          <>
            <TimeSelector
              value={selectedTime}
              width={150}
              onChange={(value) => {
                reportOnChangeToParent(selectedDate, value, showTimeState);
                setSelectedTime(value);
              }}
            />
          </>
        ) : (
          <></>
        )}
      </Space>
    </Space>
  );
};

export default DatetimePicker;
