import React from "react";
import ResponsiveCard from "./responsiveCard";

const CardCenteredTextCover = ({ onClick, children }) => {
  return (
    <div
      style={{
        cursor: onClick ? "pointer" : null,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
      }}
      onClick={onClick}
    >
      <div
        style={{
          width: "100%",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <ResponsiveCard>{children}</ResponsiveCard>
      </div>
    </div>
  );
};

export default CardCenteredTextCover;
