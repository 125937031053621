import useQueryGenericGet from "../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";
import { O_SERVICE_INVITES_FOR_CURRENT_USER } from "../../../serviceUrls/serviceUrlsOrchestrate";

const useListInvitesForCurrentUser = () => {
  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_INVITES_FOR_CURRENT_USER,
    true,
    [],
    {},
    true,
  );
};

export default useListInvitesForCurrentUser;
