import useQueryGenericGetAction from "../../framework/useQueryGenericGetAction";
import { O_SERVICE_RUN_SYNC_GMAIL } from "../../../serviceUrls/serviceUrlsOrchestrate";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";
import useOrchestrateServiceUrlHelper from "../useOrchestrateServiceUrlHelper";

const useRunSyncGmail = (onSubmit, onSuccess, onError) => {
  const { endpointPath } = useOrchestrateServiceUrlHelper(
    O_SERVICE_RUN_SYNC_GMAIL,
    {},
  );

  return useQueryGenericGetAction(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    endpointPath,
    onSubmit,
    onSuccess,
    onError,
  );
};

export default useRunSyncGmail;
