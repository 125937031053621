import React from "react";
import UserEducationModal from "../../userEducation/userEducationModal";
import { Modal } from "antd";

const FilesPlusRollupUserEducationModal = ({ forced, open, onClose }) => {
  const title = "Link Gallery 🔑";
  const inner = (
    <>
      <p>
        For every email you forward to TLDR Parents, we sprinkle some magic to
        automatically pull out all attachments, links, and images. We even
        create beautiful visual previews for links and tidy up duplicates.
      </p>
      <p>
        School emails can be a jungle of links, but don’t worry--we've got your
        back. We display the most important stuff by default, like maps, Google
        Docs, forms, and Zoom links. On the hunt for something specific? Just
        use the filter dropdown to find what you need in a snap!
      </p>
      <h3>Workflow Tips 🔧</h3>
      <ul>
        <li>
          Behind on emails? Head over to Link Gallery to quickly find important
          things like forms you might need to complete.
        </li>
        <li>
          Need to save something for later? Just click the "Actions" button to
          create a to-do or pin an item to a board.
        </li>
      </ul>
    </>
  );

  if (!!forced) {
    return (
      <UserEducationModal title={title} subjects={["EXPLAIN_FILES_PLUS"]}>
        {inner}
      </UserEducationModal>
    );
  } else {
    return (
      <Modal
        title={title}
        open={open}
        closable={true}
        onCancel={() => onClose()}
        onOk={null}
        footer={null}
      >
        {inner}
      </Modal>
    );
  }
};

export default FilesPlusRollupUserEducationModal;
