import React, { useEffect } from "react";
import { Col, Divider, Row, Space, Typography } from "antd";
import { AppContextEmailRollupFeed } from "../../ecObjectViewers/actionMappers/appContextsToActions";
import PaddedSpinner from "../../common/generic/paddedSpinner";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import useListQueryRollup from "../hooks/useListQueryRollup";
import EcObjectCardsContainer from "../../ecObjectViewers/ecObjectCardsContainer";
import { convertWeekStringToHumanReadable } from "../../../utils/dayJsUtils";
import useSearchParamPagination from "../../../hooks/utils/useSearchParamPagination";
import SortSelect from "../../common/commonTldr/sortSelect";
import HelpIconAndModal from "../../helpIconAndModal";
import StandardPagination from "../../common/generic/standardPagination";
import EmailAddressesRollupEducationModal from "./emailAddressesRollupEducationModal";

const { Text } = Typography;

const PAGE_SIZE = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);

const EmailAddressesRollup = () => {
  const {
    currentPage,
    orderBy,
    changePage,
    getSearchParam,
    changeOrderOrFilterAndNavigate,
  } = useSearchParamPagination({
    order: "message_date",
    native_tag: "email_address",
  });

  const { setBreadcrumbs, setBreadcrumbExtra } = useTldrPageContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Email Address List",
      },
    ]);
    setBreadcrumbExtra(
      <HelpIconAndModal
        educationModalClass={EmailAddressesRollupEducationModal}
      />,
    );
  }, [setBreadcrumbs, setBreadcrumbExtra]);

  const { data: listQueryData, isLoading } = useListQueryRollup(
    getSearchParam("native_tag"),
    null,
    PAGE_SIZE,
    (currentPage - 1) * PAGE_SIZE,
    orderBy,
    true,
  );

  const getPagination = () => {
    if (!listQueryData) {
      return <></>;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <div></div>
        <StandardPagination
          current={currentPage}
          pageSize={PAGE_SIZE}
          onChange={changePage}
          total={listQueryData.found}
        />
      </div>
    );
  };

  const getSelectDropdownsSection = () => {
    return (
      <Row justify={"start"} gutter={[15, 15]} style={{ marginBottom: 15 }}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space
            size={"small"}
            style={{ width: "100%" }}
            direction={"vertical"}
          >
            <div>
              <Text strong={true}>Sort</Text>
            </div>
            <SortSelect
              orderByValue={orderBy}
              onChange={(selectedItem) => {
                changeOrderOrFilterAndNavigate("order", selectedItem);
              }}
              selectStyle={{ width: "100%" }}
            />
          </Space>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <EmailAddressesRollupEducationModal forced={true} />
      {getSelectDropdownsSection()}
      {getPagination()}
      {listQueryData?.weeks?.map((week, index) => {
        const humanReadableDate = convertWeekStringToHumanReadable(week);
        let foundInMessagesText = `Found in messages sent during the week of ${humanReadableDate}`;
        if (orderBy === "submission_date") {
          foundInMessagesText = `Found in messages submitted during the week of ${humanReadableDate}`;
        }

        if (Object.keys(listQueryData.items_by_week).length > 0) {
          return (
            <div key={index}>
              <div style={{ marginBottom: 30 }}>
                <h2>{foundInMessagesText}</h2>
                <EcObjectCardsContainer
                  ecObjects={listQueryData.items_by_week[week]}
                  appContext={AppContextEmailRollupFeed}
                  cardProperties={null}
                  showClickDetailsTag={true}
                  scrollToFirstHighlight={false}
                />
              </div>
              <Divider />
            </div>
          );
        } else {
          return <span key={index} />;
        }
      })}
      {isLoading ? <PaddedSpinner /> : <></>}
      {getPagination()}
    </>
  );
};

export default EmailAddressesRollup;
