import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "./apiClient";
import { replacePlaceholdersInTemplate } from "../../serviceUrls/serviceUrlHelpers";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../serviceUrls/baseUrls";

const useQueryGenericPut = (
  baseUrl,
  endpoint,
  onSubmit,
  onSuccess,
  onError,
) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);

  return useMutation({
    mutationFn: async (data) => {
      const { idz, ...restOfData } = data;
      const token = await getAccessTokenSilently();
      const finalEndpoint = replacePlaceholdersInTemplate(endpoint, {
        objectId: idz,
      });

      return getApiClientWithBaseUrl(
        BACKEND_API_BASE_URL_ORCHESTRATE,
        finalEndpoint,
        token,
      ).put(restOfData);
    },

    onMutate: (unsaved) => {
      if (onSubmit) onSubmit(unsaved);
    },

    onSuccess: (saved, submitted) => {
      if (onSuccess) onSuccess(saved);
    },

    onError: (error, unsaved, context) => {
      if (onError) onError(error);
    },
  });
};

export default useQueryGenericPut;
