import React from "react";
import EmailAddress from "./message/emailAddress";

const EmailAddresses = ({ addresses }) => {
  return addresses.map((address, index) => {
    return (
      <span key={index}>
        <EmailAddress
          label={address?.[1]}
          email={address?.[0]}
          useString={false}
        />
        {index < addresses.length - 1 && <>, </>}
      </span>
    );
  });
};

export default EmailAddresses;
