import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0GoogleScopesProviderContext from "../../context/auth0GoogleScopesProviderContext";
import gmailConnectImage from "../../images/gmail.png";
import useGetGoogleAndGmailConnectionInfo from "./hooks/useGetGoogleAndGmailConnectionInfo";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";

const ConnectGmail = ({ onComplete }) => {
  const { data: currentUser } = useGetCurrentUser();
  const { loginWithPopup } = useAuth0(Auth0GoogleScopesProviderContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    isLoading: isLoadingGoogleAuthTokenInfo,
    isGmailConnected,
    googleAuthTokenInfo,
    refetchAfterUserAction,
  } = useGetGoogleAndGmailConnectionInfo();

  useEffect(() => {
    if (!isLoadingGoogleAuthTokenInfo) {
      if (isGmailConnected) {
        onComplete();
      }
    }
  }, [isLoadingGoogleAuthTokenInfo, isGmailConnected]);

  const handleClickAddGMailScope = async () => {
    setIsSubmitting(true);
    await loginWithPopup({
      authorizationParams: { login_hint: currentUser?.email },
    });

    await refetchAfterUserAction();
    setIsSubmitting(false);
  };

  const getAllSetState = () => {
    return (
      <>
        {googleAuthTokenInfo.email}
        <Button
          danger
          style={{ marginLeft: 10 }}
          target="_blank"
          href="https://myaccount.google.com/permissions"
        >
          Disconnect
        </Button>
      </>
    );
  };

  const getLoadingState = () => {
    return <>Checking Gmail connection...</>;
  };

  const getDisconnectedState = () => {
    return (
      <Button
        onClick={handleClickAddGMailScope}
        loading={isSubmitting}
        icon={
          <img
            style={{ cursor: "pointer", width: 25, marginRight: 5 }}
            src={gmailConnectImage}
            alt="Connect Gmail"
          />
        }
      >
        Connect Gmail
      </Button>
    );
  };

  const getJsx = () => {
    if (isLoadingGoogleAuthTokenInfo) {
      return getLoadingState();
    } else if (isGmailConnected) {
      return getAllSetState();
    } else {
      return getDisconnectedState();
    }
  };

  return <>{getJsx()}</>;
};

export default ConnectGmail;
