import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "./apiClient";
import { createQueryKey } from "../../utils/utils";

const useQueryGenericGetWithPost = (
  baseUrl,
  endpoint,
  postData,
  sendAuthToken,
  enabled,
) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);

  if (!sendAuthToken) sendAuthToken = false;

  const queryKey = createQueryKey(baseUrl, endpoint, [], postData || {});

  return {
    ...useQuery({
      enabled: enabled !== false,
      queryKey: queryKey,
      queryFn: async () => {
        let token = undefined;
        if (sendAuthToken) {
          token = await getAccessTokenSilently();
        }
        const client = getApiClientWithBaseUrl(baseUrl, endpoint, token);
        return client.post(postData);
      },
      staleTime: 0,
      placeholderData: keepPreviousData,
    }),
    enabled: enabled,
    queryKey: queryKey,
  };
};

export default useQueryGenericGetWithPost;
