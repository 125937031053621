import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "./apiClient";
import { replacePlaceholdersInTemplate } from "../../serviceUrls/serviceUrlHelpers";

const useQueryGenericGetAction = (
  baseUrl,
  endpoint,
  onSubmit,
  onSuccess,
  onError,
) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);

  return useMutation({
    mutationFn: async (replacementPathParams) => {
      const endpointPath = replacePlaceholdersInTemplate(
        endpoint,
        replacementPathParams,
      );
      const token = await getAccessTokenSilently();
      const client = getApiClientWithBaseUrl(baseUrl, endpointPath, token);
      return client.get();
    },

    onMutate: (unsaved) => {
      if (onSubmit) onSubmit(unsaved);
    },

    onSuccess: (saved, submitted) => {
      if (onSuccess) onSuccess(saved);
    },

    onError: (error, unsaved, context) => {
      if (onError) onError(error);
    },
  });
};

export default useQueryGenericGetAction;
