import React, { useState } from "react";
import { Popover, Space, Typography } from "antd";
import { Link, useParams } from "react-router-dom";
import horizontalLogo from "../../images/tldrparents-logo-horizontal.svg";
import HeaderAvatar from "./headerAvatar";
import UserInfoPopoverCard from "./userInfoPopoverCard";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PrimaryFamilyNavDrawer from "./primaryFamilyNavDrawer";
import PrimaryMeNavDrawer from "./primaryMeNavDrawer";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";
import useSimpleFamilyDetails from "../../hooks/orchestrate/user/useSimpleFamilyDetails";

const TopBar = ({ isMeMode }) => {
  const { familyId } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);

  const { data: currentUser } = useGetCurrentUser();
  const currentFamily = useSimpleFamilyDetails(familyId, currentUser);

  useTldrPageContext();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 10px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <MenuOutlinedIcon
            style={{ fontSize: 30, color: "grey" }}
            onClick={() => setMenuOpen(true)}
          />
          <Link to={familyId ? `/families/${familyId}` : "/"}>
            <img
              style={{
                maxHeight: 75,
              }}
              src={horizontalLogo}
              alt="TLDR Parents"
            />
          </Link>
          <Typography.Text
            style={{
              position: "relative",
              color: "#ff4081",
              left: -15,
              top: 20,
            }}
          >
            BETA
          </Typography.Text>
        </div>
        <div>
          <Space>
            {isMeMode === false && !!currentFamily && (
              <div
                style={{
                  fontSize: 24,
                  fontWeight: 300,
                  marginRight: 5,
                  color: "grey",
                }}
                className={"hide-on-small-screen"}
              >
                {currentFamily.name}
              </div>
            )}
            <Popover placement="bottomRight" content={<UserInfoPopoverCard />}>
              <a onClick={(e) => e.preventDefault()}>
                <HeaderAvatar />
              </a>
            </Popover>
          </Space>
        </div>
      </div>
      {isMeMode === true ? (
        <PrimaryMeNavDrawer
          open={menuOpen}
          onSelectAndNavigate={() => setMenuOpen(false)}
          onClose={() => setMenuOpen(false)}
        />
      ) : (
        <PrimaryFamilyNavDrawer
          open={menuOpen}
          onSelectAndNavigate={() => setMenuOpen(false)}
          onClose={() => setMenuOpen(false)}
        />
      )}
    </>
  );
};

export default TopBar;
