import React from "react";
import { Alert, Col, Row, Space, Typography } from "antd";
import ArtifactCard from "../common/generic/artifactCard";
import LinkPreview from "../common/generic/linkPreview";
import { dedupItems } from "./inboundForwardUtils";
import AnchorNoPropagation from "../common/generic/anchorNoPropagation";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { getQueryParams } from "../../utils/utils";

const InboundForwardLinks = ({
  links,
  linkImages,
  onClickViewInMessage,
  onClickHelpIcon,
}) => {
  const getCleanedLinks = () => {
    const linksLocal = dedupItems(links || []);
    const linkImagesLocal = dedupItems(linkImages || []);
    let combinedLinks = linksLocal.concat(linkImagesLocal);

    if (combinedLinks.length === 0) {
      return [];
    }

    const linkTypesToShow = [
      "tldr",
      "amazon",
      "apple_app_store",
      "google_photos",
      "google_play",
    ];
    combinedLinks = combinedLinks.filter((item) => {
      const intersection = item.rollup.native_tags.filter(function (n) {
        return linkTypesToShow.indexOf(n) !== -1;
      });
      return intersection.length > 0;
    });

    const finalUrlSet = new Set();
    combinedLinks = combinedLinks.filter((item) => {
      let finalUrl;
      if (item.rollup.class_id === "link_rollup") {
        finalUrl = item.rollup.final_url;
      } else {
        finalUrl = item.rollup.link_final_url;
      }
      if (finalUrlSet.has(finalUrl)) {
        return false;
      } else {
        finalUrlSet.add(finalUrl);
        return true;
      }
    });
    return combinedLinks;
  };

  const getLinkCard = (link) => {
    if (link.rollup && link.rollup.preview_summary.image_src) {
      const linkUrl = link.rollup.final_url;
      const imageSrc = link.rollup.preview_summary.image_src;

      let cover;
      if (linkUrl.startsWith("https://drive.google.com/file/d/")) {
        cover = (
          <div className="iframe-wrapper">
            <iframe
              src={linkUrl.replace("/view", "/preview")}
              allow="autoplay"
            />
          </div>
        );
      } else if (linkUrl.startsWith("https://www.youtube.com/watch")) {
        const embedId = getQueryParams(linkUrl)["v"];
        return (
          <div className="iframe-wrapper">
            <iframe
              src={`https://www.youtube-nocookie.com/embed/${embedId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>
        );
      } else {
        cover = (
          <LinkPreview
            href={linkUrl}
            title={link.rollup.preview_summary.title}
            domain={link.rollup.preview_summary.fqdn}
            imageSrc={imageSrc}
            contentType={link.rollup.content_type}
            coverHeight={243}
          />
        );
      }

      return (
        <ArtifactCard
          cover={cover}
          position1={null}
          position2={null}
          position3={
            <AnchorNoPropagation onClick={() => onClickViewInMessage(link)}>
              View in Message
            </AnchorNoPropagation>
          }
        />
      );
    } else {
      return <></>;
    }
  };

  const getLinkImageCard = (linkImage) => {
    if (linkImage.rollup && linkImage.rollup.link_preview_summary.image_src) {
      const linkUrl = linkImage.rollup.link_final_url;
      const imageSrc = linkImage.obj.image_final_url;

      let cover;
      if (linkUrl.startsWith("https://drive.google.com/file/d/")) {
        cover = (
          <div className="iframe-wrapper">
            <iframe
              src={linkUrl.replace("/view", "/preview")}
              allow="autoplay"
            />
          </div>
        );
      } else if (linkUrl.startsWith("https://www.youtube.com/watch")) {
        const embedId = getQueryParams(linkUrl)["v"];
        return (
          <div className="iframe-wrapper">
            <iframe
              src={`https://www.youtube-nocookie.com/embed/${embedId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>
        );
      } else {
        cover = (
          <LinkPreview
            href={linkUrl}
            title={linkImage.rollup.link_preview_summary.title}
            domain={linkImage.rollup.link_preview_summary.fqdn}
            imageSrc={imageSrc}
            contentType={linkImage.rollup.content_type}
            coverHeight={243}
          />
        );
      }

      return (
        <ArtifactCard
          cover={cover}
          position1={null}
          position2={null}
          position3={null}
        />
      );
    } else {
      return <></>;
    }
  };

  const getCard = (item) => {
    const classId = item.rollup.class_id;
    if (classId === "link_rollup") {
      return getLinkCard(item);
    } else {
      return getLinkImageCard(item);
    }
  };

  const getLinks = () => {
    const cleanedLinks = getCleanedLinks();
    if (cleanedLinks.length === 0) {
      return <></>;
    }

    return (
      <>
        <Space style={{ marginBottom: 5 }}>
          <h2>Key Links</h2>
          <HelpOutlineIcon
            sx={{ fontSize: 25, color: "#ff4081", cursor: "pointer" }}
            onClick={() => onClickHelpIcon("links")}
          />
        </Space>
        <Alert
          message={
            <Typography.Text strong={true}>
              Never lose track of key info. <a href={"/login"}>Sign up</a> and
              pin any of these links to a Pinterest-style board. 📌
            </Typography.Text>
          }
          type={"warning"}
          style={{ marginBottom: 10 }}
        />
        <Row gutter={[12, 12]}>
          {cleanedLinks.map((link, index) => {
            return (
              <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6} key={index}>
                {getCard(link)}
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  return getLinks();
};

export default InboundForwardLinks;
