export const getUrlToArtifactDetails = (
  familyId,
  classId,
  hashKey,
  sortKey,
) => {
  if (!familyId || !classId || !hashKey) {
    return null;
  }

  if (sortKey) {
    return `${
      process.env.REACT_APP_BASE_URL
    }/families/${familyId}/artifacts/${classId.replace(
      "_",
      "-",
    )}/${hashKey}/${sortKey}`;
  } else {
    return `${
      process.env.REACT_APP_BASE_URL
    }/families/${familyId}/artifacts/${classId.replace("_", "-")}/${hashKey}`;
  }
};
