import useQueryGenericGet from "../../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../../serviceUrls/baseUrls";
import { EC_SERVICE_MESSAGES_PREV_IDS_GET } from "../../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../../../../hooks/emailCollector/useEmailCollectorServiceUrlHelper";

const useGetPrevMessageIds = (uniqueMessageId, archived, limit, orderBy) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_MESSAGES_PREV_IDS_GET,
    { uniqueMessageId: uniqueMessageId },
  );

  const queryParams = {
    limit: limit,
    order_by: orderBy,
  };
  if (archived) {
    queryParams.archived = "true";
  }

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    queryParams,
    !!(uniqueMessageId && currentFamily),
  );
};

export default useGetPrevMessageIds;
