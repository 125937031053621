import React, { useEffect, useState } from "react";
import useGetMessagesForArtifact from "../../hooks/emailCollector/common/useGetMessagesForArtifact";
import MessagePartOfEcArtifactDetailsPage from "./messagePartOfEcArtifactDetailsPage";
import useUnrollAnyArtifact from "../useUnrollAnyArtifact";
import AnyDetailPanel from "./detailPanels/anyDetailPanel";

const EcArtifactDetailInner = ({
  ecObject,
  scrollToFirstHighlight,
  actionMenu,
}) => {
  const { messages } = useGetMessagesForArtifact(
    ecObject?.class_id,
    ecObject?.hash_key,
    ecObject?.sort_key,
  );

  const { data: componentArtifacts } = useUnrollAnyArtifact(
    ecObject?.class_id,
    ecObject?.hash_key,
    ecObject?.sort_key,
  );

  const [
    tlecIndexesToHighlightByUniqueMessageId,
    setTlecIndexesToHighlightByUniqueMessageId,
  ] = useState({});

  useEffect(() => {
    if (componentArtifacts) {
      if (!ecObject?.class_id.startsWith("attachment")) {
        const tlecIndexes = {};
        for (const i in componentArtifacts) {
          const componentArtifact = componentArtifacts[i];
          let indexes = componentArtifact.indexes;
          if (!indexes) {
            indexes = componentArtifact.tlec_index;
          }

          if (indexes) {
            const uniqueMessageId = componentArtifact.unique_message_id;
            if (!tlecIndexes[uniqueMessageId]) {
              tlecIndexes[uniqueMessageId] = new Set();
            }
            indexes.forEach((item) => tlecIndexes[uniqueMessageId].add(item));
          }
        }

        const tlecIndexesToHighlightByUniqueMessageIdLocal = {};
        for (let umId in tlecIndexes) {
          tlecIndexesToHighlightByUniqueMessageIdLocal[umId] = Array.from(
            tlecIndexes[umId],
          ).sort((a, b) => a - b);
        }
        setTlecIndexesToHighlightByUniqueMessageId(
          tlecIndexesToHighlightByUniqueMessageIdLocal,
        );
      }
    }
  }, [componentArtifacts, ecObject]);

  useEffect(() => {
    if (componentArtifacts && ecObject?.class_id === "summary_bullet") {
      const tlecIndexesToHighlightByUniqueMessageIdLocal = {};
      tlecIndexesToHighlightByUniqueMessageIdLocal[ecObject.unique_message_id] =
        [...ecObject.tlec_index];
      setTlecIndexesToHighlightByUniqueMessageId(
        tlecIndexesToHighlightByUniqueMessageIdLocal,
      );
    }
  }, [componentArtifacts, ecObject]);

  return (
    <>
      <div style={{ paddingBottom: 16 }}>
        <AnyDetailPanel ecObject={ecObject} actionMenu={actionMenu} />
      </div>

      <MessagePartOfEcArtifactDetailsPage
        artifactObject={ecObject}
        messages={ecObject?.class_id === "message" ? [ecObject] : messages} // This is a bit hacky.
        tlecIndexesToHighlightByUniqueMessageId={
          tlecIndexesToHighlightByUniqueMessageId
        }
        scrollToFirstHighlight={scrollToFirstHighlight}
      />
    </>
  );
};

export default EcArtifactDetailInner;
