import HandymanIcon from "@mui/icons-material/Handyman";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

export const Actions = Object.freeze({
  createClippingAction: {
    name: "createClippingAction",
    tooltipTitle: "Copy to workbench",
    icon: HandymanIcon,
    actionType: "create",
    order: 1,
  },
  createEventAction: {
    name: "createEventAction",
    tooltipTitle: "Add to calendar",
    icon: CalendarMonthOutlinedIcon,
    actionType: "create",
    order: 2,
  },
  createTodoItemAction: {
    name: "createTodoItemAction",
    tooltipTitle: "Create to-do",
    icon: FormatListNumberedOutlinedIcon,
    actionType: "create",
    order: 3,
  },
  createTileWithBoardAssociationAction: {
    name: "createTileWithBoardAssociationAction",
    tooltipTitle: "Pin to board",
    icon: PushPinOutlinedIcon,
    actionType: "create",
    order: 4,
  },
  createContactAction: {
    name: "createContactAction",
    tooltipTitle: "Create contact",
    icon: CalendarMonthOutlinedIcon,
    actionType: "create",
    order: 5,
  },
  useImageAction: {
    name: "useImageAction",
    tooltipTitle: "Use image as board cover",
    icon: ImageOutlinedIcon,
    actionType: "create",
    order: 6,
  },
  editTodoAction: {
    name: "editTodoAction",
    tooltipTitle: "Edit to-do",
    icon: EditOutlinedIcon,
    actionType: "edit",
    order: 7,
  },
  editTileAction: {
    name: "editTileAction",
    tooltipTitle: "Edit tile",
    icon: EditOutlinedIcon,
    actionType: "edit",
    order: 8,
  },
  deleteTileBoardAssociationAction: {
    name: "deleteTileBoardAssociationAction",
    tooltipTitle: "Delete tile",
    icon: DeleteOutlineIcon,
    actionType: "delete",
    order: 9,
  },
  deleteClippingAction: {
    name: "deleteClippingAction",
    tooltipTitle: "Delete clipping",
    icon: DeleteOutlineIcon,
    actionType: "delete",
    order: 11,
  },
});
