import useQueryGenericPost from "../framework/useQueryGenericPost";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../serviceUrls/baseUrls";
import useOrchestrateServiceUrlHelper from "./useOrchestrateServiceUrlHelper";

const useCreateOrchestrateObject = (
  endpointTemplateUrl,
  queryKey,
  onSubmit,
  onSuccess,
  onError,
) => {
  const { endpointPath } = useOrchestrateServiceUrlHelper(
    endpointTemplateUrl,
    {},
  );

  return useQueryGenericPost(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    endpointPath,
    queryKey,
    onSubmit,
    onSuccess,
    onError,
    true,
  );
};

export default useCreateOrchestrateObject;
