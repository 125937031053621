import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../serviceUrls/baseUrls";
import { EC_SERVICE_ARTIFACT_ROLLUPS_LIST_FOR_TENANT } from "../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../../../hooks/emailCollector/useEmailCollectorServiceUrlHelper";
import useQueryGenericGet from "../../../hooks/framework/useQueryGenericGet";

const useListQueryRollup = (
  nativeTag,
  fromDomain,
  limit,
  offset,
  orderBy,
  showHidden,
) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_ARTIFACT_ROLLUPS_LIST_FOR_TENANT,
    {},
  );

  const queryParams = {
    limit: limit,
    offset: offset,
    native_tag: nativeTag,
    from_domain: fromDomain,
    order_by: orderBy,
    show_hidden: showHidden,
  };

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    queryParams,
    !!currentFamily,
    0,
    false,
  );
};

export default useListQueryRollup;
