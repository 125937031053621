import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "antd";
import useRunAdminJob from "../hooks/useRunAdminJob";
import useTldrMePageContext from "../hooks/useTldrMePageContext";
import SettingsConnectedAccounts from "./settingsConnectedAccounts";
import MyFamilies from "./myFamilies";
import useIsDebugMode from "../../../hooks/utils/useIsDebugMode";

const JOBS = [
  {
    jobName: "tldrparents-time-to-queue-up-update-emails",
    data: {},
    buttonText: "Send Update Emails",
  },
];

const MyAdmin = () => {
  const { currentUser, openLoadingMessage, openSuccessMessage } =
    useTldrMePageContext();
  const navigate = useNavigate();
  const [isAdminConfirmed, setIsAdminConfirmed] = useState(false);
  const [buttonsLoading, setButtonsLoading] = useState([]);
  const { isDebugMode, isDebugLoading } = useIsDebugMode();

  const runAdminJobHook = useRunAdminJob(
    (postData) => {
      openLoadingMessage(postData.job_name, postData.job_name);
    },
    (response, postData) => {
      openSuccessMessage(postData.job_name, postData.job_name);
      setButtonsLoading(
        buttonsLoading.filter((item) => item !== postData.job_name),
      );
    },
    null,
    false,
  );

  useEffect(() => {
    if (currentUser && !isDebugLoading) {
      if (currentUser.is_admin && isDebugMode) {
        setIsAdminConfirmed(true);
      } else {
        navigate("/me/settings");
      }
    }
  }, [currentUser, navigate, isDebugMode, isDebugLoading]);

  const getAdminJsx = () => {
    return (
      <>
        <SettingsConnectedAccounts />
        <MyFamilies />
        <Card title={"Jobs"}>
          {JOBS.map((job, index) => {
            return (
              <p key={index}>
                <Button
                  loading={buttonsLoading.includes(job.jobName)}
                  type="primary"
                  onClick={() => {
                    const jobDetails = {
                      job_name: job.jobName,
                      data: job.data,
                    };
                    runAdminJobHook.mutate(jobDetails);
                    setButtonsLoading([...buttonsLoading, job.jobName]);
                  }}
                >
                  {job.buttonText}
                </Button>
              </p>
            );
          })}
        </Card>
      </>
    );
  };

  return <>{isAdminConfirmed ? getAdminJsx() : <></>}</>;
};

export default MyAdmin;
