import useQueryGenericGet from "../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_ORCHESTRATE_NOAUTH } from "../../../serviceUrls/baseUrls";

const useGetUnregisteredFamilyInfo = (tenantId) => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE_NOAUTH,
    "/unregistered-family-info",
    false,
    [],
    { tenant_id: tenantId, browser_timezone: browserTimezone },
    !!tenantId,
  );
};

export default useGetUnregisteredFamilyInfo;
