import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Alert, Modal } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";
import PaddedSpinner from "../../../common/generic/paddedSpinner";
import useBillingGet from "../hooks/useBillingGet";
import { O_SERVICE_BILLING_GET_INTENT } from "../../../../serviceUrls/serviceUrlsOrchestrate";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionPaymentModal = ({ open, onOk, onCancel }) => {
  const [statusFromStripe, setStatusFromStripe] = useState(undefined);
  const { data: intent, isLoading } = useBillingGet(
    O_SERVICE_BILLING_GET_INTENT,
  );

  const options = {
    clientSecret: intent?.client_secret,
  };

  const handleStripeStatusReceived = (stripeStatus) => {
    setStatusFromStripe(stripeStatus);
  };

  const getModalTitle = () => {
    if (intent && intent.intent_type === "payment") {
      return "Add payment method";
    } else if (intent && intent.intent_type === "setup") {
      return "Update payment method";
    }
    return " ";
  };

  const getSubmitButtonText = () => {
    if (intent && intent.intent_type === "payment") {
      return "Upgrade Now";
    } else if (intent && intent.intent_type === "setup") {
      return "Update Card";
    }
    return " ";
  };

  return (
    <Modal
      title={getModalTitle()}
      open={open}
      onCancel={() => onCancel()}
      footer={null}
    >
      {statusFromStripe && (
        <Alert message={statusFromStripe} style={{ marginBottom: 10 }} />
      )}
      {!isLoading && intent ? (
        <Elements
          options={options}
          stripe={stripePromise}
          key={intent.client_secret}
        >
          <CheckoutForm
            intentType={intent.intent_type}
            onStatusUpdated={handleStripeStatusReceived}
            onError={(error) => {
              console.log(error.message);
            }}
            submitButtonText={getSubmitButtonText()}
          />
        </Elements>
      ) : (
        <PaddedSpinner />
      )}
    </Modal>
  );
};

export default SubscriptionPaymentModal;
