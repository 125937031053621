import React from "react";
import ConnectGmail from "../../gmail/connectGmail";
import {
  gmailProviderConfig,
  googleCalendarProviderConfig,
} from "../../routingAndAuth/auth0ProviderConfigs";
import { Card } from "antd";
import Auth0ProviderWithRedirectCallbackParent from "../../routingAndAuth/auth0ProviderWithRedirectCallbackParent";
import ConnectGoogleCalendar from "../../gmail/connectGoogleCalendar";

const SettingsConnectedAccounts = () => {
  return (
    <>
      <Auth0ProviderWithRedirectCallbackParent {...gmailProviderConfig}>
        <Card title="Gmail Connection" style={{ marginBottom: 20 }}>
          <ConnectGmail onComplete={() => console.log("Gmail connected.")} />
        </Card>
      </Auth0ProviderWithRedirectCallbackParent>
      <Auth0ProviderWithRedirectCallbackParent
        {...googleCalendarProviderConfig}
      >
        <Card title="Google Calendar Connection" style={{ marginBottom: 20 }}>
          <ConnectGoogleCalendar
            onComplete={() => console.log("Google Calendar connected.")}
          />
        </Card>
      </Auth0ProviderWithRedirectCallbackParent>
    </>
  );
};

export default SettingsConnectedAccounts;
