export const formatCurrency = (amount) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
};

export const getFamilyName = (familyId, currentUser) => {
  if (!currentUser) {
    return "";
  }
  const families = currentUser.families;
  for (const i in families) {
    if (families[i].id === parseInt(familyId)) {
      return families[i].name;
    }
  }
  return "";
};

export const stopEventPropagation = (e) => {
  if (e.stopPropagation) {
    e.stopPropagation(); // W3C model
  } else {
    e.cancelBubble = true; // IE model
  }
};

export const createQueryKey = (
  baseUrl,
  endpoint,
  extraPathParams,
  queryParamsOrPostDataDict,
) => {
  const baseUrlPart = baseUrl.split("/").filter((item) => {
    return item !== "";
  });

  const endPointPart = endpoint.split("/").filter((item) => {
    return item !== "";
  });

  let queryKey = [...baseUrlPart, ...endPointPart];

  if (extraPathParams && extraPathParams.length > 0) {
    queryKey = [...queryKey, ...extraPathParams];
  }

  if (queryParamsOrPostDataDict) {
    const queryParmsKeys = Object.keys(queryParamsOrPostDataDict).sort();
    for (const i in queryParmsKeys) {
      const key = queryParmsKeys[i];
      const value = queryParamsOrPostDataDict[key];
      queryKey.push(`${key}=${value}`);
    }
  }

  return queryKey;
};

export function setBodyColor(color) {
  document.documentElement.style.setProperty("--color-background-body", color);
}

export const getOpenGoogleCalendarToDateUrl = (dateAsString) => {
  const splitStartDate = dateAsString.split("-");
  const year = parseInt(splitStartDate[0]);
  const month = parseInt(splitStartDate[1]);
  const day = parseInt(splitStartDate[2]);
  return `https://calendar.google.com/calendar/u/0/r/day/${year}/${month}/${day}`;
};

export const getQueryParams = (url) => {
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);
  const paramsDict = {};
  searchParams.forEach((value, key) => {
    paramsDict[key] = value;
  });

  return paramsDict;
};
