import { getFormattedUtcDateTimeInUsersTz } from "../../../../utils/dayJsUtils";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";

const TodoCompletedAtPart = ({ todoItem }) => {
  const { browserTimezone } = useTldrPageContext();

  const getCompleted = () => {
    return getFormattedUtcDateTimeInUsersTz(
      todoItem.completed_at,
      browserTimezone,
    );
  };

  return getCompleted();
};

export default TodoCompletedAtPart;
