import React from "react";
import { Modal } from "antd";

import useListUrlRequestsForRequestUrl from "../../../../hooks/urlCentral/useListUrlRequestsForRequestUrl";

const RequestHistoryModal = ({ open, onCancel, requestUrl }) => {
  const { data: urlCentralRequestsForUrl } = useListUrlRequestsForRequestUrl(
    requestUrl,
    false,
  );

  return (
    <Modal
      open={open}
      title="Request History"
      footer={false}
      onCancel={onCancel}
    >
      <>
        {urlCentralRequestsForUrl ? (
          <pre>
            <code>{JSON.stringify(urlCentralRequestsForUrl, null, 2)}</code>
          </pre>
        ) : (
          <></>
        )}
      </>
    </Modal>
  );
};

export default RequestHistoryModal;
