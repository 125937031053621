import React from "react";
import DetailPanelSkeleton from "./detailPanelParts/detailPanelSkeleton";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import SummaryBulletCardCover from "../cardCovers/summaryBulletCardCover";
import useGetMessagesForArtifact from "../../../hooks/emailCollector/common/useGetMessagesForArtifact";

const SummaryBulletDetailPanel = ({ ecObject, actionMenu }) => {
  const { messages } = useGetMessagesForArtifact(
    ecObject?.class_id,
    ecObject?.hash_key,
    ecObject?.sort_key,
  );

  const position1 = (
    <TldrCardWithCoverComponent
      coverComponent={
        <SummaryBulletCardCover
          message={messages && messages.length ? messages[0] : null}
          ecObject={ecObject}
        />
      }
      width={"auto"}
      coverHeight={300}
      hoverable={false}
      onClick={null}
    />
  );

  return (
    <DetailPanelSkeleton
      position1={position1}
      position2={!!actionMenu && actionMenu}
      position3={null}
    />
  );
};

export default SummaryBulletDetailPanel;
