import React from "react";
import { Button, List, Space, Tag, Typography } from "antd";

const { Text } = Typography;

const WorkingRequestListItem = ({
  workingRequest,
  onClickViewRequestHistory,
  onclickViewCaptureData,
}) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "succeeded":
        return "success";
      case "failed":
        return "error";
      default:
        return "default";
    }
  };

  return (
    <>
      <List.Item key={workingRequest.unique_working_id}>
        <List.Item.Meta
          title={`Unique Working Id: ${workingRequest.unique_working_id}`}
          description={
            <>
              {workingRequest.sister_unique_working_id ? (
                <div style={{ marginTop: 5 }}>
                  Sister:{" "}
                  <span style={{ fontStyle: "italic", color: "lightskyblue" }}>
                    {workingRequest.sister_unique_working_id}
                  </span>
                </div>
              ) : (
                <></>
              )}
              <div style={{ marginTop: 5 }}>
                Request URL: {workingRequest.request_url}
              </div>
              {workingRequest.error ? (
                <Text type="danger">{workingRequest.error}</Text>
              ) : (
                <></>
              )}
              <div style={{ marginTop: 5 }}>
                <Tag color={getStatusColor(workingRequest.status)}>
                  {workingRequest.status}
                </Tag>
              </div>
              <div style={{ marginTop: 5 }}>
                <Space>
                  <Button
                    onClick={() => {
                      onClickViewRequestHistory(workingRequest.request_url);
                    }}
                  >
                    Request History
                  </Button>
                  {workingRequest.capture ? (
                    <Button
                      onClick={() => {
                        onclickViewCaptureData(workingRequest.capture);
                      }}
                    >
                      Working Request Capture Data
                    </Button>
                  ) : (
                    <></>
                  )}
                </Space>
              </div>
            </>
          }
        />
      </List.Item>
    </>
  );
};

export default WorkingRequestListItem;
