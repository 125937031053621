import React from "react";
import { Card, Col, Divider, Row } from "antd";
import RandomlyColoredAvatar from "../../common/generic/randomlyColoredAvatar";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import useSettingsInvites from "./hooks/useSettingsInvites";

const SettingsInvites = () => {
  const { invites, handleDeleteInvite, handleResendInvite } =
    useSettingsInvites();

  const getJsx = () => {
    if (!invites || invites.length === 0) {
      return <></>;
    }

    return (
      <>
        <Divider />
        <h2 style={{ paddingBottom: 10 }}>Invites</h2>
        <Row gutter={[16, 8]}>
          {invites && invites.length > 0 ? (
            invites.map((invite) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={8}
                  xxl={6}
                  key={invite.id}
                >
                  <Card
                    style={{ marginBottom: 10 }}
                    actions={[
                      <DeleteOutlineOutlinedIcon
                        sx={{ fontSize: 20 }}
                        onClick={() => {
                          handleDeleteInvite(invite.id).then();
                        }}
                      />,
                      <SendOutlinedIcon
                        sx={{ fontSize: 20 }}
                        onClick={() => {
                          handleResendInvite(invite.id).then();
                        }}
                      />,
                    ]}
                  >
                    <Card.Meta
                      avatar={
                        <RandomlyColoredAvatar
                          seedString={invite.invitee_email}
                        />
                      }
                      title={invite.invitee_email}
                      description=""
                    />
                  </Card>
                </Col>
              );
            })
          ) : (
            <></>
          )}
        </Row>
      </>
    );
  };

  return <>{getJsx()}</>;
};

export default SettingsInvites;
