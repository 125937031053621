export const NATIVE_TAG_OPTIONS = [
  {
    value: "tldr",
    label: "Key File & Link Types",
    hasQuickButton: false,
    quickButtonOrder: 1,
  },
  {
    value: "apple_app_store",
    label: "Apple Apps",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "amazon",
    label: "Amazon",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "attachment",
    label: "Attachments",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "facebook",
    label: "Facebook",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "google",
    label: "Google",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "google_doc",
    label: "Google Docs",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "google_drive",
    label: "Google Drive",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "google_login",
    label: "Google Login Required",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "google_map",
    label: "Google Maps",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "google_photos",
    label: "Google Photos",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "google_play",
    label: "Google Play",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "google_site",
    label: "Google Site",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "image",
    label: "Images",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "instagram",
    label: "Instagram",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "jotform",
    label: "Jotform",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  { value: "link", label: "Links", hasQuickButton: false, quickButtonOrder: 1 },
  {
    value: "linkedin",
    label: "LinkedIn",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "office_form",
    label: "Office Form",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "pdf",
    label: "PDF",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "pinterest",
    label: "Pinterest",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "surveymonkey",
    label: "SurveyMonkey",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "twitter",
    label: "Twitter",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "youtube",
    label: "YouTube",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  {
    value: "youtube_video",
    label: "YouTube Videos",
    hasQuickButton: false,
    quickButtonOrder: 2,
  },
  { value: "zoom", label: "Zoom", hasQuickButton: false, quickButtonOrder: 2 },
];
