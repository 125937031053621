import React from "react";
import ArtifactCard from "../common/generic/artifactCard";
import AttachmentTextCover from "../ecObjectViewers/cardCovers/attachmentTextCover";
import ArtifactCardWithCoverImage from "../common/generic/artifactCardWithCoverImage";

const InboundForwardAttachmentCard = ({ attachment }) => {
  const isImage = attachment?.mime_type.startsWith("image/");
  const hasPdfPreview = !!attachment?.preview_signed_url;

  const getStandardCardWithTextDescription = () => {
    const onClick = () => {
      window.open(attachment.signed_url, "_blank");
    };

    return (
      <ArtifactCard
        cover={
          <AttachmentTextCover onClick={onClick} attachment={attachment} />
        }
        position1={null}
        position2={null}
        position3={null}
      />
    );
  };

  const getCardCapableOfPreview = () => {
    const imageSrc = hasPdfPreview
      ? attachment.preview_signed_url
      : attachment.signed_url;

    return (
      <ArtifactCardWithCoverImage
        imageSrc={imageSrc}
        imageSize={null}
        alt={attachment?.filename || ""}
        preview={true}
        position1={null}
        position2={null}
        position3={null}
      />
    );
  };

  const getCard = () => {
    if (isImage || hasPdfPreview) {
      return getCardCapableOfPreview();
    } else {
      return getStandardCardWithTextDescription();
    }
  };

  return getCard();
};

export default InboundForwardAttachmentCard;
