import React, { useEffect } from "react";
import useSavedObjectDetail from "./hooks/useSavedObjectDetail";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import EcArtifactDetailInner from "../ecObjectViewers/ecArtifactDetailInner";
import useMetadataForSavedObject from "./hooks/useMetadataForSavedObject";
import useCreateOrEditOrchestrateObject from "../../hooks/orchestrate/common/useCreateOrEditOrchestrateObject";
import useTldrUniversalMutateContext from "../../hooks/utils/useTldrUniversalMutateContext";
import TodoCard from "./todoList/todoCard";
import ClippingCard from "./clippingScroll/clippingCard";
import TileCard from "./boards/tileCard";
import GoogleEventCard from "./googleEvents/googleEventCard";
import ResponsiveCard from "../common/generic/responsiveCard";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";
import ObjectCardActionsButtons from "../ecObjectViewers/cardParts/objectCardActionsButtons";
import { AppContextObjectDetailOverlay } from "../ecObjectViewers/actionMappers/appContextsToActions";
import OrchestrateObjectFormOverlays from "./orchestrateObjectFormOverlays";
import { ALL_CLASSES_BY_CLASS_ID } from "../ecObjectViewers/actionMappers/classesToActions";
import { Actions } from "../ecObjectViewers/actionMappers/actions";

const SavedObjectDetail = ({ classId }) => {
  const { familyId, setBreadcrumbs, clearBreadcrumbExtra, showDeleteConfirm } =
    useTldrPageContext();

  const navigate = useNavigate();
  const { orchestrateObject, ecArtifact } = useSavedObjectDetail(classId);
  const {
    objectName,
    objectTypeForDisplay,
    objectTypePluralForDisplay,
    urlPath,
  } = useMetadataForSavedObject(orchestrateObject);

  const {
    eventOverlayOpen,
    closeEventOverlay,
    eventFormValues,
    eventMergeValues,
    todoOverlayOpen,
    closeTodoOverlay,
    todoFormValues,
    todoMergeValues,
    clippingOverlayOpen,
    closeClippingOverlay,
    clippingFormValues,
    clippingMergeValues,
    tileOverlayOpen,
    closeTileOverlay,
    tileFormValues,
    tileMergeValues,
    useImageOverlayOpen,
    closeUseImageOverlay,
    useImageFormValues,
    useImageMergeValues,
    onClickCardAction,
    ecObjectActedUpon,
  } = useCreateOrEditOrchestrateObject();

  useEffect(() => {
    setBreadcrumbs([
      {
        link: `/families/${familyId}/${urlPath}`,
        label: objectTypePluralForDisplay,
      },
      { label: objectName || objectTypeForDisplay },
    ]);
    clearBreadcrumbExtra();
  }, [
    familyId,
    objectName,
    urlPath,
    objectTypeForDisplay,
    objectTypePluralForDisplay,
    setBreadcrumbs,
    clearBreadcrumbExtra,
  ]);

  const { deleteTodo, deleteTile, deleteClipping, deleteGoogleEvent } =
    useTldrUniversalMutateContext(null, null, null, null, null, null);

  const handleClickDeleteTodo = (todoItem) => {
    showDeleteConfirm("Delete?", "", () => {
      deleteTodo(todoItem.id);
      navigate(`/families/${familyId}/todos`);
    });
  };

  const handleClickEditTodo = () => {
    const clazz = ALL_CLASSES_BY_CLASS_ID[ecArtifact.class_id];
    onClickCardAction(
      {
        classAction: Actions.editTodoAction.name,
        sourceInfo: clazz.getSourceInfo(ecArtifact),
        appContext: AppContextObjectDetailOverlay,
        cardProperties: null,
        savedObject: orchestrateObject,
      },
      ecArtifact,
    );
  };

  const handleClickDeleteTile = (tileItem) => {
    showDeleteConfirm("Delete?", "", () => {
      deleteTile(tileItem.id);
      navigate(
        `/families/${familyId}/boards/${tileItem.board_associations[0].board_id}`,
      );
    });
  };

  const handleClickDeleteClipping = (clippingItem) => {
    showDeleteConfirm("Delete?", "", () => {
      deleteClipping(clippingItem.id);
      navigate(`/families/${familyId}/workbench`);
    });
  };

  const handleClickDeleteGoogleEvent = (googleEventItem) => {
    showDeleteConfirm("Delete?", "", () => {
      deleteGoogleEvent(googleEventItem.id);
      navigate(`/families/${familyId}/google-events`);
    });
  };

  const getSavedObjectCard = () => {
    let inner = <></>;
    switch (orchestrateObject?.class_id) {
      case "todo":
        inner = (
          <TodoCard
            savedObject={orchestrateObject}
            onClickEdit={() => handleClickEditTodo()}
            onClickDelete={() => {
              handleClickDeleteTodo(orchestrateObject);
            }}
          />
        );
        break;
      case "clipping":
        inner = (
          <ClippingCard
            savedObject={orchestrateObject}
            onClickEdit={null}
            onClickDelete={() => {
              handleClickDeleteClipping(orchestrateObject);
            }}
          />
        );
        break;
      case "tile":
        inner = (
          <TileCard
            savedObject={orchestrateObject}
            onClickEdit={null}
            onClickDelete={() => {
              handleClickDeleteTile(orchestrateObject);
            }}
          />
        );
        break;
      case "google_event":
        inner = (
          <GoogleEventCard
            savedObject={orchestrateObject}
            onClickEdit={null}
            onClickDelete={() => {
              handleClickDeleteGoogleEvent(orchestrateObject);
            }}
          />
        );
        break;
      default:
        inner = <></>;
    }

    return (
      <ResponsiveCard
        title={null}
        style={{ border: "1px solid #168dff", width: "100%" }}
      >
        {inner}
      </ResponsiveCard>
    );
  };

  return (
    <>
      <OrchestrateObjectFormOverlays
        eventInitialFormValues={eventFormValues}
        eventMergeValues={eventMergeValues}
        eventOverlayOpen={eventOverlayOpen}
        onCancelEvent={() => {
          closeEventOverlay();
        }}
        onSubmitEvent={() => {
          closeEventOverlay();
        }}
        onPersistEvent={(item) => {}}
        todoInitialFormValues={todoFormValues}
        todoMergeValues={todoMergeValues}
        todoOverlayOpen={todoOverlayOpen}
        onCancelTodo={() => {
          closeTodoOverlay();
        }}
        onSubmitTodo={() => {
          closeTodoOverlay();
        }}
        onPersistTodo={(item) => {}}
        clippingInitialFormValues={clippingFormValues}
        clippingMergeValues={clippingMergeValues}
        clippingOverlayOpen={clippingOverlayOpen}
        onCancelClipping={() => {
          closeClippingOverlay();
        }}
        onSubmitClipping={() => {
          closeClippingOverlay();
        }}
        onPersistClipping={(item) => {}}
        tileInitialFormValues={tileFormValues}
        tileMergeValues={tileMergeValues}
        tileOverlayOpen={tileOverlayOpen}
        onCancelTile={() => {
          closeTileOverlay();
        }}
        onSubmitTile={() => {
          closeTileOverlay();
        }}
        onPersistTile={(item) => {}}
        useImageForBoardCoverInitialFormValues={useImageFormValues}
        useImageForBoardCoverMergeValues={useImageMergeValues}
        useImageForBoardCoverOverlayOpen={useImageOverlayOpen}
        onCancelUseImageForBoardCover={() => {
          closeUseImageOverlay();
        }}
        onSubmitUseImageForBoardCover={() => {
          closeUseImageOverlay();
        }}
        onPersistUseImageForBoardCover={(item) => {}}
        ecObjectActedUpon={ecObjectActedUpon}
      />
      <div style={{ height: 10 }}></div>
      {getSavedObjectCard()}
      <Divider orientation={"left"}>Auto-Linked Info</Divider>
      <EcArtifactDetailInner
        ecObject={ecArtifact}
        scrollToFirstHighlight={false}
        actionMenu={
          <ObjectCardActionsButtons
            ecObject={ecArtifact}
            appContext={AppContextObjectDetailOverlay}
            onClick={(values, ecObjectLocal) => {
              onClickCardAction(values, ecObjectLocal);
            }}
            savedObject={null}
          />
        }
      />
    </>
  );
};

export default SavedObjectDetail;
