import React, { useState } from "react";
import FullScreenOverlay from "../common/generic/fullScreenOverlay";
import {
  ALL_CLASSES_BY_CLASS_ID,
  getObjectName,
} from "./actionMappers/classesToActions";
import EcArtifactDetailInner from "./ecArtifactDetailInner";
import { AppContextObjectDetailOverlay } from "./actionMappers/appContextsToActions";
import FullscreenOverlaySwitcher from "../common/generic/fullscreenOverlaySwitcher";
import OrchestrateObjectFormOverlays from "../familyOrganizer/orchestrateObjectFormOverlays";
import useCreateOrEditOrchestrateObject from "../../hooks/orchestrate/common/useCreateOrEditOrchestrateObject";
import ObjectCardActionsButtons from "./cardParts/objectCardActionsButtons";
import { Typography } from "antd";
import { getUrlToArtifactDetails } from "../../utils/ecUtils";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";

const AnyEcArtifactDetailsOverlay = ({
  open,
  onClose,
  ecObject,
  scrollToFirstHighlight,
}) => {
  const { familyId } = useTldrPageContext();
  const [mode, setMode] = useState("view");

  const {
    eventOverlayOpen,
    closeEventOverlay,
    eventFormValues,
    eventMergeValues,
    todoOverlayOpen,
    closeTodoOverlay,
    todoFormValues,
    todoMergeValues,
    clippingOverlayOpen,
    closeClippingOverlay,
    clippingFormValues,
    clippingMergeValues,
    tileOverlayOpen,
    closeTileOverlay,
    tileFormValues,
    tileMergeValues,
    useImageOverlayOpen,
    closeUseImageOverlay,
    useImageFormValues,
    useImageMergeValues,
    onClickCardAction,
    ecObjectActedUpon,
  } = useCreateOrEditOrchestrateObject();

  const objectTypePart =
    ALL_CLASSES_BY_CLASS_ID[ecObject?.class_id]?.objectTitle || "";
  const objectNamePart = getObjectName(ecObject);

  let title = objectTypePart;
  if (objectTypePart && objectNamePart !== "") {
    title = `${objectTypePart}: ${objectNamePart}`;
  }

  const getOverlayIndexToShow = () => {
    switch (mode) {
      case "wizard":
        return 1;
      default:
        return 0;
    }
  };

  const getEcObjectDetailsOverlay = () => {
    return (
      <FullScreenOverlay title={title} open={open} onClose={onClose}>
        <div style={{ textAlign: "right", marginBottom: 5 }}>
          <Typography.Text
            copyable={{
              text: getUrlToArtifactDetails(
                familyId,
                ecObject?.class_id,
                ecObject?.hash_key,
                ecObject?.sort_key,
              ),
            }}
          >
            Permalink
          </Typography.Text>
        </div>
        <EcArtifactDetailInner
          ecObject={ecObject}
          scrollToFirstHighlight={scrollToFirstHighlight}
          actionMenu={
            <ObjectCardActionsButtons
              ecObject={ecObject}
              appContext={AppContextObjectDetailOverlay}
              onClick={(values, ecObjectLocal) => {
                onClickCardAction(values, ecObjectLocal);
                if (
                  values.classAction !== "createClippingAction" &&
                  values.classAction !== "createContactAction"
                ) {
                  setMode("wizard");
                }
              }}
              savedObject={null}
            />
          }
        />
      </FullScreenOverlay>
    );
  };

  const getEcOrchestrateWizardOverlay = () => {
    return (
      <OrchestrateObjectFormOverlays
        eventInitialFormValues={eventFormValues}
        eventMergeValues={eventMergeValues}
        eventOverlayOpen={eventOverlayOpen}
        onCancelEvent={() => {
          closeEventOverlay();
          setMode("view");
        }}
        onSubmitEvent={() => {
          closeEventOverlay();
          setMode("view");
        }}
        onPersistEvent={(item) => {}}
        todoInitialFormValues={todoFormValues}
        todoMergeValues={todoMergeValues}
        todoOverlayOpen={todoOverlayOpen}
        onCancelTodo={() => {
          closeTodoOverlay();
          setMode("view");
        }}
        onSubmitTodo={() => {
          closeTodoOverlay();
          setMode("view");
        }}
        onPersistTodo={(item) => {}}
        clippingInitialFormValues={clippingFormValues}
        clippingMergeValues={clippingMergeValues}
        clippingOverlayOpen={clippingOverlayOpen}
        onCancelClipping={() => {
          closeClippingOverlay();
          setMode("view");
        }}
        onSubmitClipping={() => {
          closeClippingOverlay();
          setMode("view");
        }}
        onPersistClipping={(item) => {}}
        tileInitialFormValues={tileFormValues}
        tileMergeValues={tileMergeValues}
        tileOverlayOpen={tileOverlayOpen}
        onCancelTile={() => {
          closeTileOverlay();
          setMode("view");
        }}
        onSubmitTile={() => {
          closeTileOverlay();
          setMode("view");
        }}
        onPersistTile={(item) => {}}
        useImageForBoardCoverInitialFormValues={useImageFormValues}
        useImageForBoardCoverMergeValues={useImageMergeValues}
        useImageForBoardCoverOverlayOpen={useImageOverlayOpen}
        onCancelUseImageForBoardCover={() => {
          closeUseImageOverlay();
          setMode("view");
        }}
        onSubmitUseImageForBoardCover={() => {
          closeUseImageOverlay();
          setMode("view");
        }}
        onPersistUseImageForBoardCover={(item) => {}}
        ecObjectActedUpon={ecObjectActedUpon}
      />
    );
  };

  return (
    <FullscreenOverlaySwitcher
      overlayIndex={getOverlayIndexToShow()}
      overlays={[getEcObjectDetailsOverlay(), getEcOrchestrateWizardOverlay()]}
    />
  );
};

export default AnyEcArtifactDetailsOverlay;
