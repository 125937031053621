import React from "react";
import { Pagination } from "antd";

const StandardPagination = ({ current, pageSize, onChange, total }) => {
  return (
    <Pagination
      current={current}
      pageSize={pageSize}
      onChange={onChange}
      total={total}
      showSizeChanger={false}
      hideOnSinglePage
    />
  );
};

export default StandardPagination;
