import useQueryGenericGet from "../framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_URL_CENTRAL } from "../../serviceUrls/baseUrls";
import { UC_SERVICE_GET_SIGNED_CONTENT_URL } from "../../serviceUrls/serviceUrlsUrlCentral";

const useGetSignedUrlForOriginalUrl = (originalUrl, enabled) => {
  const { data } = useQueryGenericGet(
    BACKEND_API_BASE_URL_URL_CENTRAL,
    UC_SERVICE_GET_SIGNED_CONTENT_URL,
    true,
    [],
    { url: originalUrl },
    !!originalUrl && enabled,
  );

  if (!enabled) {
    return null;
  } else if (data?.content_url) {
    return data.content_url;
  } else {
    return originalUrl;
  }
};

export default useGetSignedUrlForOriginalUrl;
