import React, { useEffect, useState } from "react";
import { Typography } from "antd";

const { Text } = Typography;

const SavedObjectUsages = ({ savedObjectUsages }) => {
  const [usageCounter, setUsageCounter] = useState(null);

  useEffect(() => {
    const counter = {
      google_event: 0,
      todo: 0,
      tile: 0,
      clipping: 0,
      total: 0,
    };
    if (savedObjectUsages) {
      if (savedObjectUsages.length > 0) {
        for (const i in savedObjectUsages) {
          counter.total++;
          const classId = savedObjectUsages[i].class_id;
          switch (classId) {
            case "google_event":
              counter.google_event++;
              break;
            case "todo":
              counter.todo++;
              break;
            case "tile":
              counter.tile++;
              break;
            case "clipping":
              counter.clipping++;
              break;
            default:
              break;
          }
        }
      }
    }
    setUsageCounter(counter);
  }, [savedObjectUsages]);

  const getPart = (count, displayName, displayNamePlural) => {
    if (count > 0) {
      if (count === 1) {
        return `1 ${displayName}`;
      } else {
        return `${count} ${displayNamePlural}`;
      }
    }
    return null;
  };

  const generateDisplaySentence = () => {
    if (!usageCounter || usageCounter.total === 0) {
      return <></>;
    }

    const parts = [];
    const googleEventPart = getPart(
      usageCounter.google_event,
      "Google event",
      "Google events",
    );
    if (googleEventPart) {
      parts.push(googleEventPart);
    }
    const todoPart = getPart(usageCounter.todo, "to-do", "to-dos");
    if (todoPart) {
      parts.push(todoPart);
    }
    const tilePart = getPart(usageCounter.tile, "tile", "tiles");
    if (tilePart) {
      parts.push(tilePart);
    }
    const clippingPart = getPart(
      usageCounter.clipping,
      "clipping",
      "clippings",
    );
    if (clippingPart) {
      parts.push(clippingPart);
    }

    return (
      <Text
        italic={true}
        style={{ color: "#ff4081" }}
      >{`Referenced in ${parts.join(", ")}`}</Text>
    );
  };

  return <>{generateDisplaySentence()}</>;
};

export default SavedObjectUsages;
