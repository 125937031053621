import React from "react";
import logo from "../../images/tldrparents-logo-horizontal-white.svg";
import { Card, Steps } from "antd";

const OnboardingPageFrame = ({ numSteps, currentStep, title, children }) => {
  const getStepsItems = () => {
    const items = [];
    if (numSteps && currentStep !== undefined) {
      for (let i = 0; i < numSteps; i++) {
        items.push({
          title: "",
        });
      }
    }
    return items;
  };

  const getSteps = () => {
    if (numSteps === 1) {
      return <></>;
    }
    return numSteps && currentStep !== undefined ? (
      <Steps
        size="small"
        type="navigation"
        responsive={false}
        current={currentStep}
        items={getStepsItems()}
        style={{ marginBottom: 15 }}
      />
    ) : (
      <></>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <img src={logo} alt="TLDR Parents" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: 7,
        }}
      >
        <div style={{ width: "100%", maxWidth: 500 }}>
          <Card>
            {getSteps()}
            <h2>{title}</h2>
            {children}
          </Card>
        </div>
      </div>
    </>
  );
};

export default OnboardingPageFrame;
