import React from "react";
import LinkPreview from "../../common/generic/linkPreview";
import ArtifactCard from "../../common/generic/artifactCard";
import ArtifactCardWithCoverImage from "../../common/generic/artifactCardWithCoverImage";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { Space, Tooltip } from "antd";
import { getQueryParams } from "../../../utils/utils";

const LinkImageCard = ({
  ecObject,
  cardProperties,
  onClickViewEcObjectDetails,
  sourceMessageInfo,
  actionMenu,
}) => {
  const cardPropertiesLocal = cardProperties?.[ecObject.class_id] || {};
  const showImage = cardPropertiesLocal?.showImage === true;
  const showLink = cardPropertiesLocal?.showLink === true;

  const getJsxAsImageCard = () => {
    return (
      <ArtifactCardWithCoverImage
        imageSrc={ecObject.image_signed_url || ecObject.image_final_url}
        imageSize={ecObject.image_size}
        alt={""}
        preview={true}
        position1={sourceMessageInfo}
        position2={
          <Tooltip title={"Link + Image"}>
            <Space>
              <ImageOutlinedIcon sx={{ color: "#ff4081" }} />
              <LinkOutlinedIcon sx={{ color: "#ff4081" }} />
            </Space>
          </Tooltip>
        }
        position3={actionMenu}
      />
    );
  };

  const getImgSrcForLink = () => {
    const isImage = ecObject?.link_content_type?.startsWith("image/");
    if (isImage) {
      return ecObject.link_signed_url;
    }
    const hasPdfPreview = !!ecObject?.link_preview_signed_url;
    if (hasPdfPreview) {
      return ecObject.link_preview_signed_url;
    }
    return ecObject.link_preview_summary.image_src;
  };

  const getCover = () => {
    if (
      ecObject.link_final_url.startsWith("https://drive.google.com/file/d/")
    ) {
      return (
        <div className="iframe-wrapper">
          <iframe
            src={ecObject.link_final_url.replace("/view", "/preview")}
            // width="640"
            // height="480"
            allow="autoplay"
          />
        </div>
      );
    }

    if (ecObject.link_final_url.startsWith("https://www.youtube.com/watch")) {
      const embedId = getQueryParams(ecObject.link_final_url)["v"];
      return (
        <div className="iframe-wrapper">
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${embedId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </div>
      );
    }

    const linkUrl = ecObject.link_content_disposition
      ? ecObject.link_signed_url
      : ecObject.link_final_url;

    return (
      <LinkPreview
        href={linkUrl}
        title={ecObject.link_preview_summary.title}
        domain={ecObject.link_preview_summary.fqdn}
        description={ecObject.link_preview_summary.description}
        imageSrc={getImgSrcForLink()}
        contentType={ecObject.link_content_type}
        coverHeight={243}
      />
    );
  };

  const getJsxAsLinkCard = () => {
    return (
      <ArtifactCard
        cover={getCover()}
        position1={sourceMessageInfo}
        position2={
          <Tooltip title={"Link + Image"}>
            <ImageOutlinedIcon sx={{ color: "#ff4081" }} />
            <LinkOutlinedIcon sx={{ color: "#ff4081" }} />
          </Tooltip>
        }
        position3={actionMenu}
      />
    );
  };

  const getJsx = () => {
    if (showImage) {
      return getJsxAsImageCard();
    } else {
      return getJsxAsLinkCard();
    }
  };

  return getJsx();
};

export default LinkImageCard;
