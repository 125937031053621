import React from "react";

import {
  DISPLAY_DATE_FORMAT_LONG,
  DISPLAY_DATETIME_FORMAT_LONG,
  DISPLAY_TIME_FORMAT,
  getDayJsDatetimeFromStringsGivenTimezones,
} from "../../../utils/dayJsUtils";

const FormattedDateTimeRange = ({
  startDateAsString,
  startTimeAsString,
  endDateAsString,
  endTimeAsString,
  timezoneInterpret,
  timezoneDisplay,
}) => {
  const formatDatetime = (dateAsString, timeAsString) => {
    if (dateAsString && timeAsString) {
      return getDayJsDatetimeFromStringsGivenTimezones(
        dateAsString,
        timeAsString,
        timezoneInterpret,
        timezoneDisplay,
      ).format(DISPLAY_DATETIME_FORMAT_LONG);
    } else if (dateAsString) {
      return getDayJsDatetimeFromStringsGivenTimezones(
        dateAsString,
        timeAsString,
        timezoneInterpret,
        timezoneDisplay,
      ).format(DISPLAY_DATE_FORMAT_LONG);
    } else if (timeAsString) {
      return getDayJsDatetimeFromStringsGivenTimezones(
        dateAsString,
        timeAsString,
        timezoneInterpret,
        timezoneDisplay,
      ).format(DISPLAY_TIME_FORMAT);
    } else {
      return null;
    }
  };

  const getFormattedDatetimeRange = () => {
    if (
      startDateAsString &&
      startTimeAsString &&
      endDateAsString &&
      endTimeAsString
    ) {
      if (startDateAsString === endDateAsString) {
        if (startTimeAsString === endTimeAsString) {
          return <>{formatDatetime(startDateAsString, startTimeAsString)}</>;
        } else {
          return (
            <>
              {formatDatetime(startDateAsString, startTimeAsString)} -{" "}
              {formatDatetime(null, endTimeAsString)}
            </>
          );
        }
      } else {
        return (
          <>
            {formatDatetime(startDateAsString, startTimeAsString)} -{" "}
            {formatDatetime(endDateAsString, endTimeAsString)}
          </>
        );
      }
    } else if (startDateAsString && startTimeAsString && endTimeAsString) {
      return (
        <>
          {formatDatetime(startDateAsString, startTimeAsString)} -{" "}
          {formatDatetime(null, endTimeAsString)}
        </>
      );
    } else if (startDateAsString && startTimeAsString) {
      return <>{formatDatetime(startDateAsString, startTimeAsString)}</>;
    } else if (
      startDateAsString &&
      endDateAsString &&
      startDateAsString !== endDateAsString
    ) {
      return (
        <>
          {formatDatetime(startDateAsString, null)} -{" "}
          {formatDatetime(endDateAsString, null)}
        </>
      );
    } else if (startDateAsString) {
      return <>{formatDatetime(startDateAsString, null)}</>;
    } else if (startTimeAsString && endTimeAsString) {
      return (
        <>
          {formatDatetime(null, startTimeAsString)} -{" "}
          {formatDatetime(null, endTimeAsString)}
        </>
      );
    } else if (startTimeAsString) {
      return <>{formatDatetime(null, startTimeAsString)}</>;
    } else {
      return <></>;
    }
  };

  return <>{getFormattedDatetimeRange()}</>;
};

export default FormattedDateTimeRange;
