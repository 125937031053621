import React, { useEffect } from "react";
import useListInvitesForCurrentUser from "./hooks/useListInvitesForCurrentUser";
import OnboardingPageFrame from "../onboarding/onboardingPageFrame";
import { useNavigate } from "react-router-dom";
import ConfirmInvitesList from "./confirmInvitesList";

const ConfirmInvites = () => {
  const { data: invites } = useListInvitesForCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (invites) {
      if (invites.length === 0) {
        navigate("/");
      }
    }
  }, [invites, navigate]);

  return (
    <OnboardingPageFrame
      numSteps={0}
      currentStep={0}
      title="Confirm or ignore the following invitations."
    >
      <ConfirmInvitesList invites={invites} />
    </OnboardingPageFrame>
  );
};

export default ConfirmInvites;
