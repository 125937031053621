import React, { useEffect } from "react";
import SimpleMessageViewer from "../inboxesAndMessages/message/simpleViewer/simpleMessageViewer";
import { Card, Col, Row, Space, Tag } from "antd";
import useNavigateMessagesInOverlay from "../ecObjectViewers/hooks/useNavigateMessagesInOverlay";
import PrevNextMessageNavForDetailsPage from "../common/generic/prevNextMessageNavForDetailsPage";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const MessagePartOfEcArtifactDetailsPage = ({
  artifactObject,
  messages,
  tlecIndexesToHighlightByUniqueMessageId,
  scrollToFirstHighlight,
}) => {
  const {
    currentMessageIndex,
    setCurrentMessageIndex,
    currentMessageId,
    setCurrentMessageId,
  } = useNavigateMessagesInOverlay(messages);

  useEffect(() => {
    if (messages) {
      setCurrentMessageIndex(0);
      const message = messages[0];
      setCurrentMessageId(
        `${message.tenant_id}-${message.unique_tenancy_message_id}`,
      );
    }
  }, [messages, setCurrentMessageId, setCurrentMessageIndex]);

  const getJsx = () => {
    if (!artifactObject) {
      return <></>;
    }

    return (
      <>
        {messages?.length > 1 ? (
          <Row
            align={"bottom"}
            justify={"space-between"}
            style={{ marginBottom: 10 }}
          >
            <Col>
              <Tag
                color="#ff4081"
                style={{
                  verticalAlign: "bottom",
                }}
              >
                {messages?.length} messages
              </Tag>
            </Col>
            <Col>
              <PrevNextMessageNavForDetailsPage
                currentMessageIndex={currentMessageIndex}
                messages={messages}
                onUpdateIndex={(newIndex) => {
                  const newMessage = messages[newIndex];
                  setCurrentMessageIndex(newIndex);
                  setCurrentMessageId(
                    `${newMessage.tenant_id}-${newMessage.unique_tenancy_message_id}`,
                  );
                }}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Card
          styles={{
            header: { backgroundColor: "#6c6c6c", color: "#ffffff" },
          }}
          title={
            <Space>
              <div style={{ display: "flex" }}>
                <EmailOutlinedIcon sx={{ fontSize: 24 }} />
              </div>
              <div>
                {messages?.length > 1 ? (
                  <>
                    Message {currentMessageIndex ? currentMessageIndex + 1 : 1}
                  </>
                ) : (
                  <>Message</>
                )}
              </div>
            </Space>
          }
        >
          <SimpleMessageViewer
            uniqueMessageId={currentMessageId}
            scrollToFirstHighlight={scrollToFirstHighlight}
            tlecIndexesToHighlight={
              tlecIndexesToHighlightByUniqueMessageId?.[currentMessageId] || []
            }
          />
        </Card>
      </>
    );
  };

  return getJsx();
};

export default MessagePartOfEcArtifactDetailsPage;
