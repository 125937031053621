import useQueryGenericGet from "../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_USER_EDUCATION } from "../../../serviceUrls/baseUrls";
import useUserEducationServiceUrlHelper from "./useUserEducationServiceUrlHelper";
import { UE_SUBJECTS } from "../../../serviceUrls/serviceUrlsUserEducation";
import { useEffect, useState } from "react";
import useGetCurrentUser from "../../../hooks/orchestrate/user/useGetCurrentUser";

const useGetUserEducationSubjects = () => {
  const { data: currentUser } = useGetCurrentUser();
  const { endpointPath, currentFamily } = useUserEducationServiceUrlHelper(
    UE_SUBJECTS,
    {},
  );

  const { data: userEducationSubjectsFromReactQuery } = useQueryGenericGet(
    BACKEND_API_BASE_URL_USER_EDUCATION,
    endpointPath,
    true,
    [],
    {},
    !!currentFamily,
    // 60 * 1000,
  );

  const [organizedUserEducationSubjects, setOrganizedUserEducationSubjects] =
    useState(null);

  useEffect(() => {
    if (userEducationSubjectsFromReactQuery && currentUser && currentFamily) {
      const passed = new Set();
      const notPassed = new Set();
      let nextEducationSubject = null;
      for (const i in userEducationSubjectsFromReactQuery) {
        const subject = userEducationSubjectsFromReactQuery[i];
        if (subject.status === "completed" || subject.status === "skipped") {
          passed.add(subject.subject_id);
        } else {
          if (!nextEducationSubject) {
            nextEducationSubject = subject;
          }
          notPassed.add(subject.subject_id);
        }
      }

      setOrganizedUserEducationSubjects({
        passed: passed,
        notPassed: notPassed,
        hasFinishedOnboarding: [
          "NAME_FAMILY_WORKSPACE",
          "INVITE_PARTNER",
          "PROCESS_FIRST_MESSAGE",
        ].every((value) => passed.has(value)),
        nextEducationSubject: nextEducationSubject,
        subjects: [...userEducationSubjectsFromReactQuery],
      });
    }
  }, [userEducationSubjectsFromReactQuery, currentUser, currentFamily]);

  return { data: organizedUserEducationSubjects };
};

export default useGetUserEducationSubjects;
