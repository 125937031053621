import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import useVideoOverlay from "../hooks/utils/useVideoOverlay";

const HelpIconAndModal = ({ educationModalClass }) => {
  const { videoHelpOverlayOpen, setVideoHelpOverlayOpen } = useVideoOverlay();
  const EducationModal = educationModalClass;
  return (
    <>
      <HelpOutlineIcon
        onClick={() => {
          setVideoHelpOverlayOpen(true);
        }}
        sx={{ fontSize: 25, color: "#ff4081", cursor: "pointer" }}
      />
      <EducationModal
        forced={false}
        open={videoHelpOverlayOpen}
        onClose={() => setVideoHelpOverlayOpen(false)}
      />
    </>
  );
};

export default HelpIconAndModal;
