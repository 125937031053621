import React from "react";
import { Form, Input } from "antd";
import DatetimeRangePicker from "../datetimePickers/datetimeRangePicker";
import UserCheckboxes from "./userCheckboxes";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";

const CreateGoogleEventFormItems = () => {
  const { currentFamily } = useTldrPageContext();
  return (
    <>
      <Form.Item
        name="title"
        label={"Event Title"}
        rules={[{ required: true, message: "Event title is required" }]}
      >
        <Input maxLength={100} placeholder={""} />
      </Form.Item>
      <Form.Item
        label={"Dates"}
        name="datetimes"
        style={{ margin: 0, padding: 0 }}
      >
        <DatetimeRangePicker
          showTime={true}
          requireTime={false}
          allowTimeChoice={true}
          datePresets1={[]}
          timePresets1={[]}
          datePresets2={[]}
          timePresets2={[]}
        />
      </Form.Item>
      <Form.Item name="location" label={"Location"}>
        <Input maxLength={100} placeholder={"Middle School Library"} />
      </Form.Item>
      <Form.Item name="details" label={"Details"}>
        <Input.TextArea rows={4} />
      </Form.Item>
      {currentFamily?.family_members?.length > 1 && (
        <Form.Item name="invitedUsers" label={"Invites"}>
          <UserCheckboxes excludeSelf={true} checkedIds={[]} />
        </Form.Item>
      )}
    </>
  );
};

export default CreateGoogleEventFormItems;
