import React, { useEffect, useState } from "react";
import FullScreenOverlay from "../common/generic/fullScreenOverlay";
import EcArtifactDetailInner from "../ecObjectViewers/ecArtifactDetailInner";
import useMetadataForSavedObject from "./hooks/useMetadataForSavedObject";
import useGetArtifact from "../ecObjectViewers/hooks/useGetArtifact";
import AnyOrchestrateObjectFormOverlay from "./anyOrchestrateObjectFormOverlay";
import useGetInitialAndMergeValuesForEdit from "../../hooks/orchestrate/common/useGetInitialAndMergeValuesForEdit";
import { Divider } from "antd";
import useGetAnyOrchestrateObject from "../../hooks/orchestrate/useGetAnyOrchestrateObject";
import FullscreenOverlaySwitcher from "../common/generic/fullscreenOverlaySwitcher";
import useCreateOrEditOrchestrateObject from "../../hooks/orchestrate/common/useCreateOrEditOrchestrateObject";
import OrchestrateObjectFormOverlays from "./orchestrateObjectFormOverlays";
import { AppContextObjectDetailOverlay } from "../ecObjectViewers/actionMappers/appContextsToActions";
import ObjectCardActionsButtons from "../ecObjectViewers/cardParts/objectCardActionsButtons";
import ResponsiveCard from "../common/generic/responsiveCard";
import TodoCard from "./todoList/todoCard";
import ClippingCard from "./clippingScroll/clippingCard";
import TileCard from "./boards/tileCard";
import GoogleEventCard from "./googleEvents/googleEventCard";
import useTldrUniversalMutateContext from "../../hooks/utils/useTldrUniversalMutateContext";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";

const SavedObjectDetailsOverlay = ({ open, onClose, savedObject }) => {
  const { showDeleteConfirm } = useTldrPageContext();

  const { ecArtifact } = useGetArtifact(
    savedObject?.artifacts?.[0]?.rollup_class_id,
    savedObject?.artifacts?.[0]?.rollup_hash_key,
    savedObject?.artifacts?.[0]?.rollup_sort_key,
  );

  const {
    eventOverlayOpen,
    closeEventOverlay,
    eventFormValues,
    eventMergeValues,
    todoOverlayOpen,
    closeTodoOverlay,
    todoFormValues,
    todoMergeValues,
    clippingOverlayOpen,
    closeClippingOverlay,
    clippingFormValues,
    clippingMergeValues,
    tileOverlayOpen,
    closeTileOverlay,
    tileFormValues,
    tileMergeValues,
    useImageOverlayOpen,
    closeUseImageOverlay,
    useImageFormValues,
    useImageMergeValues,
    onClickCardAction,
    ecObjectActedUpon,
  } = useCreateOrEditOrchestrateObject();

  const [savedObjectForDisplay, setSavedObjectForDisplay] =
    useState(savedObject);

  const { data: freshSavedObject } = useGetAnyOrchestrateObject(
    savedObject?.class_id,
    savedObject?.id,
  );

  useEffect(() => {
    if (savedObject) {
      if (freshSavedObject) {
        if (savedObject.id === freshSavedObject.id) {
          setSavedObjectForDisplay(freshSavedObject);
        } else {
          setSavedObjectForDisplay(savedObject);
        }
      } else {
        setSavedObjectForDisplay(savedObject);
      }
    } else {
      setSavedObjectForDisplay(null);
    }
  }, [freshSavedObject, savedObject]);

  const { pageTitle } = useMetadataForSavedObject(savedObjectForDisplay);

  const { getInitialValuesForEdit, getMergeValuesForEdit } =
    useGetInitialAndMergeValuesForEdit();

  const [mode, setMode] = useState("view");

  const getOverlayIndexToShow = () => {
    switch (mode) {
      case "edit":
        return 1;
      case "create":
        return 2;
      default:
        return 0;
    }
  };

  const { deleteTodo, deleteTile, deleteClipping, deleteGoogleEvent } =
    useTldrUniversalMutateContext(null, null, null, null, null, null);

  const handleClickDeleteTodo = (todoItem) => {
    showDeleteConfirm("Delete?", "", () => {
      deleteTodo(todoItem.id);
      onClose();
    });
  };

  const handleClickDeleteTile = (tileItem) => {
    showDeleteConfirm("Delete?", "", () => {
      deleteTile(tileItem.id);
      onClose();
    });
  };

  const handleClickDeleteClipping = (clippingItem) => {
    showDeleteConfirm("Delete?", "", () => {
      deleteClipping(clippingItem.id);
      onClose();
    });
  };

  const handleClickDeleteGoogleEvent = (googleEventItem) => {
    showDeleteConfirm("Delete?", "", () => {
      deleteGoogleEvent(googleEventItem.id);
      onClose();
    });
  };

  const getSavedObjectCard = () => {
    let inner = <></>;
    switch (savedObjectForDisplay?.class_id) {
      case "todo":
        inner = (
          <TodoCard
            savedObject={savedObjectForDisplay}
            onClickEdit={() => setMode("edit")}
            onClickDelete={() => {
              handleClickDeleteTodo(savedObjectForDisplay);
            }}
          />
        );
        break;
      case "clipping":
        inner = (
          <ClippingCard
            savedObject={savedObjectForDisplay}
            onClickEdit={null}
            onClickDelete={() => {
              handleClickDeleteClipping(savedObjectForDisplay);
            }}
          />
        );
        break;
      case "tile":
        inner = (
          <TileCard
            savedObject={savedObjectForDisplay}
            onClickEdit={null}
            onClickDelete={() => {
              handleClickDeleteTile(savedObjectForDisplay);
            }}
          />
        );
        break;
      case "google_event":
        inner = (
          <GoogleEventCard
            savedObject={savedObjectForDisplay}
            onClickEdit={null}
            onClickDelete={() => {
              handleClickDeleteGoogleEvent(savedObjectForDisplay);
            }}
          />
        );
        break;
      default:
        inner = <></>;
    }

    return (
      <ResponsiveCard
        title={null}
        style={{ border: "1px solid #168dff", width: "100%" }}
      >
        {inner}
      </ResponsiveCard>
    );
  };

  const getJsx = () => {
    return (
      <FullscreenOverlaySwitcher
        overlayIndex={getOverlayIndexToShow()}
        overlays={[
          <FullScreenOverlay title={pageTitle} open={open} onClose={onClose}>
            {getSavedObjectCard()}
            <Divider orientation={"left"}>Auto-Linked Info</Divider>
            <EcArtifactDetailInner
              ecObject={ecArtifact}
              scrollToFirstHighlight={false}
              actionMenu={
                <ObjectCardActionsButtons
                  ecObject={ecArtifact}
                  appContext={AppContextObjectDetailOverlay}
                  onClick={(values, ecObjectLocal) => {
                    onClickCardAction(values, ecObjectLocal);
                    if (
                      values.classAction !== "createClippingAction" &&
                      values.classAction !== "createContactAction"
                    ) {
                      setMode("create");
                    }
                  }}
                  savedObject={null}
                />
              }
            />
          </FullScreenOverlay>,
          <AnyOrchestrateObjectFormOverlay
            classId={savedObjectForDisplay?.class_id}
            initialFormValues={getInitialValuesForEdit(savedObjectForDisplay)}
            mergeValues={getMergeValuesForEdit(savedObjectForDisplay)}
            queryKey={null}
            overlayOpen={open}
            onCancel={() => {
              setMode("view");
            }}
            onSubmit={() => {
              setMode("view");
            }}
            onPersist={(item) => {}}
            ecArtifact={ecArtifact}
          />,
          <OrchestrateObjectFormOverlays
            eventInitialFormValues={eventFormValues}
            eventMergeValues={eventMergeValues}
            eventOverlayOpen={eventOverlayOpen}
            onCancelEvent={() => {
              closeEventOverlay();
              setMode("view");
            }}
            onSubmitEvent={() => {
              closeEventOverlay();
              setMode("view");
            }}
            onPersistEvent={(item) => {}}
            todoInitialFormValues={todoFormValues}
            todoMergeValues={todoMergeValues}
            todoOverlayOpen={todoOverlayOpen}
            onCancelTodo={() => {
              closeTodoOverlay();
              setMode("view");
            }}
            onSubmitTodo={() => {
              closeTodoOverlay();
              setMode("view");
            }}
            onPersistTodo={(item) => {}}
            clippingInitialFormValues={clippingFormValues}
            clippingMergeValues={clippingMergeValues}
            clippingOverlayOpen={clippingOverlayOpen}
            onCancelClipping={() => {
              setMode("view");
            }}
            onSubmitClipping={() => {
              setMode("view");
            }}
            onPersistClipping={(item) => {}}
            tileInitialFormValues={tileFormValues}
            tileMergeValues={tileMergeValues}
            tileOverlayOpen={tileOverlayOpen}
            onCancelTile={() => {
              closeTileOverlay();
              setMode("view");
            }}
            onSubmitTile={() => {
              closeTileOverlay();
              setMode("view");
            }}
            onPersistTile={(item) => {}}
            useImageForBoardCoverInitialFormValues={useImageFormValues}
            useImageForBoardCoverMergeValues={useImageMergeValues}
            useImageForBoardCoverOverlayOpen={useImageOverlayOpen}
            onCancelUseImageForBoardCover={() => {
              closeUseImageOverlay();
              setMode("view");
            }}
            onSubmitUseImageForBoardCover={() => {
              closeUseImageOverlay();
              setMode("view");
            }}
            onPersistUseImageForBoardCover={(item) => {}}
            ecObjectActedUpon={ecObjectActedUpon}
          />,
        ]}
      />
    );
  };

  return getJsx();
};

export default SavedObjectDetailsOverlay;
