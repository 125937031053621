import React, { useEffect } from "react";
import { Alert, Form, Typography } from "antd";
import CreateGoogleEventFormItems from "./createGoogleEventFormItems";
import LinkedArtifactRow from "./linkedArtifactRow";
import {
  getCurrentDateString,
  getCurrentTimePlusOneHourStringFlooredToHour,
  getCurrentTimeStringFlooredToHour,
} from "../datetimePickers/dateTimePickerUtils";

const { Text } = Typography;

const CreateGoogleEventForm = ({
  initialValues,
  ecObject,
  onValuesChange,
  onFormInstanceReady,
}) => {
  const [form] = Form.useForm();

  if (!initialValues?.datetimes) {
    const date1 = getCurrentDateString();
    const date2 = getCurrentDateString();
    const time1 = getCurrentTimeStringFlooredToHour();
    const time2 = getCurrentTimePlusOneHourStringFlooredToHour();
    initialValues.datetimes = {
      date1: date1,
      date2: date2,
      time1: time1,
      time2: time2,
    };
  }

  useEffect(() => {
    onFormInstanceReady(form);
  }, [form, onFormInstanceReady]);

  return (
    <>
      <Alert
        style={{ marginBottom: 10 }}
        showIcon
        type={"warning"}
        message={"After opening in Google Calendar, remember to hit save."}
      />

      <Form
        layout="vertical"
        form={form}
        name={"CreateGoogleEventForm"}
        initialValues={initialValues}
        onValuesChange={(value) => {
          if (onValuesChange) {
            onValuesChange?.(value, form.getFieldsValue());
          }
        }}
      >
        <CreateGoogleEventFormItems />
      </Form>
      <LinkedArtifactRow ecObject={ecObject} />
    </>
  );
};

export default CreateGoogleEventForm;
