import React, { useEffect } from "react";
import { Form } from "antd";
import CreateOrEditTodoFormItems from "./createOrEditTodoFormItems";
import {
  getDefaultPresetDates,
  getDefaultPresetTimes,
} from "../../../common/datetimePickers/dateTimePickerUtils";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";
import LinkedArtifactRow from "../../../common/commonTldr/linkedArtifactRow";

const CreateOrEditTodoItemForm = ({
  initialValues,
  ecObject,
  onValuesChange,
  onFormInstanceReady,
}) => {
  const { familyId } = useTldrPageContext();
  const [form] = Form.useForm();

  useEffect(() => {
    onFormInstanceReady(form);
  }, [form, onFormInstanceReady]);

  return (
    <Form
      layout="vertical"
      form={form}
      name={"CreateOrEditTodoItemForm"}
      initialValues={initialValues}
      onValuesChange={(value) => {
        if (onValuesChange) {
          onValuesChange?.(value, form.getFieldsValue());
        }
      }}
    >
      <CreateOrEditTodoFormItems
        familyId={familyId}
        datePresets={getDefaultPresetDates()}
        timePresets={getDefaultPresetTimes()}
      />
      <LinkedArtifactRow ecObject={ecObject} />
    </Form>
  );
};

export default CreateOrEditTodoItemForm;
