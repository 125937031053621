import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import useSignedUrlAsImageBackupUrl from "../../../hooks/urlCentral/useSignedUrlAsImageBackupUrl";

const { Text } = Typography;

const IMAGE_DISPLAY_STYLE_NONE = 1;
const IMAGE_DISPLAY_STYLE_ICON = 2;
const IMAGE_DISPLAY_STYLE_HERO = 3;

const LinkPreview = ({
  href,
  title,
  domain,
  imageSrc,
  contentType,
  coverHeight,
}) => {
  const [imageDisplayStyle, setImageDisplayStyle] = useState(
    IMAGE_DISPLAY_STYLE_NONE,
  );
  const imageDisplaySrc = useSignedUrlAsImageBackupUrl(imageSrc);

  useEffect(() => {
    if (imageDisplaySrc) {
      const imageLoadCallback = ({ target: image }) => {
        const width = image.naturalWidth;
        const height = image.naturalHeight;
        if (width < 16 || height < 16) {
          setImageDisplayStyle(IMAGE_DISPLAY_STYLE_NONE);
        } else if (width === height && width < 100) {
          setImageDisplayStyle(IMAGE_DISPLAY_STYLE_ICON);
        } else {
          setImageDisplayStyle(IMAGE_DISPLAY_STYLE_HERO);
        }
      };

      const imageErrorCallback = (e) => {};

      const img = new Image();
      img.onload = imageLoadCallback;
      img.onerror = imageErrorCallback;
      img.src = imageDisplaySrc;

      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }
  }, [imageDisplaySrc]);

  const getCardMeta = () => {
    return (
      <div>
        <div>
          <Text strong={true} ellipsis={true}>
            {title}
          </Text>
        </div>
        <div>
          <Text type={"secondary"} ellipsis={true}>
            {domain}
          </Text>
        </div>
      </div>
    );
  };

  const getCardCover = () => {
    if (imageDisplayStyle === IMAGE_DISPLAY_STYLE_NONE) {
      return <></>;
    } else {
      return (
        <img src={imageDisplaySrc} style={{ objectFit: "contain" }} alt={""} />
      );
    }
  };

  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div
        onClick={() => {}}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "top",
            paddingTop: 10,
            minHeight: coverHeight,
            maxHeight: coverHeight,
          }}
        >
          {getCardCover()}
        </div>
        <div style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 5 }}>
          {getCardMeta()}
        </div>
      </div>
    </a>
  );
};

export default LinkPreview;
