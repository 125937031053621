import React, { useEffect } from "react";
import MessagesAsCards from "./messagesAsCards";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import HelpIconAndModal from "../../helpIconAndModal";
import SmartInboxEducationModal from "./smartInboxEducationModal";

const SmartInbox = () => {
  const { setBreadcrumbs, setBreadcrumbExtra } = useTldrPageContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Smart Inbox",
      },
    ]);
    setBreadcrumbExtra(
      <HelpIconAndModal educationModalClass={SmartInboxEducationModal} />,
    );
  }, [setBreadcrumbs, setBreadcrumbExtra]);

  const getMessagesAsCards = () => {
    return <MessagesAsCards viewArchived={false} />;
  };

  return getMessagesAsCards();
};

export default SmartInbox;
