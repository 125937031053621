import useQueryGenericPost from "../framework/useQueryGenericPost";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../serviceUrls/baseUrls";
import { O_SERVICE_POST_TRACKING_DATA } from "../../serviceUrls/serviceUrlsOrchestrate";

const usePostTrackingInfo = (onSubmit, onSuccess, onError) => {
  return useQueryGenericPost(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_POST_TRACKING_DATA,
    null,
    onSubmit,
    onSuccess,
    onError,
    true,
  );
};

export default usePostTrackingInfo;
