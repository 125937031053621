import useQueryGenericGet from "../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../serviceUrls/baseUrls";
import { EC_SERVICE_MAGIC_EVENTS_LIST_MESSAGES_FOR_EVENT } from "../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../../../hooks/emailCollector/useEmailCollectorServiceUrlHelper";

const useListMessagesForMagicCalendarEvent = (eventRollupSortKey) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_MAGIC_EVENTS_LIST_MESSAGES_FOR_EVENT,
    { eventRollupSortKey: eventRollupSortKey },
  );

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    {},
    !!(eventRollupSortKey && currentFamily),
  );
};

export default useListMessagesForMagicCalendarEvent;
