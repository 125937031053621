import { useEffect, useState } from "react";

import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";

const useMapEcQueryIdToOwnerId = () => {
  const { currentUser } = useTldrPageContext();
  const [queryIdsToOwnerIds, setQueryIdsToOwnerIds] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const queryIdsToOwnerIdsLocal = {};
      for (const i in currentUser.families) {
        const family = currentUser.families[i];
        for (const j in family.inboxes) {
          const inbox = family.inboxes[j];
          const ownerId = inbox.owner_id;
          const queryId = inbox.email_collector_query_id;
          queryIdsToOwnerIdsLocal[queryId] = ownerId;
        }
      }
      setQueryIdsToOwnerIds(queryIdsToOwnerIdsLocal);
    }
  }, [currentUser]);

  return { queryIdsToOwnerIds };
};

export default useMapEcQueryIdToOwnerId;
