import React from "react";
import { useNavigate } from "react-router-dom";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";
import TldrCardWithCoverImage from "../../../common/generic/tldrCardWithCoverImage";
import { Button, Modal, Space, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const BoardListItem = ({ board, onEdit, onDelete }) => {
  const navigate = useNavigate();
  const { familyId } = useTldrPageContext();

  const showDeleteConfirm = () => {
    confirm({
      title: `Delete ${board.name}?`,
      icon: <WarningOutlined />,
      content: "Deleting this board will also delete any pinned tiles.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: onDelete,
      cancelButtonProps: {
        style: {
          borderRadius: 20,
        },
      },
      okButtonProps: {
        style: {
          borderRadius: 20,
        },
      },
    });
  };

  return (
    <TldrCardWithCoverImage
      imageSrc={board.cover_image_url}
      coverHeight={300}
      imageSize={null}
      width={"auto"}
      alt={board.name}
      preview={false}
      hoverable={false}
      bodyPadding={8}
      onClickCover={() => {
        navigate(`/families/${familyId}/boards/${board.id}`);
      }}
    >
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 5,
          paddingTop: 5,
          alignItems: "center",
        }}
      >
        <Typography.Title
          level={3}
          ellipsis={true}
          style={{ margin: 0, padding: 0 }}
        >
          {board.name}
        </Typography.Title>
        <Space style={{ marginLeft: 4 }}>
          <Button type={"primary"} ghost={true} onClick={onEdit}>
            Edit
          </Button>
          <Button
            type={"primary"}
            ghost={true}
            danger={true}
            onClick={() => showDeleteConfirm()}
          >
            Delete
          </Button>
        </Space>
      </div>
    </TldrCardWithCoverImage>
  );
};

export default BoardListItem;
