import React, { useEffect, useState } from "react";
import { List, Skeleton } from "antd";
import useScreenSizeAndOrientation, {
  SCREEN_SIZE_EXTRA_SMALL,
  SCREEN_SIZE_MEDIUM,
  SCREEN_SIZE_SMALL,
} from "../../../hooks/utils/useScreenSizeAndOrientation";
import useCreateOrEditOrchestrateObject from "../../../hooks/orchestrate/common/useCreateOrEditOrchestrateObject";
import OrchestrateObjectFormOverlays from "../../familyOrganizer/orchestrateObjectFormOverlays";
import AnyEcArtifactDetailsOverlay from "../../ecObjectViewers/anyEcArtifactDetailsOverlay";
import useArtifactDetailOverlay from "../../ecObjectViewers/hooks/useArtifactDetailOverlay";
import useGetSavedObjectsForEcArtifacts from "../../../hooks/orchestrate/common/useGetSavedObjectsForEcArtifacts";
import EventSuggestionsEducationModal from "./../eventSuggestionsEducationModal";
import MagicCalendarDay from "./magicCalendarDay";

const MagicCalendarInner = ({
  zeroResults,
  eventsAndStartDates,
  eventsList,
  showHidden,
  handleUpdateHiddenStatus,
}) => {
  const {
    dataForDetailsOverlay,
    setDataForDetailsOverlay,
    detailsOverlayOpen,
    openArtifactDetailOverlay,
    closeArtifactDetailOverlay,
  } = useArtifactDetailOverlay();

  const { size: screenSize } = useScreenSizeAndOrientation();
  const [showCardTitles, setShowCardTitles] = useState(false);

  const {
    eventOverlayOpen,
    closeEventOverlay,
    eventFormValues,
    eventMergeValues,
    todoOverlayOpen,
    closeTodoOverlay,
    todoFormValues,
    todoMergeValues,
    clippingOverlayOpen,
    closeClippingOverlay,
    clippingFormValues,
    clippingMergeValues,
    tileOverlayOpen,
    closeTileOverlay,
    tileFormValues,
    tileMergeValues,
    ecObjectActedUpon,
    onClickCardAction,
  } = useCreateOrEditOrchestrateObject();

  useEffect(() => {
    if (
      [SCREEN_SIZE_EXTRA_SMALL, SCREEN_SIZE_SMALL, SCREEN_SIZE_MEDIUM].includes(
        screenSize,
      )
    ) {
      setShowCardTitles(true);
    } else {
      setShowCardTitles(false);
    }
  }, [screenSize]);

  const { data: savedObjectsByEcArtifactIds } =
    useGetSavedObjectsForEcArtifacts(eventsList);

  const getEventsList = () => {
    if (zeroResults) {
      return <>No Event Suggestions Found</>;
    }

    if (!eventsAndStartDates) {
      return <Skeleton active={true} />;
    }

    return (
      <List
        itemLayout="horizontal"
        dataSource={eventsAndStartDates?.startDates || []}
        renderItem={(startDate) => {
          return (
            <MagicCalendarDay
              startDateAsString={startDate}
              eventsAndStartDates={eventsAndStartDates}
              showHidden={showHidden}
              handleUpdateHiddenStatus={handleUpdateHiddenStatus}
              showCardTitles={showCardTitles}
              savedObjectsByEcArtifactIds={savedObjectsByEcArtifactIds}
              onClickCardAction={onClickCardAction}
              onClickFoundInMessages={(event, messages) => {
                setDataForDetailsOverlay({
                  event: { ...event },
                  messages: [...messages],
                });
                openArtifactDetailOverlay();
              }}
            />
          );
        }}
      />
    );
  };

  return (
    <>
      <OrchestrateObjectFormOverlays
        eventInitialFormValues={eventFormValues}
        eventMergeValues={eventMergeValues}
        eventOverlayOpen={eventOverlayOpen}
        onCancelEvent={() => closeEventOverlay()}
        onSubmitEvent={() => closeEventOverlay()}
        onPersistEvent={(item) => {}}
        todoInitialFormValues={todoFormValues}
        todoMergeValues={todoMergeValues}
        todoOverlayOpen={todoOverlayOpen}
        onCancelTodo={() => closeTodoOverlay()}
        onSubmitTodo={() => closeTodoOverlay()}
        onPersistTodo={(item) => {}}
        clippingInitialFormValues={clippingFormValues}
        clippingMergeValues={clippingMergeValues}
        clippingOverlayOpen={clippingOverlayOpen}
        onCancelClipping={() => closeClippingOverlay()}
        onSubmitClipping={() => closeClippingOverlay()}
        onPersistClipping={(item) => {}}
        tileInitialFormValues={tileFormValues}
        tileMergeValues={tileMergeValues}
        tileOverlayOpen={tileOverlayOpen}
        onCancelTile={() => closeTileOverlay()}
        onSubmitTile={() => closeTileOverlay()}
        onPersistTile={(item) => {}}
        ecObjectActedUpon={ecObjectActedUpon}
      />
      <EventSuggestionsEducationModal forced={true} />
      <AnyEcArtifactDetailsOverlay
        open={detailsOverlayOpen}
        onClose={() => {
          closeArtifactDetailOverlay();
          setDataForDetailsOverlay(null);
        }}
        ecObject={dataForDetailsOverlay?.event}
        scrollToFirstHighlight={true}
      />
      {getEventsList()}
    </>
  );
};

export default MagicCalendarInner;
