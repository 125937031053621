import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "./apiClient";
import { createQueryKey } from "../../utils/utils";

const useQueryGenericGet = (
  baseUrl,
  endpoint,
  sendAuthToken,
  extraPathParams,
  queryParams,
  enabled,
  staleTime,
  keepPrevData,
) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);

  if (!sendAuthToken) sendAuthToken = false;
  if (!extraPathParams) extraPathParams = [];
  if (!queryParams) queryParams = {};
  if (!staleTime) staleTime = 0;

  const queryKey = createQueryKey(
    baseUrl,
    endpoint,
    extraPathParams,
    queryParams,
  );

  if (keepPrevData !== false) keepPrevData = true;
  const placeholderDataDict = {};
  if (keepPrevData) {
    placeholderDataDict.placeholderData = keepPreviousData;
  }

  return {
    ...useQuery({
      enabled: enabled !== false,
      queryKey: queryKey,
      queryFn: async () => {
        let token = undefined;
        if (sendAuthToken) {
          token = await getAccessTokenSilently();
        }

        const client = getApiClientWithBaseUrl(baseUrl, endpoint, token);
        return client.get(extraPathParams, queryParams);
      },
      ...placeholderDataDict,
      staleTime: staleTime,
    }),
    queryKey: queryKey,
  };
};

export default useQueryGenericGet;
