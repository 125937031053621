import React from "react";
import { Typography } from "antd";
import CardCenteredTextCover from "../../common/generic/cardCenteredTextCover";
import prettyBytes from "pretty-bytes";

const AttachmentTextCover = ({ ecObject, onClick }) => {
  const getFileName = () => {
    if (!ecObject) return "";
    return !!ecObject?.filename ? ecObject.filename : "- No filename - ";
  };

  return (
    <CardCenteredTextCover onClick={onClick || null}>
      <Typography.Title ellipsis={true} level={3}>
        {getFileName()}
      </Typography.Title>
      {ecObject?.content_length && (
        <div>{prettyBytes(ecObject.content_length)}</div>
      )}
      {ecObject?.mime_type && <div>{ecObject.mime_type}</div>}
    </CardCenteredTextCover>
  );
};

export default AttachmentTextCover;
