import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "../../../hooks/framework/apiClient";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../serviceUrls/baseUrls";
import { EC_SERVICE_MAGIC_EVENTS_UPDATE_HIDDEN } from "../../../serviceUrls/serviceUrlsEmailCollector";
import { replacePlaceholdersInTemplate } from "../../../serviceUrls/serviceUrlHelpers";

const useUpdateTldrEventRollupHiddenStatus = (onSubmit, onSuccess, onError) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);

  return useMutation({
    mutationFn: async (data) => {
      const token = await getAccessTokenSilently();
      const tenantId = data.tenant_id;
      const eventRollupSortKey = data.event_rollup_sort_key;
      const patchData = { is_hidden: data.is_hidden };
      let endpoint = replacePlaceholdersInTemplate(
        EC_SERVICE_MAGIC_EVENTS_UPDATE_HIDDEN,
        { eventRollupSortKey },
      );
      endpoint = `/tenants/${tenantId}${endpoint}`;

      return getApiClientWithBaseUrl(
        BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
        endpoint,
        token,
      ).patchWithoutId(patchData);
    },

    onMutate: (unsaved) => {
      if (onSubmit) onSubmit(unsaved);
    },

    onSuccess: (saved, submitted) => {
      if (onSuccess) onSuccess(saved);
    },

    onError: (error, unsaved, context) => {
      if (onError) onError(error);
    },
  });
};

export default useUpdateTldrEventRollupHiddenStatus;
