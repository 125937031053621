import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";
import { O_SERVICE_FAMILIES_UPDATE } from "../../../serviceUrls/serviceUrlsOrchestrate";
import useQueryGenericPut from "../../framework/useQueryGenericPut";

const useUpdateFamily = (onSubmit, onSuccess, onError) => {
  return useQueryGenericPut(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_FAMILIES_UPDATE,
    onSubmit,
    onSuccess,
    onError,
  );
};

export default useUpdateFamily;
