export const dedupItems = (items) => {
  const usedFingerprints = new Set();
  return items.filter((item) => {
    const fingerprint = item.rollup.fingerprint;
    if (usedFingerprints.has(fingerprint)) {
      return false;
    }
    usedFingerprints.add(fingerprint);
    return true;
  });
};
