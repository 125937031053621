import React, { useEffect } from "react";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import { Tabs } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import HomeSuggestedWorkflow from "./homeSuggestedWorkflow";
import HomeDashboard from "../dashboard/homeDashboard";

const Home = () => {
  const { clearBreadcrumbs, clearBreadcrumbExtra } = useTldrPageContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    clearBreadcrumbs();
    clearBreadcrumbExtra();
  }, [clearBreadcrumbs, clearBreadcrumbExtra]);

  const getContent = () => {
    if (searchParams.get("view") === "workflow") {
      return <HomeSuggestedWorkflow />;
    } else {
      return <HomeDashboard />;
    }
  };

  const tabItems = [
    {
      label: "Dashboard",
      key: "dashboard",
    },
    {
      label: "Recommended Workflow",
      key: "workflow",
    },
  ];

  return (
    <>
      <Tabs
        size={"large"}
        activeKey={searchParams.get("view") || "dashboard"}
        items={tabItems}
        onChange={(value) => {
          searchParams.set("view", value);
          navigate({
            search: searchParams.toString(),
          });
        }}
      />
      {getContent()}
    </>
  );
};

export default Home;
