import React, { useEffect } from "react";
import logo from "../../../images/tldrparents-logo-vertical.svg";
import { Button } from "antd/lib";
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import { Card } from "antd";
import MaxWidthWrapper from "./maxWidthWrapper";

const NotFoundOrErrorPage = () => {
  const navigate = useNavigate();
  const error = useRouteError();
  const isInvalidUrl = isRouteErrorResponse(error);

  useEffect(() => {}, []);

  if (isInvalidUrl) {
    return (
      <div style={{ textAlign: "center", marginTop: "25vh" }}>
        <MaxWidthWrapper maxWidth={1220}>
          <Card>
            <img src={logo} alt="TLDR Parents" />
            <h1>Page Not Found</h1>
            <p>
              <Button onClick={() => navigate("/")}>Return Home</Button>
            </p>
          </Card>
        </MaxWidthWrapper>
      </div>
    );
  } else {
    return (
      <div style={{ textAlign: "center", marginTop: "25vh" }}>
        <MaxWidthWrapper maxWidth={1220}>
          <Card>
            <img src={logo} alt="TLDR Parents" />
            <h1>An Error Occurred</h1>
            <p>
              <Button onClick={() => navigate("/")}>Return Home</Button>
            </p>
          </Card>
        </MaxWidthWrapper>
      </div>
    );
  }
};

export default NotFoundOrErrorPage;
