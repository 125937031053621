import useGetUserEducationSubjects from "./useGetUserEducationSubjects";
import { useEffect, useState } from "react";

const useShowUserEducationModal = (subjects) => {
  const [subjectToExplain, setSubjectToExplain] = useState(null);
  const { data: currentUserEducationSubjects } = useGetUserEducationSubjects();

  useEffect(() => {
    if (currentUserEducationSubjects?.hasFinishedOnboarding) {
      for (const i in currentUserEducationSubjects.subjects) {
        const subjectToTest = currentUserEducationSubjects.subjects[i];
        if (
          currentUserEducationSubjects.notPassed.has(subjectToTest.subject_id)
        ) {
          if (subjects.includes(subjectToTest.subject_id)) {
            setSubjectToExplain(subjectToTest.subject_id);
            break;
          }
        }
      }
    }
  }, [currentUserEducationSubjects, subjects]);

  return { subjectToExplain };
};

export default useShowUserEducationModal;
