import React from "react";
import { Avatar } from "antd";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";
import { useNavigate } from "react-router-dom";
import NavButtonGrid from "../common/generic/navButtons/navButtonGrid";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

const UserInfoPopoverCard = () => {
  const navigate = useNavigate();
  const { data: currentUser } = useGetCurrentUser();
  const { logout } = useAuth0(Auth0UniversalProviderContext);

  const doLogout = () => {
    logout({ returnTo: window.location.origin }).then();
  };

  const getNavButtonRows = () => {
    return [
      {
        navButtonItems: [
          //   {
          //     text: "My Settings",
          //     icon: <SettingsOutlinedIcon sx={{ fontSize: 25 }} />,
          //     onClick: () => {
          //       navigate(`/me/settings`);
          //     },
          //   },
          {
            text: "Logout",
            icon: <LogoutOutlinedIcon sx={{ fontSize: 25 }} />,
            onClick: () => {
              doLogout();
            },
          },
        ],
      },
    ];
  };

  const getJsx = () => {
    if (currentUser) {
      return (
        <div
          style={{
            width: 250,
            paddingTop: 20,
            paddingRight: 10,
            paddingLeft: 10,
            textAlign: "center",
            borderRadius: 10,
          }}
        >
          <Avatar
            size={100}
            style={{ marginBottom: 20 }}
            src={currentUser.picture}
            alt={currentUser.name}
          />
          <h3>{currentUser.name}</h3>
          <h4 style={{ marginBottom: 20 }}>{currentUser.email}</h4>
          <NavButtonGrid
            navButtonRows={getNavButtonRows()}
            bottomSpacer={8}
            buttonHeight={90}
          />
        </div>
      );
    }
  };

  return getJsx();
};

export default UserInfoPopoverCard;
