import React from "react";
import DetailPanelSkeleton from "./detailPanelParts/detailPanelSkeleton";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import EventCardCover from "../cardCovers/eventCardCover";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";

const EventDetailPanel = ({ ecObject, actionMenu }) => {
  const { currentFamily } = useTldrPageContext();

  const position1 = (
    <TldrCardWithCoverComponent
      coverComponent={<EventCardCover ecObject={ecObject} />}
      width={"auto"}
      coverHeight={300}
      hoverable={false}
      onClick={null}
    />
  );

  return (
    <DetailPanelSkeleton
      position1={position1}
      position2={!!actionMenu && actionMenu}
      position3={null}
    />
  );
};

export default EventDetailPanel;
