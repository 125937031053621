import React from "react";
import ArtifactCard from "../../common/generic/artifactCard";
import { Tooltip } from "antd";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import EventCardCover from "../cardCovers/eventCardCover";

const EventCard = ({
  ecObject,
  cardProperties,
  onClickViewEcObjectDetails,
  sourceMessageInfo,
  actionMenu,
}) => {
  return (
    <ArtifactCard
      cover={<EventCardCover ecObject={ecObject} />}
      position1={sourceMessageInfo}
      position2={
        <Tooltip title={"Event"}>
          <EventOutlinedIcon sx={{ color: "#ff4081" }} />
        </Tooltip>
      }
      position3={actionMenu}
    />
  );
};

export default EventCard;
