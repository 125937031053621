import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../serviceUrls/baseUrls";
import useQueryGenericGetWithPost from "../../../hooks/framework/useQueryGenericGetWithPost";
import { EC_SERVICE_MAGIC_EVENTS_BATCH_GET } from "../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../../../hooks/emailCollector/useEmailCollectorServiceUrlHelper";

const useBatchGetMagicEvents = (magicEventSortKeys) => {
  const postData = { event_rollup_sort_keys: magicEventSortKeys };

  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_MAGIC_EVENTS_BATCH_GET,
    {},
  );

  return useQueryGenericGetWithPost(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    postData,
    true,
    !!(currentFamily && magicEventSortKeys?.length > 0 && postData),
  );
};

export default useBatchGetMagicEvents;
