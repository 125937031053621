import React, { useEffect, useState } from "react";
import { Button, Calendar, theme, Typography } from "antd";
import dayjs from "dayjs";
import dayLocaleData from "dayjs/plugin/localeData";
import {
  DUMMY_TIME_STRING,
  getDayJsDatetimeFromStrings,
  toDateString,
} from "../../../utils/dayJsUtils";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { getCurrentDateString } from "./dateTimePickerUtils";

const { Text } = Typography;

dayjs.extend(dayLocaleData);

const changePanel = (panelSelectedDateValue, onChange, sign, magnitude) => {
  let newDate;
  if (magnitude > 1) {
    if (sign > 0) {
      newDate = panelSelectedDateValue.clone().add(1, "year");
    } else {
      newDate = panelSelectedDateValue.clone().add(-1, "year");
    }
  } else {
    if (sign > 0) {
      newDate = panelSelectedDateValue.clone().add(1, "month");
    } else {
      newDate = panelSelectedDateValue.clone().add(-1, "month");
    }
  }
  onChange(newDate);
};

const getHeader = (panelSelectedDateValue, onChange) => {
  return (
    <div
      style={{
        padding: 8,
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button
            onClick={() => {
              changePanel(panelSelectedDateValue, onChange, -1, 2);
            }}
            size={"small"}
            type={"text"}
          >
            <DoubleLeftOutlined />
          </Button>
          <Button
            onClick={() => {
              changePanel(panelSelectedDateValue, onChange, -1, 1);
            }}
            size={"small"}
            type={"text"}
          >
            <LeftOutlined />
          </Button>
        </div>
        <div>
          <Text strong>
            {panelSelectedDateValue.format("MMM")}{" "}
            {panelSelectedDateValue.format("YYYY")}
          </Text>
        </div>
        <div>
          <Button
            onClick={() => {
              changePanel(panelSelectedDateValue, onChange, 1, 1);
            }}
            size={"small"}
            type={"text"}
          >
            <RightOutlined />
          </Button>
          <Button
            onClick={() => {
              changePanel(panelSelectedDateValue, onChange, 1, 2);
            }}
            size={"small"}
            type={"text"}
          >
            <DoubleRightOutlined />
          </Button>
        </div>
      </div>
    </div>
  );
};

const EmbeddedDatepicker = ({ value, onChange }) => {
  const [internalFauxValue, setInternalFauxValue] = useState(undefined);
  const [actualSelectedValue, setActualSelectedValue] = useState(undefined);
  const { token } = theme.useToken();

  useEffect(() => {
    if (value) {
      setActualSelectedValue(value);
      setInternalFauxValue(value);
    } else {
      setActualSelectedValue(getCurrentDateString());
      setInternalFauxValue(getCurrentDateString());
    }
  }, [value]);

  const wrapperStyle = {
    width: 290,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  const handleSelect = (newValue) => {
    const valueAsStringLocal = toDateString(newValue);
    setActualSelectedValue(valueAsStringLocal);
    onChange(valueAsStringLocal);
  };

  return (
    <div style={wrapperStyle}>
      <Calendar
        value={getDayJsDatetimeFromStrings(
          internalFauxValue,
          DUMMY_TIME_STRING,
        )}
        fullscreen={false}
        headerRender={({ value, onChange }) => {
          return getHeader(value, onChange);
        }}
        onChange={(value) => {
          setInternalFauxValue(value.format("YYYY-MM-DD"));
        }}
        fullCellRender={(dateOfMonth) => {
          if (dateOfMonth.format("YYYY-MM-DD") === internalFauxValue) {
            if (internalFauxValue !== actualSelectedValue) {
              // Don't highlight the fake internal value. Just treat it like a normal date
              return (
                <div
                  style={{ width: "90%" }}
                  onClick={() => handleSelect(dateOfMonth)}
                >
                  <div className="ant-picker-calendar-date-value">
                    <Button size={"small"} type={"text"}>
                      {dateOfMonth.format("D")}
                    </Button>
                  </div>
                  <div className="ant-picker-calendar-date-content"></div>
                </div>
              );
            }
          }
          return (
            <div
              style={{ width: "90%" }}
              className="ant-picker-cell-inner ant-picker-calendar-date"
              onClick={() => handleSelect(dateOfMonth)}
            >
              <div className="ant-picker-calendar-date-value">
                {dateOfMonth.format("D")}
              </div>
              <div className="ant-picker-calendar-date-content"></div>
            </div>
          );
        }}
      />
    </div>
  );
};
export default EmbeddedDatepicker;
