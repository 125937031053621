import React from "react";
import ArtifactCard from "../../common/generic/artifactCard";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import { Tooltip } from "antd";
import EmailAddressCardCover from "../cardCovers/emailAddressCardCover";

const EmailAddressCard = ({
  ecObject,
  cardProperties,
  onClickViewEcObjectDetails,
  sourceMessageInfo,
  actionMenu,
}) => {
  return (
    <ArtifactCard
      cover={<EmailAddressCardCover ecObject={ecObject} />}
      position1={sourceMessageInfo}
      position2={
        <Tooltip title={"Email Address"}>
          <AlternateEmailOutlinedIcon sx={{ color: "#ff4081" }} />
        </Tooltip>
      }
      position3={actionMenu}
    />
  );
};

export default EmailAddressCard;
