import React from "react";
import { Radio, Space } from "antd";
import useListOrchestrateObjects from "../../../hooks/orchestrate/useListOrchestrateObjects";
import { O_SERVICE_FAMILY_MEMBERS } from "../../../serviceUrls/serviceUrlsOrchestrate";

const UNASSIGNED_ID_VALUE = -1;

const UserSelect = ({ value, onChange }) => {
  const { data: familyMembers } = useListOrchestrateObjects(
    O_SERVICE_FAMILY_MEMBERS,
    true,
  );

  const getOptions = () => {
    if (familyMembers) {
      const options = familyMembers.map((familyMember) => {
        return {
          value: familyMember.member.id,
          label: familyMember.member.name,
        };
      });
      options.unshift({ value: UNASSIGNED_ID_VALUE, label: "Unassigned" });
      return options;
    } else {
      return [];
    }
  };

  return (
    <Radio.Group onChange={onChange} value={value || UNASSIGNED_ID_VALUE}>
      <Space direction="vertical">
        {getOptions().map((option, index) => {
          return (
            <Radio key={index} value={option.value}>
              {option.label}
            </Radio>
          );
        })}
      </Space>
    </Radio.Group>
  );
};

export default UserSelect;
