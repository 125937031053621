import useQueryGenericGet from "../../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../../serviceUrls/baseUrls";
import useOrchestrateServiceUrlHelper from "../../../../hooks/orchestrate/useOrchestrateServiceUrlHelper";

const useBillingGet = (endpointTemplateUrl) => {
  const { endpointPath } = useOrchestrateServiceUrlHelper(
    endpointTemplateUrl,
    {},
  );
  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    endpointPath,
    true,
    [],
    {},
    true,
  );
};

export default useBillingGet;
