import React from "react";
import UserEducationModal from "../../userEducation/userEducationModal";
import { Modal } from "antd";

const EmailAddressesRollupEducationModal = ({ forced, open, onClose }) => {
  const title = "Email Address List ✉️";
  const inner = (
    <>
      <p>
        We automatically capture and save every email address from your
        messages. Can't find the school counselor's email? Stop by here for a
        look!
      </p>
      <h3>Workflow Tips 🔧</h3>
      <p>
        Click the actions menu to effortlessly add an email to your Google
        contacts or pin it to a board.
      </p>
    </>
  );

  if (!!forced) {
    return (
      <UserEducationModal
        title={title}
        subjects={["EXPLAIN_EMAIL_ADDRESSES_ROLLUP"]}
      >
        {inner}
      </UserEducationModal>
    );
  } else {
    return (
      <Modal
        title={title}
        open={open}
        closable={true}
        onCancel={() => onClose()}
        onOk={null}
        footer={null}
      >
        {inner}
      </Modal>
    );
  }
};

export default EmailAddressesRollupEducationModal;
