import React, { forwardRef } from "react";

import HtmlStringToReactComponentInner from "./htmlStringToReactComponentInner";
import { domToReact } from "html-react-parser";

const HtmlStringToReactComponent = (
  { htmlAsString, attachments, onComponentDidMount },
  ref,
) => {
  const parseOptions = {
    replace: (domNode) => {
      if (domNode.name === "!doctype") {
        return <>{domToReact(domNode.next, parseOptions)}</>;
      } else if (domNode.name === "html" || domNode.name === "head") {
        return <>{domToReact(domNode.children, parseOptions)}</>;
      } else if (domNode.name === "meta") {
        return <></>;
      } else if (domNode.name === "body") {
        return <div>{domToReact(domNode.children, parseOptions)}</div>;
      } else if (domNode.name === "img") {
        if (domNode.attribs?.src?.toLowerCase().startsWith("cid")) {
          if (attachments && attachments.length > 0) {
            domNode.attribs.src = attachments[0].signed_url;
          }
        }
      }
    },
  };

  return (
    <div style={{ overflowX: "scroll" }}>
      <HtmlStringToReactComponentInner
        htmlAsString={htmlAsString}
        parseOptions={parseOptions}
        onComponentDidMount={onComponentDidMount}
        ref={ref}
      />
    </div>
  );
};

export default forwardRef(HtmlStringToReactComponent);
