import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";
import AbsoluteSpin from "../common/generic/absoluteSpin";
import useGetAndSetSelectedFamilyId from "../../hooks/orchestrate/common/useGetAndSetSelectedFamilyId";

const IndexComponent = () => {
  let location = useLocation();
  const { isLoading: isAuth0Loading, isAuthenticated: isAuth0Authenticated } =
    useAuth0(Auth0UniversalProviderContext);
  const { data: currentUser } = useGetCurrentUser();
  const { selectedFamilyId } = useGetAndSetSelectedFamilyId();

  function getJsx() {
    if (isAuth0Loading) {
      console.log("Auth Loading");
      return <AbsoluteSpin />;
    }

    if (!isAuth0Authenticated) {
      console.log("Not authed, going to login");
      return <Navigate to="/login" state={{ from: location }} />;
    }

    if (!currentUser) {
      console.log("Waiting for current user to load");
      return <AbsoluteSpin />;
    }

    if (currentUser.families.length === 0) {
      console.log("User has no families, sending them to workspace creation");
      return <Navigate to="/create-workspace" />;
    }

    // This is a bit hacky. It's for when the family ID changes such as after accepting an invitation
    if (!selectedFamilyId) {
      console.log(
        "Selected family Id still not loaded. Force a redirect to the first family we find",
      );
      return <Navigate to={`/families/${currentUser.families[0].id}`} />;
    }

    console.log("Sending user to their home screen");
    return <Navigate to={`/families/${selectedFamilyId}`} replace={true} />;
  }

  return <>{getJsx()}</>;
};

export default IndexComponent;
