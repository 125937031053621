import useOrchestrateServiceUrlHelper from "../../../../hooks/orchestrate/useOrchestrateServiceUrlHelper";
import { O_SERVICE_CLIPPINGS_REMOVE_DUPLICATES } from "../../../../serviceUrls/serviceUrlsOrchestrate";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getApiClientWithBaseUrl } from "../../../../hooks/framework/apiClient";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../../../context/auth0UniversalProviderContext";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../../serviceUrls/baseUrls";

const useRemoveDuplicatesFromClippingScroll = (
  queryKey,
  onSubmit,
  onSuccess,
  onError,
) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);
  const queryClient = useQueryClient();

  const { endpointPath } = useOrchestrateServiceUrlHelper(
    O_SERVICE_CLIPPINGS_REMOVE_DUPLICATES,
    {},
  );

  return useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();

      const client = getApiClientWithBaseUrl(
        BACKEND_API_BASE_URL_ORCHESTRATE,
        endpointPath,
        token,
      );
      return client.delete();
    },

    onMutate: () => {
      if (onSubmit) onSubmit();
    },

    onSuccess: (x) => {
      queryClient.invalidateQueries({ queryKey: queryKey }).then();
      if (onSuccess) onSuccess();
    },

    onError: (error, unsaved, context) => {
      if (onError) onError(error);
    },
  });
};

export default useRemoveDuplicatesFromClippingScroll;
