import React from "react";
import { Card } from "antd";

const ResponsiveCard = ({
  title,
  className,
  classNames,
  styles,
  style,
  hoverable,
  cover,
  onClick,
  extra,
  bordered,
  children,
}) => {
  if (!classNames) {
    classNames = { body: "responsive-card-body" };
  } else if (classNames.body) {
    classNames.body = classNames.body + " responsive-card-body";
  } else {
    classNames.body = "responsive-card-body";
  }
  return (
    <Card
      bordered={bordered !== false}
      title={title}
      className={className}
      classNames={classNames}
      styles={styles}
      style={style}
      hoverable={hoverable === true}
      cover={cover}
      onClick={onClick}
      extra={extra}
    >
      {children}
    </Card>
  );
};

export default ResponsiveCard;
