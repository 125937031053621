import React from "react";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import MiniCardTitleRow from "../miniCardParts/miniCardTitleRow";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import EventMiniCardCover from "../cardCovers/eventMiniCardCover";

const EventMiniCard = ({ ecObject, appContext, onClick }) => {
  return (
    <TldrCardWithCoverComponent
      coverComponent={<EventMiniCardCover ecObject={ecObject} />}
      width={250}
      coverHeight={150}
      hoverable={false}
      onClick={onClick}
    >
      <MiniCardTitleRow
        titleText={ecObject?.title}
        tooltipText={"Event"}
        icon={<EventOutlinedIcon sx={{ color: "#ff4081" }} />}
      />
    </TldrCardWithCoverComponent>
  );
};

export default EventMiniCard;
