import React from "react";
import { Card, Divider } from "antd";
import TldrCardCoverImage from "./tldrCardCoverImage";
import useObserveElementSize from "./useObserveElementSize";

const TldrCardWithCoverImage = ({
  imageSrc,
  imageSize,
  alt,
  width,
  coverHeight,
  hoverable,
  preview,
  onClick,
  onClickCover,
  bodyPadding,
  children,
}) => {
  const { observedElementRef: cardRef, width: cardWidth } =
    useObserveElementSize();

  if (!width) {
    width = "auto";
  }

  if (!bodyPadding) {
    bodyPadding = 8;
  }

  return (
    <Card
      hoverable={hoverable}
      ref={cardRef}
      style={{ width: width, cursor: !!onClick && "pointer" }}
      styles={{ body: { padding: 0 } }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <div
        style={{
          width: "100%",
          height: coverHeight,
          overflow: "clip",
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: !children && 8,
          borderBottomRightRadius: !children && 8,
          cursor: !!onClickCover && "pointer",
        }}
        onClick={() => {
          if (onClickCover) {
            onClickCover();
          }
        }}
      >
        <TldrCardCoverImage
          imageSrc={imageSrc}
          imageSize={imageSize}
          preview={preview}
          containerWidth={cardWidth}
          containerHeight={coverHeight}
          alt={alt}
        />
      </div>
      {!!children && (
        <>
          <Divider style={{ margin: 0 }} />
          <div style={{ width: "100%", padding: bodyPadding }}>{children}</div>
        </>
      )}
    </Card>
  );
};

export default TldrCardWithCoverImage;
