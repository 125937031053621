import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useGetCurrentUser from "../../../hooks/orchestrate/user/useGetCurrentUser";
import useUpdateableMessage from "../../../hooks/utils/useUpdateableMessage";
import useInvalidateQuery from "../../../hooks/utils/useInvalidateQuery";

const useTldrMePageContext = () => {
  const location = useLocation();
  const { data: currentUser, refetch: refetchCurrentUser } =
    useGetCurrentUser();

  const { openLoadingMessage, openSuccessMessage, openErrorMessage } =
    useUpdateableMessage();
  const { invalidateQueryWhereKeyIs, invalidateQueryWhereKeyContains } =
    useInvalidateQuery();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      location &&
      currentUser &&
      openLoadingMessage &&
      openSuccessMessage &&
      openErrorMessage &&
      invalidateQueryWhereKeyIs &&
      invalidateQueryWhereKeyContains
    ) {
      setIsLoading(false);
    }
  }, [
    location,
    currentUser,
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyIs,
    invalidateQueryWhereKeyContains,
  ]);

  return {
    location: location,
    currentUser: currentUser,
    refetchCurrentUser: refetchCurrentUser,
    openLoadingMessage: openLoadingMessage,
    openSuccessMessage: openSuccessMessage,
    openErrorMessage: openErrorMessage,
    invalidateQueryWhereKeyIs: invalidateQueryWhereKeyIs,
    invalidateQueryWhereKeyContains: invalidateQueryWhereKeyContains,
    isLoading: isLoading,
  };
};

export default useTldrMePageContext;
