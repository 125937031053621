import React from "react";
import DetailPanelSkeleton from "./detailPanelParts/detailPanelSkeleton";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import TopicCardCover from "../cardCovers/topicCardCover";

const TopicDetailPanel = ({ ecObject, actionMenu }) => {
  const cover = <TopicCardCover ecObject={ecObject} />;

  const position1 = (
    <TldrCardWithCoverComponent
      coverComponent={cover}
      width={"auto"}
      coverHeight={300}
      hoverable={false}
      onClick={null}
    />
  );

  return (
    <DetailPanelSkeleton
      position1={position1}
      position2={!!actionMenu && actionMenu}
      position3={null}
    />
  );
};

export default TopicDetailPanel;
