const getItem = (artifactKey) => {
  if (artifactKey.item_sort_key) {
    return [artifactKey.item_hash_key, artifactKey.item_sort_key];
  } else {
    return artifactKey.item_hash_key;
  }
};

const getRollupItem = (artifactKey) => {
  if (artifactKey.rollup_sort_key) {
    return [artifactKey.rollup_hash_key, artifactKey.rollup_sort_key];
  } else {
    return artifactKey.rollup_hash_key;
  }
};

export const createBatchGetPostData = (items) => {
  const batchGetDefaultDict = {
    attachment: [],
    attachment_rollup: [],
    email_address: [],
    email_address_rollup: [],
    image: [],
    image_rollup: [],
    link: [],
    link_rollup: [],
    link_image: [],
    link_image_rollup: [],
    non_http_link: [],
    non_http_link_rollup: [],
    event: [],
    event_rollup: [],
    topic: [],
    topic_rollup: [],
    message: [],
    summary_bullet: [],
  };

  for (const i in items) {
    if (items[i].artifacts) {
      for (const j in items[i].artifacts) {
        const artifact = items[i].artifacts[j];
        if (artifact.rollup_class_id) {
          batchGetDefaultDict[artifact.rollup_class_id].push(
            getRollupItem(artifact),
          );
        } else {
          batchGetDefaultDict[artifact.item_class_id].push(getItem(artifact));
        }
      }
    } else {
      const artifact = items[i];
      if (artifact.rollup_class_id) {
        batchGetDefaultDict[artifact.rollup_class_id].push(
          getRollupItem(artifact),
        );
      } else {
        batchGetDefaultDict[artifact.item_class_id].push(getItem(artifact));
      }
    }
  }
  return batchGetDefaultDict;
};
