import React from "react";

const ArtifactCardInner = ({ position1, position2, position3 }) => {
  return (
    <>
      <div
        style={{
          height: 55,
          overflowY: "hidden",
        }}
      >
        {position1}
      </div>
      <div
        style={{
          display: "flex",
          height: 45,
          overflowY: "hidden",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>{position2}</div>
        <div>{position3}</div>
      </div>
    </>
  );
};

export default ArtifactCardInner;
