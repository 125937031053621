import React from "react";
import { Button, Form, Input } from "antd";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";

const FamilyWorkspaceRenameForm = ({ onSubmit, isSubmitting }) => {
  const { currentUser } = useTldrPageContext();
  const [formFamily] = Form.useForm();

  return (
    <Form
      form={formFamily}
      name="familyForm"
      layout="vertical"
      size="large"
      onFinish={(values) => {
        onSubmit(values);
      }}
    >
      <Form.Item
        name="name"
        initialValue={
          currentUser?.family_name
            ? `The ${currentUser.family_name} Family`
            : ""
        }
        rules={[
          {
            required: true,
            message: "Come on. You know the drill. We need a name.",
          },
        ]}
      >
        <Input
          maxLength={100}
          placeholder="The Smith Family"
          disabled={isSubmitting}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          loading={isSubmitting}
          disabled={isSubmitting}
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Continue
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FamilyWorkspaceRenameForm;
