import {
  convertServerUtcDateTimeToUsersTimeZone,
  toDateString,
  toTimeString,
} from "../../../utils/dayJsUtils";
import useTldrPageContext from "./useTldrPageContext";

const useGetInitialAndMergeValuesForEdit = () => {
  const { browserTimezone } = useTldrPageContext();

  const getInitialValuesForEditTodo = (savedObject) => {
    let due = {};
    if (savedObject.due) {
      due = savedObject.due
        ? convertServerUtcDateTimeToUsersTimeZone(
            savedObject.due,
            browserTimezone,
          )
        : null;
      due = { date: toDateString(due), time: toTimeString(due) };
      if (savedObject.is_all_day) {
        delete due.time;
      }
    }

    return {
      name: savedObject.name,
      assignedTo: savedObject.assigned_to_id,
      notes: savedObject.notes,
      due: due,
    };
  };

  const getMergeValuesForEditTodo = (savedObject) => {
    return { id: savedObject.id };
  };

  const getInitialValuesForEditClipping = (savedObject) => {
    return { notes: savedObject.notes };
  };

  const getMergeValuesForEditClipping = (savedObject) => {
    return { id: savedObject.id };
  };

  const getInitialValuesForEditTile = (savedObject) => {
    return { name: savedObject.name, notes: savedObject.notes };
  };

  const getMergeValuesForEditTile = (savedObject) => {
    return { id: savedObject.id };
  };

  const getInitialValuesForEdit = (savedObject) => {
    if (savedObject) {
      switch (savedObject.class_id) {
        case "todo":
          return getInitialValuesForEditTodo(savedObject);
        case "clipping":
          return getInitialValuesForEditClipping(savedObject);
        case "tile":
          return getInitialValuesForEditTile(savedObject);
        default:
          break;
      }
    }
  };

  const getMergeValuesForEdit = (savedObject) => {
    if (savedObject) {
      switch (savedObject.class_id) {
        case "todo":
          return getMergeValuesForEditTodo(savedObject);
        case "clipping":
          return getMergeValuesForEditClipping(savedObject);
        case "tile":
          return getMergeValuesForEditTile(savedObject);
        default:
          break;
      }
    }
  };

  return {
    getInitialValuesForEdit,
    getMergeValuesForEdit,
  };
};

export default useGetInitialAndMergeValuesForEdit;
