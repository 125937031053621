import { useEffect, useState } from "react";
import useGetGoogleAuthTokenInfo from "./useGetGoogleAuthTokenInfo";
import useGetGoogleAuthTokenInfoWithForce from "./useGetGoogleAuthTokenInfoWithForce";

const useGetGoogleAndGmailConnectionInfo = () => {
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isGmailConnected, setIsGmailConnected] = useState(undefined);
  const [isGoogleCalendarConnected, setIsGoogleCalendarConnected] =
    useState(undefined);
  const [googleAuthTokenInfo, setGoogleAuthTokenInfo] = useState(undefined);

  const { data: googleAuthTokenInfoFromHook } = useGetGoogleAuthTokenInfo();

  const {
    data: googleAuthTokenInfoFromForceHook,
    refetch: refetchGoogleAuthTokenInfoWithForce,
  } = useGetGoogleAuthTokenInfoWithForce();

  useEffect(() => {
    if (!isDone) {
      if (googleAuthTokenInfoFromHook) {
        if (
          googleAuthTokenInfoFromHook.access_type === "offline" &&
          googleAuthTokenInfoFromHook.has_gmail_read_only_scope &&
          googleAuthTokenInfoFromHook.has_refresh_token
        ) {
          setIsGmailConnected(true);
          setGoogleAuthTokenInfo(googleAuthTokenInfoFromHook);
          setIsLoading(false);
          setIsDone(true);
        }
        setIsGoogleCalendarConnected(
          googleAuthTokenInfoFromHook?.has_google_calendar_scope === true,
        );
      }
    }
  }, [googleAuthTokenInfoFromHook, isDone]);

  useEffect(() => {
    if (googleAuthTokenInfoFromForceHook) {
      if (
        googleAuthTokenInfoFromForceHook.access_type === "offline" &&
        googleAuthTokenInfoFromForceHook.has_gmail_read_only_scope &&
        googleAuthTokenInfoFromForceHook.has_refresh_token
      ) {
        setIsGmailConnected(true);
      } else {
        setIsGmailConnected(false);
      }
      setIsGoogleCalendarConnected(
        googleAuthTokenInfoFromHook?.has_google_calendar_scope === true,
      );
      setGoogleAuthTokenInfo(googleAuthTokenInfoFromForceHook);
      setIsLoading(false);
      setIsDone(true);
    }
  }, [googleAuthTokenInfoFromForceHook]);

  const refetchAfterUserAction = async () => {
    await refetchGoogleAuthTokenInfoWithForce();
  };

  return {
    isLoading,
    isGmailConnected,
    isGoogleCalendarConnected,
    googleAuthTokenInfo,
    refetchAfterUserAction,
  };
};

export default useGetGoogleAndGmailConnectionInfo;
