import React, { useEffect, useState } from "react";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import { Alert, Typography } from "antd";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { Link } from "react-router-dom";

const TimezoneMismatchAlert = () => {
  const { currentFamily } = useTldrPageContext();
  const [showWarning, setShowWarning] = useState(false);
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (currentFamily) {
      const familyTimezone = currentFamily.timezone;
      if (browserTimezone !== familyTimezone) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
  }, [currentFamily, browserTimezone]);

  return showWarning ? (
    <Alert
      showIcon={true}
      icon={<LightbulbOutlinedIcon />}
      message={
        <>
          🕒 Time travel alert! Your browser is set to{" "}
          <Typography.Text strong={true}>{browserTimezone}</Typography.Text>{" "}
          time, but your family is partying in{" "}
          <Typography.Text strong={true}>
            {currentFamily.timezone}
          </Typography.Text>{" "}
          time. If you're travelling in another time zone, you can safely ignore
          this heads-up! Otherwise, you may need to{" "}
          <Link to={`/families/${currentFamily.id}/settings`}>update</Link> your
          family's time zone. Just make sure it is set the location where your
          schools and activities happen.
        </>
      }
      style={{ marginTop: 5, marginBottom: 12 }}
      type={"warning"}
    />
  ) : (
    <></>
  );
};

export default TimezoneMismatchAlert;
