import React from "react";
import gettingStartedLogo from "../../../images/getting-started.png";
import { Col, Image as AntDImage, Row, Typography } from "antd";

const BoardsEmpty = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col>
        <AntDImage
          src={gettingStartedLogo}
          preview={false}
          alt={""}
          style={{ width: 200, height: 200, borderRadius: 10 }}
        />
      </Col>
      <Col>
        <Typography.Title type={"secondary"} level={2}>
          No boards yet. All good, you're just getting started!
        </Typography.Title>
        <Typography.Paragraph>
          Boards offer a place to store things like links, images, and
          attachments.
          <br />
          All you need is a title, maybe something like "Westlake Middle
          School".
          <br />
          What kinds of things should you pin here? Digital versions of things
          you might put on your fridge.
        </Typography.Paragraph>
      </Col>
    </Row>
  );
};

export default BoardsEmpty;
