import React from "react";
import ArtifactCard from "../../common/generic/artifactCard";
import { Tooltip } from "antd";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import SummaryBulletCardCover from "../cardCovers/summaryBulletCardCover";
import useGetMessagesForArtifact from "../../../hooks/emailCollector/common/useGetMessagesForArtifact";

const SummaryBulletCard = ({
  ecObject,
  cardProperties,
  onClickViewEcObjectDetails,
  sourceMessageInfo,
  actionMenu,
}) => {
  const { messages } = useGetMessagesForArtifact(
    ecObject?.class_id,
    ecObject?.hash_key,
    ecObject?.sort_key,
  );

  return (
    <ArtifactCard
      cover={
        <SummaryBulletCardCover
          message={messages && messages.length ? messages[0] : null}
          ecObject={ecObject}
          onClick={null}
          isMini={false}
        />
      }
      position1={sourceMessageInfo}
      position2={
        <Tooltip title={"Topic"}>
          <TagOutlinedIcon sx={{ color: "#ff4081" }} />
        </Tooltip>
      }
      position3={
        <>
          <div></div>
          <div>{actionMenu}</div>
        </>
      }
    />
  );
};

export default SummaryBulletCard;
