import React from "react";
import { Form, Input } from "antd";

const NameInputFormItem = ({ requiredMessage, placeholderText }) => {
  return (
    <Form.Item
      name="name"
      label="Name"
      rules={[
        {
          required: true,
          message: requiredMessage,
        },
      ]}
    >
      <Input maxLength={100} placeholder={placeholderText} />
    </Form.Item>
  );
};

export default NameInputFormItem;
