import React from "react";
import DetailPanelSkeleton from "./detailPanelParts/detailPanelSkeleton";
import TagsDetailRow from "./detailPanelParts/tagsDetailRow";
import TldrCardWithCoverImage from "../../common/generic/tldrCardWithCoverImage";

const ImageDetailPanel = ({ ecObject, actionMenu }) => {
  const getImagePart = () => {
    const position1 = (
      <TldrCardWithCoverImage
        imageSrc={ecObject.signed_url || ecObject.final_url}
        imageSize={ecObject.size}
        alt={""}
        preview={true}
        width={"100%"}
        coverHeight={300}
        hoverable={false}
        onClick={null}
        bodyPadding={8}
      />
    );

    return (
      <DetailPanelSkeleton
        position1={position1}
        position2={!!actionMenu && actionMenu}
        position3={<TagsDetailRow tags={ecObject?.native_tags} />}
      />
    );
  };

  return getImagePart();
};

export default ImageDetailPanel;
