import React from "react";
import { Col, Row } from "antd";

const SpaceBetweenRow = ({ leftSide, rightSide }) => {
  if (!leftSide) {
    leftSide = <>&nbsp;</>;
  }

  return (
    <Row wrap={false} justify={"space-between"} style={{ paddingBottom: 10 }}>
      <Col>{leftSide}</Col>
      <Col>{rightSide}</Col>
    </Row>
  );
};

export default SpaceBetweenRow;
