import React from "react";
import CreateOrEditTodoItemForm from "./createOrEditTodoItemForm";
import OverlayFormWrapper from "../../../common/formComponents/overlayFormWrapper";
import useCreateOrEditTodoForm from "../hooks/useCreateOrEditTodoForm";

const CreateOrEditTodoItemOverlay = ({
  open,
  onOk,
  onCancel,
  initialValues,
  confirmLoading,
  isEditMode,
  formMergeValues,
  ecObject,
}) => {
  const titleText = isEditMode ? "Edit to-do" : "Create to-do";
  const okText = isEditMode ? "Save" : "Create";
  const cancelText = "Cancel";

  const { handleOk } = useCreateOrEditTodoForm(formMergeValues, onOk);

  return (
    <OverlayFormWrapper
      open={open}
      title={titleText}
      onOk={handleOk}
      okText={okText}
      onCancel={onCancel}
      cancelText={cancelText}
      confirmLoading={confirmLoading}
      initialValues={initialValues}
      formMergeValues={formMergeValues}
      formClass={CreateOrEditTodoItemForm}
      ecObject={ecObject}
      onValuesChange={() => {}}
      onValidationError={() => {}}
    />
  );
};

export default CreateOrEditTodoItemOverlay;
