import React from "react";
import { useParams } from "react-router-dom";
import { Col, Popover, Row, Typography } from "antd";
import SecondarySettingsMenu from "./secondarySettingsMenu";
import FamilySelector from "../common/commonTldr/familySelector";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import SettingsMembers from "./settingsMembers";
import SettingsSubscriptionBilling from "./settingsSubscriptionAndBilling";
import { getFormattedUtcDateTimeInUsersTz } from "../../utils/dayJsUtils";
import FamilyMemberName from "../familyMembers/familyMemberName";
import { Divider } from "antd/lib";
import TimeZoneSelector from "../common/generic/timeZoneSelector";
import useUpdateFamily from "../../hooks/orchestrate/common/useUpdateFamily";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FamilyMemberAvatarIcon from "../familyMembers/familyMemberAvatarIcon";
import SettingsGmailAddon from "./settingsGmailAddon";

const { Text } = Typography;

const SettingsContentOutlet = () => {
  let { viewType } = useParams();
  if (!viewType) viewType = "members";
  const {
    familyId,
    currentUser,
    currentFamily,
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyContains,
    browserTimezone,
  } = useTldrPageContext();

  const { mutate: updateTimezone } = useUpdateFamily(
    () => {
      openLoadingMessage("updateTimezone", "Updating time zone");
    },
    () => {
      openSuccessMessage("updateTimezone", "Time zone updated");
      invalidateQueryWhereKeyContains("current-user");
    },
    () => {
      openErrorMessage("updateTimezone", "Error updating time zone");
      invalidateQueryWhereKeyContains("current-user");
    },
  );

  const { mutate: updateFamilyName } = useUpdateFamily(
    () => {
      openLoadingMessage("updateFamilyName", "Updating family name");
    },
    () => {
      openSuccessMessage("updateFamilyName", "Family name updated");
      invalidateQueryWhereKeyContains("current-user");
    },
    () => {
      openErrorMessage("updateFamilyName", "Error updating family name");
      invalidateQueryWhereKeyContains("current-user");
    },
  );

  const getContentJsx = () => {
    switch (viewType) {
      case "members":
        return <SettingsMembers />;
      case "subscription-billing":
        return <SettingsSubscriptionBilling />;
      case "gmail-addon":
        return <SettingsGmailAddon />;
      default:
        return <SettingsMembers />;
    }
  };

  const getCreatedDatetime = () => {
    if (currentUser && currentFamily) {
      return (
        <>
          {getFormattedUtcDateTimeInUsersTz(
            currentFamily.created,
            browserTimezone,
          )}
        </>
      );
    }
  };

  const getFamilyInfoSection = () => {
    return (
      <>
        <Row style={{ marginBottom: 5 }}>
          <Col span={24}>
            <Typography.Title
              level={2}
              editable={{
                triggerType: ["icon", "text"],
                maxLength: 100,
                onChange: (newValue) => {
                  updateFamilyName({ idz: currentFamily.id, name: newValue });
                },
              }}
            >
              {currentFamily?.name}
            </Typography.Title>
          </Col>
        </Row>

        <Row style={{ marginBottom: 20 }}>
          <Col>
            <Text type="secondary">
              <FamilyMemberAvatarIcon
                familyId={familyId}
                userId={currentFamily?.owner_id}
              />
              <span style={{ marginLeft: 4 }}>
                Created by{" "}
                <FamilyMemberName
                  familyId={familyId}
                  userId={currentFamily?.owner_id}
                />{" "}
                on {getCreatedDatetime()}.
              </span>
            </Text>
          </Col>
        </Row>

        <Row style={{ marginBottom: 5 }}>
          <Col>
            <Text strong={true}>Selected family</Text>
            <Popover
              content={
                "If you're part of multiple families, use this to switch the portal context."
              }
              trigger={["hover", "click"]}
            >
              <span style={{ marginLeft: 4 }}>
                <InfoOutlinedIcon />
              </span>
            </Popover>
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col>
            <FamilySelector selectedFamilyId={familyId} forceShow={true} />
          </Col>
        </Row>

        <Row style={{ marginBottom: 5 }}>
          <Col>
            <Text strong={true}>Family time zone</Text>
            <Popover
              content={
                "The time zone used to interpret times in email messages."
              }
              trigger={["hover", "click"]}
            >
              <span style={{ marginLeft: 4 }}>
                <InfoOutlinedIcon />
              </span>
            </Popover>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "100%" }}>
            <TimeZoneSelector
              initiallySelectedTimezone={currentFamily?.timezone}
              onChange={(newValue) => {
                updateTimezone({ idz: currentFamily.id, timezone: newValue });
              }}
            />
          </Col>
        </Row>

        <Divider />
      </>
    );
  };

  return (
    <>
      {getFamilyInfoSection()}
      <SecondarySettingsMenu familyId={familyId} selectedValue={viewType} />
      {getContentJsx()}
    </>
  );
};

export default SettingsContentOutlet;
