import React from "react";
import { Tooltip } from "antd";
import ArtifactCard from "../../common/generic/artifactCard";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MessageCardCover from "../cardCovers/messageCardCover";

const MessageCard = ({
  ecObject,
  cardProperties,
  onClickViewEcObjectDetails,
  sourceMessageInfo,
  actionMenu,
}) => {
  return (
    <ArtifactCard
      cover={<MessageCardCover ecObject={ecObject} onClick={null} />}
      position1={sourceMessageInfo}
      position2={
        <Tooltip title={"Message"}>
          <EmailOutlinedIcon sx={{ color: "#ff4081" }} />
        </Tooltip>
      }
      position3={
        <>
          <div></div>
          <div>{actionMenu}</div>
        </>
      }
    />
  );
};

export default MessageCard;
