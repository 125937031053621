import React from "react";
import { Button, Col, Divider, Row } from "antd";
import EmailAddress from "../inboxesAndMessages/message/emailAddress";
import { timestampToFullDatetimeString } from "../../utils/dayJsUtils";

const InboundForwardMessageMetadata = ({
  messageObject,
  onClickViewMessage,
}) => {
  const getMessageMetadata = () => {
    if (messageObject) {
      return (
        <>
          <Row
            justify={"space-between"}
            align={"top"}
            style={{ marginBottom: 20 }}
          >
            <Col flex={"auto"}>
              <h2>{messageObject?.subject || ""}</h2>
              <>
                <div>
                  From:{" "}
                  <EmailAddress
                    label={messageObject.from_address[1]}
                    email={messageObject.from_address[0]}
                    useString={true}
                  />
                </div>
                <div>
                  Date: {timestampToFullDatetimeString(messageObject.timestamp)}
                </div>
              </>
            </Col>
            <Col flex={"none"}>
              <Button
                type={"primary"}
                ghost={true}
                onClick={() => onClickViewMessage()}
              >
                View Message
              </Button>
            </Col>
          </Row>
          <Divider />
        </>
      );
    }
  };

  return getMessageMetadata();
};

export default InboundForwardMessageMetadata;
