import { useEffect, useState } from "react";
import {
  CODE_DATE_FORMAT,
  getDayJsDatetimeFromStringsGivenTimezones,
} from "../../../utils/dayJsUtils";
import useScreenSizeAndOrientation, {
  SCREEN_SIZE_EXTRA_SMALL,
  SCREEN_SIZE_MEDIUM,
  SCREEN_SIZE_SMALL,
} from "../../../hooks/utils/useScreenSizeAndOrientation";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const correctMisinterpretedDateTime = (
  dateStr,
  timeStr,
  originalTzForProcessing,
  correctedTzForProcessing,
) => {
  if (!timeStr) {
    return {
      date: dateStr,
      time: null,
    };
  }

  const dateTimeStr = `${dateStr} ${timeStr}`;
  const dateTimeUtc = dayjs.utc(dateTimeStr);
  const dateTimeInOriginalTz = dateTimeUtc.tz(originalTzForProcessing);
  const originalDtString = dateTimeInOriginalTz.format("YYYY-MM-DD HH:mm:ss");
  const dateTimeInToTz = dayjs.tz(originalDtString, correctedTzForProcessing);
  const correctedDtInUtc = dateTimeInToTz.utc();
  return {
    date: correctedDtInUtc.format("YYYY-MM-DD"),
    time: correctedDtInUtc.format("HH:mm:ss"),
  };
};

const useOrganizeEventsForInboundForward = (
  events,
  processingTimezone,
  tenantTimezone,
) => {
  const { size: screenSize } = useScreenSizeAndOrientation();

  const [startDates, setStartDates] = useState([]);
  const [eventsByStartDate, setEventsByStartDate] = useState([]);
  const [showCardTitles, setShowCardTitles] = useState(false);
  const [eventsForDisplay, setEventsForDisplay] = useState([]);

  useEffect(() => {
    if (events) {
      const eventsForDisplayLocal = [];
      for (const i in events) {
        const eventRollup = { ...events[i].rollup };
        const eventObj = { ...events[i].obj };
        const processingTimezoneToUse =
          eventObj.timezone && eventObj.timezone !== ""
            ? eventObj.timezone
            : processingTimezone;

        if (processingTimezoneToUse && tenantTimezone) {
          const correctedStartDateAndTime = correctMisinterpretedDateTime(
            eventRollup.start_date,
            eventRollup.start_time,
            processingTimezoneToUse,
            tenantTimezone,
          );
          const correctedEndDateAndTime = correctMisinterpretedDateTime(
            eventRollup.end_date,
            eventRollup.end_time,
            processingTimezoneToUse,
            tenantTimezone,
          );
          eventRollup.start_date = correctedStartDateAndTime.date;
          eventRollup.start_time = correctedStartDateAndTime.time;
          eventRollup.end_date = correctedEndDateAndTime.date;
          eventRollup.end_time = correctedEndDateAndTime.time;
        }
        eventsForDisplayLocal.push({ obj: eventObj, rollup: eventRollup });
      }
      setEventsForDisplay(eventsForDisplayLocal);
    }
  }, [events, processingTimezone, tenantTimezone]);

  useEffect(() => {
    if (eventsForDisplay) {
      const startDateSet = new Set();
      const eventsByStartDateLocal = {};
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      for (const i in eventsForDisplay) {
        const eventPair = eventsForDisplay[i];
        const eventRollup = eventsForDisplay[i].rollup;
        const startDateString = getDayJsDatetimeFromStringsGivenTimezones(
          eventRollup.start_date,
          eventRollup.start_time,
          "UTC",
          timezone,
        ).format(CODE_DATE_FORMAT);

        startDateSet.add(startDateString);
        if (eventsByStartDateLocal[startDateString]) {
          const eventList = [
            ...eventsByStartDateLocal[startDateString],
            eventPair,
          ];
          eventList.sort(
            (a, b) =>
              a.rollup.event_start_timestamp - b.rollup.event_start_timestamp,
          );
          eventsByStartDateLocal[startDateString] = eventList;
        } else {
          eventsByStartDateLocal[startDateString] = [eventPair];
        }
      }

      setStartDates(Array.from(startDateSet).sort());
      setEventsByStartDate(eventsByStartDateLocal);
    }
  }, [eventsForDisplay]);

  useEffect(() => {
    if (
      [SCREEN_SIZE_EXTRA_SMALL, SCREEN_SIZE_SMALL, SCREEN_SIZE_MEDIUM].includes(
        screenSize,
      )
    ) {
      setShowCardTitles(true);
    } else {
      setShowCardTitles(false);
    }
  }, [screenSize]);

  return { startDates, eventsByStartDate, showCardTitles };
};

export default useOrganizeEventsForInboundForward;
