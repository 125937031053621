import useQueryGenericPost from "../../framework/useQueryGenericPost";
import { O_SERVICE_AUTH_UPDATE_TIME_ZONE } from "../../../serviceUrls/serviceUrlsOrchestrate";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";

const useUpdateTimezone = (onSubmit, onSuccess, onError) => {
  return useQueryGenericPost(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_AUTH_UPDATE_TIME_ZONE,
    undefined,
    onSubmit,
    onSuccess,
    onError,
  );
};

export default useUpdateTimezone;
