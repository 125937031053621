import useTldrPageContext from "../orchestrate/common/useTldrPageContext";
import useCreateTileWithAssociation from "../../components/familyOrganizer/boards/hooks/useCreateTileWithAssociation";
import useCreateComment from "../../components/comments/hooks/useCreateComment";
import {
  O_SERVICE_BOARDS_CREATE,
  O_SERVICE_BOARDS_DELETE,
  O_SERVICE_BOARDS_UPDATE,
  O_SERVICE_CLIPPINGS_CREATE,
  O_SERVICE_CLIPPINGS_DELETE,
  O_SERVICE_CLIPPINGS_UPDATE,
  O_SERVICE_GOOGLE_EVENTS_DELETE,
  O_SERVICE_TILES_DELETE,
  O_SERVICE_TILES_UPDATE,
  O_SERVICE_TODOS_CREATE,
  O_SERVICE_TODOS_DELETE,
  O_SERVICE_TODOS_UPDATE,
} from "../../serviceUrls/serviceUrlsOrchestrate";
import useUpdateOrchestrateObjectWithNotifications from "../orchestrate/useUpdateOrchestrateObjectWithNotifications";
import useCreateOrchestrateObjectWithNotifications from "../orchestrate/useCreateOrchestrateObjectWithNotifications";
import useDeleteOrchestrateObjectWithNotifications from "../orchestrate/useDeleteOrchestrateObjectWithNotifications";

const useTldrUniversalMutateContext = (
  todosQueryKey,
  boardsQueryKey,
  tilesQueryKey,
  clippingQueryKey,
  commentsQueryKey,
  googleEventsQueryKey,
) => {
  const {
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyContains,
  } = useTldrPageContext();

  // Google Events
  // ===================================================================================================================
  const { deleteOrchestrateObject: deleteGoogleEventInner } =
    useDeleteOrchestrateObjectWithNotifications(
      O_SERVICE_GOOGLE_EVENTS_DELETE,
      googleEventsQueryKey,
      "google_event",
      "google-events",
      "google-events",
      "Google Event",
      "Google Events",
    );

  const deleteGoogleEvent = (googleEventId) => {
    deleteGoogleEventInner({ objectId: googleEventId });
  };

  // Todos
  // ===================================================================================================================
  const { createOrchestrateObject: createTodo } =
    useCreateOrchestrateObjectWithNotifications(
      O_SERVICE_TODOS_CREATE,
      todosQueryKey,
      "todo",
      "todos",
      "todos",
      "To-do",
      "To-dos",
    );

  const { updateOrchestrateObject: updateTodo } =
    useUpdateOrchestrateObjectWithNotifications(
      O_SERVICE_TODOS_UPDATE,
      todosQueryKey,
      "todo",
      "todos",
      "todos",
      "To-do",
      "To-dos",
    );

  const { deleteOrchestrateObject: deleteTodoInner } =
    useDeleteOrchestrateObjectWithNotifications(
      O_SERVICE_TODOS_DELETE,
      todosQueryKey,
      "todo",
      "todos",
      "todos",
      "To-do",
      "To-dos",
    );

  const deleteTodo = (todoId) => {
    deleteTodoInner({ objectId: todoId });
  };

  // Clipping
  // ===================================================================================================================
  const { createOrchestrateObject: createClipping } =
    useCreateOrchestrateObjectWithNotifications(
      O_SERVICE_CLIPPINGS_CREATE,
      clippingQueryKey,
      "clipping",
      "workbench",
      "clippings",
      "Item",
      "Workbench",
    );

  const { updateOrchestrateObject: updateClipping } =
    useUpdateOrchestrateObjectWithNotifications(
      O_SERVICE_CLIPPINGS_UPDATE,
      clippingQueryKey,
      "clipping",
      "workbench",
      "clippings",
      "Item",
      "Workbench",
    );

  const { deleteOrchestrateObject: deleteClippingInner } =
    useDeleteOrchestrateObjectWithNotifications(
      O_SERVICE_CLIPPINGS_DELETE,
      clippingQueryKey,
      "clipping",
      "workbench",
      "clippings",
      "Item",
      "Workbench",
    );

  const deleteClipping = (clippingId) => {
    deleteClippingInner({ objectId: clippingId });
  };

  // Boards
  // ===================================================================================================================
  const { createOrchestrateObject: createBoard } =
    useCreateOrchestrateObjectWithNotifications(
      O_SERVICE_BOARDS_CREATE,
      boardsQueryKey,
      "board",
      "boards",
      "boards",
      "Board",
      "Boards",
    );

  const { updateOrchestrateObject: updateBoard } =
    useUpdateOrchestrateObjectWithNotifications(
      O_SERVICE_BOARDS_UPDATE,
      boardsQueryKey,
      "board",
      "boards",
      "boards",
      "Board",
      "Boards",
    );

  const { deleteOrchestrateObject: deleteBoardInner } =
    useDeleteOrchestrateObjectWithNotifications(
      O_SERVICE_BOARDS_DELETE,
      boardsQueryKey,
      "board",
      "boards",
      "boards",
      "Board",
      "Boards",
    );

  const deleteBoard = (boardId) => {
    deleteBoardInner(boardId);
  };

  // Tiles
  // ===================================================================================================================
  const createTileWithAssociationHook = useCreateTileWithAssociation(
    boardsQueryKey,
    () => openLoadingMessage("createTileWithAssociation", "Creating tile"),
    () => {
      openSuccessMessage("createTileWithAssociation", "Tile created");
      invalidateQueryWhereKeyContains("tiles");
    },
    () => {
      openErrorMessage("createTileWithAssociation", "Error creating tile");
      invalidateQueryWhereKeyContains("tiles");
    },
  );

  const createTileWithAssociation = (values) => {
    createTileWithAssociationHook.mutate(values);
  };

  const { updateOrchestrateObject: updateTile } =
    useUpdateOrchestrateObjectWithNotifications(
      O_SERVICE_TILES_UPDATE,
      tilesQueryKey,
      "tile",
      "boards",
      "tiles",
      "Tile",
      "Tiles",
    );

  const { deleteOrchestrateObject: deleteTileInner } =
    useDeleteOrchestrateObjectWithNotifications(
      O_SERVICE_TILES_DELETE,
      tilesQueryKey,
      "tile",
      "boards",
      "tiles",
      "Tile",
      "Tiles",
    );

  const deleteTile = (tileId) => {
    deleteTileInner({ objectId: tileId });
  };

  // Comments
  // ===================================================================================================================
  const { mutate: createComment } = useCreateComment(
    () => openLoadingMessage("createComment", "Creating comment"),
    () => {
      openSuccessMessage("createComment", "Comment created");
      invalidateQueryWhereKeyContains("comments");
    },
    () => {
      openErrorMessage("createComment", "Error creating comment");
      invalidateQueryWhereKeyContains("comments");
    },
  );

  const updateComment = (values) => {};

  const deleteComment = (commentId) => {};

  return {
    deleteGoogleEvent,
    createTodo,
    updateTodo,
    deleteTodo,
    createBoard,
    updateBoard,
    deleteBoard,
    createTileWithAssociation,
    updateTile,
    deleteTile,
    createClipping,
    updateClipping,
    deleteClipping,
    createComment,
    updateComment,
    deleteComment,
  };
};

export default useTldrUniversalMutateContext;
