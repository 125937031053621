import React from "react";
import { Button } from "antd";

const NavButton = ({ width, height, icon, text, onClick }) => {
  return (
    <Button
      type={"primary"}
      ghost={true}
      style={{ width: width, height: height, marginLeft: 3, marginRight: 3 }}
      onClick={onClick}
    >
      {icon ? (
        <>
          <div>
            <span style={{ color: "#ff4081" }}>{icon}</span>
            <br />
            {text}
          </div>
        </>
      ) : (
        <span style={{ fontSize: "1.25em" }}>{text}</span>
      )}
    </Button>
  );
};

export default NavButton;
