import React from "react";
import { Drawer } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";
import { useNavigate } from "react-router-dom";
import NavButtonGrid from "../common/generic/navButtons/navButtonGrid";

const PrimaryMeNavDrawer = ({ open, onSelectAndNavigate, onClose }) => {
  const { data: currentUser } = useGetCurrentUser();
  const navigate = useNavigate();

  const getNavButtonRows = (families) => {
    return families.map((family, index) => {
      return {
        sectionTitle: index === 0 ? "Families" : "",
        navButtonItems: [
          {
            text: family.name,
            icon: null,
            onClick: () => {
              navigate(`/families/${family.id}`);
              onSelectAndNavigate();
            },
          },
        ],
      };
    });
  };

  const getJsx = () => {
    if (currentUser) {
      return (
        <NavButtonGrid
          navButtonRows={getNavButtonRows(currentUser.families)}
          bottomSpacer={8}
          buttonHeight={90}
        />
      );
    }
    return <></>;
  };

  return (
    <Drawer
      closeIcon={<MenuFoldOutlined style={{ fontSize: 25 }} />}
      title=""
      placement="left"
      open={open}
      onClose={() => onClose()}
      style={{ maxWidth: "100%" }}
      // styles={{ body: { backgroundColor: "#168dff" } }}
    >
      {getJsx()}
    </Drawer>
  );
};

export default PrimaryMeNavDrawer;
