import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "./apiClient";
import { replacePlaceholdersInTemplate } from "../../serviceUrls/serviceUrlHelpers";

const useQueryGenericDelete = (
  baseUrl,
  endpoint,
  queryKey,
  onSubmit,
  onSuccess,
  onError,
) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (urlReplacements) => {
      const token = await getAccessTokenSilently();
      const finalEndpoint = replacePlaceholdersInTemplate(
        endpoint,
        urlReplacements,
      );
      const client = getApiClientWithBaseUrl(baseUrl, finalEndpoint, token);
      return client.delete();
    },

    onMutate: (idToDelete) => {
      if (queryKey) {
        const previousItems = queryClient.getQueryData(queryKey) || [];
        if (Array.isArray(previousItems)) {
          queryClient.setQueryData(queryKey, (items) => {
            return items.filter((item) => item.id !== idToDelete);
          });
        }
        if (onSubmit) onSubmit(idToDelete);
        return { previousItems };
      } else {
        if (onSubmit) onSubmit(idToDelete);
      }
    },

    onSuccess: (saved, submitted) => {
      queryClient.invalidateQueries({ queryKey: queryKey }).then();
      if (onSuccess) onSuccess(saved);
    },

    onError: (error, unsaved, context) => {
      if (queryKey) {
        queryClient.setQueryData(queryKey, context.previousItems);
        queryClient.invalidateQueries({ queryKey: queryKey }).then();
      }
      if (onError) onError(error);
    },
  });
};

export default useQueryGenericDelete;
