import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "../../../hooks/framework/apiClient";
import { O_SERVICE_INVITES_RESEND_EMAIL } from "../../../serviceUrls/serviceUrlsOrchestrate";
import useOrchestrateServiceUrlHelper from "../../../hooks/orchestrate/useOrchestrateServiceUrlHelper";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";
import { replacePlaceholdersInTemplate } from "../../../serviceUrls/serviceUrlHelpers";

const useResendInviteEmail = (onSubmit, onSuccess, onError) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);

  const { endpointPath } = useOrchestrateServiceUrlHelper(
    O_SERVICE_INVITES_RESEND_EMAIL,
    {},
  );

  return useMutation({
    mutationFn: async (inviteId) => {
      const token = await getAccessTokenSilently();
      const finalEndpoint = replacePlaceholdersInTemplate(endpointPath, {
        objectId: inviteId,
      });
      return getApiClientWithBaseUrl(
        BACKEND_API_BASE_URL_ORCHESTRATE,
        finalEndpoint,
        token,
      ).get([], {});
    },

    onMutate: (unsaved) => {
      if (onSubmit) onSubmit(unsaved);
    },

    onSuccess: (saved, submitted) => {
      if (onSuccess) onSuccess(saved);
    },

    onError: (error, unsaved, context) => {
      if (onError) onError(error);
    },
  });
};

export default useResendInviteEmail;
