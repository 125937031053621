import React from "react";
import LinkPreview from "../../common/generic/linkPreview";
import ArtifactCard from "../../common/generic/artifactCard";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { Tooltip } from "antd";
import { getQueryParams } from "../../../utils/utils";

const LinkCard = ({
  ecObject,
  cardProperties,
  onClickViewEcObjectDetails,
  sourceMessageInfo,
  actionMenu,
}) => {
  const linkUrl = ecObject.content_disposition
    ? ecObject.signed_url
    : ecObject.final_url;

  const imageSrc = ecObject.preview_signed_url
    ? ecObject.preview_signed_url
    : ecObject.preview_summary.image_src;

  const getCover = () => {
    if (ecObject.final_url.startsWith("https://drive.google.com/file/d/")) {
      return (
        <div className="iframe-wrapper">
          <iframe
            src={ecObject.final_url.replace("/view", "/preview")}
            // width="640"
            // height="480"
            allow="autoplay"
          />
        </div>
      );
    }

    if (ecObject.final_url.startsWith("https://www.youtube.com/watch")) {
      const embedId = getQueryParams(ecObject.final_url)["v"];
      return (
        <div className="iframe-wrapper">
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${embedId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </div>
      );
    }

    return (
      <LinkPreview
        href={linkUrl}
        title={ecObject.preview_summary.title}
        domain={ecObject.preview_summary.fqdn}
        imageSrc={imageSrc}
        contentType={ecObject.content_type}
        coverHeight={243}
      />
    );
  };

  return (
    <ArtifactCard
      cover={getCover()}
      position1={sourceMessageInfo}
      position2={
        <Tooltip title={"Link"}>
          <LinkOutlinedIcon sx={{ color: "#ff4081" }} />
        </Tooltip>
      }
      position3={actionMenu}
    />
  );
};

export default LinkCard;
