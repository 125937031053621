import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const EmailAddress = ({ label, email, useStrong }) => {
  if ((!label || label === "") && (!email || email === "")) {
    return <Text strong={useStrong}>Undisclosed</Text>;
  }
  if (!label || label === "") {
    return <>&lt;{email}&gt;</>;
  }
  if (!email || email === "") {
    return <Text strong={useStrong}>{label}</Text>;
  }
  return (
    <>
      <Text strong={useStrong}>{label}</Text>
      &nbsp;&lt;{email}&gt;
    </>
  );
};

export default EmailAddress;
