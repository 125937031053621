import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetPublicBoard from "./hooks/useGetPublicBoard";
import LoadingEmptyOrContent from "../../common/generic/loadingEmptyOrContent";
import { AppContextPublicBoard } from "../../ecObjectViewers/actionMappers/appContextsToActions";
import { Typography } from "antd";
import EcObjectCardsContainer from "../../ecObjectViewers/ecObjectCardsContainer";
import ResponsiveCard from "../../common/generic/responsiveCard";
import LayoutChromeNoAuth from "../../layout/layoutChromeNoAuth";

const { Text } = Typography;

const PublicBoard = () => {
  const { boardUuid } = useParams();
  const { data: board } = useGetPublicBoard(boardUuid);

  const [ecObjects, setEcObjects] = useState([]);

  useEffect(() => {
    if (board) {
      const ecObjectsLocal = [];
      const tiles = board.tiles;
      const itemData = board.item_data;
      for (const i in tiles) {
        const tile = tiles[i];
        const tileArtifacts = tile.artifacts;
        if (tileArtifacts && tileArtifacts.length > 0) {
          const tileArtifact = tile.artifacts[0];
          const classId = tileArtifact.rollup_class_id;
          const hashKey = tileArtifact.rollup_hash_key;
          const sortKey = tileArtifact.rollup_sort_key;
          let ecObject;
          if (classId && hashKey) {
            if (sortKey) {
              ecObject = itemData[classId]?.[hashKey]?.[sortKey];
            } else {
              ecObject = itemData[classId]?.[hashKey];
            }
          }
          if (ecObject) {
            ecObjectsLocal.push(ecObject);
          }
        }
      }
      setEcObjects(ecObjectsLocal);
    }
  }, [board]);

  const getBoardHeader = () => {
    const header = board?.name || "";
    const subHeader = board?.family?.name || "";
    return (
      <>
        <h3>{header}</h3>
        <h4>
          <Text type="secondary">Shared by </Text>
          <Text type="secondary" italic>
            {subHeader}
          </Text>
        </h4>
      </>
    );
  };

  const getJsx = () => {
    return (
      <LayoutChromeNoAuth>
        <ResponsiveCard className="main-container-card">
          <div>{getBoardHeader()}</div>
          <LoadingEmptyOrContent
            isLoading={!board}
            isEmpty={board && board.tiles.length === 0}
          >
            <EcObjectCardsContainer
              ecObjects={ecObjects}
              appContext={AppContextPublicBoard}
              cardProperties={null}
              showClickDetailsTag={false}
              scrollToFirstHighlight={false}
            />
          </LoadingEmptyOrContent>
        </ResponsiveCard>
      </LayoutChromeNoAuth>
    );
  };

  return getJsx();
};

export default PublicBoard;
