import { useState } from "react";
import { Form } from "antd";
import useCreateOrchestrateObject from "../../../../hooks/orchestrate/useCreateOrchestrateObject";
import { O_SERVICE_INVITES_CREATE } from "../../../../serviceUrls/serviceUrlsOrchestrate";
import useUpdateableMessage from "../../../../hooks/utils/useUpdateableMessage";

const useInvitePartnerForm = (onSuccess) => {
  const [formInvite] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { openLoadingMessage, openSuccessMessage, openErrorMessage } =
    useUpdateableMessage();

  const createInviteHook = useCreateOrchestrateObject(
    O_SERVICE_INVITES_CREATE,
    null,
    () => {
      setIsSubmitting(true);
      openLoadingMessage("createInvite", "Sending invite");
    },
    () => {
      openSuccessMessage("createInvite", "📬 Invite dispatched!");
      setIsSubmitting(false);
      onSuccess();
    },
    () => {
      openErrorMessage("createInvite", "Error sending invite");
      setIsSubmitting(false);
    },
  );

  return { formInvite, createInviteHook, isSubmitting, setIsSubmitting };
};

export default useInvitePartnerForm;
