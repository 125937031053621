import React from "react";
import TldrCardWithCoverImage from "../../common/generic/tldrCardWithCoverImage";
import MiniCardTitleRow from "../miniCardParts/miniCardTitleRow";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

const ImageMiniCard = ({ ecObject, appContext, onClick }) => {
  const getTitleRow = () => {
    const contentType = ecObject?.content_type;
    const width = ecObject?.size?.[0];
    const height = ecObject?.size?.[1];

    if (contentType) {
      if (width && height) {
        return `${contentType} (${width} x ${height})`;
      } else {
        return contentType;
      }
    }
    return "";
  };

  return (
    <TldrCardWithCoverImage
      imageSrc={ecObject.signed_url || ecObject.final_url}
      coverHeight={150}
      imageSize={ecObject?.size}
      width={250}
      alt={""}
      preview={false}
      hoverable={false}
      bodyPadding={8}
      onClick={onClick}
    >
      <MiniCardTitleRow
        titleText={getTitleRow()}
        tooltipText={"Image"}
        icon={<ImageOutlinedIcon sx={{ color: "#ff4081" }} />}
      />
    </TldrCardWithCoverImage>
  );
};

export default ImageMiniCard;
