import React from "react";
import { Col, Row } from "antd";
import ResponsiveCard from "../../../common/generic/responsiveCard";

const DetailPanelSkeleton = ({ position1, position2, position3 }) => {
  return (
    <ResponsiveCard
      title={null}
      style={{ border: "1px solid #168dff", width: "100%" }}
    >
      <Row gutter={[10, 10]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          {position1}
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <div
            style={{
              backgroundColor: "#e6f7ff",
              borderRadius: 8,
              paddingTop: 10,
              paddingBottom: 10,
              height: "100%",
            }}
          >
            {position2}
          </div>
        </Col>
      </Row>
      {position3 && (
        <Row style={{ marginTop: 16 }}>
          <Col>{position3}</Col>
        </Row>
      )}
    </ResponsiveCard>
  );
};

export default DetailPanelSkeleton;
