import React from "react";
import { useParams } from "react-router-dom";
import SecondaryMeMenu from "./secondaryMeMenu";
import MyAdmin from "./admin/myAdmin";
import MyInvites from "./myInvites";

const MessageContentOutlet = () => {
  let { viewType } = useParams();
  if (!viewType) viewType = "invites";

  const getContentJsx = () => {
    switch (viewType) {
      // case "settings":
      //   return <MyGeneralSettings />;
      case "invites":
        return <MyInvites />;
      case "admin":
        return <MyAdmin />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <SecondaryMeMenu selectedTabValue={viewType} />
      {getContentJsx()}
    </>
  );
};

export default MessageContentOutlet;
