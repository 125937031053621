import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "../../../hooks/framework/apiClient";
import { O_SERVICE_INVITES_ACCEPT_INVITE } from "../../../serviceUrls/serviceUrlsOrchestrate";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../serviceUrls/baseUrls";

const useAcceptInvite = (onSubmit, onSuccess, onError) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);

  return useMutation({
    mutationFn: async (inviteCode) => {
      const token = await getAccessTokenSilently();
      return getApiClientWithBaseUrl(
        BACKEND_API_BASE_URL_ORCHESTRATE,
        O_SERVICE_INVITES_ACCEPT_INVITE,
        token,
      ).post({
        invite_uuid: inviteCode,
      });
    },

    onMutate: (unsaved) => {
      if (onSubmit) onSubmit(unsaved);
    },

    onSuccess: (saved, submitted) => {
      if (onSuccess) onSuccess(saved);
    },

    onError: (error, unsaved, context) => {
      if (onError) onError(error);
    },
  });
};

export default useAcceptInvite;
