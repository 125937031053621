import React from "react";
import { Button, Col, List, Row, Space, Typography } from "antd";
import TodoAvatarPart from "./todoAvatarPart";
import TodoAssignedToAndDuePart from "./todoAssignedToAndDuePart";
import TodoCreatedAtAndBySentence from "./todoCreatedAtAndBySentence";
import TodoCompletedAtAndBySentence from "./todoCompletedAtAndBySentence";

const { Text } = Typography;

const TodoListItem = ({
  todoItem,
  todoItemData,
  onClickViewDetails,
  onClickEdit,
  onClickMarkComplete,
  onClickMarkIncomplete,
  onClickDelete,
}) => {
  const isComplete = !!todoItem?.completed_at;

  return (
    <List.Item>
      <Row wrap={false} style={{ width: "100%" }}>
        <Col flex={"none"} style={{ paddingRight: 5 }}>
          <TodoAvatarPart todoItem={todoItem} />
        </Col>
        <Col flex={"auto"}>
          <Space
            direction={"vertical"}
            style={{ cursor: "pointer", width: "100%" }}
            onClick={() => onClickViewDetails(todoItem, todoItemData)}
          >
            <Text strong ellipsis={true}>
              {todoItem.name}
            </Text>

            {todoItem.notes && (
              <Text type="secondary" ellipsis={true}>
                {todoItem.notes}
              </Text>
            )}
            {todoItem.completed_at ? (
              <Text type="secondary" ellipsis={true}>
                <TodoCompletedAtAndBySentence todoItem={todoItem} />
              </Text>
            ) : (
              <Text type="secondary" ellipsis={true}>
                <TodoAssignedToAndDuePart todoItem={todoItem} />
              </Text>
            )}

            <Text type="secondary" ellipsis={true}>
              <TodoCreatedAtAndBySentence todoItem={todoItem} />
            </Text>
          </Space>
          <div style={{ marginTop: 8, marginBottom: 5 }}>
            <Space>
              <Button type={"primary"} ghost={true} onClick={onClickEdit}>
                Edit
              </Button>

              {isComplete ? (
                <Button
                  type={"primary"}
                  ghost={true}
                  onClick={() => onClickMarkIncomplete(todoItem)}
                >
                  Mark Incomplete
                </Button>
              ) : (
                <Button
                  type={"primary"}
                  ghost={true}
                  onClick={() => onClickMarkComplete(todoItem)}
                >
                  Mark complete
                </Button>
              )}

              <Button
                type={"primary"}
                ghost={true}
                danger={true}
                onClick={() => onClickDelete(todoItem)}
              >
                Delete
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </List.Item>
  );
};

export default TodoListItem;
