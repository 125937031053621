import React from "react";
import OverlayFormWrapper from "../../../common/formComponents/overlayFormWrapper";
import UseImageAsBoardCoverForm from "./useImageAsBoardCoverForm";

const UseImageAsBoardCoverOverlay = ({
  open,
  onOk,
  onCancel,
  initialValues,
  confirmLoading,
  formMergeValues,
  ecObject,
}) => {
  const titleText = "Use image as board cover";
  const okText = "Save";
  const cancelText = "Cancel";

  const handleOk = (values) => {
    const boardId = values.board_id;
    delete values.board_id;
    const response = { ...values, ...formMergeValues, id: boardId };
    onOk(response);
  };

  return (
    <OverlayFormWrapper
      open={open}
      title={titleText}
      onOk={handleOk}
      okText={okText}
      onCancel={onCancel}
      cancelText={cancelText}
      confirmLoading={confirmLoading}
      initialValues={initialValues}
      formMergeValues={formMergeValues}
      formClass={UseImageAsBoardCoverForm}
      ecObject={ecObject}
      onValuesChange={() => {}}
      onValidationError={() => {}}
    />
  );
};

export default UseImageAsBoardCoverOverlay;
