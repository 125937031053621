import useTldrPageContext from "../orchestrate/common/useTldrPageContext";
import { replacePlaceholdersInTemplate } from "../../serviceUrls/serviceUrlHelpers";

const useEmailCollectorServiceUrlHelper = (template, templateReplacements) => {
  const { currentFamily } = useTldrPageContext();
  const firstEndpointPart = `/tenants/${currentFamily?.tenant_id}`;
  const secondEndpointPart = replacePlaceholdersInTemplate(
    template,
    templateReplacements,
  );

  return {
    endpointPath: `${firstEndpointPart}${secondEndpointPart}`,
    currentFamily,
  };
};

export default useEmailCollectorServiceUrlHelper;
