import React from "react";
import NativeTags from "./nativeTags";
import DetailRow from "./detailRow";

const TagsDetailRow = ({ tags }) => {
  if (!tags || tags.length === 0) {
    return <></>;
  }

  return <DetailRow label={"Tags"} value={<NativeTags tags={tags} />} />;
};

export default TagsDetailRow;
