import React from "react";
import { Typography } from "antd";
import CardCenteredTextCover from "../../common/generic/cardCenteredTextCover";

const NonHttpLinkCardCover = ({ ecObject, onClick }) => {
  return (
    <CardCenteredTextCover onClick={onClick || null}>
      <Typography.Title ellipsis={true} level={3}>
        {ecObject?.link_url}
      </Typography.Title>
    </CardCenteredTextCover>
  );
};

export default NonHttpLinkCardCover;
