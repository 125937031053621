import React from "react";
import DetailRow from "./detailPanelParts/detailRow";
import DetailPanelSkeleton from "./detailPanelParts/detailPanelSkeleton";
import TagsDetailRow from "./detailPanelParts/tagsDetailRow";
import TldrCardWithCoverImage from "../../common/generic/tldrCardWithCoverImage";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import AttachmentTextCover from "../cardCovers/attachmentTextCover";

const AttachmentDetailPanel = ({ ecObject, actionMenu }) => {
  const isImage = ecObject?.mime_type.startsWith("image/");
  const hasPdfPreview = !!ecObject?.preview_signed_url;

  const getStandardCardWithTextDescription = () => {
    return (
      <TldrCardWithCoverComponent
        coverComponent={<AttachmentTextCover ecObject={ecObject} />}
        width={"auto"}
        coverHeight={300}
        hoverable={false}
        onClick={() => {
          window.open(ecObject?.signed_url, "_blank");
        }}
      />
    );
  };

  const getCardCapableOfPreview = () => {
    return (
      <TldrCardWithCoverImage
        imageSrc={
          hasPdfPreview ? ecObject.preview_signed_url : ecObject.signed_url
        }
        imageSize={null}
        width={"auto"}
        coverHeight={300}
        alt={""}
        preview={false}
        hoverable={false}
        onClick={() => {
          window.open(ecObject?.signed_url, "_blank");
        }}
      />
    );
  };

  const getCard = () => {
    if (isImage || hasPdfPreview) {
      return getCardCapableOfPreview();
    } else {
      return getStandardCardWithTextDescription();
    }
  };

  return (
    <DetailPanelSkeleton
      position1={getCard()}
      position2={!!actionMenu && actionMenu}
      position3={
        <>
          <DetailRow label={"Filename"} value={ecObject?.filename} />
          <TagsDetailRow tags={ecObject?.native_tags} />
        </>
      }
    />
  );
};

export default AttachmentDetailPanel;
