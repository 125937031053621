import React from "react";
import CardCenteredTextCover from "../../common/generic/cardCenteredTextCover";
import { Typography } from "antd";

const EmailAddressCardCover = ({ ecObject }) => {
  const getHeaderAndTextContent = () => {
    const displayNamesAsString = ecObject.display_names.join(", ");
    const email = ecObject.email;

    if (displayNamesAsString === "") {
      return (
        <Typography.Title level={3} ellipsis={true}>
          {email}
        </Typography.Title>
      );
    } else {
      return (
        <>
          <Typography.Title level={3} ellipsis={true}>
            {displayNamesAsString}
          </Typography.Title>
          <Typography.Text ellipsis={true}>{email}</Typography.Text>
        </>
      );
    }
  };

  return (
    <CardCenteredTextCover>{getHeaderAndTextContent()}</CardCenteredTextCover>
  );
};

export default EmailAddressCardCover;
