import React from "react";
import useListMessagesForMagicCalendarEvent from "../hooks/useListMessagesForMagicCalendarEvent";
import { Col, Row, Typography } from "antd";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FoundInMessagesBlock from "../../common/commonTldr/foundInMessagesBlock";

const TagForMessagesContainingEvent = ({ eventObject, onClick }) => {
  const { data: messages } = useListMessagesForMagicCalendarEvent(
    eventObject.event_rollup_sort_key,
  );

  const getJsx = () => {
    return (
      <Row wrap={false} align="top">
        <Col style={{ paddingRight: 5 }}>
          <EmailOutlinedIcon sx={{ fontSize: 20, color: "grey" }} />
        </Col>
        <Col>
          {messages ? (
            <FoundInMessagesBlock
              messages={messages}
              onClick={() => {
                onClick(eventObject, messages);
              }}
            />
          ) : (
            <Typography.Text type={"secondary"}>Loading...</Typography.Text>
          )}
        </Col>
      </Row>
    );
  };

  return getJsx();
};

export default TagForMessagesContainingEvent;
