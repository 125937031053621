import React from "react";
import { Tag } from "antd";

const NativeTags = ({ tags }) => {
  if (!tags) {
    return <></>;
  }

  return (
    <>
      {tags.map((item, index) => {
        return (
          <Tag color="#ff4081" key={index} style={{ marginRight: 5 }}>
            {item}
          </Tag>
        );
      })}
    </>
  );
};

export default NativeTags;
