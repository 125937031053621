import useQueryGenericGet from "../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../serviceUrls/baseUrls";
import { EC_SERVICE_EVENTS } from "../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../../../hooks/emailCollector/useEmailCollectorServiceUrlHelper";

const useListMagicEvents = (showPast, showHidden, limit, offset) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_EVENTS,
    {},
  );

  const enabled = !!(
    currentFamily &&
    [true, false].includes(showHidden) &&
    limit &&
    offset >= 0
  );

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    {
      show_past: showPast,
      show_hidden: showHidden,
      limit: limit,
      offset: offset,
    },
    enabled,
  );
};

export default useListMagicEvents;
