import useQueryGenericPost from "../../../../hooks/framework/useQueryGenericPost";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../../serviceUrls/baseUrls";
import { O_SERVICE_FAMILIES_CREATE } from "../../../../serviceUrls/serviceUrlsOrchestrate";

const useCreateFamily = (onSubmit, onSuccess, onError) => {
  return useQueryGenericPost(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_FAMILIES_CREATE,
    undefined,
    onSubmit,
    onSuccess,
    onError,
    true,
  );
};

export default useCreateFamily;
