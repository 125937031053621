import useQueryGenericGet from "../../../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../../../serviceUrls/baseUrls";
import { EC_SERVICE_JOINABLE_TASKS } from "../../../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../../../../../hooks/emailCollector/useEmailCollectorServiceUrlHelper";

const useListJoinableTasksForMessage = (uniqueMessageId) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_JOINABLE_TASKS,
    {},
  );
  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    { unique_message_id: uniqueMessageId },
    !!(uniqueMessageId && currentFamily),
  );
};

export default useListJoinableTasksForMessage;
