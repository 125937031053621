import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const CreateFreeAccountButton = () => {
  const navigate = useNavigate();

  return (
    <Button type={"primary"} onClick={() => navigate("/login")}>
      Create Account
    </Button>
  );
};

export default CreateFreeAccountButton;
