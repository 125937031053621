import {
  DUMMY_TIME_STRING,
  getDayJsDatetimeFromStrings,
} from "../../../../utils/dayJsUtils";

const useCreateOrEditTodoForm = (formMergeValues, onFinish) => {
  const handleOk = (values) => {
    const response = { ...formMergeValues };
    response.name = values.name;
    response.notes = values.notes;
    response.due = null;
    response.is_all_day = true;
    if (values.due.date) {
      let date = values.due.date;
      let time = DUMMY_TIME_STRING;
      if (values.due.time) {
        time = values.due.time;
        response.is_all_day = false;
      }
      response.due = getDayJsDatetimeFromStrings(date, time);
    }
    response.assigned_to_id = null;
    if (values.assignedTo && values.assignedTo > 0) {
      response.assigned_to_id = values.assignedTo;
    }
    delete response.assignedTo;
    onFinish(response);
  };

  return {
    handleOk: handleOk,
  };
};

export default useCreateOrEditTodoForm;
