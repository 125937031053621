import { useState } from "react";

export const EMPTY_BOARD = {
  name: "",
};
export const MERGE_VALUES_BOARD = {};

const useCreateOrEditOrchestrateBoard = () => {
  const [boardOverlayOpen, setBoardOverlayOpen] = useState(false);
  const [boardFormValues, setBoardFormValues] = useState({});
  const [boardMergeValues, setBoardMergeValues] = useState({});

  const handleCreateBoard = (formValues, mergeValues) => {
    setBoardFormValues({
      ...EMPTY_BOARD,
      ...formValues,
    });
    setBoardMergeValues({
      ...MERGE_VALUES_BOARD,
      ...mergeValues,
    });
    openBoardOverlay();
  };

  const openBoardOverlay = () => {
    setBoardOverlayOpen(true);
    document.body.classList.add("overlay-open");
  };

  const closeBoardOverlay = () => {
    setBoardOverlayOpen(false);
    document.body.classList.remove("overlay-open");
  };

  return {
    boardFormValues,
    boardMergeValues,
    boardOverlayOpen,
    closeBoardOverlay,
    handleCreateBoard,
    setBoardFormValues,
    setBoardMergeValues,
  };
};

export default useCreateOrEditOrchestrateBoard;
