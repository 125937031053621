import React from "react";
import { List } from "antd";
import ConfirmInviteListItem from "./confirmInviteListItem";

const ConfirmInvitesList = ({ invites }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={invites || []}
      renderItem={(invite) => <ConfirmInviteListItem invite={invite} />}
    />
  );
};

export default ConfirmInvitesList;
