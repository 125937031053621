import React from "react";
import { Button, Typography } from "antd";
import FormattedDateTimeRange from "../common/generic/formattedDateTimeRange";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import IconAndText from "../common/generic/iconAndText";
import AnchorNoPropagation from "../common/generic/anchorNoPropagation";
import { generateGoogleCalendarTemplateUrl } from "../googleCalendar/googleCalendarEventTemplateHelpers";

const { Text, Paragraph } = Typography;

const InboundForwardEventDetails = ({ eventPair, onClickViewInMessage }) => {
  const getGoogleCalendarUrl = () => {
    const eventRollup = eventPair.rollup;
    return generateGoogleCalendarTemplateUrl(
      eventRollup.title,
      eventRollup.location,
      eventRollup.start_date,
      eventRollup.start_time,
      eventRollup.end_date,
      eventRollup.end_time,
      "UTC",
      eventRollup.description,
      [],
    );
  };

  const getItem = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
      <div>
        <h3>
          {eventPair.rollup.title}
          {"  "}
          <span style={{ fontSize: 25 }}>{eventPair.rollup.emoji}</span>
        </h3>

        {eventPair?.rollup.description && (
          <IconAndText
            icon={
              <DescriptionOutlinedIcon sx={{ fontSize: 20, color: "grey" }} />
            }
            text={
              <Paragraph
                type="secondary"
                ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
              >
                {eventPair.rollup.description}
              </Paragraph>
            }
          />
        )}
        <IconAndText
          icon={<EventOutlinedIcon sx={{ fontSize: 20, color: "grey" }} />}
          text={
            <Text type="secondary">
              <FormattedDateTimeRange
                startDateAsString={eventPair.rollup.start_date}
                startTimeAsString={eventPair.rollup.start_time}
                endDateAsString={eventPair.rollup.end_date}
                endTimeAsString={eventPair.rollup.end_time}
                timezoneInterpret={"UTC"}
                timezoneDisplay={timezone}
              />
            </Text>
          }
        />
        {eventPair?.rollup.location && (
          <IconAndText
            icon={
              <LocationOnOutlinedIcon sx={{ fontSize: 20, color: "grey" }} />
            }
            text={<Text type="secondary">{eventPair.rollup.location}</Text>}
          />
        )}
        <div style={{ paddingTop: 8 }}>
          <AnchorNoPropagation
            onClick={() => {
              onClickViewInMessage(eventPair);
            }}
          >
            View in Message
          </AnchorNoPropagation>
        </div>
        <div style={{ paddingTop: 8 }}>
          <Button
            type={"primary"}
            ghost={true}
            onClick={() => {
              const googleCalendarUrl = getGoogleCalendarUrl();
              window.open(googleCalendarUrl, "_blank");
            }}
          >
            Add to Google Calendar
          </Button>
        </div>
      </div>
    );
  };

  return <>{getItem()}</>;
};

export default InboundForwardEventDetails;
