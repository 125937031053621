import React from "react";
import TodoCompletedByPart from "./todoCompletedByPart";
import TodoCompletedAtPart from "./todoCompletedAtPart";

const TodoCompletedAtAndBySentence = ({ todoItem }) => {
  const getCompleted = () => {
    return (
      <>
        <TodoCompletedByPart todoItem={todoItem} />
        {" on "}
        <TodoCompletedAtPart todoItem={todoItem} />
      </>
    );
  };

  return <>{todoItem.completed_at ? <div>{getCompleted()}</div> : <></>}</>;
};

export default TodoCompletedAtAndBySentence;
