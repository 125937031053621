import { useState } from "react";

const useArtifactDetailOverlay = () => {
  const [dataForDetailsOverlay, setDataForDetailsOverlay] = useState(null);
  const [detailsOverlayOpen, setDetailsOverlayOpen] = useState(false);

  const openArtifactDetailOverlay = () => {
    setDetailsOverlayOpen(true);
    document.body.classList.add("overlay-open");
  };

  const closeArtifactDetailOverlay = () => {
    setDetailsOverlayOpen(false);
    document.body.classList.remove("overlay-open");
  };

  return {
    dataForDetailsOverlay,
    setDataForDetailsOverlay,
    detailsOverlayOpen,
    openArtifactDetailOverlay,
    closeArtifactDetailOverlay,
  };
};

export default useArtifactDetailOverlay;
