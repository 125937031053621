import {
  CODE_DATE_FORMAT,
  getDayJsDatetimeFromStrings,
} from "../../utils/dayJsUtils";

const getSingleDateTimeForInGoogleCalendarFormat = (date, time) => {
  if (!date) return null;
  if (!time) {
    return date.replaceAll("-", "");
  }
  return `${date}T${time}:00`.replaceAll("-", "").replaceAll(":", "");
};

const rewriteDatesAndTimesForLogicalRange = (date1, time1, date2, time2) => {
  if (date1) {
    if (time1) {
      if (date2) {
        if (time2) {
          // Use all dates and times
          return {
            date1: date1,
            time1: time1,
            date2: date2,
            time2: time2,
          };
        } else {
          // If dates are the same, just set start datetime and the end datetime to the same thing
          // otherwise, use date1 and date2 but no time
          if (date1 === date2) {
            return {
              date1: date1,
              time1: time1,
              date2: date1,
              time2: time1,
            };
          } else {
            return {
              date1: date1,
              time1: null,
              date2: date2,
              time2: null,
            };
          }
        }
      } else {
        if (time2) {
          // Use start date for both dates and then use each time
          return {
            date1: date1,
            time1: time1,
            date2: date1,
            time2: time2,
          };
        } else {
          // Just use start date and time and set the end to the same
          return {
            date1: date1,
            time1: time1,
            date2: date1,
            time2: time1,
          };
        }
      }
    } else {
      if (date2) {
        // Just use start and end date
        return {
          date1: date1,
          time1: null,
          date2: date2,
          time2: null,
        };
      } else {
        // Just use start date
        return {
          date1: date1,
          time1: null,
          date2: date1,
          time2: null,
        };
      }
    }
  }
};

const getDateTimeStringForGoogleCalendarUrl = (date1, time1, date2, time2) => {
  const cleanedDatesAndTimes = rewriteDatesAndTimesForLogicalRange(
    date1,
    time1,
    date2,
    time2,
  );

  date1 = cleanedDatesAndTimes["date1"];
  time1 = cleanedDatesAndTimes["time1"];

  date2 = cleanedDatesAndTimes["date2"];
  time2 = cleanedDatesAndTimes["time2"];

  // Conform to the way Google does all-day events. That is, if it's a one-day event on 2024-05-10, then the bounds
  // should be 2024-05-10 to 2024-05-11. I have been storing this as 2024-05-10 to 2024-05-10, so the conversion needs
  // to be made.
  let newDate2 = date2;
  if (!time1 && !time2) {
    if (date1 && date2) {
      const d2 = getDayJsDatetimeFromStrings(date2, null);
      newDate2 = d2.add(1, "day").format(CODE_DATE_FORMAT);
    }
  }

  const start = getSingleDateTimeForInGoogleCalendarFormat(date1, time1);
  const end = getSingleDateTimeForInGoogleCalendarFormat(newDate2, time2);

  if (end) return `${start}/${end}`;
  return start;
};

const getQsItem = (name, value) => {
  if (!value) return null;
  const encValue = encodeURIComponent(value);
  return `${name}=${encValue}`;
};

export const generateGoogleCalendarTemplateUrl = (
  text,
  location,
  date1,
  time1,
  date2,
  time2,
  timezone,
  details,
  add,
) => {
  const baseUrl = "http://www.google.com/calendar/event";
  const qsAction = "action=TEMPLATE";
  const qsText = getQsItem("text", text);
  const qsLocation = getQsItem("location", location);
  const qsDetails = getQsItem("details", details);
  const qsTz = getQsItem("ctz", timezone);

  const dates = getDateTimeStringForGoogleCalendarUrl(
    date1,
    time1,
    date2,
    time2,
  );
  const encDates = encodeURIComponent(dates);
  const qsDates = `dates=${encDates}`;

  const addArray = [];
  if (add) {
    for (const i in add) {
      addArray.push(`add=${encodeURIComponent(add[i])}`);
    }
  }
  const qsAdd = addArray.join("&");

  const qsStringBuffer = [];
  if (qsLocation) qsStringBuffer.push(qsLocation);
  if (qsDates) qsStringBuffer.push(qsDates);
  if (qsTz) qsStringBuffer.push(qsTz);
  if (qsDetails) qsStringBuffer.push(qsDetails);
  if (qsAdd) qsStringBuffer.push(qsAdd);
  const qs = qsStringBuffer.join("&");
  return `${baseUrl}?${qsAction}&${qsText}&${qs}`;
};

export const generateInitialEventDetails = (
  familyId,
  artifactObject,
  description,
  location,
) => {
  let eventDescription = "";
  if (description) {
    eventDescription = `${description}\n\n`;
  }
  let eventLocation = "";
  if (location) {
    eventLocation = `Location: ${location}`;
  }

  return `${eventDescription}${eventLocation}`;
};
