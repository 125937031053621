import axios from "axios";

class ApiClient {
  baseUrl;
  endpoint;
  axiosConfig;

  constructor(baseUrl, endpoint, token) {
    this.baseUrl = baseUrl;
    this.endpoint = endpoint;

    const headers = {
      "tldr-user-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    this.axiosConfig = {
      baseURL: baseUrl,
      headers: headers,
    };
  }

  get = (pathParams, queryParams) => {
    let pathExtension = "";
    if (pathParams && pathParams.length > 0) {
      pathExtension = `/${pathParams.join("/")}`;
    }

    const localAxiosConfig = { ...this.axiosConfig, params: queryParams };
    return axios
      .get(`${this.endpoint}${pathExtension}`, localAxiosConfig)
      .then((res) => res.data);
  };

  post = (data) => {
    return axios
      .post(this.endpoint, data, this.axiosConfig)
      .then((res) => res.data);
  };

  put = (data) => {
    return axios
      .put(this.endpoint, data, this.axiosConfig)
      .then((res) => res.data);
  };

  patch = (data) => {
    return axios
      .patch(this.endpoint, data, this.axiosConfig)
      .then((res) => res.data);
  };

  patchWithoutId = (data) => {
    return axios
      .patch(this.endpoint, data, this.axiosConfig)
      .then((res) => res.data);
  };

  delete = () => {
    return axios
      .delete(this.endpoint, this.axiosConfig)
      .then((res) => res.data);
  };
}

export const getApiClientWithBaseUrl = (baseUrl, endpoint, token) => {
  return new ApiClient(baseUrl, endpoint, token);
};
