import useQueryGenericGet from "../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_ORCHESTRATE_NOAUTH } from "../../../serviceUrls/baseUrls";
import { O_SERVICE_INVITES_BY_INVITE_CODE_GET } from "../../../serviceUrls/serviceUrlsOrchestrate";
import { replacePlaceholdersInTemplate } from "../../../serviceUrls/serviceUrlHelpers";

const useGetInviteGivenCode = (inviteCode) => {
  const endpointPath = replacePlaceholdersInTemplate(
    O_SERVICE_INVITES_BY_INVITE_CODE_GET,
    {
      inviteCode,
    },
  );

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE_NOAUTH,
    endpointPath,
    false,
    [],
    {},
    true,
  );
};

export default useGetInviteGivenCode;
