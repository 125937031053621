import React from "react";
import { AppContextFilesPlusMessage } from "../../../../ecObjectViewers/actionMappers/appContextsToActions";
import EcObjectCardsContainer from "../../../../ecObjectViewers/ecObjectCardsContainer";
import { Skeleton } from "antd";

const MessageSummaryAttachments = ({ messageArtifacts }) => {
  const getJsx = () => {
    if (!messageArtifacts) {
      return <Skeleton active={true} />;
    }

    if (!messageArtifacts?.attachments?.length > 0) {
      return <></>;
    }

    return (
      <div style={{ marginBottom: 20 }}>
        <h2>Attachments</h2>
        <EcObjectCardsContainer
          ecObjects={[...messageArtifacts.attachments]}
          appContext={AppContextFilesPlusMessage}
          cardProperties={{}}
          showClickDetailsTag={true}
          scrollToFirstHighlight={true}
        />
      </div>
    );
  };

  return getJsx();
};

export default MessageSummaryAttachments;
