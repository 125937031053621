import React, { useEffect, useState } from "react";
import useGetInboundForward from "./hooks/useGetInboundForward";
import { useNavigate, useParams } from "react-router-dom";
import LayoutChromeNoAuth from "../layout/layoutChromeNoAuth";
import InboundForwardCreateAccountAlert from "./inboundForwardCreateAccountAlert";
import InboundForwardEvents from "./inboundForwardEvents";
import InboundForwardLinks from "./inboundForwardLinks";
import ResponsiveCard from "../common/generic/responsiveCard";
import InboundForwardMessageViewer from "./inboundForwardMessageViewer";
import AbsoluteSpin from "../common/generic/absoluteSpin";
import InboundForwardAttachments from "./inboundForwardAttachments";
import { FloatButton } from "antd";
import InboundMessageSummary from "./inboundMessageSummary";
import InboundForwardMessageMetadata from "./inboundForwardMessageMetadata";
import TrialEducationModal from "./trialEducationModal";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";
import useGetUnregisteredFamilyInfo from "./hooks/useGetUnregisteredFamilyInfo";

const InboundForwardHome = () => {
  let { inboundForwardId } = useParams();
  const navigate = useNavigate();

  const { data: inboundForwardData } = useGetInboundForward(inboundForwardId);

  const { data: unregisteredFamilyInfo } = useGetUnregisteredFamilyInfo(
    inboundForwardData?.message?.tenant_id,
  );

  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [tlecIndexesToHighlight, setTlecIndexesToHighlight] = useState([]);
  const [trialEducationModalOpen, setTrialEducationModalOpen] = useState(false);
  const [trialEducationModalTitle, setTrialEducationModalTitle] = useState("");

  const { data: currentUser } = useGetCurrentUser();

  useEffect(() => {
    if (inboundForwardData) {
      if (inboundForwardData.error) {
        navigate("/");
      }
    }
  }, [inboundForwardData, navigate]);

  useEffect(() => {
    if (unregisteredFamilyInfo) {
      if (unregisteredFamilyInfo.registered === true) {
        if (inboundForwardData && !inboundForwardData.error) {
          navigate(
            `/families/${unregisteredFamilyInfo.family_id}/smart-inbox/${inboundForwardData.message.tenant_id}-${inboundForwardData.message.unique_tenancy_message_id}`,
          );
        } else {
          navigate(`/families/${unregisteredFamilyInfo.family_id}/smart-inbox`);
        }
      }
    }
  }, [inboundForwardData, unregisteredFamilyInfo, navigate]);

  const openMessageOverlay = (indexes) => {
    if (!indexes) {
      indexes = [];
    }

    setTlecIndexesToHighlight(indexes);
    setMessageOverlayOpen(true);
  };

  const closeMessageOverlay = () => {
    setTlecIndexesToHighlight([]);
    setMessageOverlayOpen(false);
  };

  return (
    <LayoutChromeNoAuth>
      {inboundForwardData ? (
        <>
          <InboundForwardMessageViewer
            open={messageOverlayOpen}
            onClose={() => closeMessageOverlay()}
            messageObject={inboundForwardData?.message}
            htmlAsString={inboundForwardData?.body_indexed}
            tlecIndexesToHighlight={tlecIndexesToHighlight}
          />
          <TrialEducationModal
            title={trialEducationModalTitle}
            open={trialEducationModalOpen}
            onGotIt={() => setTrialEducationModalOpen(false)}
          />
          <ResponsiveCard className="main-container-card">
            <InboundForwardCreateAccountAlert />
            <InboundForwardMessageMetadata
              messageObject={inboundForwardData?.message}
              onClickViewMessage={() => openMessageOverlay()}
            />
            <InboundMessageSummary
              messageSummary={inboundForwardData?.summary_bullets || []}
              onClickViewInMessage={(indexes) => {
                openMessageOverlay(indexes);
              }}
              onClickHelpIcon={() => {
                setTrialEducationModalTitle("Topics at a Glance");
                setTrialEducationModalOpen(true);
              }}
            />
            <InboundForwardEvents
              processingTimezone={inboundForwardData?.tenant_timezone}
              tenantTimezone={unregisteredFamilyInfo?.timezone}
              events={inboundForwardData?.events}
              onClickViewInMessage={(item) => {
                openMessageOverlay(item.obj.indexes);
              }}
              onClickHelpIcon={() => {
                setTrialEducationModalTitle("Events");
                setTrialEducationModalOpen(true);
              }}
            />
            <InboundForwardAttachments
              attachments={inboundForwardData?.attachments}
              onClickHelpIcon={() => {
                setTrialEducationModalTitle("Attachments");
                setTrialEducationModalOpen(true);
              }}
            />
            <InboundForwardLinks
              links={inboundForwardData?.links}
              linkImages={inboundForwardData?.link_images}
              onClickViewInMessage={(item) => {
                openMessageOverlay(item.obj.tlec_index);
              }}
              onClickHelpIcon={() => {
                setTrialEducationModalTitle("Key Links");
                setTrialEducationModalOpen(true);
              }}
            />
            {/*<InboundForwardEmailAddresses*/}
            {/*  emailAddresses={inboundForwardData?.email_addresses}*/}
            {/*  onClickViewInMessage={(item) => {*/}
            {/*    openMessageOverlay(item.obj.tlec_index);*/}
            {/*  }}*/}
            {/*/>*/}
          </ResponsiveCard>
        </>
      ) : (
        <AbsoluteSpin />
      )}
      <FloatButton.BackTop />
    </LayoutChromeNoAuth>
  );
};

export default InboundForwardHome;
