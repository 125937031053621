import React from "react";
import { useParams } from "react-router-dom";
import useListMessageArtifacts from "../../../../hooks/emailCollector/messages/useListMessageArtifacts";
import { AppContextFilesPlusMessage } from "../../../ecObjectViewers/actionMappers/appContextsToActions";
import { Divider, Skeleton } from "antd";
import EcObjectCardsContainer from "../../../ecObjectViewers/ecObjectCardsContainer";

const MessageFilesPlusView = () => {
  const { uniqueMessageId } = useParams();
  const { data: messageArtifacts } = useListMessageArtifacts(
    uniqueMessageId,
    !!uniqueMessageId,
  );

  const getCleanedItems = (items) => {
    const usedFingerprints = new Set();
    return items.filter((item) => {
      const isUsed = usedFingerprints.has(item.fingerprint);
      if (isUsed) {
        return false;
      } else {
        usedFingerprints.add(item.fingerprint);
        return true;
      }
    });
  };

  const getJsxForAttachments = (items) => {
    if (!items || items.length === 0) {
      return <></>;
    }

    return (
      <>
        <div style={{ marginBottom: 20 }}>
          <h2>Attachments</h2>
          <EcObjectCardsContainer
            ecObjects={[...items]}
            appContext={AppContextFilesPlusMessage}
            cardProperties={{
              link_image: {
                showLink: false,
                showImage: true,
              },
            }}
            showClickDetailsTag={true}
            scrollToFirstHighlight={true}
          />
        </div>
      </>
    );
  };

  const getJsxForLinks = (items) => {
    if (!items || items.length === 0) {
      return <></>;
    }

    return (
      <>
        <div style={{ marginBottom: 20 }}>
          <h2>Links</h2>
          <EcObjectCardsContainer
            ecObjects={[...items]}
            appContext={AppContextFilesPlusMessage}
            cardProperties={{
              link_image: {
                showLink: true,
                showImage: false,
              },
            }}
            showClickDetailsTag={true}
            scrollToFirstHighlight={true}
          />
        </div>
      </>
    );
  };

  const getJsxForImages = (items) => {
    if (!items || items.length === 0) {
      return <></>;
    }

    return (
      <>
        <div style={{ marginBottom: 20 }}>
          <h2>Images</h2>
          <EcObjectCardsContainer
            ecObjects={[...items]}
            appContext={AppContextFilesPlusMessage}
            cardProperties={{
              link_image: {
                showLink: false,
                showImage: true,
              },
            }}
            showClickDetailsTag={true}
            scrollToFirstHighlight={true}
          />
        </div>
      </>
    );
  };

  const getJsxForEmailAddresses = (items) => {
    if (!items || items.length === 0) {
      return <></>;
    }

    return (
      <>
        <div style={{ marginBottom: 20 }}>
          <h2>Email Address List</h2>
          <EcObjectCardsContainer
            ecObjects={[...items]}
            appContext={AppContextFilesPlusMessage}
            cardProperties={{}}
            showClickDetailsTag={true}
            scrollToFirstHighlight={true}
          />
        </div>
      </>
    );
  };

  const getJsx = () => {
    if (!messageArtifacts) {
      return <Skeleton active={true} />;
    }

    const cleanedAttachments = getCleanedItems(messageArtifacts.attachments);
    const numAttachments = cleanedAttachments.length;

    const cleanedImages = getCleanedItems([
      ...messageArtifacts.images,
      ...messageArtifacts.link_images,
    ]);
    const numImages = cleanedImages.length;

    let cleanedLinks = getCleanedItems([
      ...messageArtifacts.links,
      ...messageArtifacts.link_images,
    ]);
    const numLinks = cleanedLinks.length;

    let cleanedEmailAddresses = getCleanedItems(
      messageArtifacts.email_addresses,
    );
    const numEmailAddresses = cleanedEmailAddresses.length;

    return (
      <>
        {getJsxForAttachments(cleanedAttachments)}
        {numAttachments > 0 && numImages + numLinks + numEmailAddresses > 0 && (
          <Divider />
        )}
        {getJsxForImages(cleanedImages)}
        {numImages > 0 && numLinks + numEmailAddresses > 0 && <Divider />}
        {getJsxForLinks(cleanedLinks)}
        {numLinks > 0 && numEmailAddresses > 0 && <Divider />}
        {getJsxForEmailAddresses(cleanedEmailAddresses)}
      </>
    );
  };

  return getJsx();
};

export default MessageFilesPlusView;
