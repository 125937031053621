import React from "react";
import horizontalLogo from "../../images/tldrparents-logo-horizontal.svg";

const TopBarPublic = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 10px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a href={"http://tldrparents.com"}>
            <img
              style={{
                maxHeight: 75,
              }}
              src={horizontalLogo}
              alt="TLDR Parents"
            />
          </a>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default TopBarPublic;
