import React from "react";
import { Col, Row, Tooltip, Typography } from "antd";

const { Text } = Typography;

const MiniCardTitleRow = ({ titleText, tooltipText, icon }) => {
  return (
    <Row wrap={false} gutter={[5, 5]}>
      <Col>
        <Tooltip title={tooltipText}>{icon}</Tooltip>
      </Col>
      <Col flex={"auto"}>
        <Text ellipsis={true}>{titleText}</Text>
      </Col>
    </Row>
  );
};

export default MiniCardTitleRow;
