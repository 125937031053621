import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { List } from "antd";
import useBatchGetArtifacts from "../../../hooks/emailCollector/useBatchGetArtifacts";
import SecondaryTodoListMenu from "./secondaryTodoListMenu";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import TodoListItem from "./parts/todoListItem";
import LoadingEmptyOrContent from "../../common/generic/loadingEmptyOrContent";
import OrchestrateObjectFormOverlays from "../orchestrateObjectFormOverlays";
import useTldrUniversalMutateContext from "../../../hooks/utils/useTldrUniversalMutateContext";
import useListOrchestrateObjects from "../../../hooks/orchestrate/useListOrchestrateObjects";
import { O_SERVICE_TODOS_LIST } from "../../../serviceUrls/serviceUrlsOrchestrate";
import SavedObjectDetailsOverlay from "../savedObjectDetailsOverlay";
import { Actions } from "../../ecObjectViewers/actionMappers/actions";
import { AppContextTodoList } from "../../ecObjectViewers/actionMappers/appContextsToActions";
import useCreateOrEditOrchestrateObject from "../../../hooks/orchestrate/common/useCreateOrEditOrchestrateObject";
import { ALL_CLASSES_BY_CLASS_ID } from "../../ecObjectViewers/actionMappers/classesToActions";
import HelpIconAndModal from "../../helpIconAndModal";
import TodoListEducationModal from "./todoListEducationModal";

const TodoList = () => {
  const { familyId, setBreadcrumbs, setBreadcrumbExtra, showDeleteConfirm } =
    useTldrPageContext();

  const [searchParams] = useSearchParams();
  const completed = (searchParams.get("completed") || "open") === "completed";
  const {
    queryKey: todoQueryKey,
    data: todoItems,
    isLoading: isLoadingTodoItems,
  } = useListOrchestrateObjects(O_SERVICE_TODOS_LIST, true, {
    completed: completed,
  });
  const { data: todoItemData } = useBatchGetArtifacts(todoItems);

  const [modalEditTodoOpen, setModalEditTodoOpen] = useState(false);
  const [formTodoFieldValues, setFormTodoFieldValues] = useState(null);
  const [formTodoMergeValues, setFormTodoMergeValues] = useState(null);
  const [detailsOverlayOpen, setDetailsOverlayOpen] = useState(false);
  const [dataForDetailsOverlay, setDataForDetailsOverlay] = useState(null);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "To-dos",
      },
    ]);
    setBreadcrumbExtra(
      <HelpIconAndModal educationModalClass={TodoListEducationModal} />,
    );
  }, [setBreadcrumbs, setBreadcrumbExtra]);

  const {
    todoOverlayOpen,
    closeTodoOverlay,
    todoFormValues,
    todoMergeValues,
    onClickCardAction,
    ecObjectActedUpon,
  } = useCreateOrEditOrchestrateObject();

  const { deleteTodo, updateTodo } = useTldrUniversalMutateContext(
    null,
    todoQueryKey,
    null,
    null,
    null,
    null,
  );

  const handleClickViewDetails = (todoItem, todoItemData) => {
    setDataForDetailsOverlay({
      savedObject: todoItem,
      savedObjectData: todoItemData,
    });
    setDetailsOverlayOpen(true);
  };

  const getEcArtifactForTodo = (todoItem) => {
    if (todoItem && todoItemData) {
      const classId = todoItem.artifacts?.[0].rollup_class_id;
      const hashKey = todoItem.artifacts?.[0].rollup_hash_key;
      const sortKey = todoItem.artifacts?.[0].rollup_sort_key;

      if (sortKey) {
        return todoItemData[classId]?.[hashKey]?.[sortKey] || null;
      } else {
        return todoItemData[classId]?.[hashKey] || null;
      }
    }
  };

  const handleClickEdit = (todoItem) => {
    const ecArtifact = getEcArtifactForTodo(todoItem);
    const clazz = ALL_CLASSES_BY_CLASS_ID[ecArtifact.class_id];
    onClickCardAction(
      {
        classAction: Actions.editTodoAction.name,
        sourceInfo: clazz.getSourceInfo(ecArtifact),
        appContext: AppContextTodoList,
        cardProperties: null,
        savedObject: todoItem,
      },
      ecArtifact,
    );
  };

  const handleClickMarkComplete = (todoItem) => {
    updateTodo({ id: todoItem.id, completed: true });
  };

  const handleClickMarkInomplete = (todoItem) => {
    updateTodo({ id: todoItem.id, completed: false });
  };

  const handleClickDelete = (todoItem) => {
    showDeleteConfirm("Delete?", "", () => deleteTodo(todoItem.id));
  };

  const getListItem = (todoItem) => {
    return (
      <TodoListItem
        todoItem={todoItem}
        todoItemData={todoItemData}
        onClickViewDetails={handleClickViewDetails}
        onClickEdit={() => handleClickEdit(todoItem)}
        onClickMarkComplete={() => handleClickMarkComplete(todoItem)}
        onClickMarkIncomplete={() => handleClickMarkInomplete(todoItem)}
        onClickDelete={() => handleClickDelete(todoItem)}
      />
    );
  };

  const getJsx = () => {
    return (
      <>
        <SecondaryTodoListMenu
          familyId={familyId}
          selectedValue={completed ? "completed" : "open"}
        />
        <TodoListEducationModal forced={true} />
        <OrchestrateObjectFormOverlays
          todoInitialFormValues={todoFormValues}
          todoMergeValues={todoMergeValues}
          todoOverlayOpen={todoOverlayOpen}
          onCancelTodo={() => closeTodoOverlay()}
          onSubmitTodo={() => closeTodoOverlay()}
          onPersistTodo={(item) => {}}
          ecObjectActedUpon={ecObjectActedUpon}
        />
        <OrchestrateObjectFormOverlays
          todoInitialFormValues={formTodoFieldValues}
          todoMergeValues={formTodoMergeValues}
          todosQueryKey={todoQueryKey}
          todoOverlayOpen={modalEditTodoOpen}
          onCancelTodo={() => setModalEditTodoOpen(false)}
          onSubmitTodo={() => setModalEditTodoOpen(false)}
          onPersistTodo={(savedItem) => {}}
        />
        <SavedObjectDetailsOverlay
          open={detailsOverlayOpen}
          onClose={() => {
            setDetailsOverlayOpen(false);
            setDataForDetailsOverlay(null);
          }}
          savedObject={dataForDetailsOverlay?.savedObject}
          savedObjectData={dataForDetailsOverlay?.savedObjectData}
        />
        <LoadingEmptyOrContent
          isLoading={isLoadingTodoItems}
          isEmpty={!todoItems || todoItems.length === 0}
        >
          <List
            itemLayout="horizontal"
            dataSource={todoItems}
            renderItem={(todoItem) => {
              if (completed && todoItem.completed_at) {
                return getListItem(todoItem);
              } else if (!completed && !todoItem.completed_at) {
                return getListItem(todoItem);
              }
            }}
          />
        </LoadingEmptyOrContent>
      </>
    );
  };

  return <>{getJsx()}</>;
};

export default TodoList;
