import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";
import useGetAndSetSelectedFamilyId from "../../hooks/orchestrate/common/useGetAndSetSelectedFamilyId";

const FamiliesOutlet = () => {
  const { familyId: familyIdFromParams } = useParams();
  const { data: currentUser } = useGetCurrentUser();
  const { selectedFamilyId, setSelectedFamily } =
    useGetAndSetSelectedFamilyId();
  const navigate = useNavigate();
  const location = useLocation();

  // TODO: This is a little hacky... Here's what the problem was: Sometimes users would close a full-screen overlay by
  //  clicking the back button. This path would bypass the usual closing route and the app would not re-enable scrolling.
  useEffect(() => {
    if (document.body.classList.contains("overlay-open")) {
      document.body.classList.remove("overlay-open");
    }
  }, [location]);

  useEffect(() => {
    if (!familyIdFromParams) {
      if (selectedFamilyId) {
        navigate(`/families/${selectedFamilyId}`);
      } else {
        if (currentUser) {
          if (currentUser.families?.length > 0) {
            setSelectedFamily(currentUser.families[0].id);
            navigate(`/families/${currentUser.families[0].id}`);
          } else {
            navigate("/");
          }
        }
      }
    }
  }, [
    familyIdFromParams,
    currentUser,
    selectedFamilyId,
    navigate,
    setSelectedFamily,
  ]);

  return <Outlet />;
};

export default FamiliesOutlet;
