import React from "react";
import ArtifactCardInner from "./artifactCardInner";
import TldrCardWithCoverImage from "./tldrCardWithCoverImage";

const ArtifactCardWithCoverImage = ({
  imageSrc,
  imageSize,
  alt,
  preview,
  position1,
  position2,
  position3,
}) => {
  return (
    <TldrCardWithCoverImage
      imageSrc={imageSrc}
      imageSize={imageSize}
      alt={alt}
      preview={preview}
      width={"auto"}
      coverHeight={300}
      hoverable={false}
      onClick={null}
      bodyPadding={8}
    >
      <ArtifactCardInner
        position1={position1}
        position2={position2}
        position3={position3}
      />
    </TldrCardWithCoverImage>
  );
};

export default ArtifactCardWithCoverImage;
