import React from "react";
import { Button, Col, Row, Space, Typography } from "antd";
import TodoAvatarPart from "./parts/todoAvatarPart";
import TodoAssignedToAndDuePart from "./parts/todoAssignedToAndDuePart";
import TodoCreatedAtAndBySentence from "./parts/todoCreatedAtAndBySentence";
import TodoCompletedAtAndBySentence from "./parts/todoCompletedAtAndBySentence";

const { Text } = Typography;

const TodoCard = ({ savedObject, onClickEdit, onClickDelete }) => {
  return (
    <Row wrap={false} style={{ width: "100%" }}>
      <Col flex={"none"} style={{ paddingRight: 5 }}>
        <TodoAvatarPart todoItem={savedObject} />
      </Col>
      <Col flex={"auto"}>
        <Space direction={"vertical"} style={{ width: "100%" }} onClick={null}>
          <Text strong ellipsis={true}>
            {savedObject.name}
          </Text>

          {savedObject.notes && (
            <Text type="secondary" ellipsis={true}>
              {savedObject.notes}
            </Text>
          )}
          {savedObject?.completed_at ? (
            <Text type="secondary" ellipsis={true}>
              <TodoCompletedAtAndBySentence todoItem={savedObject} />
            </Text>
          ) : (
            <Text type="secondary" ellipsis={true}>
              <TodoAssignedToAndDuePart todoItem={savedObject} />
            </Text>
          )}
          <Text type="secondary" ellipsis={true}>
            <TodoCreatedAtAndBySentence todoItem={savedObject} />
          </Text>
        </Space>
        <div style={{ marginTop: 8, marginBottom: 5 }}>
          <Space>
            {onClickEdit && (
              <Button
                type={"primary"}
                ghost={true}
                onClick={() => onClickEdit()}
              >
                Edit
              </Button>
            )}
            <Button
              type={"primary"}
              ghost={true}
              danger={true}
              onClick={() => onClickDelete()}
            >
              Delete
            </Button>
          </Space>
        </div>
      </Col>
    </Row>
  );
};

export default TodoCard;
