import React from "react";
import useGetMessagesForArtifact from "../../../hooks/emailCollector/common/useGetMessagesForArtifact";
import FoundInMessagesBlock from "../../common/commonTldr/foundInMessagesBlock";
import { Typography } from "antd";

const TagForMessagesContainingArtifact = ({ artifactObject, onClick }) => {
  const { messages } = useGetMessagesForArtifact(
    artifactObject?.class_id,
    artifactObject?.hash_key,
    artifactObject?.sort_key,
  );

  const getJsx = () => {
    if (!messages) {
      return <Typography.Text type={"secondary"}>Loading...</Typography.Text>;
    }

    return (
      <FoundInMessagesBlock
        messages={messages}
        onClick={() => {
          onClick(artifactObject, messages);
        }}
      />
    );
  };

  return getJsx();
};

export default TagForMessagesContainingArtifact;
