import React from "react";
import MessageMetadata from "../messageMetadata";

const MessageInfoForSimpleMessageViewer = ({ messageObject }) => {
  if (!messageObject) {
    return <></>;
  }

  return (
    <>
      <h2>{messageObject.subject}</h2>
      <MessageMetadata messageObject={messageObject} />
    </>
  );
};

export default MessageInfoForSimpleMessageViewer;
