const getQsItem = (name, value) => {
  if (!value) return null;
  const encValue = encodeURIComponent(value);
  return `${name}=${encValue}`;
};

export const generateGoogleContactTemplateTemplateUrl = (
  email,
  firstName,
  lastName,
) => {
  const baseUrl = "https://contacts.google.com/new";
  const qsEmail = getQsItem("email", email);
  const qsFirstName = getQsItem("givenname", firstName);
  const qsLastName = getQsItem("familyname", lastName);

  const qsStringBuffer = [];
  if (qsEmail) qsStringBuffer.push(qsEmail);
  if (qsFirstName) qsStringBuffer.push(qsFirstName);
  if (qsLastName) qsStringBuffer.push(qsLastName);

  const qs = qsStringBuffer.join("&");
  return `${baseUrl}?${qs}`;
};
