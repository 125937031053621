import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "../../../hooks/framework/apiClient";
import useUserEducationServiceUrlHelper from "./useUserEducationServiceUrlHelper";
import { UE_SUBJECTS_WITH_SUBJECT_ID_IN_PATH } from "../../../serviceUrls/serviceUrlsUserEducation";
import { BACKEND_API_BASE_URL_USER_EDUCATION } from "../../../serviceUrls/baseUrls";
import { replacePlaceholdersInTemplate } from "../../../serviceUrls/serviceUrlHelpers";

const useUpdateUserEducationSubjectStatus = (onSubmit, onSuccess, onError) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);
  const { endpointPath } = useUserEducationServiceUrlHelper(
    UE_SUBJECTS_WITH_SUBJECT_ID_IN_PATH,
    {},
  );

  return useMutation({
    mutationFn: async (data) => {
      const token = await getAccessTokenSilently();
      return getApiClientWithBaseUrl(
        BACKEND_API_BASE_URL_USER_EDUCATION,
        replacePlaceholdersInTemplate(endpointPath, {
          subjectId: data.subjectId,
        }),
        token,
      ).post({ status: data.status });
    },

    onMutate: (unsaved) => {
      if (onSubmit) onSubmit(unsaved);
    },

    onSuccess: (saved, submitted) => {
      if (onSuccess) onSuccess(saved);
    },

    onError: (error, unsaved, context) => {
      if (onError) onError(error);
    },
  });
};

export default useUpdateUserEducationSubjectStatus;
