import { useEffect, useState } from "react";

const useNavigateMessagesInOverlay = (messages) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [currentMessageId, setCurrentMessageId] = useState(null);

  useEffect(() => {
    if (messages) {
      setCurrentMessageIndex(0);
      const message = messages[0];
      setCurrentMessageId(
        `${message.tenant_id}-${message.unique_tenancy_message_id}`,
      );
    }
  }, [messages]);

  return {
    currentMessageIndex,
    setCurrentMessageIndex,
    currentMessageId,
    setCurrentMessageId,
  };
};

export default useNavigateMessagesInOverlay;
