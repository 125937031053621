import useTldrPageContext from "../orchestrate/common/useTldrPageContext";
import useGetAndSetSelectedFamilyId from "./common/useGetAndSetSelectedFamilyId";
import { replacePlaceholdersInTemplate } from "../../serviceUrls/serviceUrlHelpers";

const useOrchestrateServiceUrlHelper = (template, templateReplacements) => {
  const { familyId, currentFamily } = useTldrPageContext();
  const { selectedFamilyId } = useGetAndSetSelectedFamilyId();

  // Below is to deal with invites during onboarding where the family ID isn't in the query string
  let resolvedFamilyId = familyId;
  if (!familyId) {
    if (selectedFamilyId) {
      resolvedFamilyId = selectedFamilyId;
    }
  }

  let endpointPath = null;
  if (template) {
    const firstEndpointPart = `/families/${resolvedFamilyId}`;
    const secondEndpointPart = replacePlaceholdersInTemplate(
      template,
      templateReplacements,
    );
    endpointPath = `${firstEndpointPart}${secondEndpointPart}`;
  }

  return {
    endpointPath: endpointPath,
    currentFamily,
  };
};

export default useOrchestrateServiceUrlHelper;
