import React from "react";
import { Spin } from "antd";

const AbsoluteSpin = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "25%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default AbsoluteSpin;
