import React, { useEffect } from "react";
import { Form } from "antd";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";
import NameInputFormItem from "../../../common/formComponents/nameInputFormItem";

const CreateOrEditBoardForm = ({
  initialValues,
  ecObject,
  onValuesChange,
  onFormInstanceReady,
}) => {
  const { familyId } = useTldrPageContext();
  const [form] = Form.useForm();

  useEffect(() => {
    onFormInstanceReady(form);
  }, [form, onFormInstanceReady]);

  return (
    <Form
      layout="vertical"
      form={form}
      name={"CreateOrEditBoardForm"}
      initialValues={initialValues}
      onValuesChange={(value) => {
        if (onValuesChange) {
          onValuesChange?.(value, form.getFieldsValue());
        }
      }}
    >
      <NameInputFormItem
        requiredMessage={"Name your board"}
        placeholderText={"Soccer tournament board"}
      />
    </Form>
  );
};

export default CreateOrEditBoardForm;
