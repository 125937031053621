import { useState } from "react";
import { MERGE_SOURCE_DATA_COMMON } from "../../../components/familyOrganizer/orchestrateObjectPrototypes";
import useTldrPageContext from "./useTldrPageContext";
import {
  CODE_DATE_FORMAT,
  CODE_TIME_FORMAT,
  getDayJsDatetimeFromStringsGivenTimezones,
} from "../../../utils/dayJsUtils";
import useGetInitialAndMergeValuesForEdit from "./useGetInitialAndMergeValuesForEdit";

export const EMPTY_TODO = {
  name: "",
  due: {
    date: null,
  },
  notes: "",
};

export const MERGE_VALUES_TODO = {
  ...MERGE_SOURCE_DATA_COMMON,
};

const useCreateOrEditOrchestrateTodo = () => {
  const { currentFamily } = useTldrPageContext();

  const [todoOverlayOpen, setTodoOverlayOpen] = useState(false);
  const [todoFormValues, setTodoFormValues] = useState({});
  const [todoMergeValues, setTodoMergeValues] = useState({});

  const { getInitialValuesForEdit, getMergeValuesForEdit } =
    useGetInitialAndMergeValuesForEdit();

  const getInitialValuesForEvent = (classAction, ecObject) => {
    const startDatetime = getDayJsDatetimeFromStringsGivenTimezones(
      ecObject.start_date,
      ecObject.start_time,
      "UTC",
      currentFamily.timezone,
    );

    return {
      name: `${ecObject.emoji} ${ecObject.title}`,
      notes: "",
      due: {
        date: startDatetime.format(CODE_DATE_FORMAT),
        time: ecObject.start_time && startDatetime.format(CODE_TIME_FORMAT),
      },
    };
  };

  const getInitialValuesForTopic = (classAction, ecObject) => {
    return { name: `${ecObject.emoji} ${ecObject.title}` };
  };

  const getInitialValuesForMessage = (classAction, ecObject) => {
    return { name: ecObject?.subject };
  };

  const getInitialValuesForSummaryBullet = (classAction, ecObject) => {
    return { name: ecObject?.title };
  };

  const getInitialValuesForAttachment = (classAction, ecObject) => {
    return { name: ecObject?.filename };
  };

  const getInitialValuesForEmailAddress = (classAction, ecObject) => {
    return { name: ecObject?.email || "" };
  };

  const getInitialValuesForImage = (classAction, ecObject) => {
    return {};
  };

  const getInitialValuesForLink = (classAction, ecObject) => {
    return { name: ecObject?.preview_summary?.title || "" };
  };

  const getInitialValuesForLinkImage = (classAction, ecObject) => {
    return { name: ecObject?.link_preview_summary?.title || "" };
  };

  const getInitialValuesForNonHttpLink = (classAction, ecObject) => {
    return {};
  };

  const getInitialValues = (classAction, classId, ecObject) => {
    switch (classId) {
      case "attachment_rollup":
        return getInitialValuesForAttachment(classAction, ecObject);

      case "email_address_rollup":
        return getInitialValuesForEmailAddress(classAction, ecObject);

      case "image_rollup":
        return getInitialValuesForImage(classAction, ecObject);

      case "link_rollup":
        return getInitialValuesForLink(classAction, ecObject);

      case "link_image_rollup":
        return getInitialValuesForLinkImage(classAction, ecObject);

      case "non_http_link_rollup":
        return getInitialValuesForNonHttpLink(classAction, ecObject);

      case "event_rollup":
        return getInitialValuesForEvent(classAction, ecObject);

      case "topic_rollup":
        return getInitialValuesForTopic(classAction, ecObject);

      case "message":
        return getInitialValuesForMessage(classAction, ecObject);

      case "summary_bullet":
        return getInitialValuesForSummaryBullet(classAction, ecObject);

      default:
        return {};
    }
  };

  const handleCreateTodo = (values, ecObject) => {
    setTodoFormValues({
      ...EMPTY_TODO,
      ...getInitialValues(values.classAction, ecObject.class_id, ecObject),
    });
    setTodoMergeValues({
      ...MERGE_VALUES_TODO,
      ...values.sourceInfo,
    });
    openTodoOverlay();
  };

  const handleEditTodo = (values, ecObject) => {
    const savedTodo = values?.savedObject;
    setTodoFormValues(getInitialValuesForEdit(savedTodo));
    setTodoMergeValues(getMergeValuesForEdit(savedTodo));
    openTodoOverlay();
  };

  const openTodoOverlay = () => {
    setTodoOverlayOpen(true);
    document.body.classList.add("overlay-open");
  };

  const closeTodoOverlay = () => {
    setTodoOverlayOpen(false);
    document.body.classList.remove("overlay-open");
  };

  return {
    getInitialValuesForEdit,
    getMergeValuesForEdit,
    closeTodoOverlay,
    handleCreateTodo,
    handleEditTodo,
    setTodoFormValues,
    setTodoMergeValues,
    todoFormValues,
    todoMergeValues,
    todoOverlayOpen,
  };
};

export default useCreateOrEditOrchestrateTodo;
