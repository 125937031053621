import React, { useEffect } from "react";
import SettingsDefaultPaymentMethod from "./billing/settingsDefaultPaymentMethod";
import SettingsSubscription from "./billing/settingsSubscription";
import SettingsInvoices from "./billing/settingsInvoices";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";

const SettingsSubscriptionBilling = () => {
  const { currentFamily, setBreadcrumbs, clearBreadcrumbExtra } =
    useTldrPageContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Settings",
        link: `/families/${currentFamily?.id}/settings`,
      },
      {
        label: "Subscription & billing",
      },
    ]);
    clearBreadcrumbExtra();
  }, [setBreadcrumbs, clearBreadcrumbExtra, currentFamily]);

  return (
    <>
      <SettingsSubscription />
      <SettingsDefaultPaymentMethod />
      <SettingsInvoices />
    </>
  );
};

export default SettingsSubscriptionBilling;
