import React from "react";
import { Button } from "antd";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";

const SmallButtonWithIcon = ({ iconType, iconColor, onClick }) => {
  const getIconStyle = () => {
    const style = { fontSize: 20 };
    if (iconColor) {
      style.color = iconColor;
    }
    return style;
  };

  const getIcon = () => {
    switch (iconType) {
      case "DELETE":
        return <DeleteOutlineOutlinedIcon sx={getIconStyle()} />;
      // case "MARK_READ":
      //   return <MarkEmailReadOutlinedIcon sx={getIconStyle()} />;
      // case "MARK_UNREAD":
      //   return <MarkEmailUnreadOutlinedIcon sx={getIconStyle()} />;
      case "SYNC":
        return <SyncOutlinedIcon sx={getIconStyle()} />;
      case "INBOX":
        return <InboxOutlinedIcon sx={getIconStyle()} />;
      case "CHECKBOX_ALL":
        return <CheckBoxOutlinedIcon sx={getIconStyle()} />;
      case "CHECKBOX_NONE":
        return <CheckBoxOutlineBlankOutlinedIcon sx={getIconStyle()} />;
      case "BUG":
        return <BugReportOutlinedIcon sx={getIconStyle()} />;

      default:
        return null;
    }
  };

  return (
    <Button
      size="small"
      icon={getIcon()}
      style={{ width: 50 }}
      onClick={onClick}
    />
  );
};

export default SmallButtonWithIcon;
