import React from "react";
import TldrCardWithCoverImage from "../../common/generic/tldrCardWithCoverImage";
import AttachmentTextCover from "../cardCovers/attachmentTextCover";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import MiniCardTitleRow from "../miniCardParts/miniCardTitleRow";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

const AttachmentMiniCard = ({ ecObject, appContext, onClick }) => {
  const isImage = ecObject?.mime_type.startsWith("image/");
  const hasPdfPreview = !!ecObject?.preview_signed_url;

  const getStandardCardWithTextDescription = () => {
    return (
      <TldrCardWithCoverComponent
        coverComponent={
          <AttachmentTextCover ecObject={ecObject} onClick={null} />
        }
        width={250}
        coverHeight={150}
        hoverable={false}
        onClick={onClick}
      >
        <MiniCardTitleRow
          titleText={ecObject?.filename}
          tooltipText={"Attachment"}
          icon={<AttachFileOutlinedIcon sx={{ color: "#ff4081" }} />}
        />
      </TldrCardWithCoverComponent>
    );
  };

  const getCardCapableOfPreview = () => {
    return (
      <TldrCardWithCoverImage
        imageSrc={
          hasPdfPreview ? ecObject.preview_signed_url : ecObject.signed_url
        }
        coverHeight={150}
        imageSize={null}
        width={250}
        alt={""}
        preview={false}
        hoverable={false}
        onClick={onClick}
      >
        <MiniCardTitleRow
          titleText={ecObject?.filename}
          tooltipText={"Attachment"}
          icon={<AttachFileOutlinedIcon sx={{ color: "#ff4081" }} />}
        />
      </TldrCardWithCoverImage>
    );
  };

  const getCard = () => {
    if (isImage || hasPdfPreview) {
      return getCardCapableOfPreview();
    } else {
      return getStandardCardWithTextDescription();
    }
  };

  return getCard();
};

export default AttachmentMiniCard;
