import React from "react";
import {
  Button,
  Col,
  Input,
  Radio,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import useBoardSelector2 from "./useBoardSelector2";
import TldrCardWithCoverImage from "./common/generic/tldrCardWithCoverImage";
import MiniCardTitleRow from "./ecObjectViewers/miniCardParts/miniCardTitleRow";

const BoardSelector2 = ({ value, onChange }) => {
  const {
    newBoardInputRef,
    newBoardName,
    setNewBoardName,
    boardsAsOptions,
    selectedBoardId,
    handleChangeBoardRadioSelection,
    handleCreateNewBoardSubmitClicked,
    getImageUrlForBoardId,
  } = useBoardSelector2(value, onChange);

  const getBoardsRadioSection = () => {
    if (!boardsAsOptions) {
      return <Skeleton active={true} />;
    }

    if (boardsAsOptions.length === 0) {
      return (
        <Typography.Text strong={true}>
          You need a board! Use the form below to bring one to life and start
          organizing.
        </Typography.Text>
      );
    }

    return (
      <div>
        <Radio.Group
          onChange={handleChangeBoardRadioSelection}
          value={selectedBoardId}
          style={{ width: "100%" }}
        >
          <Row gutter={[10, 10]}>
            {boardsAsOptions.map((option, index) => {
              return (
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} key={index}>
                  <Radio value={option.value}>
                    <TldrCardWithCoverImage
                      imageSrc={getImageUrlForBoardId(option.value)}
                      coverHeight={200}
                      imageSize={null}
                      width={250}
                      alt={""}
                      preview={false}
                      hoverable={false}
                      bodyPadding={0}
                      onClick={null}
                    >
                      <MiniCardTitleRow titleText={option.label} />
                    </TldrCardWithCoverImage>
                  </Radio>
                </Col>
              );
            })}
          </Row>
        </Radio.Group>
      </div>
    );
  };

  const getNewBoardInputSection = () => {
    return (
      <div style={{ marginTop: 10 }}>
        <Space.Compact style={{ maxWidth: 400, width: "100%" }}>
          <Input
            placeholder={"New Board"}
            maxLength={50}
            onPressEnter={handleCreateNewBoardSubmitClicked}
            value={newBoardName}
            ref={newBoardInputRef}
            onChange={(e) => {
              setNewBoardName(newBoardInputRef.current.input.value);
            }}
          />
          <Button
            type="primary"
            style={{ borderTopRightRadius: 6, borderBottomRightRadius: 6 }}
            onClick={handleCreateNewBoardSubmitClicked}
          >
            New board
          </Button>
        </Space.Compact>
      </div>
    );
  };

  return (
    <>
      {getBoardsRadioSection()}
      {getNewBoardInputSection()}
    </>
  );
};

export default BoardSelector2;
