import React from "react";
import { Button, Typography } from "antd";
import InvitePartnerForm from "../forms/invitePartnerForm";
import UsersGivenName from "../../../common/commonTldr/usersGivenName";

const { Text } = Typography;

const InvitePartner = ({ onSuccess, onSkip }) => {
  return (
    <div>
      <div style={{ marginBottom: 20, textAlign: "left" }}>
        <Text type="secondary">
          Hey <UsersGivenName fallbackGivenName={"Friend"} />! Why not send an
          invite to the person who's your go-to when organizing the kids' school
          and activities? Or, you can totally skip this step and do it later if
          you’re in a rush.
        </Text>
      </div>
      <InvitePartnerForm onSuccess={onSuccess} onSkip={onSkip} />
      <div style={{ textAlign: "right" }}>
        <Button
          size={"small"}
          type={"link"}
          style={{ fontSize: 12 }}
          onClick={() => {
            onSkip();
          }}
        >
          Skip
        </Button>
      </div>
    </div>
  );
};

export default InvitePartner;
