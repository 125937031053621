import React from "react";
import AnchorNoPropagation from "../common/generic/anchorNoPropagation";
import { Alert, Divider, Space, Typography } from "antd";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const InboundMessageSummary = ({
  messageSummary,
  onClickViewInMessage,
  onClickHelpIcon,
}) => {
  const getJsx = () => {
    if (!messageSummary || messageSummary.length === 0) {
      return <></>;
    }
    return (
      <>
        <Space style={{ marginBottom: 5 }}>
          <h2>Topics at a Glance</h2>
          <HelpOutlineIcon
            sx={{ fontSize: 25, color: "#ff4081", cursor: "pointer" }}
            onClick={() => onClickHelpIcon("summary_bullets")}
          />
        </Space>
        <Alert
          message={
            <Typography.Text strong={true}>
              Unlock the full potential!{" "}
              <a href={"/login"}>Create a free account</a> and effortlessly turn
              topics like these into into trackable to-do items! ✅
            </Typography.Text>
          }
          type={"warning"}
          style={{ marginBottom: 10 }}
        />
        {messageSummary.map((topic, index) => {
          return (
            <div key={index} style={{ paddingTop: 5 }}>
              <AnchorNoPropagation
                onClick={() => {
                  onClickViewInMessage(topic.tlec_index);
                }}
              >
                {topic.emoji} {topic.title}
              </AnchorNoPropagation>
            </div>
          );
        })}
        <Divider />
      </>
    );
  };

  return getJsx();
};

export default InboundMessageSummary;
