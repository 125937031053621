import React from "react";
import { Col, Row, Typography } from "antd";

const { Text } = Typography;

const DetailRow = ({ label, value, copyable }) => {
  if (value) {
    return (
      <Row style={{ padding: "3px 5px" }}>
        <Col>
          <Text ellipsis={true}>
            <Text strong={true}>{label}: </Text>
            <Text copyable={copyable === true ? { text: value } : false}>
              {value}
            </Text>
          </Text>
        </Col>
      </Row>
    );
  }
};

export default DetailRow;
