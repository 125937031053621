import { useState } from "react";
import { Actions } from "../../../components/ecObjectViewers/actionMappers/actions";
import useCreateOrEditOrchestrateTodo from "./useCreateOrEditOrchestrateTodo";
import useCreateOrEditOrchestrateClipping from "./useCreateOrEditOrchestrateClipping";
import useCreateOrEditOrchestrateBoard from "./useCreateOrEditOrchestrateBoard";
import useCreateOrEditOrchestrateTile from "./useCreateOrEditOrchestrateTile";
import useCreateOrEditOrchestrateImageAsCover from "./useCreateOrEditOrchestrateImageAsCover";
import useCreateOrEditOrchestrateEvent from "./useCreateOrEditOrchestrateEvent";
import useCreateOrEditOrchestrateContact from "./useCreateOrEditOrchestrateContact";

const useCreateOrEditOrchestrateObject = () => {
  const {
    closeEventOverlay,
    eventFormValues,
    eventMergeValues,
    eventOverlayOpen,
    handleCreateEvent,
  } = useCreateOrEditOrchestrateEvent();

  const {
    closeTodoOverlay,
    handleCreateTodo,
    handleEditTodo,
    todoFormValues,
    todoMergeValues,
    todoOverlayOpen,
  } = useCreateOrEditOrchestrateTodo();

  const {
    clippingFormValues,
    clippingMergeValues,
    clippingOverlayOpen,
    closeClippingOverlay,
    handleCreateClipping,
    handleDeleteClipping,
  } = useCreateOrEditOrchestrateClipping();

  const {
    closeTileOverlay,
    handleCreateTileWithBoardAssociation,
    handleDeleteTileBoardAssociation,
    handleEditTile,
    tileFormValues,
    tileMergeValues,
    tileOverlayOpen,
  } = useCreateOrEditOrchestrateTile();

  const {
    closeUseImageOverlay,
    handleUseImageAsCover,
    useImageFormValues,
    useImageMergeValues,
    useImageOverlayOpen,
  } = useCreateOrEditOrchestrateImageAsCover();

  const {
    boardFormValues,
    boardMergeValues,
    boardOverlayOpen,
    closeBoardOverlay,
    handleCreateBoard,
  } = useCreateOrEditOrchestrateBoard();

  const { handleCreateContact } = useCreateOrEditOrchestrateContact();

  const [ecObjectActedUpon, setEcObjectActedUpon] = useState(undefined);

  const onClickCardAction = (values, ecObject) => {
    setEcObjectActedUpon(ecObject);

    switch (values.classAction) {
      case Actions.createEventAction.name:
        handleCreateEvent(values, ecObject);
        break;
      case Actions.createTodoItemAction.name:
        handleCreateTodo(values, ecObject);
        break;
      case Actions.createTileWithBoardAssociationAction.name:
        handleCreateTileWithBoardAssociation(values, ecObject);
        break;
      case Actions.createClippingAction.name:
        handleCreateClipping(values, ecObject);
        break;
      case Actions.useImageAction.name:
        handleUseImageAsCover(values, ecObject);
        break;
      case Actions.createContactAction.name:
        handleCreateContact(values, ecObject);
        break;
      case Actions.editTodoAction.name:
        handleEditTodo(values, ecObject);
        break;
      case Actions.editTileAction.name:
        handleEditTile(values, ecObject);
        break;
      case Actions.deleteTileBoardAssociationAction.name:
        handleDeleteTileBoardAssociation(values, ecObject);
        break;
      case Actions.deleteClippingAction.name:
        handleDeleteClipping(values, ecObject);
        break;
      default:
        console.log("No handler", values);
    }
  };

  return {
    onClickCardAction,
    setEcObjectActedUpon,
    handleCreateContact,

    boardFormValues,
    boardMergeValues,
    boardOverlayOpen,
    closeBoardOverlay,
    ecObjectActedUpon,
    handleCreateBoard,

    closeEventOverlay,
    eventFormValues,
    eventMergeValues,
    eventOverlayOpen,

    closeTodoOverlay,
    todoFormValues,
    todoMergeValues,
    todoOverlayOpen,

    clippingFormValues,
    clippingMergeValues,
    clippingOverlayOpen,
    closeClippingOverlay,

    closeTileOverlay,
    tileFormValues,
    tileMergeValues,
    tileOverlayOpen,

    closeUseImageOverlay,
    useImageFormValues,
    useImageMergeValues,
    useImageOverlayOpen,
  };
};

export default useCreateOrEditOrchestrateObject;
