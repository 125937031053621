import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import MessageSummaryEvents from "./summarySubViews/messageSummaryEvents";
import MessageSummaryLinksView from "./summarySubViews/messageSummaryLinksView";
import MessageSummaryBullets from "./summarySubViews/messageSummaryBullets";
import MessageSummaryAttachments from "./summarySubViews/messageSummaryAttachments";
import useTldrViewMessageContext from "../hooks/useTldrViewMessageContext";
import useCreateOrEditOrchestrateObject from "../../../../hooks/orchestrate/common/useCreateOrEditOrchestrateObject";
import OrchestrateObjectFormOverlays from "../../../familyOrganizer/orchestrateObjectFormOverlays";
import useArtifactDetailOverlay from "../../../ecObjectViewers/hooks/useArtifactDetailOverlay";
import AnyEcArtifactDetailsOverlay from "../../../ecObjectViewers/anyEcArtifactDetailsOverlay";
import useListMessageArtifacts from "../../../../hooks/emailCollector/messages/useListMessageArtifacts";

const MessageSummaryView = () => {
  const {
    messageObject: [messageObject],
  } = useOutletContext();

  const { uniqueMessageId } = useParams();
  const { data: messageArtifacts } = useListMessageArtifacts(
    uniqueMessageId,
    !!uniqueMessageId,
  );

  const { messageHtmlForDisplayAsString } = useTldrViewMessageContext();

  const {
    eventOverlayOpen,
    closeEventOverlay,
    eventFormValues,
    eventMergeValues,
    todoOverlayOpen,
    closeTodoOverlay,
    todoFormValues,
    todoMergeValues,
    clippingOverlayOpen,
    closeClippingOverlay,
    clippingFormValues,
    clippingMergeValues,
    tileOverlayOpen,
    closeTileOverlay,
    tileFormValues,
    tileMergeValues,
    onClickCardAction,
    ecObjectActedUpon,
  } = useCreateOrEditOrchestrateObject();

  const {
    dataForDetailsOverlay,
    setDataForDetailsOverlay,
    detailsOverlayOpen,
    openArtifactDetailOverlay,
    closeArtifactDetailOverlay,
  } = useArtifactDetailOverlay();

  const onClickFoundInMessages = (ecObject, messages) => {
    setDataForDetailsOverlay({
      ecObject: { ...ecObject },
      messages: [...messages],
    });
    openArtifactDetailOverlay();
  };

  return (
    <>
      <OrchestrateObjectFormOverlays
        eventInitialFormValues={eventFormValues}
        eventMergeValues={eventMergeValues}
        eventOverlayOpen={eventOverlayOpen}
        onCancelEvent={() => closeEventOverlay()}
        onSubmitEvent={() => closeEventOverlay()}
        onPersistEvent={(item) => {}}
        todoInitialFormValues={todoFormValues}
        todoMergeValues={todoMergeValues}
        todoOverlayOpen={todoOverlayOpen}
        onCancelTodo={() => closeTodoOverlay()}
        onSubmitTodo={() => closeTodoOverlay()}
        onPersistTodo={(item) => {}}
        clippingInitialFormValues={clippingFormValues}
        clippingMergeValues={clippingMergeValues}
        clippingOverlayOpen={clippingOverlayOpen}
        onCancelClipping={() => closeClippingOverlay()}
        onSubmitClipping={() => closeClippingOverlay()}
        onPersistClipping={(item) => {}}
        tileInitialFormValues={tileFormValues}
        tileMergeValues={tileMergeValues}
        tileOverlayOpen={tileOverlayOpen}
        onCancelTile={() => closeTileOverlay()}
        onSubmitTile={() => closeTileOverlay()}
        onPersistTile={(item) => {}}
        ecObjectActedUpon={ecObjectActedUpon}
      />
      <AnyEcArtifactDetailsOverlay
        open={detailsOverlayOpen}
        onClose={() => {
          closeArtifactDetailOverlay();
          setDataForDetailsOverlay(null);
        }}
        ecObject={dataForDetailsOverlay?.ecObject}
        scrollToFirstHighlight={true}
      />
      <MessageSummaryBullets
        onClickViewInMessage={(summaryBulletObject) =>
          onClickFoundInMessages(summaryBulletObject, [messageObject])
        }
        onClickCardAction={onClickCardAction}
        messageArtifacts={messageArtifacts}
      />
      <MessageSummaryEvents
        messageObject={messageObject}
        onClickCardAction={onClickCardAction}
        onClickFoundInMessages={onClickFoundInMessages}
      />
      <MessageSummaryAttachments messageArtifacts={messageArtifacts} />
      <MessageSummaryLinksView messageArtifacts={messageArtifacts} />
    </>
  );
};

export default MessageSummaryView;
