import dayjs from "dayjs";
import {
  CODE_DATE_FORMAT,
  DISPLAY_DATE_FORMAT_LONG,
  DISPLAY_TIME_FORMAT,
  DUMMY_DATE_STRING,
  DUMMY_TIME_STRING,
  getDayJsDatetimeFromStrings,
} from "../../../utils/dayJsUtils";

export const getCurrentTimeStringFlooredToHour = () => {
  return dayjs().format("HH:00");
};

export const getCurrentDateString = () => {
  return dayjs().format(CODE_DATE_FORMAT);
};

export const getCurrentTimePlusOneHourStringFlooredToHour = () => {
  return dayjs().add(1, "hour").format("HH:00");
};

export const getTimesGivenIncrement = (increment) => {
  if (!increment) {
    increment = 15;
  }
  const minTime = getDayJsDatetimeFromStrings(
    DUMMY_DATE_STRING,
    DUMMY_TIME_STRING,
  );
  const minLoop = 60 / increment - 1;
  const timeList = [];
  for (let i = 0; i <= 23; i++) {
    for (let j = 0; j <= minLoop; j++) {
      let time = minTime.add(i, "hour");
      time = time.add(j * increment, "minute");
      timeList.push(time);
    }
  }
  return timeList;
};

export const getTodayAsDateAndString = () => {
  const today = dayjs();
  return {
    label: "Today",
    value: today,
    codeString: today.format(CODE_DATE_FORMAT),
  };
};

export const getTomorrowAsDateAndString = () => {
  const tomorrow = dayjs().add(1, "day");
  return {
    label: "Tomorrow",
    value: tomorrow,
    codeString: tomorrow.format(CODE_DATE_FORMAT),
  };
};

export const getThisWeekendSaturdayAsDateAndString = () => {
  const saturday = dayjs().weekday(6);
  return {
    label: "This Weekend",
    value: saturday,
    codeString: saturday.format(CODE_DATE_FORMAT),
  };
};

export const getNextWeekMondayAsDateAndString = () => {
  const monday = dayjs().add(1, "week").weekday(1);
  return {
    label: "Next Week",
    value: monday,
    codeString: monday.format(CODE_DATE_FORMAT),
  };
};

export const getDefaultPresetDates = () => {
  const today = getTodayAsDateAndString();
  const tomorrow = getTomorrowAsDateAndString();
  const saturday = getThisWeekendSaturdayAsDateAndString();
  const monday = getNextWeekMondayAsDateAndString();
  return [today, tomorrow, saturday, monday];
};

export const getDefaultPresetTimes = () => {
  return [
    {
      label: "Morning",
      value: getDayJsDatetimeFromStrings(DUMMY_DATE_STRING, "08:00"),
      codeString: "08:00",
    },
    {
      label: "Noon",
      value: getDayJsDatetimeFromStrings(DUMMY_DATE_STRING, "12:00"),
      codeString: "12:00",
    },
    {
      label: "Evening",
      value: getDayJsDatetimeFromStrings(DUMMY_DATE_STRING, "18:00"),
      codeString: "18:00",
    },
    {
      label: "Night",
      value: getDayJsDatetimeFromStrings(DUMMY_DATE_STRING, "21:00"),
      codeString: "21:00",
    },
  ];
};

export const createTimePreset = (timeAsString) => {
  const timeAsDayJs = getDayJsDatetimeFromStrings(
    DUMMY_DATE_STRING,
    timeAsString,
  );
  return {
    label: timeAsDayJs.format(DISPLAY_TIME_FORMAT),
    value: timeAsDayJs,
    codeString: timeAsString,
  };
};

export const createTimePresets = (listTimeStrings) => {
  const presets = listTimeStrings.map((item) => {
    return createTimePreset(item);
  });
  presets.sort((t1, t2) => t1.value.diff(t2.value, "minute"));
  return presets;
};

export const createDatePreset = (dateAsString) => {
  const dateAsDayJs = getDayJsDatetimeFromStrings(
    dateAsString,
    DUMMY_TIME_STRING,
  );
  return {
    label: dateAsDayJs.format(DISPLAY_DATE_FORMAT_LONG),
    value: dateAsDayJs,
    codeString: dateAsString,
  };
};

export const createDatePresets = (listDateStrings) => {
  const presets = listDateStrings.map((item) => {
    return createDatePreset(item);
  });
  presets.sort((d1, d2) => d1.value.diff(d2.value, "minute"));
  return presets;
};
