import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import ReactGA from "react-ga4";

const Auth0ProviderWithRedirectCallbackOutlet = ({ ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const onRedirectCallback = (appState) => {
    if (appState) {
      if (appState.returnTo) {
        if (appState.returnTo.toLowerCase().startsWith("http")) {
          window.location.href = appState.returnTo;
          return;
        } else {
          navigate(appState.returnTo);
          return;
        }
      }
    }
    navigate("/");
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      <Outlet />
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirectCallbackOutlet;
