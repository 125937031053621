import { useState } from "react";

const useCreateOrEditOrchestrateImageAsCover = () => {
  const [useImageOverlayOpen, setUseImageOverlayOpen] = useState(false);
  const [useImageFormValues, setUseImageFormValues] = useState({});
  const [useImageMergeValues, setUseImageMergeValues] = useState({});

  const handleUseImageAsCover = (values, ecObject) => {
    setUseImageFormValues({});
    let classId = ecObject.class_id;
    if (!classId.endsWith("_rollup")) {
      classId = `${classId}_rollup`;
    }

    const useLink =
      values.cardProperties?.["link_image_rollup"]?.["showLink"] === true;

    setUseImageMergeValues({
      context: { use_link: useLink },
      rollup_class_id: classId,
      rollup_hash_key: ecObject.fingerprint,
      rollup_sort_key: null,
    });
    openUseImageOverlay();
  };

  const openUseImageOverlay = () => {
    setUseImageOverlayOpen(true);
    document.body.classList.add("overlay-open");
  };

  const closeUseImageOverlay = () => {
    setUseImageOverlayOpen(false);
    document.body.classList.remove("overlay-open");
  };

  return {
    closeUseImageOverlay,
    handleUseImageAsCover,
    setUseImageFormValues,
    setUseImageMergeValues,
    useImageFormValues,
    useImageMergeValues,
    useImageOverlayOpen,
  };
};

export default useCreateOrEditOrchestrateImageAsCover;
