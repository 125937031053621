import { useEffect, useState } from "react";
import useEmailCollectorServiceUrlHelper from "../../../hooks/emailCollector/useEmailCollectorServiceUrlHelper";
import { EC_SERVICE_BATCH_GET_ARTIFACTS } from "../../../serviceUrls/serviceUrlsEmailCollector";
import useQueryGenericGetWithPost from "../../../hooks/framework/useQueryGenericGetWithPost";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../serviceUrls/baseUrls";

const useGetArtifact = (classId, hashKey, sortKey) => {
  const [postData, setPostData] = useState(null);
  const [ecArtifact, setEcArtifact] = useState(null);

  useEffect(() => {
    if (classId && hashKey) {
      const data = {};
      if (sortKey) {
        data[classId] = [[hashKey, sortKey]];
      } else {
        data[classId] = [hashKey];
      }
      setPostData(data);
    } else {
      setEcArtifact(null);
    }
  }, [classId, hashKey, sortKey]);

  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_BATCH_GET_ARTIFACTS,
    {},
  );

  const { data: batchGetResponse, isLoading } = useQueryGenericGetWithPost(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    postData,
    true,
    !!(currentFamily && postData),
  );

  useEffect(() => {
    if (batchGetResponse) {
      if (classId && hashKey) {
        if (sortKey) {
          const artifact = batchGetResponse[classId]?.[hashKey]?.[sortKey];
          if (artifact) {
            setEcArtifact(artifact);
          }
        } else {
          const artifact = batchGetResponse[classId]?.[hashKey];
          if (artifact) {
            setEcArtifact(artifact);
          }
        }
      }
    }
  }, [classId, hashKey, sortKey, batchGetResponse]);

  return { ecArtifact };
};

export default useGetArtifact;
