import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";

import useShowUserEducationModal from "./userEducation/useShowUserEducationModal";
import useUpdateUserEducationSubjectStatus from "./userEducation/useUpdateUserEducationSubjectStatus";
import useInvalidateQuery from "../../hooks/utils/useInvalidateQuery";

const UserEducationModal = ({ title, subjects, children }) => {
  const { subjectToExplain } = useShowUserEducationModal(subjects || []);
  const { invalidateQueryWhereKeyContains } = useInvalidateQuery();
  const { mutate: updateUserEducationSubjectStatus } =
    useUpdateUserEducationSubjectStatus(
      () => {
        setModalOpen(false);
      },
      () => {
        invalidateQueryWhereKeyContains("subjects");
      },
      () => {},
    );

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!!subjectToExplain) {
      setModalOpen(true);
    }
  }, [subjectToExplain]);

  return (
    <Modal
      title={title || ""}
      open={modalOpen}
      closable={false}
      onCancel={null}
      onOk={null}
      footer={[
        <Button
          type="primary"
          key="submit"
          onClick={() =>
            updateUserEducationSubjectStatus({
              subjectId: subjectToExplain,
              status: "completed",
            })
          }
        >
          Got it!
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
};

export default UserEducationModal;
