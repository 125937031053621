const FullscreenOverlaySwitcher = ({ overlayIndex, overlays }) => {
  const getJsx = () => {
    if (overlayIndex >= 0 && !!overlays && overlays.length > 0) {
      return overlays[overlayIndex];
    }
  };

  return getJsx();
};

export default FullscreenOverlaySwitcher;
