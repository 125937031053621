import React from "react";
import { Typography } from "antd";
import FormattedDateTimeRange from "../../common/generic/formattedDateTimeRange";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import CardCenteredTextCover from "../../common/generic/cardCenteredTextCover";

const EventMiniCardCover = ({ ecObject }) => {
  const { currentFamily } = useTldrPageContext();

  const getCover = () => {
    return (
      <CardCenteredTextCover>
        <Typography.Title level={3} ellipsis={true}>
          <span style={{ fontSize: 25 }}>{ecObject.emoji}</span>{" "}
          {ecObject.title}
        </Typography.Title>
        {ecObject.description && (
          <Typography.Text ellipsis={true}>
            {ecObject.description}
          </Typography.Text>
        )}
        <Typography.Paragraph ellipsis={{ rows: 2 }} type={"secondary"}>
          <FormattedDateTimeRange
            startDateAsString={ecObject.start_date}
            startTimeAsString={ecObject.start_time}
            endDateAsString={ecObject.end_date}
            endTimeAsString={ecObject.end_time}
            showDate={true}
            timezoneInterpret={"UTC"}
            timezoneDisplay={currentFamily?.timezone || "UTC"}
          />
        </Typography.Paragraph>
      </CardCenteredTextCover>
    );
  };

  return getCover();
};

export default EventMiniCardCover;
