import React from "react";

const LoadingEmptyOrContent2 = ({
  isLoading,
  loadingComponent,
  isEmpty,
  emptyComponent,
  children,
}) => {
  const getEmptyLoadingOrList = () => {
    if (isLoading === true) {
      return <>{loadingComponent}</>;
    } else if (isEmpty === true) {
      return <>{emptyComponent}</>;
    } else {
      return <>{children}</>;
    }
  };

  return getEmptyLoadingOrList();
};

export default LoadingEmptyOrContent2;
