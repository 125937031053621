import useQueryGenericGet from "../../../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../../../serviceUrls/baseUrls";
import { EC_SERVICE_TEXT_FOR_FINGERPRINT_GET } from "../../../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../../../../../hooks/emailCollector/useEmailCollectorServiceUrlHelper";

const useGetTextByFingerprint = (fingerprint) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_TEXT_FOR_FINGERPRINT_GET,
    { fingerprint },
  );

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    {},
    !!(fingerprint && currentFamily),
  );
};

export default useGetTextByFingerprint;
