import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import AbsoluteSpin from "../common/generic/absoluteSpin";

const Login = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0(
    Auth0UniversalProviderContext,
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    } else {
      if (!isLoading) {
        const returnTo = location?.state?.from?.pathname;
        // TODO: Also include search params if required
        if (returnTo) {
          loginWithRedirect({
            appState: { returnTo: returnTo },
          }).then();
        } else {
          loginWithRedirect().then();
        }
      }
    }
  }, [isAuthenticated, navigate]);

  if (isLoading || !isAuthenticated) {
    return <AbsoluteSpin />;
  } else {
    return <></>;
  }
};

export default Login;
