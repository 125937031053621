import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { blue, green, orange, purple, red, yellow } from "@ant-design/colors";
import md5 from "md5";

const RandomlyColoredAvatar = ({ seedString }) => {
  const x = md5(seedString);

  const colors = [blue[6], red[6], orange[6], yellow[6], green[6], purple[6]];
  const index = parseInt(x[0], 16) % 6;

  const color = colors[index];

  return <Avatar style={{ backgroundColor: color }} icon={<UserOutlined />} />;
};

export default RandomlyColoredAvatar;
