import React from "react";
import { Select, Typography } from "antd";

const { Text } = Typography;

const SortSelect = ({ orderByValue, onChange, selectStyle }) => {
  return (
    <Select
      style={selectStyle}
      onChange={onChange}
      options={[
        { label: "Message Date", value: "message_date" },
        { label: "Submission Date", value: "submission_date" },
      ]}
      value={orderByValue}
    />
  );
};

export default SortSelect;
