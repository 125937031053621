import React, { useState } from "react";
import { Avatar, Button, List } from "antd";
import useInvalidateQuery from "../../hooks/utils/useInvalidateQuery";
import useAcceptInvite from "./hooks/useAcceptInvite";
import useDeleteInviteByRecipient from "./hooks/useDeleteInviteByRecipient";

const ConfirmInviteListItem = ({ invite }) => {
  const { invalidateQueryWhereKeyContains } = useInvalidateQuery();
  const [isProcessing, setIsProcessing] = useState(false);

  const { mutate: acceptInvite } = useAcceptInvite(
    () => {},
    () => {
      invalidateQueryWhereKeyContains("invites");
      setIsProcessing(false);
    },
    () => {},
  );

  const { mutate: ignoreInvite } = useDeleteInviteByRecipient(
    () => {},
    () => {
      invalidateQueryWhereKeyContains("invites");
      setIsProcessing(false);
    },
    () => {},
  );

  return (
    <List.Item
      actions={[
        <Button
          type="primary"
          key="confirm"
          loading={isProcessing}
          disabled={isProcessing}
          onClick={() => {
            setIsProcessing(true);
            acceptInvite(invite.invite_uuid);
          }}
        >
          Confirm
        </Button>,
        <Button
          key="ignore"
          loading={isProcessing}
          disabled={isProcessing}
          onClick={() => {
            setIsProcessing(true);
            ignoreInvite(invite);
          }}
        >
          Ignore
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={<Avatar src={invite.creator.picture} />}
        title={invite.family.name}
        description=""
      />
    </List.Item>
  );
};

export default ConfirmInviteListItem;
