import React, { useEffect } from "react";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import { Button, Space } from "antd";

const SettingsGmailAddon = () => {
  const { currentFamily, setBreadcrumbs, clearBreadcrumbExtra } =
    useTldrPageContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Settings",
        link: `/families/${currentFamily?.id}/settings`,
      },
      {
        label: "Gmail Add-on",
      },
    ]);
    clearBreadcrumbExtra();
  }, [setBreadcrumbs, clearBreadcrumbExtra, currentFamily]);

  return (
    <Space direction={"vertical"}>
      <Button
        type="primary"
        onClick={() => {
          window.open(
            "https://workspace.google.com/marketplace/app/tldr_parents/419060182558",
            "_blank",
          );
        }}
      >
        TLDR Parents Add-on for Gmail
      </Button>
      <Button
        type="primary"
        onClick={() => {
          window.open(
            "https://myaccount.google.com/connections?continue=https%3A%2F%2Fmyaccount.google.com%2Fsecurity",
            "_blank",
          );
        }}
      >
        Revoke TLDR Parents third-party access
      </Button>
    </Space>
  );
};

export default SettingsGmailAddon;
