import React, { useEffect, useState } from "react";
import { Button, Divider, List, Skeleton } from "antd";
import useListMessageTldrEvents from "../../hooks/useListMessageTldrEvents";
import MagicEventDetails from "../../../../magicCalendar/parts/magicEventDetails";
import { ALL_CLASSES_BY_CLASS_ID } from "../../../../ecObjectViewers/actionMappers/classesToActions";
import { Actions } from "../../../../ecObjectViewers/actionMappers/actions";
import { AppContextMagicCalendar } from "../../../../ecObjectViewers/actionMappers/appContextsToActions";
import { useQueryClient } from "@tanstack/react-query";

const MessageSummaryEvents = ({
  messageObject,
  onClickCardAction,
  onClickFoundInMessages,
}) => {
  const queryClient = useQueryClient();

  const [uniqueMessageId, setUniqueMessageId] = useState(undefined);
  const [showHiddenEvents, setShowHiddenEvents] = useState(false);

  useEffect(() => {
    if (messageObject) {
      setUniqueMessageId(
        `${messageObject?.tenant_id}-${messageObject?.unique_tenancy_message_id}`,
      );
    }
  }, [messageObject]);

  const { data: messageTldrEvents, queryKey: messageTldrEventsQueryKey } =
    useListMessageTldrEvents(uniqueMessageId);

  const getEventsJsx = () => {
    if (!messageTldrEvents) {
      return <Skeleton active={true} />;
    }
    if (messageTldrEvents.items.length === 0) {
      return <></>;
    }

    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Event Suggestions</h2>
          <Button
            ghost={true}
            type="primary"
            onClick={() => setShowHiddenEvents(!showHiddenEvents)}
          >
            {showHiddenEvents
              ? "Hide dismissed events"
              : "Show dismissed events"}
          </Button>
        </div>
        <List
          itemLayout="horizontal"
          dataSource={messageTldrEvents?.items || []}
          renderItem={(eventObject) => {
            if (!showHiddenEvents && eventObject.is_hidden) {
              return <></>;
            }
            return (
              <>
                <MagicEventDetails
                  eventObject={eventObject}
                  savedObjectUsages={null}
                  onClickFoundInMessages={onClickFoundInMessages}
                  showHideButton={true}
                  onClickAddToGoogleCalendar={(event) => {
                    const clazz = ALL_CLASSES_BY_CLASS_ID[event.class_id];
                    onClickCardAction(
                      {
                        classAction: Actions.createEventAction.name,
                        sourceInfo: clazz.getSourceInfo(event),
                        appContext: AppContextMagicCalendar,
                        cardProperties: null,
                        savedObject: null,
                      },
                      event,
                    );
                  }}
                  onClickCardAction={onClickCardAction}
                  onUpdateHiddenStatus={(item) => {
                    if (messageTldrEventsQueryKey) {
                      queryClient.setQueryData(
                        messageTldrEventsQueryKey,
                        (currentSuggestedEventsQuery) => {
                          const filteredItems = {
                            ...currentSuggestedEventsQuery,
                          };
                          const newItemsList = [];
                          for (const i in currentSuggestedEventsQuery.items) {
                            if (
                              currentSuggestedEventsQuery.items[i]
                                .event_rollup_sort_key ===
                              item.event_rollup_sort_key
                            ) {
                              newItemsList.push({
                                ...currentSuggestedEventsQuery.items[i],
                                is_hidden: item.is_hidden,
                              });
                            } else {
                              newItemsList.push({
                                ...currentSuggestedEventsQuery.items[i],
                              });
                            }
                          }
                          filteredItems.items = newItemsList;
                          return filteredItems;
                        },
                      );
                    }
                  }}
                />
                <Divider />
              </>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <>{getEventsJsx()}</>
    </>
  );
};

export default MessageSummaryEvents;
