import React from "react";
import { Form } from "antd";
import UserSelect from "../../../common/commonTldr/userSelect";
import DatetimePickerWithPresets from "../../../common/datetimePickers/datetimePickerWithPresets";
import NameInputFormItem from "../../../common/formComponents/nameInputFormItem";
import NotesInputFormItem from "../../../common/formComponents/notesInputFormItem";

const CreateOrEditTodoFormItems = ({ familyId, datePresets, timePresets }) => {
  return (
    <>
      <NameInputFormItem
        requiredMessage={"Name your todo"}
        placeholderText={"Return form for field trip."}
      />
      <Form.Item name="due" label="Due">
        <DatetimePickerWithPresets
          onChange={(value) => console.log(value)}
          allowDateChoice={true}
          allowTimeChoice={true}
          datePresets={datePresets}
          timePresets={timePresets}
          editPanelOpen={false}
          // onEditPanelOpenChange={(value) => {}}
        />
      </Form.Item>
      <Form.Item name="assignedTo" label="Assigned To">
        <UserSelect familyId={familyId} />
      </Form.Item>
      <NotesInputFormItem />
    </>
  );
};

export default CreateOrEditTodoFormItems;
