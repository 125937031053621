import React from "react";
import DetailPanelSkeleton from "./detailPanelParts/detailPanelSkeleton";
import TagsDetailRow from "./detailPanelParts/tagsDetailRow";
import LinkPreview from "../../common/generic/linkPreview";
import { Divider } from "antd";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import TldrCardWithCoverImage from "../../common/generic/tldrCardWithCoverImage";
import { getQueryParams } from "../../../utils/utils";

const LinkImageDetailPanel = ({ ecObject, actionMenu }) => {
  const linkUrl = ecObject.link_content_disposition
    ? ecObject.link_signed_url
    : ecObject.link_final_url;

  const getImgSrcForLink = () => {
    const isImage = ecObject?.link_content_type?.startsWith("image/");
    if (isImage) {
      return ecObject.link_signed_url;
    }
    const hasPdfPreview = !!ecObject?.link_preview_signed_url;
    if (hasPdfPreview) {
      return ecObject.link_preview_signed_url;
    }
    return ecObject.link_preview_summary.image_src;
  };

  const getLinkPart = () => {
    let cover;
    if (
      ecObject.link_final_url.startsWith("https://drive.google.com/file/d/")
    ) {
      cover = (
        <div className="iframe-wrapper">
          <iframe
            src={ecObject.link_final_url.replace("/view", "/preview")}
            // width="640"
            // height="480"
            allow="autoplay"
          />
        </div>
      );
    } else if (
      ecObject.link_final_url.startsWith("https://www.youtube.com/watch")
    ) {
      const embedId = getQueryParams(ecObject.link_final_url)["v"];
      return (
        <div className="iframe-wrapper">
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${embedId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </div>
      );
    } else {
      cover = (
        <LinkPreview
          href={linkUrl}
          title={ecObject.link_preview_summary.title}
          domain={ecObject.link_preview_summary.fqdn}
          description={ecObject.link_preview_summary.description}
          imageSrc={getImgSrcForLink()}
          contentType={ecObject.link_content_type}
          coverHeight={121}
        />
      );
    }

    return (
      <TldrCardWithCoverComponent
        coverComponent={cover}
        width={"auto"}
        coverHeight={190}
        hoverable={false}
        onClick={null}
        bodyPadding={0}
      />
    );

    // const position2 = (
    //   <>
    //     <h2>Link</h2>
    //     <DetailRow
    //       label={"Submitted"}
    //       value={getShortHumanizedDateTimeForEmail(ecObject?.created_ts)}
    //     />
    //     <DetailRow label={"URL"} value={ecObject?.link_final_url} />
    //     <TagsDetailRow tags={ecObject?.native_tags} />
    //   </>
    // );
    //
    // return <DetailPanelSkeleton position1={position1} position2={position2} />;
  };

  const getImagePart = () => {
    return (
      <TldrCardWithCoverImage
        imageSrc={ecObject.image_signed_url || ecObject.image_final_url}
        imageSize={ecObject.image_size}
        alt={""}
        preview={true}
        width={"auto"}
        coverHeight={190}
        hoverable={false}
        onClick={null}
        bodyPadding={0}
      />
    );

    // const position2 = (
    //   <>
    //     <h2>Image</h2>{" "}
    //     <DetailRow
    //       label={"Submitted"}
    //       value={getShortHumanizedDateTimeForEmail(ecObject?.created_ts)}
    //     />
    //     <DetailRow
    //       label={"File Size"}
    //       value={prettyBytes(ecObject.image_content_length)}
    //     />
    //     <DetailRow label={"Type"} value={ecObject.image_content_type} />
    //     <DetailRow
    //       label={"Image Size"}
    //       value={
    //         ecObject?.image_size &&
    //         `${ecObject.image_size[0]} x ${ecObject?.image_size[1]}`
    //       }
    //     />
    //     <TagsDetailRow tags={ecObject?.native_tags} />
    //     {!!actionMenu && actionMenu}
    //   </>
    // );
    // return <DetailPanelSkeleton position1={position1} position2={position2} />;
  };

  return (
    <DetailPanelSkeleton
      position1={
        <div>
          {getLinkPart()}
          <Divider />
          {getImagePart()}
        </div>
      }
      position2={!!actionMenu && actionMenu}
      position3={<TagsDetailRow tags={ecObject?.native_tags} />}
    />
  );
};

export default LinkImageDetailPanel;
