import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Button, Col, Row } from "antd";
import { useParams } from "react-router-dom";

const CheckoutForm = ({
  intentType,
  onStatusUpdated,
  onError,
  submitButtonText,
}) => {
  const { familyId } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    let clientSecret;
    if (intentType === "setup") {
      clientSecret = new URLSearchParams(window.location.search).get(
        "setup_intent_client_secret",
      );
    } else if (intentType === "payment") {
      clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret",
      );
    }

    if (!clientSecret) {
      return;
    }
    if (intentType === "setup") {
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        onStatusUpdated(setupIntent.status);
      });
    } else if (intentType === "payment") {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        onStatusUpdated(paymentIntent.status);
      });
    }
  }, [stripe, intentType, onStatusUpdated]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    let error;
    if (intentType === "payment") {
      const { error: paymentError } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${process.env.REACT_APP_BASE_URL}/families/${familyId}/settings/return-payment-confirmation`,
        },
      });
      error = paymentError;
    } else if (intentType === "setup") {
      const { error: setupError } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${process.env.REACT_APP_BASE_URL}/families/${familyId}/settings/return-payment-confirmation`,
        },
      });
      error = setupError;
    }
    setLoading(false);

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (error.type === "card_error" || error.type === "validation_error") {
      onError(error);
    } else {
      onError({ code: "", type: "", message: "An unexpected error occurred." });
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Row style={{ marginTop: 15 }}>
        <Col flex="auto">
          <Button
            disabled={loading || !stripe || !elements}
            id="submit"
            type="primary"
            loading={loading}
            htmlType="submit"
            style={{ float: "right" }}
          >
            {submitButtonText}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default CheckoutForm;
