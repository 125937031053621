import React from "react";
import useBreadcrumbStore from "./hooks/useBreadcrumbStore";
import { Button, Col, Divider, Row, Space } from "antd";
import AnchorNoPropagation from "../common/generic/anchorNoPropagation";
import { useNavigate, useParams } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

const TldrBreadcrumbRibbon = () => {
  const { familyId } = useParams();
  const breadcrumbs = useBreadcrumbStore((state) => state.breadcrumbs);
  const breadcrumbExtra = useBreadcrumbStore((state) => state.breadcrumbExtra);
  const navigate = useNavigate();

  const getHomeButton = () => {
    const linkHome = familyId ? `/families/${familyId}` : "/";
    return (
      <Button
        type={"primary"}
        ghost={true}
        style={{ borderRadius: 6, height: 30, width: 30 }}
        icon={<HomeOutlinedIcon sx={{ fontSize: 20 }} />}
        onClick={() => navigate(linkHome)}
      />
    );
  };

  const getJsx = () => {
    if (!breadcrumbs && !breadcrumbExtra) {
      return <></>;
    }

    return (
      <>
        <Row justify="space-between" align="middle" style={{ height: 40 }}>
          <Col>
            <div
              style={{ display: "flex", alignItems: "center", fontSize: 16 }}
            >
              <Space split={<>/</>}>
                <div>{getHomeButton()}</div>
                {breadcrumbs?.map((item, index) => {
                  if (item.link) {
                    return (
                      <AnchorNoPropagation
                        key={index}
                        onClick={() => {
                          navigate(item.link);
                        }}
                      >
                        {item.label}
                      </AnchorNoPropagation>
                    );
                  }
                  return <div key={index}>{item.label}</div>;
                })}
              </Space>
            </div>
          </Col>
          <Col>{breadcrumbExtra || <></>}</Col>
        </Row>
        <Divider style={{ paddingTop: 0, marginTop: 8, marginBottom: 10 }} />
      </>
    );
  };

  return getJsx();
};

export default TldrBreadcrumbRibbon;
