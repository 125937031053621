import React from "react";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import NonHttpLinkCardCover from "../cardCovers/nonHttpLinkCardCover";
import MiniCardTitleRow from "../miniCardParts/miniCardTitleRow";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";

const NonHttpLinkMiniCard = ({ ecObject, appContext, onClick }) => {
  return (
    <TldrCardWithCoverComponent
      coverComponent={<NonHttpLinkCardCover ecObject={ecObject} />}
      width={250}
      coverHeight={150}
      hoverable={false}
      onClick={onClick}
    >
      <MiniCardTitleRow
        titleText={ecObject?.preview_summary?.title}
        tooltipText={"Non-HTTP Link"}
        icon={<LinkOutlinedIcon sx={{ color: "#ff4081" }} />}
      />
    </TldrCardWithCoverComponent>
  );
};

export default NonHttpLinkMiniCard;
