import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";
import { useEffect, useState } from "react";
import useGetMessageContent from "../../../../hooks/emailCollector/messages/useGetMessageContent";
import { useParams } from "react-router-dom";

const useTldrViewMessageContext = () => {
  const tldrPageContext = useTldrPageContext();
  const { uniqueMessageId } = useParams();
  const [annotatedMessageHtmlAsString, setAnnotatedMessageHtmlAsString] =
    useState("");
  const [messageHtmlForDisplayAsString, setMessageHtmlForDisplayAsString] =
    useState("");
  const [textByHeadings, setTextByHeadings] = useState({});
  const { data: messageContent, isLoading: isLoadingMessageContent } =
    useGetMessageContent(uniqueMessageId, false);

  useEffect(() => {
    if (messageContent) {
      setAnnotatedMessageHtmlAsString(messageContent.body_indexed);
      setMessageHtmlForDisplayAsString(messageContent.body_indexed);
      setTextByHeadings(messageContent.text_by_headings);
    }
  }, [messageContent]);

  return {
    ...tldrPageContext,
    uniqueMessageId: uniqueMessageId,
    annotatedMessageHtmlAsString: annotatedMessageHtmlAsString,
    messageHtmlForDisplayAsString: messageHtmlForDisplayAsString,
    setMessageHtmlForDisplayAsString: setMessageHtmlForDisplayAsString,
    textByTlecAndLineIndex: textByHeadings,
    messageContent: messageContent,
    isLoadingMessageContent: isLoadingMessageContent,
  };
};

export default useTldrViewMessageContext;
