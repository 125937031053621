import React, { useEffect, useState } from "react";
import { Col, Row, Tag } from "antd";
import TimeSelector from "./timeSelector";
import { getCurrentTimeStringFlooredToHour } from "./dateTimePickerUtils";

const TimeSelectorWithPresetsPanel = ({
  value,
  width,
  onChange,
  presets,
  allowEmptyTime,
}) => {
  if (allowEmptyTime === undefined) {
    allowEmptyTime = false;
  }
  const [selectedValueAsString, setSelectedValueAsString] = useState(undefined);
  const [presetsState, setPresetsState] = useState([]);

  useEffect(() => {
    if (value) {
      setSelectedValueAsString(value);
    } else {
      if (!allowEmptyTime) {
        setSelectedValueAsString(getCurrentTimeStringFlooredToHour());
      } else {
        setSelectedValueAsString("");
      }
    }
  }, [value, allowEmptyTime]);

  useEffect(() => {
    if (presets) {
      if (allowEmptyTime) {
        setPresetsState([
          {
            label: "No time",
            value: null,
            codeString: "",
          },
          ...presets,
        ]);
      } else {
        setPresetsState([...presets]);
      }
    } else {
      if (allowEmptyTime) {
        setPresetsState([
          {
            label: "No time",
            value: null,
            codeString: "",
          },
        ]);
      }
    }
  }, [presets, allowEmptyTime]);

  return (
    <div
      style={{
        width: width,
      }}
    >
      <div>
        <TimeSelector
          value={selectedValueAsString}
          width={"100%"}
          onChange={(value) => {
            setSelectedValueAsString(value);
            onChange(value);
          }}
          noSelectionIfNoValue={true}
        />
      </div>
      <Row style={{ marginTop: 10 }}>
        {presetsState.map((preset, index) => {
          return (
            <Col
              key={index}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ padding: 5 }}
            >
              <a
                href="src/components/common/datetimePickers#"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedValueAsString(preset.codeString);
                  onChange(preset.codeString);
                }}
              >
                <Tag color="#3478f6">{preset.label}</Tag>
              </a>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default TimeSelectorWithPresetsPanel;
