import { createBrowserRouter, Outlet } from "react-router-dom";
import Auth0ProviderWithRedirectCallbackOutlet from "./auth0ProviderWithRedirectCallbackOutlet";
import { auth0UniversalProviderConfig } from "./auth0ProviderConfigs";
import Login from "./login";
import PrivateRoute from "./privateRoute";
import ViewInvitationPreAuth from "../invites/viewInvitationPreAuth";
import ClippingScroll from "../familyOrganizer/clippingScroll/clippingScroll";
import Boards from "../familyOrganizer/boards/boards";
import Board from "../familyOrganizer/boards/board";
import SmartInbox from "../inboxesAndMessages/inbox/smartInbox";
import MessageHeaderOutlet from "../inboxesAndMessages/message/messageHeaderOutlet";
import IndexComponent from "./indexComponent";
import NotFoundOrErrorPage from "../common/generic/notFoundOrErrorPage";
import Home from "../home/home";
import PublicBoard from "../familyOrganizer/boards/publicBoard";
import Testing from "../testing";
import MessageContentOutlet from "../inboxesAndMessages/message/messageContentOutlet";
import MeContentOutlet from "../me/meContentOutlet";
import MeOutlet from "../layout/meOutlet";
import SettingsContentOutlet from "../settings/settingsContentOutlet";
import MagicCalendar from "../magicCalendar/magicCalendar";
import FilesPlusRollup from "../artifactRollups/filesPlus/filesPlusRollup";
import EcArtifactDetail from "../ecObjectViewers/ecArtifactDetail";
import FamiliesOutlet from "../layout/familiesOutlet";
import FamilyOutlet from "../layout/familyOutlet";
import OnboardingPartDeux from "../onboarding/onboardingPart2/onboardingPartDeux";
import ConfirmInvites from "../invites/confirmInvites";
import EmailAddressesRollup from "../artifactRollups/emailAddresses/emailAddressesRollup";
import Logout from "./logout";
import ReturnPaymentConfirmation from "../settings/returnPaymentConfirmation";
import SavedObjectDetail from "../familyOrganizer/savedObjectDetail";
import GoogleEvents from "../familyOrganizer/googleEvents/googleEvents";
import TodoList from "../familyOrganizer/todoList/todoList";
import InboundForwardHome from "../inboundForwards/inboundForwardHome";
import FamilyWorkspaceCreation from "../onboarding/createWorkspace/familyWorkspaceCreation";
import React from "react";
import ArchivedMessages from "../inboxesAndMessages/inbox/archivedMessages";
import LinkSuggestions from "../linkSuggestions/linkSuggestions";

const getMessageRoutes = (archived) => {
  return [
    {
      path: ":uniqueMessageId",
      element: <MessageHeaderOutlet archived={archived} />,
      children: [
        { index: true, element: <MessageContentOutlet archived={archived} /> },
        {
          path: ":viewType",
          element: <MessageContentOutlet archived={archived} />,
        },
      ],
    },
  ];
};

const inboxRoutes = [
  {
    path: "smart-inbox",
    element: <Outlet />,
    children: [
      { index: true, element: <SmartInbox /> },
      ...getMessageRoutes(false),
    ],
  },
  {
    path: "archived-messages",
    element: <Outlet />,
    children: [
      { index: true, element: <ArchivedMessages /> },
      ...getMessageRoutes(true),
    ],
  },
];

const magicCalendarRoutes = [
  {
    path: "event-suggestions",
    element: <Outlet />,
    children: [{ index: true, element: <MagicCalendar /> }],
  },
];

const linkSuggestionRoutes = [
  {
    path: "link-suggestions",
    element: <Outlet />,
    children: [{ index: true, element: <LinkSuggestions /> }],
  },
];

const boardRoutes = [
  {
    path: "boards",
    element: <Outlet />,
    children: [
      { index: true, element: <Boards /> },
      {
        path: ":boardId",
        element: <Board />,
      },
    ],
  },
];

const tilesRoutes = [
  {
    path: "tiles/:objectId",
    element: <SavedObjectDetail classId={"tile"} />,
  },
];

const todoRoutes = [
  {
    path: "todos",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <TodoList />,
      },
      {
        path: ":objectId",
        element: <SavedObjectDetail classId={"todo"} />,
      },
    ],
  },
];

const googleEventRoutes = [
  {
    path: "google-events",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <GoogleEvents />,
      },
      {
        path: ":objectId",
        element: <SavedObjectDetail classId={"google_event"} />,
      },
    ],
  },
];

const clippingRoutes = [
  {
    path: "workbench",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <ClippingScroll />,
      },
      {
        path: ":objectId",
        element: <SavedObjectDetail classId={"clipping"} />,
      },
    ],
  },
];

const settingsRoutes = [
  {
    path: "settings/return-payment-confirmation",
    element: <ReturnPaymentConfirmation />,
  },
  {
    path: "settings",
    element: <SettingsContentOutlet />,
    children: [
      {
        index: true,
        element: <SettingsContentOutlet />,
      },
      {
        path: "return-payment-confirmation",
        element: <ReturnPaymentConfirmation />,
      },
      {
        path: ":viewType",
        element: <SettingsContentOutlet />,
      },
    ],
  },
];

const familyRoutes = [
  {
    path: "families",
    element: <FamiliesOutlet />,
    children: [
      {
        path: ":familyId",
        element: <FamilyOutlet />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "onboarding",
            element: <OnboardingPartDeux />,
          },
          {
            path: "testing",
            element: <Testing />,
          },
          {
            path: "key-links-files",
            element: <FilesPlusRollup />,
          },
          {
            path: "email-addresses",
            element: <EmailAddressesRollup />,
          },
          {
            path: "artifacts/:classId/:hashKey/:sortKey?",
            element: <EcArtifactDetail />,
          },
          ...magicCalendarRoutes,
          ...linkSuggestionRoutes,
          ...inboxRoutes,
          ...todoRoutes,
          ...boardRoutes,
          ...tilesRoutes,
          ...clippingRoutes,
          ...googleEventRoutes,
          ...settingsRoutes,
        ],
      },
    ],
  },
];

const meRoutes = [
  {
    path: "me",
    element: <MeOutlet />,
    children: [
      {
        index: true,
        element: <MeContentOutlet />,
      },
      {
        path: ":viewType",
        element: <MeContentOutlet />,
      },
    ],
  },
];

const privateRoutes = [
  {
    element: <PrivateRoute />,
    children: [
      {
        path: "create-workspace",
        element: <FamilyWorkspaceCreation />,
      },
      {
        path: "confirm-invites",
        element: <ConfirmInvites />,
      },
      ...familyRoutes,
      ...meRoutes,
    ],
  },
];

const publicRoutes = [
  { index: true, element: <IndexComponent /> },
  {
    path: "view-invite/:inviteCode",
    element: <ViewInvitationPreAuth />,
  },
  {
    path: "forward/:inboundForwardId",
    element: <InboundForwardHome />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "logout",
    element: <Logout />,
  },
  {
    path: "public",
    element: <Outlet />,
    children: [
      {
        path: "families",
        element: <Outlet />,
        children: [
          {
            path: ":familyId",
            element: <Outlet />,
            children: [
              {
                path: "boards/:boardUuid",
                element: <PublicBoard />,
              },
            ],
          },
        ],
      },
    ],
  },
];

const tldrRoutes = createBrowserRouter([
  {
    element: (
      <Auth0ProviderWithRedirectCallbackOutlet
        {...auth0UniversalProviderConfig}
      />
    ),
    errorElement: <NotFoundOrErrorPage />,
    children: [
      {
        path: "/",
        children: [...publicRoutes, ...privateRoutes],
      },
    ],
  },
]);

export default tldrRoutes;
