import { useCallback, useEffect, useState } from "react";

const useIsDebugMode = () => {
  const [isDebugMode, setIsDebugMode] = useState(null);

  useEffect(() => {
    if (isDebugMode === null) {
      setIsDebugMode(localStorage.getItem("tldrParents.debugMode") === "1");
    } else if (isDebugMode === true) {
      localStorage.setItem("tldrParents.debugMode", "1");
    } else if (isDebugMode === false) {
      localStorage.setItem("tldrParents.debugMode", "0");
    }
  }, [isDebugMode]);

  const keyPress = useCallback(
    (e) => {
      if (e.key === "d" && e.metaKey) {
        e.preventDefault();
        if (isDebugMode) {
          setIsDebugMode(false);
        } else {
          setIsDebugMode(true);
        }
      }
    },
    [setIsDebugMode, isDebugMode],
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return {
    isDebugMode,
    isDebugLoading: isDebugMode === null,
  };
};

export default useIsDebugMode;
