import React, { useEffect, useState } from "react";
import {
  DISPLAY_TIME_FORMAT,
  DUMMY_DATE_STRING,
  getDayJsDatetimeFromStrings,
} from "../../../utils/dayJsUtils";
import CollapsibleContentArea from "./collapsibleContentArea";
import TimeSelectorWithPresetsPanel from "./timeSelectorWithPresetsPanel";
import { getCurrentTimeStringFlooredToHour } from "./dateTimePickerUtils";

const TimeSelectorWithPresets = ({
  value,
  width,
  onChange,
  presets,
  allowEmptyTime,
}) => {
  if (allowEmptyTime === undefined) {
    allowEmptyTime = false;
  }
  const [selectedValueAsString, setSelectedValueAsString] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (value) {
      setSelectedValueAsString(value);
    } else {
      if (!allowEmptyTime) {
        setSelectedValueAsString(getCurrentTimeStringFlooredToHour());
      }
    }
  }, [value, allowEmptyTime]);

  return (
    <CollapsibleContentArea
      open={isEditMode}
      width={width}
      items={[
        {
          buttonText: selectedValueAsString
            ? getDayJsDatetimeFromStrings(
                DUMMY_DATE_STRING,
                selectedValueAsString,
              ).format(DISPLAY_TIME_FORMAT)
            : "[No time]",
          content: (
            <TimeSelectorWithPresetsPanel
              value={selectedValueAsString}
              width={width}
              onChange={(value) => {
                setSelectedValueAsString(value);
                setIsEditMode(false);
                onChange(value);
              }}
              presets={presets}
              allowEmptyTime={allowEmptyTime}
            />
          ),
        },
      ]}
      onOpenChange={(value) => setIsEditMode(value)}
    />
  );
};

export default TimeSelectorWithPresets;
