import { Actions } from "./actions";
import AttachmentCard from "../cards/attachmentCard";
import LinkCard from "../cards/linkCard";
import LinkImageCard from "../cards/linkImageCard";
import EmailAddressCard from "../cards/emailAddressCard";
import ImageCard from "../cards/imageCard";
import NonHttpLinkCard from "../cards/nonHttpLinkCard";
import TopicCard from "../cards/topicCard";
import EventCard from "../cards/eventCard";
import AttachmentDetailPanel from "../detailPanels/attachmentDetailPanel";
import EmailAddressDetailPanel from "../detailPanels/emailAddressDetailPanel";
import ImageDetailPanel from "../detailPanels/imageDetailPanel";
import LinkDetailPanel from "../detailPanels/linkDetailPanel";
import LinkImageDetailPanel from "../detailPanels/linkImageDetailPanel";
import NonHttpLinkDetailPanel from "../detailPanels/nonHttpLinkDetailPanel";
import EventDetailPanel from "../detailPanels/eventDetailPanel";
import TopicDetailPanel from "../detailPanels/topicDetailPanel";
import AttachmentMiniCard from "../miniCards/attachmentMiniCard";
import EventMiniCard from "../miniCards/eventMiniCard";
import TopicMiniCard from "../miniCards/topicMiniCard";
import ImageMiniCard from "../miniCards/imageMiniCard";
import LinkMiniCard from "../miniCards/linkMiniCard";
import EmailAddressMiniCard from "../miniCards/emailAddressMiniCard";
import NonHttpLinkMiniCard from "../miniCards/nonHttpLinkMiniCard";
import LinkImageMiniCard from "../miniCards/linkImageMiniCard";
import MessageCard from "../cards/messageCard";
import MessageMiniCard from "../miniCards/messageMiniCard";
import MessageDetailPanel from "../detailPanels/messageDetailPanel";
import SummaryBulletCard from "../cards/summaryBulletCard";
import SummaryBulletMiniCard from "../miniCards/summaryBulletMiniCard";
import SummaryBulletDetailPanel from "../detailPanels/summaryBulletDetailPanel";

const BASE_ACTIONS_LIST = [
  Actions.createEventAction,
  Actions.createTodoItemAction,
  Actions.createTileWithBoardAssociationAction,
  Actions.createClippingAction,
  Actions.editTileAction,
  Actions.deleteTileBoardAssociationAction,
  Actions.deleteClippingAction,
];

export const ClassAttachment = Object.freeze({
  classId: "attachment",
  objectTitle: "Attachment",
  cardClass: AttachmentCard,
  cardMiniClass: AttachmentMiniCard,
  detailPanelClass: AttachmentDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST, Actions.useImageAction];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: ClassAttachment.classId,
      item_hash_key: ecObject.hash_key,
      item_sort_key: ecObject.sort_key,
      rollup_class_id: ClassAttachmentRollup.classId,
      rollup_hash_key: ecObject.fingerprint,
      rollup_sort_key: null,
    };
  },
  getName: (ecObject) => {
    return ecObject.filename || "";
  },
});

export const ClassAttachmentRollup = Object.freeze({
  classId: "attachment_rollup",
  objectTitle: "Attachment",
  cardClass: AttachmentCard,
  cardMiniClass: AttachmentMiniCard,
  detailPanelClass: AttachmentDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST, Actions.useImageAction];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: null,
      item_hash_key: null,
      item_sort_key: null,
      rollup_class_id: ClassAttachmentRollup.classId,
      rollup_hash_key: ecObject.hash_key,
      rollup_sort_key: ecObject.sort_key,
    };
  },
  getName: (ecObject) => {
    return ecObject.filename || "";
  },
});

export const ClassEmailAddress = Object.freeze({
  classId: "email_address",
  objectTitle: "Email Address",
  cardClass: EmailAddressCard,
  cardMiniClass: EmailAddressMiniCard,
  detailPanelClass: EmailAddressDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST, Actions.createContactAction];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: ClassEmailAddress.classId,
      item_hash_key: ecObject.hash_key,
      item_sort_key: ecObject.sort_key,
      rollup_class_id: ClassEmailAddressRollup.classId,
      rollup_hash_key: ecObject.fingerprint,
      rollup_sort_key: null,
    };
  },
  getName: (ecObject) => {
    return ecObject.email;
  },
});

export const ClassEmailAddressRollup = Object.freeze({
  classId: "email_address_rollup",
  objectTitle: "Email Address",
  cardClass: EmailAddressCard,
  cardMiniClass: EmailAddressMiniCard,
  detailPanelClass: EmailAddressDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST, Actions.createContactAction];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: null,
      item_hash_key: null,
      item_sort_key: null,
      rollup_class_id: ClassEmailAddressRollup.classId,
      rollup_hash_key: ecObject.hash_key,
      rollup_sort_key: ecObject.sort_key,
    };
  },
  getName: (ecObject) => {
    return ecObject.email;
  },
});

export const ClassImage = Object.freeze({
  classId: "image",
  objectTitle: "Image",
  cardClass: ImageCard,
  cardMiniClass: ImageMiniCard,
  detailPanelClass: ImageDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST, Actions.useImageAction];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: ClassImage.classId,
      item_hash_key: ecObject.hash_key,
      item_sort_key: ecObject.sort_key,
      rollup_class_id: ClassImageRollup.classId,
      rollup_hash_key: ecObject.fingerprint,
      rollup_sort_key: null,
    };
  },
  getName: (ecObject) => {
    return "";
  },
});

export const ClassImageRollup = Object.freeze({
  classId: "image_rollup",
  objectTitle: "Image",
  cardClass: ImageCard,
  cardMiniClass: ImageMiniCard,
  detailPanelClass: ImageDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST, Actions.useImageAction];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: null,
      item_hash_key: null,
      item_sort_key: null,
      rollup_class_id: ClassImageRollup.classId,
      rollup_hash_key: ecObject.hash_key,
      rollup_sort_key: ecObject.sort_key,
    };
  },
  getName: (ecObject) => {
    return "";
  },
});

export const ClassLink = Object.freeze({
  classId: "link",
  objectTitle: "Link",
  cardClass: LinkCard,
  cardMiniClass: LinkMiniCard,
  detailPanelClass: LinkDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST, Actions.useImageAction];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: ClassLink.classId,
      item_hash_key: ecObject.hash_key,
      item_sort_key: ecObject.sort_key,
      rollup_class_id: ClassLinkRollup.classId,
      rollup_hash_key: ecObject.fingerprint,
      rollup_sort_key: null,
    };
  },
  getName: (ecObject) => {
    return ecObject.preview_summary.title;
  },
});

export const ClassLinkRollup = Object.freeze({
  classId: "link_rollup",
  objectTitle: "Link",
  cardClass: LinkCard,
  cardMiniClass: LinkMiniCard,
  detailPanelClass: LinkDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST, Actions.useImageAction];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: null,
      item_hash_key: null,
      item_sort_key: null,
      rollup_class_id: ClassLinkRollup.classId,
      rollup_hash_key: ecObject.hash_key,
      rollup_sort_key: ecObject.sort_key,
    };
  },
  getName: (ecObject) => {
    return ecObject.preview_summary.title;
  },
});

export const ClassLinkImage = Object.freeze({
  classId: "link_image",
  objectTitle: "Link & Image",
  cardClass: LinkImageCard,
  cardMiniClass: LinkImageMiniCard,
  detailPanelClass: LinkImageDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST, Actions.useImageAction];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: ClassLinkImage.classId,
      item_hash_key: ecObject.hash_key,
      item_sort_key: ecObject.sort_key,
      rollup_class_id: ClassLinkImageRollup.classId,
      rollup_hash_key: ecObject.fingerprint,
      rollup_sort_key: null,
    };
  },
  getName: (ecObject) => {
    return "";
  },
});

export const ClassLinkImageRollup = Object.freeze({
  classId: "link_image_rollup",
  objectTitle: "Link & Image",
  cardClass: LinkImageCard,
  cardMiniClass: LinkImageMiniCard,
  detailPanelClass: LinkImageDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST, Actions.useImageAction];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: null,
      item_hash_key: null,
      item_sort_key: null,
      rollup_class_id: ClassLinkImageRollup.classId,
      rollup_hash_key: ecObject.hash_key,
      rollup_sort_key: ecObject.sort_key,
    };
  },
  getName: (ecObject) => {
    return "";
  },
});

export const ClassNonHttpLink = Object.freeze({
  classId: "non_http_link",
  objectTitle: "Non-HTTP Link",
  cardClass: NonHttpLinkCard,
  cardMiniClass: NonHttpLinkMiniCard,
  detailPanelClass: NonHttpLinkDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: ClassNonHttpLink.classId,
      item_hash_key: ecObject.hash_key,
      item_sort_key: ecObject.sort_key,
      rollup_class_id: ClassNonHttpLinkRollup.classId,
      rollup_hash_key: ecObject.fingerprint,
      rollup_sort_key: null,
    };
  },
  getName: (ecObject) => {
    return "";
  },
});

export const ClassNonHttpLinkRollup = Object.freeze({
  classId: "non_http_link_rollup",
  objectTitle: "Non-HTTP Link",
  cardClass: NonHttpLinkCard,
  cardMiniClass: NonHttpLinkMiniCard,
  detailPanelClass: NonHttpLinkDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: null,
      item_hash_key: null,
      item_sort_key: null,
      rollup_class_id: ClassNonHttpLinkRollup.classId,
      rollup_hash_key: ecObject.hash_key,
      rollup_sort_key: ecObject.sort_key,
    };
  },
  getName: (ecObject) => {
    return "";
  },
});

export const ClassEvent = Object.freeze({
  classId: "event",
  objectTitle: "Event",
  cardClass: EventCard,
  cardMiniClass: EventMiniCard,
  detailPanelClass: EventDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: ClassEvent.classId,
      item_hash_key: ecObject.hash_key,
      item_sort_key: ecObject.sort_key,
      rollup_class_id: ClassEventRollup.classId,
      rollup_hash_key: null,
      rollup_sort_key: null,
    };
  },
  getName: (ecObject) => {
    return ecObject.title;
  },
});

export const ClassEventRollup = Object.freeze({
  classId: "event_rollup",
  objectTitle: "Event",
  cardClass: EventCard,
  cardMiniClass: EventMiniCard,
  detailPanelClass: EventDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: null,
      item_hash_key: null,
      item_sort_key: null,
      rollup_class_id: ClassEventRollup.classId,
      rollup_hash_key: ecObject.hash_key,
      rollup_sort_key: ecObject.sort_key,
    };
  },
  getName: (ecObject) => {
    return ecObject.title;
  },
});

export const ClassTopic = Object.freeze({
  classId: "topic",
  objectTitle: "Topic",
  cardClass: TopicCard,
  cardMiniClass: TopicMiniCard,
  detailPanelClass: TopicDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: ClassTopic.classId,
      item_hash_key: ecObject.hash_key,
      item_sort_key: ecObject.sort_key,
      rollup_class_id: ClassTopicRollup.classId,
      rollup_hash_key: null,
      rollup_sort_key: null,
    };
  },
  getName: (ecObject) => {
    return ecObject.title;
  },
});

export const ClassTopicRollup = Object.freeze({
  classId: "topic_rollup",
  objectTitle: "Topic",
  cardClass: TopicCard,
  cardMiniClass: TopicMiniCard,
  detailPanelClass: TopicDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: null,
      item_hash_key: null,
      item_sort_key: null,
      rollup_class_id: ClassTopicRollup.classId,
      rollup_hash_key: ecObject.hash_key,
      rollup_sort_key: ecObject.sort_key,
    };
  },
  getName: (ecObject) => {
    return ecObject.title;
  },
});

export const ClassMessage = Object.freeze({
  classId: "message",
  objectTitle: "Message",
  cardClass: MessageCard,
  cardMiniClass: MessageMiniCard,
  detailPanelClass: MessageDetailPanel,
  getActions: () => {
    return [...BASE_ACTIONS_LIST];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: null,
      item_hash_key: null,
      item_sort_key: null,
      rollup_class_id: ClassMessage.classId,
      rollup_hash_key: ecObject.tenant_id,
      rollup_sort_key: ecObject.unique_tenancy_message_id,
    };
  },
  getName: (ecObject) => {
    return ecObject.subject;
  },
});

export const ClassSummaryBullet = Object.freeze({
  classId: "summary_bullet",
  objectTitle: "Topic",
  cardClass: SummaryBulletCard,
  cardMiniClass: SummaryBulletMiniCard,
  detailPanelClass: SummaryBulletDetailPanel,
  getActions: () => {
    return [
      Actions.createEventAction,
      Actions.createTodoItemAction,
      Actions.createTileWithBoardAssociationAction,
      Actions.createClippingAction,
      Actions.editTileAction,
      Actions.deleteTileBoardAssociationAction,
      Actions.deleteClippingAction,
    ];
  },
  getSourceInfo: (ecObject) => {
    return {
      item_class_id: null,
      item_hash_key: null,
      item_sort_key: null,
      rollup_class_id: ClassSummaryBullet.classId,
      rollup_hash_key: ecObject.hash_key,
      rollup_sort_key: ecObject.sort_key,
    };
  },
  getName: (ecObject) => {
    return ecObject.title;
  },
});

export const ALL_CLASSES_BY_CLASS_ID = {
  attachment: ClassAttachment,
  attachment_rollup: ClassAttachmentRollup,
  email_address: ClassEmailAddress,
  email_address_rollup: ClassEmailAddressRollup,
  image: ClassImage,
  image_rollup: ClassImageRollup,
  link: ClassLink,
  link_rollup: ClassLinkRollup,
  link_image: ClassLinkImage,
  link_image_rollup: ClassLinkImageRollup,
  non_http_link: ClassNonHttpLink,
  non_http_link_rollup: ClassNonHttpLinkRollup,
  event: ClassEvent,
  event_rollup: ClassEventRollup,
  topic: ClassTopic,
  topic_rollup: ClassTopicRollup,
  message: ClassMessage,
  summary_bullet: ClassSummaryBullet,
};

export const getOnClickAction = (
  clickHandler,
  ecObject,
  savedObject,
  appContext,
  cardProperties,
  classAction,
) => {
  if (ecObject) {
    const clazz = ALL_CLASSES_BY_CLASS_ID[ecObject.class_id];
    return () => {
      clickHandler(
        {
          classAction: classAction.name,
          sourceInfo: clazz.getSourceInfo(ecObject),
          appContext: appContext,
          cardProperties: cardProperties,
          savedObject: savedObject,
        },
        ecObject,
      );
    };
  }
};

export const getObjectTitle = (ecObject) => {
  if (ecObject) {
    const clazz = ALL_CLASSES_BY_CLASS_ID[ecObject.class_id];
    return clazz.objectTitle;
  } else {
    return "";
  }
};

export const getObjectName = (ecObject) => {
  if (ecObject) {
    const clazz = ALL_CLASSES_BY_CLASS_ID[ecObject.class_id];
    return clazz.getName(ecObject);
  } else {
    return "";
  }
};
