import React, { useEffect, useState } from "react";
import { Image as AntDImage } from "antd";

const TldrCardCoverImage = ({
  imageSrc,
  imageSize,
  preview,
  containerWidth,
  containerHeight,
  alt,
}) => {
  const [coverImageProps, setCoverImageProps] = useState(null);

  if (!alt) {
    alt = "";
  }

  useEffect(() => {
    if (imageSrc) {
      if (imageSize) {
        setCoverImageProps({
          src: imageSrc,
          naturalWidth: imageSize[0],
          naturalHeight: imageSize[1],
          ratio: imageSize[0] / imageSize[1],
        });
      }
      const img = new Image();
      img.onload = () => {
        setCoverImageProps({
          src: imageSrc,
          naturalWidth: img.naturalWidth,
          naturalHeight: img.naturalHeight,
          ratio: img.naturalWidth / img.naturalHeight,
        });
      };
      img.src = imageSrc;
    }
  }, [imageSrc, imageSize]);

  const getFullyContainedCoverImage = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <AntDImage src={imageSrc} alt={alt} preview={preview} />
      </div>
    );
  };

  const getLandscapeCoverImage = () => {
    const newWidth = Math.min(
      (3 * containerWidth) / 2,
      coverImageProps.naturalWidth,
    );
    const newHeight =
      (newWidth / coverImageProps.naturalWidth) * coverImageProps.naturalHeight;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <div>
          <AntDImage
            src={imageSrc}
            alt={alt}
            preview={preview}
            width={newWidth || 0}
            height={newHeight || 0}
          />
        </div>
      </div>
    );
  };

  const getPortraitCoverImage = () => {
    const newHeight = Math.min(
      (3 * containerHeight) / 2,
      coverImageProps.naturalHeight,
    );
    const newWidth =
      (newHeight / coverImageProps.naturalHeight) *
      coverImageProps.naturalWidth;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "top",
          backgroundColor: "#f5f5f5",
        }}
      >
        <div>
          <AntDImage
            src={imageSrc}
            alt={alt}
            preview={preview}
            width={newWidth || 0}
            height={newHeight || 0}
          />
        </div>
      </div>
    );
  };

  const getBlankPlaceholder = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#f5f5f5",
        }}
      />
    );
  };

  const getCoverImage = () => {
    if (!coverImageProps) {
      return getBlankPlaceholder();
    } else {
      const naturalWidth = coverImageProps.naturalWidth;
      const naturalHeight = coverImageProps.naturalHeight;
      const ratio = coverImageProps.ratio;

      if (naturalWidth < containerWidth && naturalHeight < containerWidth) {
        return getFullyContainedCoverImage();
      }

      if (ratio < 1) {
        return getPortraitCoverImage();
      }

      if (ratio >= 1) {
        return getLandscapeCoverImage();
      }

      return <></>;
    }
  };

  return getCoverImage();
};

export default TldrCardCoverImage;
