import { useEffect, useState } from "react";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";
import useUpdateUserEducationSubjectStatus from "../../../userEducation/userEducation/useUpdateUserEducationSubjectStatus";
import { useNavigate } from "react-router-dom";
import useUpdateTimezone from "../../../../hooks/orchestrate/user/useUpdateTimezone";
import useUpdateFamily from "../../../../hooks/orchestrate/common/useUpdateFamily";

const useOnboardingPartDeux = () => {
  const navigate = useNavigate();
  const {
    familyId,
    currentUserEducationSubjects,
    currentFamily,
    currentUser,
    isCurrentUserFamilyOwner,
    invalidateQueryWhereKeyContains,
    browserTimezone,
  } = useTldrPageContext();
  const [currentStep, setCurrentStep] = useState(null);
  const [onboardingSteps, setOnboardingSteps] = useState(null);

  const { mutate: updateUserEducationSubjectStatus } =
    useUpdateUserEducationSubjectStatus(
      () => {},
      () => {
        invalidateQueryWhereKeyContains("subjects");
      },
      () => {},
    );

  useEffect(() => {
    if (currentUserEducationSubjects) {
      if (currentUserEducationSubjects.hasFinishedOnboarding) {
        navigate(`/families/${familyId}`);
      }

      for (const i in currentUserEducationSubjects.subjects) {
        const subject = currentUserEducationSubjects.subjects[i];
        if (subject.status !== "completed" && subject.status !== "skipped") {
          setCurrentStep(subject.subject_id);
          break;
        }
      }
    }
  }, [currentUserEducationSubjects, familyId, navigate]);

  useEffect(() => {
    if (!onboardingSteps && currentUserEducationSubjects?.subjects) {
      const onboardingStepsLocal = [];
      for (const i in currentUserEducationSubjects.subjects) {
        const subjectId = currentUserEducationSubjects.subjects[i].subject_id;
        if (
          [
            "NAME_FAMILY_WORKSPACE",
            "INVITE_PARTNER",
            "PROCESS_FIRST_MESSAGE",
          ].includes(subjectId)
        ) {
          const subjectStatus = currentUserEducationSubjects.subjects[i].status;
          if (!["completed", "skipped"].includes(subjectStatus)) {
            onboardingStepsLocal.push(subjectId);
          }
        }
      }
      setOnboardingSteps(onboardingStepsLocal);
    }
  }, [onboardingSteps, currentUserEducationSubjects]);

  const { mutate: updateUserTimezone } = useUpdateTimezone(
    () => {},
    () => {
      invalidateQueryWhereKeyContains("current-user");
    },
    () => {
      invalidateQueryWhereKeyContains("current-user");
    },
  );

  const { mutate: updateFamilyTimezone } = useUpdateFamily(
    () => {},
    () => {
      invalidateQueryWhereKeyContains("current-user");
    },
    () => {
      invalidateQueryWhereKeyContains("current-user");
    },
  );

  useEffect(() => {
    if (currentUser && currentFamily) {
      const newUserTimezone = currentUser?.timezone;
      const familyTimezone = currentFamily.timezone;

      if (browserTimezone) {
        if (newUserTimezone !== browserTimezone) {
          updateUserTimezone({
            timezone: browserTimezone,
          });
        }

        if (familyTimezone !== browserTimezone && isCurrentUserFamilyOwner) {
          updateFamilyTimezone({
            idz: currentFamily.id,
            timezone: browserTimezone,
          });
        }
      }
    }
  }, [
    currentUser,
    currentFamily,
    isCurrentUserFamilyOwner,
    updateFamilyTimezone,
    updateUserTimezone,
    browserTimezone,
  ]);

  const invalidateSubjectsQuery = () => {
    invalidateQueryWhereKeyContains("subjects");
  };

  return {
    onboardingSteps,
    currentStep,
    updateUserEducationSubjectStatus,
    invalidateSubjectsQuery,
  };
};

export default useOnboardingPartDeux;
