import React, { useEffect, useState } from "react";
import { Space, Tag } from "antd";

import {
  CODE_TIME_FORMAT,
  DUMMY_DATE_STRING,
  DUMMY_TIME_STRING,
  getDayJsDatetimeFromStrings,
} from "../../../utils/dayJsUtils";
import {
  getCurrentDateString,
  getCurrentTimePlusOneHourStringFlooredToHour,
  getCurrentTimeStringFlooredToHour,
} from "./dateTimePickerUtils";
import DatetimePickerWithPresets from "./datetimePickerWithPresets";

const DatetimeRangePicker = ({
  value,
  requireTime,
  onChange,
  datePresets1,
  timePresets1,
  datePresets2,
  timePresets2,
}) => {
  const [selectedDate1, setSelectedDate1] = useState("");
  const [selectedTime1, setSelectedTime1] = useState("");
  const [selectedDate2, setSelectedDate2] = useState("");
  const [selectedTime2, setSelectedTime2] = useState("");
  const [duration, setDuration] = useState(undefined);
  const [startEditOpen, setStartEditOpen] = useState(false);
  const [endEditOpen, setEndEditOpen] = useState(false);

  useEffect(() => {
    if (startEditOpen) {
      setEndEditOpen(false);
    }
  }, [startEditOpen]);

  useEffect(() => {
    if (endEditOpen) {
      setStartEditOpen(false);
    }
  }, [endEditOpen]);

  useEffect(() => {
    if (value) {
      if (value.date1) {
        setSelectedDate1(value.date1);
      } else {
        setSelectedDate1(getCurrentDateString());
      }

      if (value.date2) {
        setSelectedDate2(value.date2);
      } else {
        setSelectedDate2(getCurrentDateString());
      }

      if (value.time1 && value.time2) {
        setSelectedTime1(value.time1);
        setSelectedTime2(value.time2);
      } else {
        if (requireTime) {
          setSelectedTime1(getCurrentTimeStringFlooredToHour());
          setSelectedTime2(getCurrentTimePlusOneHourStringFlooredToHour());
        } else {
          setSelectedTime1("");
          setSelectedTime2("");
        }
      }
    } else {
      setSelectedDate1(getCurrentDateString());
      setSelectedTime1(getCurrentTimeStringFlooredToHour());
      setSelectedDate2(getCurrentDateString());
      setSelectedTime2(getCurrentTimePlusOneHourStringFlooredToHour());
    }
  }, [value]);

  useEffect(() => {
    const includeTimes = !!(selectedTime1 && selectedTime2);
    if (includeTimes) {
      const datetime1 = getDayJsDatetimeFromStrings(
        selectedDate1,
        selectedTime1,
      );
      const datetime2 = getDayJsDatetimeFromStrings(
        selectedDate2,
        selectedTime2,
      );
      setDuration(datetime2.diff(datetime1, "minute"));
    } else {
      const datetime1 = getDayJsDatetimeFromStrings(
        selectedDate1,
        DUMMY_TIME_STRING,
      );
      const datetime2 = getDayJsDatetimeFromStrings(
        selectedDate2,
        DUMMY_TIME_STRING,
      );
      setDuration(datetime2.diff(datetime1, "minute"));
    }
  }, [selectedDate1, selectedTime1, selectedDate2, selectedTime2]);

  const getDiffForChangeToDatetime1 = (values) => {
    const currentDatetime1AsDayJs = getDayJsDatetimeFromStrings(
      selectedDate1,
      selectedTime1 || DUMMY_TIME_STRING,
    );
    const newDatetime1AsDayJs = getDayJsDatetimeFromStrings(
      values.date,
      values.time || DUMMY_TIME_STRING,
    );
    return newDatetime1AsDayJs.diff(currentDatetime1AsDayJs, "minute");
  };

  const handleChange1 = (values) => {
    const changedData = {};
    const diff = getDiffForChangeToDatetime1(values);
    const currentDatetime2AsDayJs = getDayJsDatetimeFromStrings(
      selectedDate2,
      selectedTime2 || DUMMY_TIME_STRING,
    );

    const newDatetime2AsDayJs = currentDatetime2AsDayJs.add(diff, "minute");
    changedData.date2 = newDatetime2AsDayJs.format("YYYY-MM-DD");
    setSelectedDate2(newDatetime2AsDayJs.format("YYYY-MM-DD"));

    changedData.date1 = values.date;
    setSelectedDate1(values.date);

    if (values.time) {
      changedData.time1 = values.time;
      setSelectedTime1(values.time);

      if (selectedTime2) {
        changedData.time2 = newDatetime2AsDayJs.format("HH:mm");
        setSelectedTime2(newDatetime2AsDayJs.format("HH:mm"));
      } else {
        // Case where we are going from having no time to having a time, make the duration one hour
        changedData.time2 = getDayJsDatetimeFromStrings(
          DUMMY_DATE_STRING,
          values.time,
        )
          .add(1, "hour")
          .format(CODE_TIME_FORMAT);
        setSelectedTime2(changedData.time2);
      }
    } else {
      changedData.time1 = "";
      setSelectedTime1("");
      changedData.time2 = "";
      setSelectedTime2("");
    }
    onChange(changedData);
  };

  const handleChange2 = (values) => {
    const changedData = {};
    changedData.date1 = selectedDate1;
    changedData.date2 = values.date;
    setSelectedDate2(values.date);
    if (values.time) {
      changedData.time2 = values.time;
      setSelectedTime2(values.time);
      if (selectedTime1) {
        changedData.time1 = selectedTime1;
      } else {
        changedData.time1 = values.time;
        setSelectedTime1(values.time);
      }
    } else {
      changedData.time1 = "";
      setSelectedTime1("");
      changedData.time2 = "";
      setSelectedTime2("");
    }
    onChange(changedData);
  };

  const getDisplayDuration = () => {
    const toEnglish = (num, word) => {
      switch (num) {
        case 0:
          return null;
        case 1:
          return `${num} ${word}`;
        default:
          return `${num} ${word}s`;
      }
    };

    const divideDuration = () => {
      if (duration === undefined) {
        return;
      }

      let localDuration = duration;
      if (!selectedTime1 && !selectedTime2) {
        localDuration = localDuration + 1440;
      }

      const absDuration = Math.abs(localDuration);
      const weeks = Math.floor(absDuration / 10080);
      const remainderAfterWeeks = absDuration - weeks * 10080;

      const days = Math.floor(remainderAfterWeeks / 1440);
      const remainderAfterDays = remainderAfterWeeks - days * 1440;

      const hours = Math.floor(remainderAfterDays / 60);
      const minutes = remainderAfterDays - hours * 60;

      return { weeks, days, hours, minutes };
    };

    const dividedDurationDict = divideDuration();
    let response = [
      toEnglish(dividedDurationDict.weeks, "week"),
      toEnglish(dividedDurationDict.days, "day"),
      toEnglish(dividedDurationDict.hours, "hour"),
      toEnglish(dividedDurationDict.minutes, "minute"),
    ];

    response = response.filter((item) => !!item);
    if (response && response.length === 0) {
      return "0 minutes";
    }
    return response.join(", ");
  };

  const getDurationJsx = () => {
    return (
      <div style={{ textAlign: "right" }}>
        {duration !== undefined ? (
          duration >= 0 ? (
            <Tag color="success">{getDisplayDuration()}</Tag>
          ) : (
            <Tag color="error">{getDisplayDuration()}</Tag>
          )
        ) : (
          <></>
        )}
      </div>
    );
  };

  const getDatetime1AsDict = () => {
    return { date: selectedDate1, time: selectedTime1 };
  };

  const getDatetime2AsDict = () => {
    return { date: selectedDate2, time: selectedTime2 };
  };

  return (
    <Space direction="vertical">
      <DatetimePickerWithPresets
        value={getDatetime1AsDict()}
        allowDateChoice={false}
        allowTimeChoice={!requireTime}
        datePresets={datePresets1}
        timePresets={timePresets1}
        onChange={handleChange1}
        editPanelOpen={startEditOpen}
        onEditPanelOpenChange={(value) => {
          setStartEditOpen(value);
        }}
      />
      <DatetimePickerWithPresets
        value={getDatetime2AsDict()}
        allowDateChoice={false}
        allowTimeChoice={!requireTime}
        datePresets={datePresets2}
        timePresets={timePresets2}
        onChange={handleChange2}
        editPanelOpen={endEditOpen}
        onEditPanelOpenChange={(value) => {
          setEndEditOpen(value);
        }}
      />
      {getDurationJsx()}
    </Space>
  );
};

export default DatetimeRangePicker;
