import React, { useEffect, useState } from "react";
import {
  DISPLAY_DATE_FORMAT_LONG,
  DUMMY_TIME_STRING,
  getDayJsDatetimeFromStrings,
} from "../../../utils/dayJsUtils";
import CollapsibleContentArea from "./collapsibleContentArea";
import DatePickerWithPresetsPanel from "./datePickerWithPresetsPanel";
import { getCurrentDateString } from "./dateTimePickerUtils";

const DatePickerWithPresets = ({
  value,
  width,
  onChange,
  presets,
  allowEmptyDate,
}) => {
  if (allowEmptyDate === undefined) {
    allowEmptyDate = false;
  }
  const [selectedValueAsString, setSelectedValueAsString] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (value) {
      setSelectedValueAsString(value);
    } else {
      if (!allowEmptyDate) {
        setSelectedValueAsString(getCurrentDateString());
      }
    }
  }, [value, allowEmptyDate]);

  return (
    <CollapsibleContentArea
      open={isEditMode}
      width={width}
      items={[
        {
          buttonText: selectedValueAsString
            ? getDayJsDatetimeFromStrings(
                selectedValueAsString,
                DUMMY_TIME_STRING,
              ).format(DISPLAY_DATE_FORMAT_LONG)
            : "[No date]",
          content: (
            <DatePickerWithPresetsPanel
              value={selectedValueAsString}
              width={width}
              onChange={(value) => {
                setSelectedValueAsString(value);
                setIsEditMode(false);
                onChange(value);
              }}
              presets={presets}
              allowEmptyDate={allowEmptyDate}
            />
          ),
        },
      ]}
      onOpenChange={(value) => setIsEditMode(value)}
    />
  );
};

export default DatePickerWithPresets;
