import React, { useState } from "react";
import { Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import googleCalendarConnectImage from "../../images/google-calendar.png";
import useGetCurrentUser from "../../hooks/orchestrate/user/useGetCurrentUser";
import useGetGoogleAndGmailConnectionInfo from "./hooks/useGetGoogleAndGmailConnectionInfo";
import useListGoogleCalendarEvents from "../../hooks/orchestrate/googleCalendar/useListGoogleCalendarEvents";
import Auth0GoogleScopesProviderContext from "../../context/auth0GoogleScopesProviderContext";

const ConnectGoogleCalendar = ({ onComplete }) => {
  const { data: currentUser } = useGetCurrentUser();
  const { loginWithPopup } = useAuth0(Auth0GoogleScopesProviderContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    isLoading: isLoadingGoogleAuthTokenInfo,
    isGoogleCalendarConnected,
    googleAuthTokenInfo,
    refetchAfterUserAction,
  } = useGetGoogleAndGmailConnectionInfo();

  const { data: googleCalendarEvents } = useListGoogleCalendarEvents();
  console.log(googleAuthTokenInfo);
  console.log(googleCalendarEvents);

  const handleClickAddGoogleCalendarScope = async () => {
    setIsSubmitting(true);
    await loginWithPopup({
      authorizationParams: { login_hint: currentUser?.email },
    });
  };

  const getAllSetState = () => {
    return (
      <>
        {googleCalendarEvents ? (
          <>
            {googleCalendarEvents.map((googleCalendarEvent, index) => {
              return <div key={index}>{googleCalendarEvent.summary}</div>;
            })}
          </>
        ) : (
          <>Not Connected</>
        )}
      </>
    );
  };

  const getLoadingState = () => {
    return <>Checking Google Calendar connection...</>;
  };

  const getDisconnectedState = () => {
    return (
      <Button
        onClick={handleClickAddGoogleCalendarScope}
        loading={isSubmitting}
        icon={
          <img
            style={{ cursor: "pointer", width: 25, marginRight: 5 }}
            src={googleCalendarConnectImage}
            alt="Connect Google Calendar"
          />
        }
      >
        Connect Google Calendar
      </Button>
    );
  };

  const getJsx = () => {
    if (isLoadingGoogleAuthTokenInfo) {
      return getLoadingState();
    } else if (isGoogleCalendarConnected) {
      return getAllSetState();
    } else {
      return getDisconnectedState();
    }
  };

  return <>{getJsx()}</>;
};

export default ConnectGoogleCalendar;
