import React from "react";
import ArtifactCard from "../../common/generic/artifactCard";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { Tooltip } from "antd";
import NonHttpLinkCardCover from "../cardCovers/nonHttpLinkCardCover";

const NonHttpLinkCard = ({
  ecObject,
  cardProperties,
  onClickViewEcObjectDetails,
  sourceMessageInfo,
  actionMenu,
}) => {
  return (
    <ArtifactCard
      cover={<NonHttpLinkCardCover ecObject={ecObject} />}
      position1={sourceMessageInfo}
      position2={
        <Tooltip title={"Non-HTTP Link"}>
          <LinkOutlinedIcon sx={{ color: "#ff4081" }} />
        </Tooltip>
      }
      position3={actionMenu}
    />
  );
};

export default NonHttpLinkCard;
