import { useEffect, useState } from "react";

const useMetadataForSavedObject = (savedObject) => {
  const [objectName, setObjectName] = useState("");
  const [objectTypeForDisplay, setObjectTypeForDisplay] = useState("");
  const [objectTypePluralForDisplay, setObjectTypePluralForDisplay] =
    useState("");
  const [pageTitle, setPageTitle] = useState(" ");
  const [urlPath, setUrlPath] = useState("");

  useEffect(() => {
    if (savedObject) {
      switch (savedObject.class_id) {
        case "todo":
          setObjectName(savedObject?.name || "");
          setObjectTypeForDisplay("To-do");
          setObjectTypePluralForDisplay("To-dos");
          setUrlPath("todos");
          if (savedObject?.name) {
            setPageTitle(`To-do: ${savedObject?.name}`);
          } else {
            setPageTitle("To-do");
          }
          break;
        case "clipping":
          setObjectName(savedObject?.name || "");
          setObjectTypeForDisplay("Clipping");
          setObjectTypePluralForDisplay("Clippings");
          setUrlPath("clippings");
          if (savedObject?.name) {
            setPageTitle(`Clipping: ${savedObject?.name}`);
          } else {
            setPageTitle("Clipping");
          }
          break;
        case "google_event":
          setObjectName(savedObject?.name || "");
          setObjectTypeForDisplay("Google Event");
          setObjectTypePluralForDisplay("Google Events");
          setUrlPath("google-events");
          if (savedObject?.name) {
            setPageTitle(`Google Event: ${savedObject?.name}`);
          } else {
            setPageTitle("Google Event");
          }
          break;
        case "tile":
          setObjectName(savedObject?.name || "");
          setObjectTypeForDisplay("Board Tile");
          setObjectTypePluralForDisplay("Board Tiles");
          setUrlPath("boards");
          if (savedObject?.name) {
            setPageTitle(`Board Tile: ${savedObject?.name}`);
          } else {
            setPageTitle("Board Tile");
          }
          break;
        default:
          setObjectName("");
          setObjectTypeForDisplay("");
          setObjectTypePluralForDisplay("");
          setPageTitle("");
          break;
      }
    } else {
      setObjectName("");
      setObjectTypeForDisplay("");
      setObjectTypePluralForDisplay("");
      setPageTitle("");
    }
  }, [savedObject]);

  return {
    objectName,
    objectTypeForDisplay,
    objectTypePluralForDisplay,
    pageTitle,
    urlPath,
  };
};

export default useMetadataForSavedObject;
