import React from "react";
import { Divider } from "antd";

const DividerExceptOnLast = ({ list, currentItem }) => {
  if (!list || !currentItem) {
    return <></>;
  } else {
    return <>{currentItem !== list[list.length - 1] ? <Divider /> : <></>}</>;
  }
};

export default DividerExceptOnLast;
