import React from "react";
import { Button, Space, Tooltip, Typography } from "antd";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const { Text } = Typography;

const PrevNextMessageNavForDetailsPage = ({
  currentMessageIndex,
  messages,
  onUpdateIndex,
}) => {
  return (
    <Space>
      <div>
        <Text type={"secondary"}>
          {currentMessageIndex + 1} of {messages?.length}{" "}
          {messages?.length > 1 ? <>messages</> : <>message</>}
        </Text>
      </div>
      <div>
        <Tooltip title="Newer" placement="bottom">
          <Button
            type={"primary"}
            ghost={true}
            style={{ width: 50, borderRadius: 6 }}
            disabled={currentMessageIndex === 0}
            onClick={() => {
              const newIndex = currentMessageIndex - 1;
              onUpdateIndex(newIndex);
            }}
          >
            <ChevronLeftIcon sx={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="Older" placement="bottom">
          <Button
            type={"primary"}
            ghost={true}
            style={{ width: 50, borderRadius: 6 }}
            disabled={currentMessageIndex === messages.length - 1}
            onClick={() => {
              const newIndex = currentMessageIndex + 1;
              onUpdateIndex(newIndex);
            }}
          >
            <ChevronRightIcon sx={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      </div>
    </Space>
  );
};

export default PrevNextMessageNavForDetailsPage;
