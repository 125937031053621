import { useEffect, useState } from "react";

function useSimpleFamilyDetails(familyId, currentUser) {
  const [family, setFamily] = useState(undefined);

  useEffect(() => {
    if (familyId && currentUser) {
      for (const i in currentUser.families) {
        const family = currentUser.families[i];
        if (family.id === parseInt(familyId)) {
          setFamily(family);
        }
      }
    }
  }, [familyId, currentUser]);

  return family;
}

export default useSimpleFamilyDetails;
