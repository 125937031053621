import {
  getFormattedUtcDateInUsersTz,
  getFormattedUtcDateTimeInUsersTz,
} from "../../../../utils/dayJsUtils";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";

const TodoDuePart = ({ todoItem }) => {
  const { browserTimezone } = useTldrPageContext();

  const getFormattedDueDateTime = () => {
    if (todoItem.due) {
      if (todoItem.is_all_day) {
        return getFormattedUtcDateInUsersTz(todoItem.due, browserTimezone);
      } else {
        return getFormattedUtcDateTimeInUsersTz(todoItem.due, browserTimezone);
      }
    }
  };

  const getAssignedToAndDue = () => {
    if (todoItem.due) {
      return getFormattedDueDateTime();
    }
    return "";
  };

  return getAssignedToAndDue();
};

export default TodoDuePart;
