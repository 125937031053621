import React from "react";
import UserEducationModal from "../../userEducation/userEducationModal";
import { Modal } from "antd";

const ClippingScrollEducationModal = ({ forced, open, onClose }) => {
  const title = "Workbench 🛠️";
  const inner = (
    <>
      <p>
        Introducing Clippings: the fastest, easiest way to save important
        information in TLDR Parents! See the scissors button next to something
        you want to keep? With a single click, you can save it--no typing
        required.
      </p>
      <p>
        Unlike most things in TLDR Parents, Clippings are not shared with other
        family members. "My Clips" is your personal sandbox for organizing.
        Clippings are designed to give you a quick and easy way to set aside
        things without loosing your train of thought.
      </p>
      <p>
        Visit "My Clips" to transform those quick-saves into calendar events,
        to-dos, or pin them to boards. It's your hub for turning quick-saves
        into actionable items!
      </p>
      <h3>Workflow Tips 🔧</h3>
      <p>
        When processing emails, there's no need to worry about clipping the same
        content more than once. It's better to err on the side of saving too
        much rather than too little. In "My Clips," you can easily clean up
        duplicates later by using the "Remove Duplicates" button.
      </p>
    </>
  );

  if (!!forced) {
    return (
      <UserEducationModal title={title} subjects={["EXPLAIN_CLIPPINGS"]}>
        {inner}
      </UserEducationModal>
    );
  } else {
    return (
      <Modal
        title={title}
        open={open}
        closable={true}
        onCancel={() => onClose()}
        onOk={null}
        footer={null}
      >
        {inner}
      </Modal>
    );
  }
};

export default ClippingScrollEducationModal;
