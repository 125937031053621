import React from "react";
import { Col, Row } from "antd";

const IconAndText = ({ icon, text }) => {
  return (
    <Row wrap={false} align="top">
      <Col style={{ paddingRight: 5 }}>{icon}</Col>
      <Col>{text}</Col>
    </Row>
  );
};

export default IconAndText;
