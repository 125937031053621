import useQueryGenericGet from "../../framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../serviceUrls/baseUrls";
import { EC_SERVICE_MESSAGES_LIST_ARTIFACTS_FOR_MESSAGE } from "../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../useEmailCollectorServiceUrlHelper";

const useListMessageArtifacts = (uniqueMessageId, enabled) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_MESSAGES_LIST_ARTIFACTS_FOR_MESSAGE,
    {
      uniqueMessageId: uniqueMessageId,
    },
  );

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    {},
    enabled && currentFamily,
  );
};

export default useListMessageArtifacts;
