import React from "react";
import { Empty, Skeleton } from "antd";

const LoadingEmptyOrContent = ({ isLoading, isEmpty, children }) => {
  const getEmptyLoadingOrList = () => {
    if (isLoading === true) {
      return <Skeleton active />;
    } else if (isEmpty === true) {
      return <Empty image={null} description={null} />;
    }
    return children;
  };

  return getEmptyLoadingOrList();
};

export default LoadingEmptyOrContent;
