import React from "react";
import InboundForwardAttachmentCard from "./inboundForwardAttachmentCard";
import { Alert, Col, Divider, Row, Space, Typography } from "antd";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const InboundForwardAttachments = ({ attachments, onClickHelpIcon }) => {
  const getJsx = () => {
    if (attachments && attachments.length > 0) {
      return (
        <>
          <Space style={{ marginBottom: 5 }}>
            <h2>Attachments</h2>
            <HelpOutlineIcon
              sx={{ fontSize: 25, color: "#ff4081", cursor: "pointer" }}
              onClick={() => onClickHelpIcon("attachments")}
            />
          </Space>
          <Alert
            message={
              <Typography.Text strong={true}>
                Stop searching through your emails for attachments. With a free{" "}
                <a href={"/login"}>TLDR Parents account</a>, you can view all
                your attachments from across messages on a single screen. 📎
                Sign up
              </Typography.Text>
            }
            type={"warning"}
            style={{ marginBottom: 10 }}
          />
          <Row gutter={[12, 12]}>
            {attachments.map((attachment, index) => {
              return (
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6} key={index}>
                  <InboundForwardAttachmentCard
                    attachment={attachment.rollup}
                  />
                </Col>
              );
            })}
          </Row>
          <Divider />
        </>
      );
    } else {
      return <></>;
    }
  };

  return getJsx();
};

export default InboundForwardAttachments;
