import { useState } from "react";
import useListMessages from "../../../../hooks/emailCollector/messages/useListMessages";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";
import useUpdateMessages from "../../../../hooks/emailCollector/messages/useUpdateMessages";
import useRunSyncGmail from "../../../../hooks/orchestrate/google/useRunSyncGmail";
import useSearchParamPagination from "../../../../hooks/utils/useSearchParamPagination";

const PAGE_SIZE = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);

const useMessagesAsCards = (viewArchived) => {
  const {
    invalidateQueryWhereKeyIs,
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
  } = useTldrPageContext();

  const { currentPage, orderBy, changeOrderOrFilterAndNavigate, changePage } =
    useSearchParamPagination({ order: "message_date" });

  const { data: messages, queryKey: messagesQueryKey } = useListMessages(
    viewArchived === true,
    PAGE_SIZE,
    (currentPage - 1) * PAGE_SIZE,
    orderBy,
  );

  const [checkedMessageIds, setCheckedMessageIds] = useState([]);

  const { mutate: updateMessagesHook } = useUpdateMessages(
    () => {
      openLoadingMessage("updateMessages", "Updating");
    },
    () => {
      openSuccessMessage("updateMessages", "Done");
      invalidateQueryWhereKeyIs(messagesQueryKey);
    },
    () => {
      openErrorMessage("updateMessages", "Error");
    },
    false,
  );

  const { mutate: runGmailQueryHook } = useRunSyncGmail(
    () => openLoadingMessage("runReSyncGmail", "Queuing inbox for sync"),
    () => openSuccessMessage("runReSyncGmail", "Inbox queued for sync"),
    () => openErrorMessage("runReSyncGmail", "Error queuing inbox for sync"),
  );

  const isMessageChecked = (message) => {
    const uniqueMessageId = `${message.tenant_id}-${message.unique_tenancy_message_id}`;
    return checkedMessageIds.includes(uniqueMessageId);
  };

  return {
    messages,
    currentPage,
    pageSize: PAGE_SIZE,
    orderBy,
    changePage,
    changeOrderOrFilterAndNavigate,
    checkedMessageIds,
    setCheckedMessageIds,
    updateMessagesHook,
    runGmailQueryHook,
    isMessageChecked,
  };
};

export default useMessagesAsCards;
