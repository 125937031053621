import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../../serviceUrls/baseUrls";
import useQueryGenericGetWithPost from "../../../../hooks/framework/useQueryGenericGetWithPost";
import { EC_SERVICE_MESSAGE_BATCH_GET } from "../../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../../../../hooks/emailCollector/useEmailCollectorServiceUrlHelper";

const useBatchGetMessages = (uniqueMessageIds) => {
  const postData = { unique_message_ids: uniqueMessageIds };

  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_MESSAGE_BATCH_GET,
    {},
  );

  return useQueryGenericGetWithPost(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    postData,
    true,
    !!(currentFamily && uniqueMessageIds?.length > 0 && postData),
  );
};

export default useBatchGetMessages;
