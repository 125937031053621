import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGetCurrentUser from "../../../../hooks/orchestrate/user/useGetCurrentUser";
import useQueryGenericGet from "../../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../../../serviceUrls/baseUrls";
import { O_SERVICE_INVITES_FOR_CURRENT_USER } from "../../../../serviceUrls/serviceUrlsOrchestrate";
import useGetAndSetSelectedFamilyId from "../../../../hooks/orchestrate/common/useGetAndSetSelectedFamilyId";

const useOnboardingPart1 = () => {
  const navigate = useNavigate();
  const { data: currentUser } = useGetCurrentUser();
  const { selectedFamilyId } = useGetAndSetSelectedFamilyId();
  const [isLoadingOnboardingInfo, setIsLoadingOnboardingInfo] = useState(true);

  const { data: invites } = useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_INVITES_FOR_CURRENT_USER,
    true,
    [],
    {},
    !!currentUser,
  );

  useEffect(() => {
    if (currentUser && invites) {
      if (invites?.length > 0) {
        navigate(`/confirm-invites`);
      } else if (currentUser?.families?.length === 1) {
        navigate(`/families/${currentUser.families[0].id}`);
      } else if (selectedFamilyId && selectedFamilyId > 0) {
        navigate(`/families/${selectedFamilyId}`);
      }
      setIsLoadingOnboardingInfo(false);
    }
  }, [currentUser, invites, navigate, selectedFamilyId]);

  return { isLoadingOnboardingInfo };
};

export default useOnboardingPart1;
