import React from "react";
import CreateOrEditClippingOverlay from "./clippingScroll/editors/createOrEditClippingOverlay";
import CreateTileWithBoardAssociationFormOverlay from "./boards/editors/createTileWithBoardAssociationFormOverlay";
import CreateOrEditBoardOverlay from "./boards/editors/createOrEditBoardOverlay";
import useTldrUniversalMutateContextForModals from "../../hooks/utils/useTldrUniversalMutateContextForModals";
import EditTileOverlay from "./boards/editors/editTileOverlay";
import CreateOrEditCommentModal from "../comments/createOrEditCommentModal";
import UseImageAsBoardCoverOverlay from "./boards/editors/useImageAsBoardCoverOverlay";
import CreateOrEditTodoItemOverlay from "./todoList/editors/createOrEditTodoItemOverlay";
import CreateGoogleEventOverlay from "../common/commonTldr/createGoogleEventOverlay";

const OrchestrateObjectFormOverlays = ({
  eventInitialFormValues,
  eventMergeValues,
  eventsQueryKey,
  eventOverlayOpen,
  onCancelEvent,
  onSubmitEvent,
  onPersistEvent,
  todoInitialFormValues,
  todoMergeValues,
  todosQueryKey,
  todoOverlayOpen,
  onCancelTodo,
  onSubmitTodo,
  onPersistTodo,
  clippingInitialFormValues,
  clippingMergeValues,
  clippingsQueryKey,
  clippingOverlayOpen,
  onCancelClipping,
  onSubmitClipping,
  onPersistClipping,
  boardInitialFormValues,
  boardMergeValues,
  boardsQueryKey,
  boardOverlayOpen,
  onCancelBoard,
  onSubmitBoard,
  onPersistBoard,
  tileInitialFormValues,
  tileMergeValues,
  tilesQueryKey,
  tileOverlayOpen,
  onCancelTile,
  onSubmitTile,
  onPersistTile,
  useImageForBoardCoverInitialFormValues,
  useImageForBoardCoverMergeValues,
  useImageForBoardCoverQueryKey,
  useImageForBoardCoverOverlayOpen,
  onCancelUseImageForBoardCover,
  onSubmitUseImageForBoardCover,
  onPersistUseImageForBoardCover,
  commentInitialFormValues,
  commentMergeValues,
  commentsQueryKey,
  commentOverlayOpen,
  onCancelComment,
  onSubmitComment,
  onPersistComment,
  ecObjectActedUpon,
}) => {
  const includeEventOverlay =
    eventInitialFormValues &&
    eventMergeValues &&
    eventOverlayOpen &&
    onCancelEvent &&
    onSubmitEvent &&
    onPersistEvent;

  const includeTodoOverlay =
    todoInitialFormValues &&
    todoMergeValues &&
    todoOverlayOpen &&
    onCancelTodo &&
    onSubmitTodo &&
    onPersistTodo;

  const includeClippingOverlay =
    clippingInitialFormValues &&
    clippingMergeValues &&
    clippingOverlayOpen &&
    onCancelClipping &&
    onSubmitClipping &&
    onPersistClipping;

  const includeBoardOverlay =
    boardInitialFormValues &&
    boardMergeValues &&
    boardOverlayOpen &&
    onCancelBoard &&
    onSubmitBoard &&
    onPersistBoard;

  const includeTileOverlay =
    tileInitialFormValues &&
    tileMergeValues &&
    tileOverlayOpen &&
    onCancelTile &&
    onSubmitTile &&
    onPersistTile;

  const includeUseImageForBoardCoverOverlay =
    useImageForBoardCoverInitialFormValues &&
    useImageForBoardCoverMergeValues &&
    useImageForBoardCoverOverlayOpen &&
    onCancelUseImageForBoardCover &&
    onSubmitUseImageForBoardCover &&
    onPersistUseImageForBoardCover;

  const includeCommentOverlay =
    commentInitialFormValues &&
    commentMergeValues &&
    commentOverlayOpen &&
    onCancelComment &&
    onSubmitComment &&
    onPersistComment;

  const {
    eventConfirmLoading,
    handleCreateEvent,
    todoConfirmLoading,
    handleCreateTodo,
    handleUpdateTodo,
    clippingConfirmLoading,
    handleCreateClipping,
    handleUpdateClipping,
    boardConfirmLoading,
    handleCreateBoard,
    handleUpdateBoard,
    tileConfirmLoading,
    handleCreateTile,
    handleUpdateTile,
    useImageForBoardCoverConfirmLoading,
    handleUpdateUseImageForBoardCover,
    commentConfirmLoading,
    handleCreateComment,
    handleUpdateComment,
  } = useTldrUniversalMutateContextForModals(
    eventsQueryKey,
    (savedItem) => onPersistEvent(savedItem),
    todosQueryKey,
    (savedItem) => onPersistTodo(savedItem),
    clippingsQueryKey,
    (savedItem) => onPersistClipping(savedItem),
    boardsQueryKey,
    (savedItem) => onPersistBoard(savedItem),
    tilesQueryKey,
    (savedItem) => onPersistTile(savedItem),
    useImageForBoardCoverQueryKey,
    (savedItem) => onPersistUseImageForBoardCover(savedItem),
    commentsQueryKey,
    (savedItem) => onPersistComment(savedItem),
  );

  const handleSubmitEventLocal = (values) => {
    onSubmitEvent();
    handleCreateEvent(values);
  };

  const handleSubmitTodoLocal = (values) => {
    onSubmitTodo();
    if (!!todoMergeValues?.id) {
      handleUpdateTodo(values);
    } else {
      handleCreateTodo(values);
    }
  };

  const handleSubmitClippingLocal = (values) => {
    onSubmitClipping();
    if (!!clippingMergeValues?.id) {
      handleUpdateClipping(values);
    } else {
      handleCreateClipping(values);
    }
  };
  const handleSubmitBoardLocal = (values) => {
    onSubmitBoard();
    if (!!boardMergeValues?.id) {
      handleUpdateBoard(values);
    } else {
      handleCreateBoard(values);
    }
  };

  const handleSubmitUseImageForBoardCoverLocal = (values) => {
    onSubmitUseImageForBoardCover();
    handleUpdateUseImageForBoardCover(values);
  };

  const handleSubmitCommentLocal = (values) => {
    onSubmitComment();
    if (!!commentMergeValues?.id) {
      handleUpdateComment(values);
    } else {
      handleCreateComment(values);
    }
  };

  return (
    <>
      {includeEventOverlay ? (
        <CreateGoogleEventOverlay
          open={eventOverlayOpen}
          onOk={handleSubmitEventLocal}
          onCancel={() => {
            onCancelEvent();
          }}
          initialValues={eventInitialFormValues}
          confirmLoading={eventConfirmLoading}
          formMergeValues={eventMergeValues}
          ecObject={ecObjectActedUpon}
        />
      ) : (
        <></>
      )}

      {includeTodoOverlay ? (
        <CreateOrEditTodoItemOverlay
          open={todoOverlayOpen}
          onOk={handleSubmitTodoLocal}
          onCancel={() => {
            onCancelTodo();
          }}
          initialValues={todoInitialFormValues}
          confirmLoading={todoConfirmLoading}
          isEditMode={!!todoMergeValues?.id}
          formMergeValues={todoMergeValues}
          ecObject={ecObjectActedUpon}
        />
      ) : (
        <></>
      )}

      {includeClippingOverlay ? (
        <CreateOrEditClippingOverlay
          open={clippingOverlayOpen}
          initialValues={clippingInitialFormValues}
          formMergeValues={clippingMergeValues}
          onOk={handleSubmitClippingLocal}
          onCancel={() => {
            onCancelClipping();
          }}
          confirmLoading={clippingConfirmLoading}
          isEditMode={!!clippingMergeValues?.id}
          ecObject={ecObjectActedUpon}
        />
      ) : (
        <></>
      )}

      {includeBoardOverlay ? (
        <CreateOrEditBoardOverlay
          open={boardOverlayOpen}
          initialValues={boardInitialFormValues}
          formMergeValues={boardMergeValues}
          onOk={handleSubmitBoardLocal}
          onCancel={() => {
            onCancelBoard();
          }}
          confirmLoading={boardConfirmLoading}
          isEditMode={!!boardMergeValues?.id}
        />
      ) : (
        <></>
      )}

      {includeTileOverlay ? (
        !!tileMergeValues?.id ? (
          <EditTileOverlay
            open={tileOverlayOpen}
            initialValues={tileInitialFormValues}
            formMergeValues={tileMergeValues}
            onOk={(values) => {
              onSubmitTile();
              handleUpdateTile(values);
            }}
            onCancel={() => {
              onCancelTile();
            }}
            confirmLoading={tileConfirmLoading}
            ecObject={ecObjectActedUpon}
          />
        ) : (
          <CreateTileWithBoardAssociationFormOverlay
            open={tileOverlayOpen}
            initialValues={tileInitialFormValues}
            formMergeValues={tileMergeValues}
            onOk={(values) => {
              console.log(values);
              onSubmitTile();
              handleCreateTile(values);
            }}
            onCancel={() => {
              onCancelTile();
            }}
            isEditMode={false}
            confirmLoading={tileConfirmLoading}
            ecObject={ecObjectActedUpon}
          />
        )
      ) : (
        <></>
      )}

      {includeUseImageForBoardCoverOverlay ? (
        <UseImageAsBoardCoverOverlay
          open={useImageForBoardCoverOverlayOpen}
          initialValues={useImageForBoardCoverInitialFormValues}
          formMergeValues={useImageForBoardCoverMergeValues}
          onOk={handleSubmitUseImageForBoardCoverLocal}
          onCancel={() => {
            onCancelUseImageForBoardCover();
          }}
          confirmLoading={useImageForBoardCoverConfirmLoading}
          ecObject={ecObjectActedUpon}
        />
      ) : (
        <></>
      )}

      {includeCommentOverlay ? (
        <CreateOrEditCommentModal
          open={commentOverlayOpen}
          formFieldValues={commentInitialFormValues}
          formMergeValues={commentMergeValues}
          onFinish={handleSubmitCommentLocal}
          onCancel={() => {
            onCancelComment();
          }}
          confirmLoading={commentConfirmLoading}
          isEditMode={!!commentMergeValues?.id}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default OrchestrateObjectFormOverlays;
