import React from "react";
import ArtifactCard from "../../common/generic/artifactCard";
import { Tooltip } from "antd";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import TopicCardCover from "../cardCovers/topicCardCover";

const TopicCard = ({
  ecObject,
  cardProperties,
  onClickViewEcObjectDetails,
  sourceMessageInfo,
  actionMenu,
}) => {
  const contentRow = (
    <>
      <div></div>
      <div>{actionMenu}</div>
    </>
  );

  return (
    <ArtifactCard
      cover={<TopicCardCover ecObject={ecObject} />}
      position1={sourceMessageInfo}
      position2={
        <Tooltip title={"Topic"}>
          <TagOutlinedIcon sx={{ color: "#ff4081" }} />
        </Tooltip>
      }
      position3={contentRow}
    />
  );
};

export default TopicCard;
