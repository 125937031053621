import React, { useEffect, useState } from "react";
import useGetMessageContent from "../../../../hooks/emailCollector/messages/useGetMessageContent";
import { useParams } from "react-router-dom";
import useListMessageArtifacts from "../../../../hooks/emailCollector/messages/useListMessageArtifacts";
import { Badge, Button, Card, Collapse, Divider, List, Space, Tag } from "antd";
import useListMessages from "../../../../hooks/emailCollector/messages/useListMessages";
import useListWorkingRequests from "../../commonDebug/hooks/useListWorkingRequests";
import useListArchivedWorkingRequests from "../../commonDebug/hooks/useListArchivedWorkingRequests";
import useBatchGetUrlRequests from "../../../../hooks/urlCentral/useBatchGetUrlRequests";
import useGetMessageObjectStoreArtifacts from "./hooks/useGetMessageObjectStoreArtifacts";
import WorkingRequestListItem from "../../inbox/debug/workingRequestListItem";
import RequestHistoryModal from "../../inbox/debug/requestHistoryModal";
import WorkingRequestCaptureModal from "../../inbox/debug/workingRequestCaptureModal";
import dayjs from "dayjs";
import { DISPLAY_DATETIME_WITH_SECONDS_FORMAT_LONG } from "../../../../utils/dayJsUtils";
import useListMessageTldrEvent from "../hooks/useListMessageTldrEvents";
import useListMessageTldrTopic from "../hooks/useListMessageTldrTopics";
import ImageTextModal from "./imageTextModal";
import useSearchParamPagination from "../../../../hooks/utils/useSearchParamPagination";
import useListJoinableTasksForMessage from "./hooks/useListJoinableTasksForMessage";

const { Panel } = Collapse;

const PAGE_SIZE = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);

const MessageDebugView = () => {
  const { uniqueMessageId } = useParams();
  const { data: messageContent } = useGetMessageContent(uniqueMessageId, false);
  const { data: messageArtifacts } = useListMessageArtifacts(
    uniqueMessageId,
    true,
  );

  const { data: joinableTasks } =
    useListJoinableTasksForMessage(uniqueMessageId);

  const { data: messageObjectStoreArtifacts } =
    useGetMessageObjectStoreArtifacts(uniqueMessageId);

  const { currentPage, orderBy, getSearchParam } = useSearchParamPagination({
    order: "message_date",
  });

  const archived =
    getSearchParam("archived") === "true" || getSearchParam("archived") === "1";

  const { data: messages } = useListMessages(
    archived,
    PAGE_SIZE,
    (currentPage - 1) * PAGE_SIZE,
    orderBy,
  );

  const [requestIds, setRequestIds] = useState([]);
  const { data: urlCentralRequests } = useBatchGetUrlRequests(
    requestIds,
    requestIds.length > 0,
    false,
  );

  const [currentMessage, setCurrentMessage] = useState(undefined);
  const { data: workingRequests } = useListWorkingRequests(uniqueMessageId);

  const [
    archivedWorkingRequestsSucceeded,
    setArchivedWorkingRequestsSucceeded,
  ] = useState([]);
  const [archivedWorkingRequestsFailed, setArchivedWorkingRequestsFailed] =
    useState([]);

  const { data: archivedWorkingRequests } =
    useListArchivedWorkingRequests(uniqueMessageId);
  const [requestHistoryModalOpen, setRequestHistoryModalOpen] = useState(false);
  const [requestHistoryModalRequestUrl, setRequestHistoryModalRequestUrl] =
    useState(undefined);

  const [workingRequestCaptureModalOpen, setWorkingRequestCaptureModalOpen] =
    useState(false);
  const [workingRequestCaptureModalData, setWorkingRequestCaptureModalData] =
    useState(undefined);

  const { data: messageTldrEvents } = useListMessageTldrEvent(
    uniqueMessageId,
    false,
  );

  const { data: messageTldrTopics } = useListMessageTldrTopic(
    uniqueMessageId,
    false,
  );

  const [textImageModalOpen, setTextImageModalOpen] = useState(false);
  const [fingerprintForTextImageModal, setFingerprintForTextImageModal] =
    useState(undefined);

  useEffect(() => {
    if (archivedWorkingRequests) {
      setArchivedWorkingRequestsSucceeded(
        archivedWorkingRequests.filter((item) => item.status !== "failed"),
      );
      setArchivedWorkingRequestsFailed(
        archivedWorkingRequests.filter((item) => item.status === "failed"),
      );
    }
  }, [archivedWorkingRequests]);

  useEffect(() => {
    const requestIds = new Set();
    if (messageArtifacts) {
      for (const i in messageArtifacts.links) {
        requestIds.add(messageArtifacts.links[i].request_id);
      }
      for (const i in messageArtifacts.images) {
        requestIds.add(messageArtifacts.images[i].request_id);
      }
      for (const i in messageArtifacts.link_images) {
        requestIds.add(messageArtifacts.link_images[i].image_request_id);
        requestIds.add(messageArtifacts.link_images[i].link_request_id);
      }
    }
    setRequestIds([...requestIds]);
  }, [messageArtifacts]);

  useEffect(() => {
    if (messages) {
      for (const i in messages) {
        const uniqueMessageIdLocal = `${messages[i].tenant_id}-${messages[i].unique_tenancy_message_id}`;
        if (uniqueMessageIdLocal === uniqueMessageId) {
          setCurrentMessage(messages[i]);
        }
      }
    }
  }, [uniqueMessageId, messages]);

  const getArtifactPanelHeaderWithBadge = (name, artifactList) => {
    const numArtifacts = artifactList ? (
      <Badge count={artifactList.length} showZero={true} />
    ) : (
      <>(Loading)...</>
    );
    return (
      <>
        {name} {numArtifacts}
      </>
    );
  };

  const renderPanel = (name, artifactList, renderItem) => {
    return (
      <Panel
        header={getArtifactPanelHeaderWithBadge(name, artifactList)}
        key={name.toLowerCase()}
      >
        <List
          itemLayout="horizontal"
          dataSource={artifactList || []}
          renderItem={(item) => renderItem(item)}
        />
      </Panel>
    );
  };

  const renderMessageTextItems = (messageTextArray) => {
    const items = [];
    for (const i in messageTextArray) {
      const displayHeading = messageTextArray[i].display_heading;
      const textLines = messageTextArray[i].text_lines;
      items.push(
        <div key={i}>
          <h2>{displayHeading}</h2>
          {textLines.map((textLine, index) => {
            return (
              <div key={index}>
                {textLine.tlec_index} = {textLine.text}
              </div>
            );
          })}
        </div>,
      );
    }
    return items;
  };

  const getMessageStatusAsTag = () => {
    return currentMessage ? (
      <Tag
        style={{ marginLeft: 5 }}
        color={currentMessage.status === "complete" ? "success" : undefined}
      >
        {currentMessage.status}
      </Tag>
    ) : (
      <></>
    );
  };

  const getContentUrl = (requestId) => {
    if (urlCentralRequests) {
      const urlCentralRequest = urlCentralRequests[requestId] || {};
      return urlCentralRequest.content_url || "";
    }
  };

  const getContentLink = (requestId) => {
    const contentUrl = getContentUrl(requestId);
    if (contentUrl) {
      return (
        <a target="_blank" rel="noreferrer" href={contentUrl}>
          Content
        </a>
      );
    } else {
      return <></>;
    }
  };

  const getContentImage = (requestId) => {
    const contentUrl = getContentUrl(requestId);
    if (contentUrl) {
      return (
        <a target="_blank" rel="noreferrer" href={contentUrl}>
          <img alt="" style={{ maxHeight: 50 }} src={contentUrl} />
        </a>
      );
    } else {
      return <></>;
    }
  };

  const getStatusCodeTag = (statusCode) => {
    return (
      <Tag color={statusCode === 200 ? "success" : "error"}>{statusCode}</Tag>
    );
  };

  const renderImageItem = (item) => {
    return (
      <List.Item key={`${item.unique_message_id}-${item.object_sort_key}`}>
        <List.Item.Meta
          avatar={getStatusCodeTag(item.final_status_code)}
          title={
            <a target="_blank" rel="noreferrer" href={item.final_url}>
              {item.object_sort_key} {item.final_url}
            </a>
          }
          description={
            <>
              {getContentImage(item.request_id)}
              <br />
              <a target="_blank" rel="noreferrer" href={item.initial_url}>
                {item.initial_url}
              </a>
            </>
          }
        />
        <a target="_blank" href={item.signed_distill_url} rel="noreferrer">
          distill
        </a>
        <Button
          onClick={() => {
            setFingerprintForTextImageModal(item.fingerprint);
            setTextImageModalOpen(true);
          }}
        >
          Text
        </Button>
      </List.Item>
    );
  };

  const renderLinkItem = (item) => {
    return (
      <List.Item key={`${item.unique_message_id}-${item.object_sort_key}`}>
        <List.Item.Meta
          avatar={getStatusCodeTag(item.final_status_code)}
          title={
            <a target="_blank" rel="noreferrer" href={item.final_url}>
              {item.final_url}
            </a>
          }
          description={getContentLink(item.request_id)}
        />
      </List.Item>
    );
  };

  const renderLinkImageItem = (item) => {
    console.log(item);
    return (
      <Card
        key={`${item.unique_message_id}-${item.object_sort_key}`}
        style={{ marginBottom: 20 }}
      >
        <List.Item
          key={`${item.unique_message_id}-${item.object_sort_key}-link`}
        >
          <List.Item.Meta
            avatar={
              <>
                {getStatusCodeTag(item.link_final_status_code)}
                <br />
                <Tag>{item.tlec_index.join(" / ")}</Tag>
              </>
            }
            title={
              <>
                Link:{" "}
                <a target="_blank" rel="noreferrer" href={item.link_final_url}>
                  {item.link_final_url}
                </a>
              </>
            }
            description={getContentLink(item.link_request_id)}
          />
        </List.Item>
        <List.Item
          key={`${item.unique_message_id}-${item.object_sort_key}-image`}
        >
          <List.Item.Meta
            avatar={getStatusCodeTag(item.image_final_status_code)}
            title={
              <>
                Image:{" "}
                <a target="_blank" rel="noreferrer" href={item.image_final_url}>
                  {item.image_final_url}
                </a>
              </>
            }
            description={getContentImage(item.image_request_id)}
          />
          <Button
            onClick={() => {
              setFingerprintForTextImageModal(item.fingerprint);
              setTextImageModalOpen(true);
            }}
          >
            Text
          </Button>
        </List.Item>
      </Card>
    );
  };

  const renderAttachmentItem = (item) => {
    return (
      <List.Item key={`${item.unique_message_id}-${item.object_sort_key}`}>
        <List.Item.Meta title={item.filename} />
        <br />
        {item.mime_type.startsWith("image/") ? (
          <Space>
            <img src={item.signed_url} alt="" style={{ maxHeight: 50 }} />
            <>
              {item?.image_size?.[0]} x {item?.image_size?.[1]}
            </>
            <Button
              onClick={() => {
                setFingerprintForTextImageModal(item.fingerprint);
                setTextImageModalOpen(true);
              }}
            >
              Text
            </Button>
          </Space>
        ) : (
          <></>
        )}
      </List.Item>
    );
  };

  const handleOpenModal = (requestUrl) => {
    setRequestHistoryModalOpen(true);
    setRequestHistoryModalRequestUrl(requestUrl);
  };

  const renderContactItem = (item) => {
    return (
      <List.Item key={`${item.unique_message_id}-${item.object_sort_key}`}>
        <List.Item.Meta
          title={item.email}
          description={item.display_names.join(",")}
        />
      </List.Item>
    );
  };

  const renderCurrentMessageObject = () => {
    return currentMessage ? (
      <>
        <pre>
          <code>{JSON.stringify(currentMessage, undefined, 2)}</code>
        </pre>
      </>
    ) : (
      <></>
    );
  };

  const renderWorkingRequestsPanel = () => {
    return (
      <Panel
        header={getArtifactPanelHeaderWithBadge(
          "In-process Working Requests",
          workingRequests,
        )}
        key="working-requests2"
      >
        <List
          itemLayout="horizontal"
          dataSource={workingRequests || []}
          renderItem={(item) => {
            return (
              <WorkingRequestListItem
                workingRequest={item}
                onClickViewRequestHistory={() =>
                  handleOpenModal(item.request_url)
                }
                onclickViewCaptureData={() => {
                  setWorkingRequestCaptureModalOpen(true);
                  setWorkingRequestCaptureModalData(item.capture);
                }}
              />
            );
          }}
        />
      </Panel>
    );
  };

  const renderArchivedWorkingRequestsPanel = () => {
    return (
      <Panel
        header={getArtifactPanelHeaderWithBadge(
          "Successful Archived Working Requests",
          archivedWorkingRequestsSucceeded,
        )}
        key="archivedWorkingRequestsSucceeded"
      >
        <List
          itemLayout="horizontal"
          dataSource={archivedWorkingRequestsSucceeded}
          renderItem={(item) => {
            return (
              <WorkingRequestListItem
                workingRequest={item}
                onClickViewRequestHistory={() =>
                  handleOpenModal(item.request_url)
                }
                onclickViewCaptureData={() => {
                  setWorkingRequestCaptureModalOpen(true);
                  setWorkingRequestCaptureModalData(item.capture);
                }}
              />
            );
          }}
        />
      </Panel>
    );
  };

  const renderFailedWorkingRequestsPanel = () => {
    return (
      <Panel
        header={getArtifactPanelHeaderWithBadge(
          "Failed Archived Working Requests",
          archivedWorkingRequestsFailed,
        )}
        key="archivedWorkingRequestsFailed"
      >
        <List
          itemLayout="horizontal"
          dataSource={archivedWorkingRequestsFailed}
          renderItem={(item) => {
            return (
              <WorkingRequestListItem
                workingRequest={item}
                onClickViewRequestHistory={() =>
                  handleOpenModal(item.request_url)
                }
                onclickViewCaptureData={() => {
                  setWorkingRequestCaptureModalOpen(true);
                  setWorkingRequestCaptureModalData(item.capture);
                }}
              />
            );
          }}
        />
      </Panel>
    );
  };

  const renderObjectStoreArtifactsPanel = () => {
    const links = [];
    for (const key in messageObjectStoreArtifacts) {
      links.push(<a href={messageObjectStoreArtifacts[key]}>{key}</a>);
    }
    return (
      <Panel header="Object Store Artifacts" key="objectStoreArtifacts">
        {links.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </Panel>
    );
  };

  const renderJoinableTasksPanel = () => {
    return (
      <Panel header="Joinable Tasks" key="joinableTasks">
        {joinableTasks?.map((item, index) => {
          let color = "red";
          if (item.status === "completed") {
            color = "green";
          }
          return (
            <p key={index}>
              {dayjs(item.modified_ts).format(
                DISPLAY_DATETIME_WITH_SECONDS_FORMAT_LONG,
              )}{" "}
              - <span style={{ color: color }}>{item.task_id}</span>{" "}
              {item.note ? `- ${item.note}` : ""}
            </p>
          );
        })}
      </Panel>
    );
  };

  return (
    <>
      <RequestHistoryModal
        open={requestHistoryModalOpen}
        onCancel={() => {
          setRequestHistoryModalOpen(false);
        }}
        requestUrl={requestHistoryModalRequestUrl}
      />
      <WorkingRequestCaptureModal
        open={workingRequestCaptureModalOpen}
        onCancel={() => {
          setWorkingRequestCaptureModalOpen(false);
        }}
        captureData={workingRequestCaptureModalData}
      />
      <ImageTextModal
        fingerprint={fingerprintForTextImageModal}
        open={textImageModalOpen}
        onClose={() => setTextImageModalOpen(false)}
      />
      <Collapse>
        <Panel
          header={<>Message Object{getMessageStatusAsTag()}</>}
          key="messageObject"
        >
          {renderCurrentMessageObject()}
        </Panel>
        {renderJoinableTasksPanel()}
        {renderPanel(
          "Attachments",
          messageArtifacts?.attachments,
          renderAttachmentItem,
        )}
        {renderPanel(
          "Email Address List",
          messageArtifacts?.email_addresses,
          renderContactItem,
        )}
        {renderPanel("Images", messageArtifacts?.images, renderImageItem)}
        {renderPanel("Links", messageArtifacts?.links, renderLinkItem)}
        {renderPanel(
          "Links with Images",
          messageArtifacts?.link_images,
          renderLinkImageItem,
        )}
        <Panel header="Indexed HTML" key="indexedHtml">
          <pre>
            <code>{messageContent?.body_indexed || ""}</code>
          </pre>
        </Panel>
        <Panel header="Indexed Text" key="indexedText">
          {messageContent && messageContent?.text_by_headings ? (
            <List>
              {renderMessageTextItems(messageContent.text_by_headings)}
            </List>
          ) : (
            <></>
          )}
        </Panel>
        <Panel header="TLDR Events" key="tldrEvents">
          {messageTldrEvents?.items?.map((item) => {
            return (
              <>
                <h4>{item.title}</h4>
                <br />
                <pre>
                  <code>{JSON.stringify(item, null, 2)}</code>
                </pre>
                <Divider />
              </>
            );
          })}
        </Panel>
        <Panel header="TLDR Topics" key="tldrTopics">
          {messageTldrTopics?.items?.map((item) => {
            return (
              <>
                <h4>{item.title}</h4>
                <br />
                <pre>
                  <code>{JSON.stringify(item, null, 2)}</code>
                </pre>
                <Divider />
              </>
            );
          })}
        </Panel>
        {renderWorkingRequestsPanel()}
        {renderArchivedWorkingRequestsPanel()}
        {renderFailedWorkingRequestsPanel()}
        {renderObjectStoreArtifactsPanel()}
      </Collapse>
    </>
  );
};

export default MessageDebugView;
