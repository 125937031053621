import React from "react";
import { Button, Col, Layout, Row, Typography } from "antd";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

const { Header, Content } = Layout;

const FullScreenOverlay = ({ title, open, onClose, children }) => {
  const getLeft = () => {
    return (
      <Row wrap={false} align={"middle"} gutter={8}>
        <Col>
          <Button
            type={"primary"}
            ghost={true}
            style={{
              borderRadius: 6,
              height: 30,
              width: 30,
              marginTop: -15,
            }}
            icon={<ArrowBackOutlinedIcon sx={{ fontSize: 20 }} />}
            onClick={() => {
              onClose();
            }}
          />
        </Col>
        <Col flex={"auto"}>
          <Typography.Title ellipsis={true} level={2}>
            {title || <>&nbsp;</>}
          </Typography.Title>
        </Col>
      </Row>
    );
  };

  const getRight = () => {
    return <></>;
  };

  const getContent = () => {
    return <>{children}</>;
  };

  const getJsx = () => {
    if (open) {
      return (
        <Layout
          style={{
            height: "100%",
            width: "100%",
            position: "fixed",
            zIndex: 998,
            left: 0,
            top: 0,
            backgroundColor: "rgba(255, 255, 255)",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <Header
            style={{
              position: "sticky",
              top: 0,
              zIndex: 998,
              padding: 0,
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderBottom: "2px solid #f0f0f0",
              backdropFilter: "blur(1px)",
              height: "auto",
              marginBottom: 20,
              lineHeight: 1.5,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                height: 75,
              }}
            >
              <div
                style={{
                  maxWidth: 1400,
                  minWidth: 300,
                  width: "100%",
                  paddingTop: 22,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <div style={{ height: 35 }}>{getLeft()}</div>
                <div>{getRight()}</div>
              </div>
            </div>
          </Header>
          <Layout
            style={{
              backgroundColor: "rgba(255, 255, 255)",
            }}
          >
            <Content>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div
                  style={{
                    maxWidth: 1400,
                    minWidth: 300,
                    width: "100%",
                    padding: 10,
                  }}
                >
                  {getContent()}
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      );
    }
  };
  return getJsx();
};

export default FullScreenOverlay;
