import useQueryGenericGet from "../framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../serviceUrls/baseUrls";
import useOrchestrateServiceUrlHelper from "./useOrchestrateServiceUrlHelper";

const useGetOrchestrateObject = (endpointTemplateUrl, objectId) => {
  const { endpointPath } = useOrchestrateServiceUrlHelper(endpointTemplateUrl, {
    objectId: objectId,
  });

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    endpointPath,
    true,
    [],
    {},
  );
};

export default useGetOrchestrateObject;
