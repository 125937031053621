import React from "react";
import {
  ALL_CLASSES_BY_CLASS_ID,
  getOnClickAction,
} from "../actionMappers/classesToActions";
import { Button } from "antd";

const ObjectCardActionsButtons = ({
  ecObject,
  appContext,
  onClick,
  savedObject,
  cardProperties,
}) => {
  const getOrderedActionsForObjectClassAndAppContext = () => {
    if (ecObject) {
      const clazz = ALL_CLASSES_BY_CLASS_ID[ecObject.class_id];
      const classActions = clazz.getActions();
      const appContextActions = appContext.getActions();
      const response = [];
      for (const i in classActions) {
        const classAction = classActions[i];
        if (appContextActions.includes(classAction)) {
          response.push(classAction);
        }
      }
      response.sort((a, b) => (a.order > b.order ? 1 : -1));
      return response;
    } else {
      return [];
    }
  };

  const getJsx = () => {
    const relevantActions = getOrderedActionsForObjectClassAndAppContext();
    return relevantActions.map((action, index) => {
      const Icon = action.icon;
      return (
        <div key={index}>
          <Button
            type={"link"}
            icon={<Icon />}
            onClick={(e) => {
              const clickHandler = getOnClickAction(
                onClick,
                ecObject,
                savedObject,
                appContext,
                cardProperties,
                action,
              );
              clickHandler();
            }}
          >
            {action.tooltipTitle}
          </Button>
        </div>
      );
    });
  };

  return getJsx();
};

export default ObjectCardActionsButtons;
