import React from "react";
import EmailAddress from "./emailAddress";
import EmailAddresses from "../emailAddresses";
import { timestampToFullDatetimeString } from "../../../utils/dayJsUtils";

const MessageMetadata = ({ messageObject }) => {
  return (
    <>
      <div>
        From:{" "}
        <EmailAddress
          label={messageObject.from_address[1]}
          email={messageObject.from_address[0]}
          useString={true}
        />
      </div>
      <div>
        To: <EmailAddresses addresses={messageObject.to_addresses} />
      </div>
      {messageObject.cc_addresses.length > 0 && (
        <div>
          Cc: <EmailAddresses addresses={messageObject.cc_addresses} />
        </div>
      )}
      {messageObject.bcc_addresses.length > 0 && (
        <div>
          Bcc: <EmailAddresses addresses={messageObject.bcc_addresses} />
        </div>
      )}

      <div>Date: {timestampToFullDatetimeString(messageObject.timestamp)}</div>
    </>
  );
};

export default MessageMetadata;
