import { useEffect, useRef, useState } from "react";

const useObserveElementSize = () => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const observedElementRef = useRef(null);

  useEffect(() => {
    if (!observedElementRef.current) {
      return;
    }

    function throttle(f, delay) {
      let timer = 0;
      return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => f.apply(this, args), delay);
      };
    }

    const resizeObserver = new ResizeObserver(
      throttle(() => {
        if (
          observedElementRef?.current?.offsetWidth &&
          observedElementRef?.current?.offsetWidth !== width
        ) {
          setWidth(observedElementRef.current.offsetWidth);
        }
        if (
          observedElementRef?.current?.offsetHeight &&
          observedElementRef?.current?.offsetHeight !== height
        ) {
          setHeight(observedElementRef.current.offsetHeight);
        }
      }, 300),
    );
    resizeObserver.observe(observedElementRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [observedElementRef, height, width]);

  return { observedElementRef, width, height };
};

export default useObserveElementSize;
