import React from "react";
import { AppContextFilesPlusMessage } from "../../../../ecObjectViewers/actionMappers/appContextsToActions";
import EcObjectCardsContainer from "../../../../ecObjectViewers/ecObjectCardsContainer";
import { Skeleton } from "antd";

const MessageSummaryLinksView = ({ messageArtifacts }) => {
  const getIntersection = (arr1, arr2) => {
    return arr2.filter((n) => {
      return arr1.indexOf(n) !== -1;
    });
  };

  const getCleanedItems = (items) => {
    const linkTypesToShow = [
      "tldr",
      "amazon",
      "apple_app_store",
      "google_photos",
      "google_play",
    ];
    const usedFingerprints = new Set();
    return items.filter((item) => {
      const isUsed = usedFingerprints.has(item.fingerprint);
      if (isUsed) {
        return false;
      } else {
        usedFingerprints.add(item.fingerprint);
        return getIntersection(item.native_tags, linkTypesToShow).length > 0;
      }
    });
  };

  const getJsxForLinks = (links, link_images) => {
    const items = [...links, ...link_images];
    const cleanedItems = getCleanedItems(items);
    if (!cleanedItems || cleanedItems.length === 0) {
      return <></>;
    }

    return (
      <div style={{ marginBottom: 20 }}>
        <h2>Key Links</h2>
        <EcObjectCardsContainer
          ecObjects={[...cleanedItems]}
          appContext={AppContextFilesPlusMessage}
          cardProperties={{
            link_image: {
              showLink: true,
              showImage: false,
            },
          }}
          showClickDetailsTag={true}
          scrollToFirstHighlight={true}
        />
      </div>
    );
  };

  const getJsx = () => {
    if (!messageArtifacts) {
      return <Skeleton active={true} />;
    }

    return getJsxForLinks(messageArtifacts.links, messageArtifacts.link_images);
  };

  return getJsx();
};

export default MessageSummaryLinksView;
