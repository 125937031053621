import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../serviceUrls/baseUrls";
import useQueryGenericGetWithPost from "../framework/useQueryGenericGetWithPost";
import { createBatchGetPostData } from "./createBatchGetPostData";
import { EC_SERVICE_BATCH_GET_ARTIFACTS } from "../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "./useEmailCollectorServiceUrlHelper";

const useBatchGetArtifacts = (items) => {
  let postData = undefined;
  if (items && items.length > 0) {
    postData = createBatchGetPostData(items);
  }

  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_BATCH_GET_ARTIFACTS,
    {},
  );

  return useQueryGenericGetWithPost(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    postData,
    true,
    !!(currentFamily && postData),
  );
};

export default useBatchGetArtifacts;
