import { useEffect, useState } from "react";
import useGetSignedUrlForOriginalUrl from "./useGetSignedUrlForOriginalUrl";

const useSignedUrlAsImageBackupUrl = (imageSrc) => {
  const [enableBackupUrl, setEnableBackupUrl] = useState(false);
  const signedUrl = useGetSignedUrlForOriginalUrl(imageSrc, enableBackupUrl);

  useEffect(() => {
    if (imageSrc) {
      const imageLoadCallback = () => {};
      const imageErrorCallback = (e) => {
        console.log(
          `Error loading ${imageSrc}. Going to try to use local copy as backup.`,
        );
        setEnableBackupUrl(true);
      };

      const img = new Image();
      img.onload = imageLoadCallback;
      img.onerror = imageErrorCallback;
      img.src = imageSrc;

      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }
  }, [imageSrc]);

  return signedUrl ? signedUrl : imageSrc;
};

export default useSignedUrlAsImageBackupUrl;
