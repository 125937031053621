import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import useListOrchestrateObjects from "../../hooks/orchestrate/useListOrchestrateObjects";
import { O_SERVICE_FAMILY_MEMBERS } from "../../serviceUrls/serviceUrlsOrchestrate";

const FamilyMemberAvatarIcon = ({ userId }) => {
  const [selectedUser, setSelectedUser] = useState(undefined);
  const { data: currentUser, isLoading: isTldrPageContextLoading } =
    useTldrPageContext();
  const { data: familyMembers, isLoading: areFamilyMembersLoading } =
    useListOrchestrateObjects(O_SERVICE_FAMILY_MEMBERS, true);

  useEffect(() => {
    if (!isTldrPageContextLoading && !areFamilyMembersLoading) {
      if (!userId) {
        setSelectedUser(null);
      } else {
        for (const i in familyMembers) {
          const memberId = familyMembers[i].member.id;
          if (memberId === parseInt(userId)) {
            setSelectedUser(familyMembers[i].member);
          }
        }
      }
    }
  }, [
    currentUser,
    isTldrPageContextLoading,
    familyMembers,
    areFamilyMembersLoading,
    userId,
  ]);

  return (
    <>
      {selectedUser ? (
        <Avatar
          size="small"
          src={selectedUser.picture}
          alt={selectedUser.name}
        />
      ) : (
        <Avatar size="small" icon={<UserOutlined />} />
      )}
    </>
  );
};

export default FamilyMemberAvatarIcon;
