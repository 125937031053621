import useTldrPageContext from "./common/useTldrPageContext";
import { useNavigate } from "react-router-dom";
import useDeleteOrchestrateObject from "./useDeleteOrchestrateObject";

const useDeleteOrchestrateObjectWithNotifications = (
  serviceEndpoint,
  queryKey,
  classId,
  classPathPart,
  invalidateQueryString,
  objectTypeForDisplay,
  objectTypeForDisplayPlural,
) => {
  const {
    familyId,
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyContains,
    onSubmit,
    onSuccess,
    onError,
  } = useTldrPageContext();

  const navigate = useNavigate();

  const objectTypeForDisplayLower = objectTypeForDisplay.toLowerCase();
  const objectTypeForDisplayPluralLower =
    objectTypeForDisplayPlural.toLowerCase();

  const { mutate: deleteOrchestrateObject } = useDeleteOrchestrateObject(
    serviceEndpoint,
    queryKey,
    (submittedItem) => {
      if (onSubmit) {
        onSubmit(submittedItem);
      }
      openLoadingMessage(
        `delete-${classId}`,
        `Deleting ${objectTypeForDisplayLower}...`,
      );
    },
    (savedItem) => {
      if (onSuccess) {
        onSuccess(savedItem);
      }
      openSuccessMessage(
        `delete-${classId}`,
        `${objectTypeForDisplay} deleted`,
        <a
          onClick={(e) => {
            e.preventDefault();
            navigate(`/families/${familyId}/${classPathPart}`);
          }}
        >
          Go to {objectTypeForDisplayPluralLower}
        </a>,
      );
      invalidateQueryWhereKeyContains(invalidateQueryString);
    },
    (error, unsavedItem) => {
      if (onError) {
        onError(error, unsavedItem);
      }
      openErrorMessage(
        `delete-${classId}`,
        `Error deleting ${objectTypeForDisplayLower}`,
      );
      invalidateQueryWhereKeyContains(invalidateQueryString);
    },
  );

  return { deleteOrchestrateObject };
};

export default useDeleteOrchestrateObjectWithNotifications;
