import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import useGetCurrentUser from "../../../hooks/orchestrate/user/useGetCurrentUser";

const FamilySelector = ({ selectedFamilyId, forceShow }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [families, setFamilies] = useState([]);
  const { data: currentUser, isLoading: isCurrentUserLoading } =
    useGetCurrentUser();

  useEffect(() => {
    const doesCurrentUserHaveAtLeastOneFamily = (currentUser) => {
      if (!currentUser) return;
      return currentUser.families.length;
    };

    if (!isCurrentUserLoading) {
      if (doesCurrentUserHaveAtLeastOneFamily(currentUser)) {
        setFamilies([...currentUser.families]);
      }
    }
  }, [isCurrentUserLoading, currentUser]);

  const rewritePathForFamilyId = (currentPath, newFamilyId) => {
    const splitPath = currentPath.split("/");
    if (
      splitPath.length >= 3 &&
      splitPath[1] === "families" &&
      splitPath.length <= 5
    ) {
      splitPath[2] = newFamilyId;
      return splitPath.join("/");
    } else {
      return splitPath.join(`/families/${newFamilyId}`);
    }
  };

  const handleChange = (value) => {
    const path = location.pathname;
    const newPath = rewritePathForFamilyId(path, value);
    navigate(newPath);
  };

  const getSelector = () => {
    if (families.length > 1 || (families.length === 1 && forceShow)) {
      const options = families.map((family) => {
        return { value: `${family.id}`, label: family.name };
      });
      return (
        <Select
          value={selectedFamilyId}
          style={{ width: 240 }}
          onChange={handleChange}
          options={options}
        />
      );
    }
    return <></>;
  };

  return <>{getSelector()}</>;
};

export default FamilySelector;
