import React from "react";
import { Typography } from "antd";
import FormattedDateTimeRange from "../../common/generic/formattedDateTimeRange";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import IconAndText from "../../common/generic/iconAndText";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CardCenteredTextCover from "../../common/generic/cardCenteredTextCover";

const EventCardCover = ({ ecObject }) => {
  const { currentFamily } = useTldrPageContext();

  const getCover = () => {
    return (
      <CardCenteredTextCover>
        <Typography.Title level={3} ellipsis={true}>
          <span style={{ fontSize: 25 }}>{ecObject.emoji}</span>{" "}
          {ecObject.title}
        </Typography.Title>
        {ecObject.description && (
          <Typography.Paragraph ellipsis={{ rows: 4 }}>
            {ecObject.description}
          </Typography.Paragraph>
        )}
        <IconAndText
          icon={<EventOutlinedIcon sx={{ fontSize: 20, color: "grey" }} />}
          text={
            <FormattedDateTimeRange
              startDateAsString={ecObject.start_date}
              startTimeAsString={ecObject.start_time}
              endDateAsString={ecObject.end_date}
              endTimeAsString={ecObject.end_time}
              showDate={true}
              timezoneInterpret={"UTC"}
              timezoneDisplay={currentFamily?.timezone || "UTC"}
            />
          }
        />

        {ecObject.location && (
          <IconAndText
            icon={
              <LocationOnOutlinedIcon sx={{ fontSize: 20, color: "grey" }} />
            }
            text={ecObject.location}
          />
        )}
      </CardCenteredTextCover>
    );
  };

  return getCover();
};

export default EventCardCover;
