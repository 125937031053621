import React from "react";
import { Button, ConfigProvider } from "antd";

const ButtonWithCustomColor = (props) => {
  const { primaryColor, ...newProps } = props;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: `${primaryColor}`,
        },
      }}
    >
      <Button {...newProps} />
    </ConfigProvider>
  );
};

export default ButtonWithCustomColor;
