import React from "react";
import UserEducationModal from "../userEducation/userEducationModal";
import { Modal, Typography } from "antd";
import AnchorNoPropagation from "../common/generic/anchorNoPropagation";
import { useNavigate } from "react-router-dom";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";

const { Text } = Typography;

const BoardsUserEducationModal = ({ forced, open, onClose }) => {
  const title = "Boards 📌";
  const inner = (
    <>
      <p>
        Boards are customizable spaces where you can effortlessly organize and
        manage all types of information in a visual and structured way--just
        like Pinterest boards! Pin anything, from an email you need to keep tabs
        on to specific parts of messages like links, attachments, images,
        topics, or events. With just a couple of clicks, you can ensure all your
        important information is easily accessible and beautifully organized.
      </p>

      <h3>Workflow Tips 🔧</h3>

      <ul>
        <li>
          <Text strong={true}>Create Your First Board:</Text> Kick things off by
          creating a board for a specific purpose, like a school board (e.g.,
          "Woodside Elementary Board").
        </li>
        <li>
          <Text strong={true}>Pin key information you'll need later:</Text>{" "}
          Think about camp packing lists, sports rosters, maps, and forms for
          when your child is absent from school.
        </li>
        <li>
          <Text strong={true}>Collaborate with a Partner:</Text> Boards are
          shared with your family members, making collaboration and information
          sharing a breeze.
        </li>
        <li>
          <Text strong={true}>Personalize Your Boards:</Text> Add a personal
          touch by customizing board covers with images. Simply go to{" "}
          <AnchorNoPropagation
            onClick={() => navigate(`/families/${familyId}/key-links-files`)}
          >
            Link Gallery
          </AnchorNoPropagation>
          , find an image that represents your board, and select "Use image as
          board cover" from the menu.
        </li>
      </ul>
    </>
  );

  const navigate = useNavigate();
  const { familyId } = useTldrPageContext();

  if (!!forced) {
    return (
      <UserEducationModal title={title} subjects={["EXPLAIN_BOARDS"]}>
        {inner}
      </UserEducationModal>
    );
  } else {
    return (
      <Modal
        title={title}
        open={open}
        closable={true}
        onCancel={() => onClose()}
        onOk={null}
        footer={null}
      >
        {inner}
      </Modal>
    );
  }
};

export default BoardsUserEducationModal;
