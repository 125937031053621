import React from "react";
import { Button, Col, Row, Space, Typography } from "antd";
import { getDateForDisplayForISO8601 } from "../../../utils/dayJsUtils";

const ClippingCard = ({ savedObject, onClickEdit, onClickDelete }) => {
  return (
    <Row wrap={false} style={{ width: "100%" }}>
      <Col>
        <div style={{ marginTop: 8, marginBottom: 5 }}>
          <div style={{ marginBottom: 10 }}>
            <Typography.Text strong={true}>Clipped on:</Typography.Text>{" "}
            {getDateForDisplayForISO8601(savedObject?.created)}
          </div>
          <Space>
            {onClickEdit && (
              <Button
                type={"primary"}
                ghost={true}
                onClick={() => onClickEdit()}
              >
                Edit
              </Button>
            )}
            <Button
              type={"primary"}
              ghost={true}
              danger={true}
              onClick={() => onClickDelete()}
            >
              Delete
            </Button>
          </Space>
        </div>
      </Col>
    </Row>
  );
};

export default ClippingCard;
