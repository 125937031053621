import useUpdateOrchestrateObject from "./useUpdateOrchestrateObject";
import useTldrPageContext from "./common/useTldrPageContext";
import { useNavigate } from "react-router-dom";

const useUpdateOrchestrateObjectWithNotifications = (
  serviceEndpoint,
  queryKey,
  classId,
  classPathPart,
  invalidateQueryString,
  objectTypeForDisplay,
  objectTypeForDisplayPlural,
) => {
  const {
    familyId,
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyContains,
    onSubmit,
    onSuccess,
    onError,
  } = useTldrPageContext();

  const navigate = useNavigate();

  const objectTypeForDisplayLower = objectTypeForDisplay.toLowerCase();
  const objectTypeForDisplayPluralLower =
    objectTypeForDisplayPlural.toLowerCase();

  const { mutate: updateOrchestrateObject } = useUpdateOrchestrateObject(
    serviceEndpoint,
    queryKey,
    (submittedItem) => {
      if (onSubmit) {
        onSubmit(submittedItem);
      }
      openLoadingMessage(
        `update-${classId}-${submittedItem.id}`,
        `Updating ${objectTypeForDisplayLower}...`,
      );
    },
    (savedItem) => {
      if (onSuccess) {
        onSuccess(savedItem);
      }
      openSuccessMessage(
        `update-${classId}-${savedItem.id}`,
        `${objectTypeForDisplay} updated`,
        <a
          onClick={(e) => {
            e.preventDefault();
            navigate(`/families/${familyId}/${classPathPart}`);
          }}
        >
          Go to {objectTypeForDisplayPluralLower}
        </a>,
      );
      invalidateQueryWhereKeyContains(invalidateQueryString);
    },
    (error, unsavedItem) => {
      if (onError) {
        onError(error, unsavedItem);
      }
      openErrorMessage(
        `update-${classId}-${unsavedItem.id}`,
        `Error updating ${objectTypeForDisplayLower}`,
      );
      invalidateQueryWhereKeyContains(invalidateQueryString);
    },
  );

  return { updateOrchestrateObject };
};

export default useUpdateOrchestrateObjectWithNotifications;
