import React from "react";
import AnyCard from "./cards/anyCard";
import { Col, Row } from "antd";
import OrchestrateObjectFormOverlays from "../familyOrganizer/orchestrateObjectFormOverlays";
import useCreateOrEditOrchestrateObject from "../../hooks/orchestrate/common/useCreateOrEditOrchestrateObject";
import AnyEcArtifactDetailsOverlay from "./anyEcArtifactDetailsOverlay";
import useArtifactDetailOverlay from "./hooks/useArtifactDetailOverlay";
import SavedObjectDetailsOverlay from "../familyOrganizer/savedObjectDetailsOverlay";

const EcObjectCardsContainer = ({
  ecObjects,
  correspondingSavedObjectsIfAny,
  appContext,
  cardProperties,
  showClickDetailsTag,
  scrollToFirstHighlight,
  onClickUpdateHiddenStatus,
}) => {
  const {
    dataForDetailsOverlay,
    setDataForDetailsOverlay,
    detailsOverlayOpen,
    openArtifactDetailOverlay,
    closeArtifactDetailOverlay,
  } = useArtifactDetailOverlay();

  const {
    dataForDetailsOverlay: dataForSavedObjectDetailsOverlay,
    setDataForDetailsOverlay: setDataForSavedObjectDetailsOverlay,
    detailsOverlayOpen: savedObjectDetailsOverlayOpen,
    openArtifactDetailOverlay: openSavedObjectDetailOverlay,
    closeArtifactDetailOverlay: closeSavedObjectDetailOverlay,
  } = useArtifactDetailOverlay();

  const {
    eventOverlayOpen,
    closeEventOverlay,
    eventFormValues,
    eventMergeValues,
    todoOverlayOpen,
    closeTodoOverlay,
    todoFormValues,
    todoMergeValues,
    clippingOverlayOpen,
    closeClippingOverlay,
    clippingFormValues,
    clippingMergeValues,
    tileOverlayOpen,
    closeTileOverlay,
    tileFormValues,
    tileMergeValues,
    useImageOverlayOpen,
    closeUseImageOverlay,
    useImageFormValues,
    useImageMergeValues,
    onClickCardAction,
    ecObjectActedUpon,
  } = useCreateOrEditOrchestrateObject();

  const getOnClickViewEcObjectDetailsFunction = () => {
    if (!showClickDetailsTag) {
      return null;
    }
    return (o, m) => {
      setDataForDetailsOverlay({
        ecObject: { ...o },
        messages: [...m],
      });
      openArtifactDetailOverlay();
    };
  };

  const getOnClickViewSavedObjectDetailsFunction = () => {
    if (!showClickDetailsTag) {
      return null;
    }
    return (o, m, so) => {
      setDataForSavedObjectDetailsOverlay(so);
      openSavedObjectDetailOverlay();
    };
  };

  const getCards = () => {
    if (!Array.isArray(ecObjects)) {
      return <></>;
    }

    return (
      <Row gutter={[12, 12]}>
        {ecObjects.map((ecObject, index) => {
          const savedObject = correspondingSavedObjectsIfAny?.[index] || null;
          const onClickViewDetails = savedObject
            ? getOnClickViewSavedObjectDetailsFunction()
            : getOnClickViewEcObjectDetailsFunction();

          return (
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6} key={index}>
              <AnyCard
                ecObject={ecObject}
                savedObject={savedObject}
                appContext={appContext}
                onClickAction={onClickCardAction}
                cardProperties={cardProperties}
                onClickViewEcObjectDetails={onClickViewDetails}
                onClickUpdateHiddenStatus={onClickUpdateHiddenStatus}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <>
      <OrchestrateObjectFormOverlays
        eventInitialFormValues={eventFormValues}
        eventMergeValues={eventMergeValues}
        eventOverlayOpen={eventOverlayOpen}
        onCancelEvent={() => closeEventOverlay()}
        onSubmitEvent={() => closeEventOverlay()}
        onPersistEvent={(item) => {}}
        todoInitialFormValues={todoFormValues}
        todoMergeValues={todoMergeValues}
        todoOverlayOpen={todoOverlayOpen}
        onCancelTodo={() => closeTodoOverlay()}
        onSubmitTodo={() => closeTodoOverlay()}
        onPersistTodo={(item) => {}}
        clippingInitialFormValues={clippingFormValues}
        clippingMergeValues={clippingMergeValues}
        clippingOverlayOpen={clippingOverlayOpen}
        onCancelClipping={() => closeClippingOverlay()}
        onSubmitClipping={() => closeClippingOverlay()}
        onPersistClipping={(item) => {}}
        tileInitialFormValues={tileFormValues}
        tileMergeValues={tileMergeValues}
        tileOverlayOpen={tileOverlayOpen}
        onCancelTile={() => closeTileOverlay()}
        onSubmitTile={() => closeTileOverlay()}
        onPersistTile={(item) => {}}
        useImageForBoardCoverInitialFormValues={useImageFormValues}
        useImageForBoardCoverMergeValues={useImageMergeValues}
        useImageForBoardCoverOverlayOpen={useImageOverlayOpen}
        onCancelUseImageForBoardCover={() => closeUseImageOverlay()}
        onSubmitUseImageForBoardCover={() => closeUseImageOverlay()}
        onPersistUseImageForBoardCover={(item) => {}}
        ecObjectActedUpon={ecObjectActedUpon}
      />
      <AnyEcArtifactDetailsOverlay
        open={detailsOverlayOpen}
        onClose={() => closeArtifactDetailOverlay()}
        ecObject={dataForDetailsOverlay?.ecObject}
        scrollToFirstHighlight={scrollToFirstHighlight}
      />
      <SavedObjectDetailsOverlay
        open={savedObjectDetailsOverlayOpen}
        onClose={() => {
          setDataForSavedObjectDetailsOverlay(null);
          closeSavedObjectDetailOverlay();
        }}
        savedObject={dataForSavedObjectDetailsOverlay}
      />
      {getCards()}
    </>
  );
};

export default EcObjectCardsContainer;
