import React from "react";
import { Button, Card, Col, Form, Input, Modal, Row, Skeleton } from "antd";
import Avatar from "antd/es/avatar/avatar";
import { UserAddOutlined } from "@ant-design/icons";
import useSettingsFamilyMembers from "./hooks/useSettingsFamilyMembers";

const SettingsFamilyMembers = () => {
  const {
    modalOpen,
    setModalOpen,
    isLoadingFamilyMembers,
    familyMembers,
    handleOk,
    handleCancel,
  } = useSettingsFamilyMembers();
  const [form] = Form.useForm();

  const getBodyJsx = () => {
    if (isLoadingFamilyMembers) {
      return <Skeleton active />;
    }
    return (
      <Row gutter={[16, 8]}>
        {familyMembers?.map((familyMember) => {
          return (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              xxl={6}
              key={familyMember.id}
            >
              <Card
                cover={
                  <div
                    style={{
                      textAlign: "center",
                      padding: "30px 30px 10px 30px",
                    }}
                  >
                    <Avatar
                      size={125}
                      src={familyMember.member.picture}
                      alt={familyMember.member.name}
                    />
                  </div>
                }
              >
                <Card.Meta
                  title={
                    <div
                      style={{ textAlign: "center" }}
                    >{`${familyMember.member.name} (${familyMember.member_role.name})`}</div>
                  }
                  description={
                    <div style={{ textAlign: "center" }}>
                      {familyMember.member.email}
                    </div>
                  }
                />
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <>
      <Modal
        open={modalOpen}
        title="Invite family member"
        okText="Invite"
        onOk={() => handleOk(form)}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" name="createInvite">
          <Form.Item
            name="emailAddress"
            rules={[
              ({ getFieldValue }) => ({
                message: "Enter a valid email",
                validator(_, value) {
                  if (value === undefined) return Promise.resolve();
                  const emailAddress = value.trim();
                  const emailRegex =
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                  const match = emailAddress.match(emailRegex);
                  if (
                    (match !== null && match[0] === emailAddress) ||
                    emailAddress === ""
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error("Invalid email"));
                  }
                },
              }),
            ]}
          >
            <Input placeholder="helpful.partner" />
          </Form.Item>
        </Form>
      </Modal>
      <Row justify={"space-between"} style={{ paddingTop: 10 }}>
        <Col style={{ paddingBottom: 10 }}>
          <h2>Family Members</h2>
        </Col>
        <Col>
          <Button
            onClick={() => setModalOpen(true)}
            icon={<UserAddOutlined />}
            ghost={true}
            type="primary"
          >
            Invite Family Member
          </Button>
        </Col>
      </Row>
      {getBodyJsx()}
    </>
  );
};

export default SettingsFamilyMembers;
