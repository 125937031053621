import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import useSettingsSubscription from "./billing/hooks/useSettingsSubscription";
import useUpdateFamily from "../../hooks/orchestrate/common/useUpdateFamily";
import AbsoluteSpin from "../common/generic/absoluteSpin";

const ReturnPaymentConfirmation = () => {
  const { currentFamily, invalidateQueryWhereKeyContains } =
    useTldrPageContext();
  const { subscription } = useSettingsSubscription();
  const [familyPaymentPlanIsUpToDate, setFamilyPaymentPlanIsUpToDate] =
    useState("unknown");

  const { mutate: updateFamilyPlan } = useUpdateFamily(
    () => {},
    () => {
      setFamilyPaymentPlanIsUpToDate("is_up_to_date");
      invalidateQueryWhereKeyContains("families");
    },
    () => {
      invalidateQueryWhereKeyContains("families");
    },
  );

  useEffect(() => {
    if (subscription && currentFamily) {
      if (
        subscription.status === "active" &&
        currentFamily.plan_name === "free"
      ) {
        setFamilyPaymentPlanIsUpToDate("needs_updating");
      } else {
        setFamilyPaymentPlanIsUpToDate("is_up_to_date");
      }
    }
  }, [subscription, currentFamily]);

  useEffect(() => {
    if (familyPaymentPlanIsUpToDate === "needs_updating") {
      updateFamilyPlan({ idz: currentFamily.id, plan_name: "standard" });
    }
  }, [familyPaymentPlanIsUpToDate, updateFamilyPlan, currentFamily]);

  if (familyPaymentPlanIsUpToDate === "is_up_to_date") {
    return (
      <Navigate
        to={`/families/${currentFamily.id}/settings/subscription-billing`}
      />
    );
  }

  return <AbsoluteSpin />;
};

export default ReturnPaymentConfirmation;
