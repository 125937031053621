import React from "react";
import { Form, Input } from "antd";

const CreateOrEditCommentModal = ({
  open,
  formFieldValues,
  formMergeValues,
  onFinish,
  onCancel,
  confirmLoading,
  isEditMode,
}) => {
  const handleOk = (values) => {
    values.comment = values.comment.trim();
    const response = { ...values, ...formMergeValues };
    onFinish(response);
  };

  return (
    //   <SubmitOverlay
    //   formName="createOrEditCommentForm"
    //   open={open}
    //   title={isEditMode ? "Edit Comment" : "New Comment"}
    //   onOk={handleOk}
    //   okText={"Save"}
    //   onCancel={onCancel}
    //   cancelText="Cancel"
    //   confirmLoading={confirmLoading}
    //   formFieldValues={formFieldValues}
    //   onValidationError={(e) => console.log("error:", e)}
    // >
    <Form.Item name="comment" label="Comment">
      <Input.TextArea rows={4} />
    </Form.Item>
    // </SubmitOverlay>
  );
};

export default CreateOrEditCommentModal;
