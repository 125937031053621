import React from "react";
import { Col, Layout, Row } from "antd";
import TopBarPublic from "./topBarPublic";

const { Header, Content, Footer } = Layout;

const LayoutChromeNoAuth = ({ children }) => {
  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 998,
          padding: 0,
          width: "100%",
          backgroundColor: "#fff",
          marginBottom: 20,
          height: "auto",
        }}
      >
        <Row justify="space-around">
          <Col xs={24} sm={24} md={23} lg={22} xl={20}>
            <TopBarPublic />
          </Col>
        </Row>
      </Header>
      <Layout>
        <Content>
          <Row className="main-body-row" justify="space-around">
            <Col xs={24} sm={24} md={23} lg={22} xl={20}>
              {children}
            </Col>
          </Row>
        </Content>
      </Layout>
      <Footer style={{ textAlign: "center", color: "#ffffff" }}>
        ©2024 | TLDR Parents
      </Footer>
    </Layout>
  );
};

export default LayoutChromeNoAuth;
