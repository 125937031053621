import React from "react";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";

const SecondarySettingsMenu = ({ familyId, selectedValue }) => {
  const navigate = useNavigate();

  const keyToDisplayText = {
    members: "Members",
    "subscription-billing": "Subscription & Billing",
    "gmail-addon": "Gmail Add-on",
  };

  return (
    <Tabs
      activeKey={selectedValue || "members"}
      items={[
        {
          label: keyToDisplayText["members"],
          key: "members",
        },
        // {
        //   label: keyToDisplayText["subscription-billing"],
        //   key: "subscription-billing",
        // },
        {
          label: keyToDisplayText["gmail-addon"],
          key: "gmail-addon",
        },
      ]}
      onChange={(value) => navigate(`/families/${familyId}/settings/${value}`)}
    />
  );
};

export default SecondarySettingsMenu;
