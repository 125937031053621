import React from "react";
import { Descriptions } from "antd";

const CreditCardReadOnly = ({ card }) => {
  const getDescriptionOrEmptyFragment = (item, label, itemPrefixText) => {
    if (!item) {
      return <></>;
    }

    if (itemPrefixText) {
      item = `${itemPrefixText}${item}`;
    }
    return <Descriptions.Item label={label}>{item}</Descriptions.Item>;
  };

  return (
    <>
      <Descriptions>
        {getDescriptionOrEmptyFragment(
          card.last4,
          <span style={{ textTransform: "capitalize" }}>{card.brand} </span>,
          "...",
        )}
      </Descriptions>
      <Descriptions>
        {getDescriptionOrEmptyFragment(
          `${card.exp_month}/${card.exp_year}`,
          "Expires",
        )}
      </Descriptions>
    </>
  );
};

export default CreditCardReadOnly;
