import { useState } from "react";
import { MERGE_SOURCE_DATA_COMMON } from "../../../components/familyOrganizer/orchestrateObjectPrototypes";
import { generateInitialEventDetails } from "../../../components/googleCalendar/googleCalendarEventTemplateHelpers";
import useTldrPageContext from "./useTldrPageContext";
import { convertUtcDatetimeStringsToTimezoneDatetimeStrings } from "../../../utils/dayJsUtils";

export const EMPTY_EVENT = {
  title: "",
  details: "",
  location: "",
  invitedUsers: [],
};

export const MERGE_VALUES_EVENT = {
  ...MERGE_SOURCE_DATA_COMMON,
};

const useCreateOrEditOrchestrateEvent = () => {
  const { currentFamily } = useTldrPageContext();

  const [eventOverlayOpen, setEventOverlayOpen] = useState(false);
  const [eventFormValues, setEventFormValues] = useState({});
  const [eventMergeValues, setEventMergeValues] = useState({});

  const getInitialValuesForEvent = (classAction, ecObject) => {
    const { startDate, startTime, endDate, endTime } =
      convertUtcDatetimeStringsToTimezoneDatetimeStrings(
        ecObject.start_date,
        ecObject.start_time,
        ecObject.end_date,
        ecObject.end_time,
        currentFamily.timezone,
      );

    const title = `${ecObject?.emoji || ""} ${ecObject?.title || ""}`.trim();
    const eventDescription = generateInitialEventDetails(
      currentFamily.id,
      ecObject,
      ecObject?.description,
      ecObject?.location,
    );

    return {
      title: title,
      datetimes: {
        date1: startDate,
        time1: startTime,
        date2: endDate,
        time2: endTime,
      },
      location: ecObject.location,
      details: eventDescription,
    };
  };

  const getInitialValuesForTopic = (classAction, ecObject) => {
    const title = `${ecObject?.emoji || ""} ${ecObject?.title || ""}`.trim();
    const eventDescription = generateInitialEventDetails(
      currentFamily.id,
      ecObject,
      ecObject?.description,
      null,
    );

    return {
      title: title,
      details: eventDescription,
    };
  };

  const getInitialValuesForMessage = (classAction, ecObject) => {
    return {
      title: ecObject.subject,
    };
  };

  const getInitialValuesForSummaryBullet = (classAction, ecObject) => {
    return { title: ecObject?.title };
  };

  const getInitialValuesForAttachment = (classAction, ecObject) => {
    return { title: ecObject?.filename };
  };

  const getInitialValuesForEmailAddress = (classAction, ecObject) => {
    return { title: ecObject?.email || "" };
  };

  const getInitialValuesForImage = (classAction, ecObject) => {
    return {};
  };

  const getInitialValuesForLink = (classAction, ecObject) => {
    return { title: ecObject?.preview_summary?.title || "" };
  };

  const getInitialValuesForLinkImage = (classAction, ecObject) => {
    return { title: ecObject?.link_preview_summary?.title || "" };
  };

  const getInitialValuesForNonHttpLink = (classAction, ecObject) => {
    return {};
  };

  const getInitialValues = (classAction, classId, ecObject) => {
    switch (classId) {
      case "attachment_rollup":
        return getInitialValuesForAttachment(classAction, ecObject);

      case "email_address_rollup":
        return getInitialValuesForEmailAddress(classAction, ecObject);

      case "image_rollup":
        return getInitialValuesForImage(classAction, ecObject);

      case "link_rollup":
        return getInitialValuesForLink(classAction, ecObject);

      case "link_image_rollup":
        return getInitialValuesForLinkImage(classAction, ecObject);

      case "non_http_link_rollup":
        return getInitialValuesForNonHttpLink(classAction, ecObject);

      case "event_rollup":
        return getInitialValuesForEvent(classAction, ecObject);

      case "topic_rollup":
        return getInitialValuesForTopic(classAction, ecObject);

      case "message":
        return getInitialValuesForMessage(classAction, ecObject);

      case "summary_bullet":
        return getInitialValuesForSummaryBullet(classAction, ecObject);

      default:
        return {};
    }
  };

  const handleCreateEvent = (values, ecObject) => {
    setEventFormValues({
      ...EMPTY_EVENT,
      ...getInitialValues(values.classAction, ecObject.class_id, ecObject),
    });
    setEventMergeValues({
      ...MERGE_VALUES_EVENT,
      ...values.sourceInfo,
    });
    openEventOverlay();
  };

  const openEventOverlay = () => {
    setEventOverlayOpen(true);
    document.body.classList.add("overlay-open");
  };

  const closeEventOverlay = () => {
    setEventOverlayOpen(false);
    document.body.classList.remove("overlay-open");
  };

  return {
    closeEventOverlay,
    eventFormValues,
    eventMergeValues,
    eventOverlayOpen,
    handleCreateEvent,
    setEventFormValues,
    setEventMergeValues,
  };
};

export default useCreateOrEditOrchestrateEvent;
