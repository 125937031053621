import React from "react";
import CardCenteredTextCover from "../../common/generic/cardCenteredTextCover";
import { Divider, Typography } from "antd";
import { timestampToFullDatetimeString } from "../../../utils/dayJsUtils";

const SummaryBulletCardCover = ({ message, ecObject, onClick, isMini }) => {
  const getFullSize = () => {
    return (
      <>
        <Divider />
        <div>
          <Typography.Text italic={true} type={"secondary"}>
            Topic from message:
          </Typography.Text>
        </div>
        <div>
          <Typography.Text ellipsis={true}>
            {message?.subject || ""}
          </Typography.Text>
        </div>
        <div>{timestampToFullDatetimeString(message?.timestamp)}</div>
      </>
    );
  };

  const getMini = () => {
    return (
      <>
        <div>
          <Typography.Text italic={true} type={"secondary"}>
            Topic from message:
          </Typography.Text>
        </div>
        <div>
          <Typography.Text ellipsis={true}>
            {message?.subject || ""}
          </Typography.Text>
        </div>
      </>
    );
  };

  const getHeaderAndTextContent = () => {
    return (
      <CardCenteredTextCover onClick={onClick || null}>
        <div>
          <h3>
            {ecObject.emoji} {ecObject.title}
          </h3>
        </div>

        {isMini !== true ? getFullSize() : getMini()}
      </CardCenteredTextCover>
    );
  };

  return getHeaderAndTextContent();
};

export default SummaryBulletCardCover;
