import React, { useEffect } from "react";
import { Col, Row, Select, Space, Typography } from "antd";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import useListQueryRollup from "../hooks/useListQueryRollup";
import { NATIVE_TAG_OPTIONS } from "./nativeTags";
import FilesPlusRollupUserEducationModal from "./filesPlusRollupUserEducationModal";
import useSearchParamPagination from "../../../hooks/utils/useSearchParamPagination";
import SortSelect from "../../common/commonTldr/sortSelect";
import SpaceBetweenRow from "../../common/generic/spaceBetweenRow";
import HelpIconAndModal from "../../helpIconAndModal";
import StandardPagination from "../../common/generic/standardPagination";
import FilesPlusRollupInner from "./filesPlusRollupInner";
import { AppContextFilesPlusRollupFeed } from "../../ecObjectViewers/actionMappers/appContextsToActions";

const { Text } = Typography;

const PAGE_SIZE = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);

const FilesPlusRollup = () => {
  const {
    currentPage,
    orderBy,
    changePage,
    getSearchParam,
    changeOrderOrFilterAndNavigate,
  } = useSearchParamPagination({
    order: "message_date",
    native_tag: "tldr",
  });

  const { setBreadcrumbs, setBreadcrumbExtra } = useTldrPageContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Link Gallery",
      },
    ]);
    setBreadcrumbExtra(
      <HelpIconAndModal
        educationModalClass={FilesPlusRollupUserEducationModal}
      />,
    );
  }, [setBreadcrumbs, setBreadcrumbExtra]);

  const { data: listQueryData } = useListQueryRollup(
    getSearchParam("native_tag"),
    null,
    PAGE_SIZE,
    (currentPage - 1) * PAGE_SIZE,
    orderBy,
    true,
  );

  const handleFilter = (searchText, option) => {
    const searchLower = searchText.toLowerCase();
    const labelLower = option?.label.toLowerCase();
    const valueLower = option?.value.toLowerCase();
    if (labelLower.includes(searchLower) || labelLower === searchLower) {
      return true;
    }
    if (valueLower.includes(searchLower) || valueLower === searchLower) {
      return true;
    }
    return false;
  };

  const getSelectDropdownsSection = () => {
    return (
      <Row justify={"start"} gutter={[15, 15]} style={{ marginBottom: 15 }}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space
            size={"small"}
            style={{ width: "100%" }}
            direction={"vertical"}
          >
            <div>
              <Text strong={true}>Filter</Text>
            </div>
            <Select
              style={{ width: "100%" }}
              onChange={(x) => {
                changeOrderOrFilterAndNavigate("native_tag", x);
              }}
              value={getSearchParam("native_tag")}
              options={NATIVE_TAG_OPTIONS}
              showSearch={true}
              filterOption={handleFilter}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space
            size={"small"}
            style={{ width: "100%" }}
            direction={"vertical"}
          >
            <div>
              <Text strong={true}>Sort</Text>
            </div>
            <SortSelect
              orderByValue={orderBy}
              onChange={(selectedItem) => {
                changeOrderOrFilterAndNavigate("order", selectedItem);
              }}
              selectStyle={{ width: "100%" }}
            />
          </Space>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <FilesPlusRollupUserEducationModal forced={true} />
      {getSelectDropdownsSection()}
      <SpaceBetweenRow
        leftSide={null}
        rightSide={
          <StandardPagination
            current={currentPage}
            pageSize={PAGE_SIZE}
            onChange={changePage}
            total={listQueryData?.found || 0}
          />
        }
      />

      <FilesPlusRollupInner
        listQueryData={listQueryData}
        orderBy={orderBy}
        cardProperties={{
          link_image_rollup: {
            showLink:
              getSearchParam("native_tag") === "link" ||
              getSearchParam("native_tag") === "tldr",
            showImage: getSearchParam("native_tag") === "image",
          },
        }}
        appContext={AppContextFilesPlusRollupFeed}
      />

      <SpaceBetweenRow
        leftSide={null}
        rightSide={
          <StandardPagination
            current={currentPage}
            pageSize={PAGE_SIZE}
            onChange={changePage}
            total={listQueryData?.found || 0}
          />
        }
      />
    </>
  );
};

export default FilesPlusRollup;
