import { generateGoogleContactTemplateTemplateUrl } from "../../../utils/googleContactTemplateHelpers";

const useCreateOrEditOrchestrateContact = () => {
  const handleCreateContact = (values, ecObject) => {
    const displayName = ecObject.display_names?.[0];
    let firstName = "";
    let lastName = "";

    if (displayName) {
      const splitDisplayName = displayName.split(" ");
      const numTokens = splitDisplayName.length;
      if (numTokens >= 1) {
        firstName = splitDisplayName[0];
        if (numTokens >= 2) {
          splitDisplayName.shift();
          lastName = splitDisplayName.join(" ");
        }
      }
    }
    const googleContactUrl = generateGoogleContactTemplateTemplateUrl(
      ecObject.email,
      firstName,
      lastName,
    );
    window.open(googleContactUrl, "_blank");
  };

  return {
    handleCreateContact,
  };
};

export default useCreateOrEditOrchestrateContact;
