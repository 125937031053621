import React, { useState } from "react";
import { Alert, Button, Divider, Skeleton } from "antd";
import CreditCardReadOnly from "./parts/creditCardReadOnly";
import SubscriptionPaymentModal from "./parts/subscriptionPaymentModal";
import { O_SERVICE_BILLING_DEFAULT_PAYMENT_METHOD } from "../../../serviceUrls/serviceUrlsOrchestrate";
import useBillingGet from "./hooks/useBillingGet";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";

const SettingsDefaultPaymentMethod = () => {
  const { isCurrentUserFamilyOwner } = useTldrPageContext();
  const [showModal, setShowModal] = useState(false);
  const { data: defaultPaymentMethod, isLoading } = useBillingGet(
    O_SERVICE_BILLING_DEFAULT_PAYMENT_METHOD,
  );

  const getSubscriptionPaymentModal = () => {
    return (
      <SubscriptionPaymentModal
        open={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
      />
    );
  };

  const getJsx = () => {
    if (isLoading) {
      return <Skeleton active />;
    }

    if (defaultPaymentMethod && defaultPaymentMethod.card) {
      return (
        <>
          <CreditCardReadOnly
            billingDetails={defaultPaymentMethod.billing_details}
            card={defaultPaymentMethod.card}
          />
          {isCurrentUserFamilyOwner && (
            <>
              {getSubscriptionPaymentModal()}
              <Button
                ghost={true}
                type="primary"
                onClick={() => setShowModal(true)}
              >
                Update payment method
              </Button>
            </>
          )}
        </>
      );
    } else {
      if (isCurrentUserFamilyOwner) {
        return (
          <>
            {getSubscriptionPaymentModal()}
            <Button type="primary" onClick={() => setShowModal(true)}>
              Add Payment Method
            </Button>
          </>
        );
      } else {
        return (
          <Alert
            type="warning"
            message="Only the owner of the family workspace can add or update the payment method."
          />
        );
      }
    }
  };

  return (
    <>
      <h2>Payment Method</h2>
      {getJsx()}
      <Divider />
    </>
  );
};

export default SettingsDefaultPaymentMethod;
