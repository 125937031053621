import React, { useEffect } from "react";
import SettingsInvites from "./members/settingsInvites";
import SettingsFamilyMembers from "./members/settingsFamilyMembers";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";

const SettingsMembers = () => {
  const { currentFamily, setBreadcrumbs, clearBreadcrumbExtra } =
    useTldrPageContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Settings",
        link: `/families/${currentFamily?.id}/settings`,
      },
      {
        label: "Members",
      },
    ]);
    clearBreadcrumbExtra();
  }, [setBreadcrumbs, clearBreadcrumbExtra, currentFamily]);

  return (
    <>
      <SettingsFamilyMembers />
      <SettingsInvites />
    </>
  );
};

export default SettingsMembers;
