import React from "react";
import SmallButtonWithIcon from "./smallButtonWithIcon";
import { Space } from "antd";

const SmallButtonWithIconGroup = ({ buttons, showVertically, allowWrap }) => {
  const getJsx = () => {
    const buttonsAsJsx = buttons.map((button, index) => {
      return (
        <SmallButtonWithIcon
          key={index}
          iconType={button.iconType}
          iconColor={button.iconColor}
          onClick={button.onClick}
        />
      );
    });
    return (
      <Space
        wrap={allowWrap === true}
        direction={showVertically ? "vertical" : "horizontal"}
      >
        {buttonsAsJsx}
      </Space>
    );
  };

  return getJsx();
};

export default SmallButtonWithIconGroup;
