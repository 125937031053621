import React, { useEffect } from "react";
import MessagesAsCards from "./messagesAsCards";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";

const ArchivedMessages = () => {
  const { setBreadcrumbs, clearBreadcrumbExtra } = useTldrPageContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Archived Messages",
      },
    ]);
    clearBreadcrumbExtra();
  }, [setBreadcrumbs, clearBreadcrumbExtra]);

  return <MessagesAsCards viewArchived={true} />;
};

export default ArchivedMessages;
