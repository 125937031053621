import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useBatchGetArtifacts from "../../../hooks/emailCollector/useBatchGetArtifacts";

const useArtifactDetail = () => {
  const { classId, hashKey, sortKey } = useParams();
  const [artifactKeyForQuery, setArtifactKeyForQuery] = useState(null);
  const [artifact, setArtifact] = useState(null);

  useEffect(() => {
    if (classId && hashKey) {
      const localClassId = classId.replaceAll("-", "_");
      if (localClassId.endsWith("_rollup")) {
        setArtifactKeyForQuery([
          {
            item_class_id: null,
            item_hash_key: null,
            item_sort_key: null,
            rollup_class_id: localClassId,
            rollup_hash_key: hashKey,
            rollup_sort_key: sortKey || null,
          },
        ]);
      } else {
        setArtifactKeyForQuery([
          {
            item_class_id: localClassId,
            item_hash_key: hashKey,
            item_sort_key: sortKey || null,
            rollup_class_id: null,
            rollup_hash_key: null,
            rollup_sort_key: null,
          },
        ]);
      }
    }
  }, [classId, hashKey, sortKey]);

  const { data: ecArtifactRaw } = useBatchGetArtifacts(artifactKeyForQuery);

  useEffect(() => {
    if (ecArtifactRaw && classId && hashKey) {
      const localClassId = classId.replaceAll("-", "_");
      let temp = ecArtifactRaw[localClassId][hashKey];
      if (sortKey) {
        temp = temp[sortKey];
      }
      setArtifact(temp);
    }
  }, [classId, hashKey, sortKey, ecArtifactRaw]);

  return { classId: classId?.replaceAll("-", "_"), artifact };
};

export default useArtifactDetail;
