import React from "react";
import OverlayFormWrapper from "../../../common/formComponents/overlayFormWrapper";
import CreateOrEditBoardForm from "./createOrEditBoardForm";

const CreateOrEditBoardOverlay = ({
  open,
  onOk,
  onCancel,
  initialValues,
  confirmLoading,
  isEditMode,
  formMergeValues,
}) => {
  const titleText = isEditMode ? "Edit board" : "Create board";
  const okText = isEditMode ? "Save" : "Create";
  const cancelText = "Cancel";

  const handleOk = (values) => {
    const response = { ...values, ...formMergeValues };
    onOk(response);
  };

  return (
    <OverlayFormWrapper
      open={open}
      title={titleText}
      onOk={handleOk}
      okText={okText}
      onCancel={onCancel}
      cancelText={cancelText}
      confirmLoading={confirmLoading}
      initialValues={initialValues}
      formMergeValues={formMergeValues}
      formClass={CreateOrEditBoardForm}
      ecObject={null}
      onValuesChange={() => {}}
      onValidationError={() => {}}
    />
  );
};

export default CreateOrEditBoardOverlay;
