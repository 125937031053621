import { App } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const useUpdateableMessage = () => {
  const { notification: messageApi } = App.useApp();

  const openMessage = (key, type, content, description) => {
    const config = {
      key,
      message: content,
      placement: "topRight",
    };
    if (description) {
      config.description = description;
    }

    switch (type) {
      case "success":
        config.icon = (
          <CheckCircleOutlined style={{ color: "rgb(82, 196, 26)" }} />
        );
        break;

      case "error":
        config.icon = (
          <CloseCircleOutlined style={{ color: "rgb(255, 77, 79)" }} />
        );
        break;

      case "loading":
        config.icon = (
          <LoadingOutlined style={{ color: "rgb(22, 119, 255)" }} />
        );
        break;

      default:
        break;
    }
    messageApi.open(config);
  };

  const openLoadingMessage = (key, content, description) => {
    openMessage(key, "loading", content, description);
  };

  const openSuccessMessage = (key, content, description) => {
    openMessage(key, "success", content, description);
  };

  const openErrorMessage = (key, content, description) => {
    openMessage(key, "error", content, description);
  };

  return {
    openLoadingMessage: openLoadingMessage,
    openSuccessMessage: openSuccessMessage,
    openErrorMessage: openErrorMessage,
  };
};

export default useUpdateableMessage;
