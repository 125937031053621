import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button } from "antd";
import { stopEventPropagation } from "../../../utils/utils";

const CollapsibleContentArea = ({ open, width, items, onOpenChange }) => {
  const [selectedPanelIndex, setSelectedPanelIndex] = useState(0);
  const [openState, setOpenState] = useState(!!open);
  const [buttonWidth, setButtonWidth] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const contentRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    if (items) {
      setButtonWidth((width - 10 * (items.length - 1)) / items.length);
    }
    if (selectedPanelIndex > items.length - 1) {
      setSelectedPanelIndex(0);
    }
    setIsLoading(false);
  }, [items, width]);

  useEffect(() => {
    setOpenState(open);
  }, [open]);

  useLayoutEffect(() => {
    const handler = (e) => {
      if (contentRef.current) {
        if (!contentRef.current.contains(e.target)) {
          onOpenChange(false);
          setOpenState(false);
        }
      }
    };

    document.addEventListener("click", (e) => handler(e));
    return () => {
      document.removeEventListener("click", (e) => handler(e));
    };
  }, []);

  useLayoutEffect(() => {
    if (!openState) {
      contentRef.current.style.gridTemplateRows = "0fr";
    } else {
      contentRef.current.style.gridTemplateRows = "1fr";
    }
  }, [openState, selectedPanelIndex]);

  return (
    <div style={{ width: width }}>
      <div>
        {isLoading ? (
          <>Loading...</>
        ) : (
          items.map((item, index) => {
            if (item) {
              return (
                <Button
                  ghost={true}
                  type={"primary"}
                  style={{
                    width: buttonWidth,
                    marginRight: index === items.length - 1 ? 0 : 10,
                  }}
                  key={index}
                  onClick={(e) => {
                    if (!openState) {
                      setSelectedPanelIndex(index);
                      onOpenChange(true);
                      setOpenState(true);
                      stopEventPropagation(e);
                    } else {
                      if (selectedPanelIndex !== index) {
                        setSelectedPanelIndex(index);
                        stopEventPropagation(e);
                      }
                    }
                  }}
                >
                  {item.buttonText}
                </Button>
              );
            }
          })
        )}
      </div>

      <div
        ref={contentRef}
        style={{
          display: "grid",
          transition: "grid-template-rows 250ms",
        }}
      >
        <div style={{ overflow: "hidden" }}>
          <div style={{ height: 10 }} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {isLoading ? (
              <>Loading...</>
            ) : (
              items[selectedPanelIndex]?.content || <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollapsibleContentArea;
