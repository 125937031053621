import { useParams } from "react-router-dom";
import useGetAnyOrchestrateObject from "../../../hooks/orchestrate/useGetAnyOrchestrateObject";
import useGetArtifact from "../../ecObjectViewers/hooks/useGetArtifact";

const useSavedObjectDetail = (classId) => {
  const { objectId } = useParams();

  const { data: orchestrateObject } = useGetAnyOrchestrateObject(
    classId,
    objectId,
  );

  const { ecArtifact } = useGetArtifact(
    orchestrateObject?.artifacts?.[0]?.rollup_class_id,
    orchestrateObject?.artifacts?.[0]?.rollup_hash_key,
    orchestrateObject?.artifacts?.[0]?.rollup_sort_key,
  );

  return { objectId, orchestrateObject, ecArtifact };
};

export default useSavedObjectDetail;
