import React from "react";
import UserEducationModal from "../../userEducation/userEducationModal";
import { Modal, Typography } from "antd";

const { Text } = Typography;

const SmartInboxEducationModal = ({ forced, open, onClose }) => {
  const title = "Smart Inbox 🧠";
  const inner = (
    <>
      <p>
        At TLDR Parents, we're transforming how families handle school and
        extracurricular emails with our Smart Inbox, designed specifically for
        busy parents.
      </p>
      <p>
        <Text strong={true}>Shared Space, Seamless Collaboration:</Text> Every
        email forwarded to <Text strong={true}>email@tldrparents.com</Text> by
        you or your partner lands here. Don’t get the emails from gymnastics? No
        problem. Have your partner forwarding them to the Smart Inbox to ensure
        everyone stays informed and involved.
      </p>
      <p>
        <Text strong={true}>Effortless Email Management:</Text> Say goodbye to
        wading through endless paragraphs! View messages in TLDR mode to quickly
        grasp the essentials through bullet-point summaries, view all events
        extracted and ready for your calendar, and browse key links as visual
        preview cards. Transform these elements into events, to-dos, or pin them
        to your board with just a click.
      </p>
    </>
  );

  if (!!forced) {
    return (
      <UserEducationModal title={title} subjects={["EXPLAIN_SMART_INBOX"]}>
        {inner}
      </UserEducationModal>
    );
  } else {
    return (
      <Modal
        title={title}
        open={open}
        closable={true}
        onCancel={() => onClose()}
        onOk={null}
        footer={null}
      >
        {inner}
      </Modal>
    );
  }
};

export default SmartInboxEducationModal;
