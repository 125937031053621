import React from "react";
import NavButtonRow from "./navButtonRow";

const NavButtonGrid = ({ buttonHeight, navButtonRows, bottomSpacer }) => {
  return navButtonRows.map((row, index) => {
    return (
      <NavButtonRow
        key={index}
        buttonHeight={buttonHeight}
        bottomSpacer={bottomSpacer}
        navButtonItems={row.navButtonItems}
        sectionTitle={row.sectionTitle}
      />
    );
  });
};

export default NavButtonGrid;
