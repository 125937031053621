import Auth0GoogleScopesProviderContext from "../../context/auth0GoogleScopesProviderContext";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";

const authorizationParamsMain = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  redirect_uri: `${window.location.origin}?primary`,
};

export const auth0UniversalProviderConfig = {
  context: Auth0UniversalProviderContext,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: authorizationParamsMain,
  skipRedirectCallback:
    window.location.href.includes("?gmail") ||
    window.location.href.includes("?googleCalendar"),
};

export const gmailProviderConfig = {
  context: Auth0GoogleScopesProviderContext,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: `${window.location.origin}?gmail`,
    scope: "openid profile",
    connection: "google-oauth2",
    connection_scope: "https://www.googleapis.com/auth/gmail.readonly",
    access_type: "offline",
    include_granted_scopes: "true",
  },
  skipRedirectCallback:
    window.location.href.includes("?primary") ||
    window.location.href.includes("?googleCalendar"),
};

export const googleCalendarProviderConfig = {
  context: Auth0GoogleScopesProviderContext,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: `${window.location.origin}?googleCalendar`,
    scope: "openid profile",
    connection: "google-oauth2",
    connection_scope: "https://www.googleapis.com/auth/calendar",
    access_type: "offline",
    include_granted_scopes: "true",
  },
  skipRedirectCallback:
    window.location.href.includes("?primary") ||
    window.location.href.includes("?gmail"),
};
