import useQueryGenericGet from "../../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../../serviceUrls/baseUrls";
import { EC_SERVICE_ARCHIVED_WORKING_REQUESTS_LIST_FOR_MESSAGE } from "../../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../../../../hooks/emailCollector/useEmailCollectorServiceUrlHelper";

const useListArchivedWorkingRequests = (uniqueMessageId) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_ARCHIVED_WORKING_REQUESTS_LIST_FOR_MESSAGE,
    {
      uniqueMessageId: uniqueMessageId,
    },
  );

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    {},
    !!uniqueMessageId && !!currentFamily,
  );
};

export default useListArchivedWorkingRequests;
