import useListMessageArtifacts from "../../../../hooks/emailCollector/messages/useListMessageArtifacts";
import { useEffect, useState } from "react";

const useOrganizedMessageArtifacts = (uniqueMessageId) => {
  const { data: artifactsAsDictOfLists } = useListMessageArtifacts(
    uniqueMessageId,
    !!uniqueMessageId,
  );
  const [artifactsByTlecIndex, setArtifactsByTlecIndex] = useState(undefined);
  const [attachments, setAttachments] = useState(undefined);

  useEffect(() => {
    if (artifactsAsDictOfLists) {
      const email_addresses = artifactsAsDictOfLists.email_addresses;
      setAttachments(artifactsAsDictOfLists.attachments);
      const images = artifactsAsDictOfLists.images;
      const links = artifactsAsDictOfLists.links;
      const link_images = artifactsAsDictOfLists.link_images;
      const non_http_links = artifactsAsDictOfLists.non_http_links;

      const artifactLists = [
        links,
        link_images,
        images,
        non_http_links,
        email_addresses,
      ];
      const artifactsByTlecIndexLocal = {};
      for (const i in artifactLists) {
        const artifactList = artifactLists[i];
        artifactList.map((artifact) => {
          if (artifact.tlec_index) {
            artifact.tlec_index.map((item) => {
              if (artifactsByTlecIndexLocal[item]) {
                artifactsByTlecIndexLocal[item].push(artifact);
              } else {
                artifactsByTlecIndexLocal[item] = [artifact];
              }
            });
          }
        });
      }

      setArtifactsByTlecIndex(artifactsByTlecIndexLocal);
    }
  }, [artifactsAsDictOfLists]);

  return { artifactsByTlecIndex, attachments };
};

export default useOrganizedMessageArtifacts;
