import { replacePlaceholdersInTemplate } from "../../../serviceUrls/serviceUrlHelpers";
import { useParams } from "react-router-dom";
import useGetCurrentUser from "../../../hooks/orchestrate/user/useGetCurrentUser";
import useSimpleFamilyDetails from "../../../hooks/orchestrate/user/useSimpleFamilyDetails";

const useUserEducationServiceUrlHelper = (template, templateReplacements) => {
  const { familyId } = useParams();
  const { data: currentUser } = useGetCurrentUser();
  const currentFamily = useSimpleFamilyDetails(familyId, currentUser);

  const firstEndpointPart = `/tenants/${currentFamily?.tenant_id}`;
  const secondEndpointPart = replacePlaceholdersInTemplate(
    template,
    templateReplacements,
  );

  return {
    endpointPath: `${firstEndpointPart}${secondEndpointPart}`,
    currentFamily,
  };
};

export default useUserEducationServiceUrlHelper;
