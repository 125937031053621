import React, { useEffect } from "react";
import { Button, Space } from "antd";
import useStateForMagicCalendar from "./hooks/useStateForMagicCalendar";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import useGetSavedObjectsForEcArtifacts from "../../hooks/orchestrate/common/useGetSavedObjectsForEcArtifacts";
import HelpIconAndModal from "../helpIconAndModal";
import StandardPagination from "../common/generic/standardPagination";
import EventSuggestionsEducationModal from "./eventSuggestionsEducationModal";
import MagicCalendarInner from "./parts/magicCalendarInner";

const MagicCalendar = () => {
  const {
    zeroResults,
    totalPages,
    currentPageNumForDisplay,
    eventsAndStartDates,
    eventsList,
    pageSize,
    handleChangePage,
    showHidden,
    handleToggleHidden,
    showPast,
    handleToggleShowPast,
    handleUpdateHiddenStatus,
  } = useStateForMagicCalendar();

  const { setBreadcrumbs, setBreadcrumbExtra } = useTldrPageContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Event Suggestions",
      },
    ]);
    setBreadcrumbExtra(
      <HelpIconAndModal educationModalClass={EventSuggestionsEducationModal} />,
    );
  }, [setBreadcrumbs, setBreadcrumbExtra]);

  const { data: savedObjectsByEcArtifactIds } =
    useGetSavedObjectsForEcArtifacts(eventsList);

  const getPagination = () => {
    if (!currentPageNumForDisplay) {
      return <></>;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <div></div>
        <StandardPagination
          current={currentPageNumForDisplay}
          pageSize={pageSize}
          onChange={handleChangePage}
          total={totalPages * pageSize || 0}
        />
      </div>
    );
  };

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "right", marginBottom: 10 }}
      >
        <Space>
          <Button
            ghost={!showHidden}
            type="primary"
            onClick={handleToggleHidden}
          >
            {showHidden ? "Hide dismissed events" : "Show dismissed events"}
          </Button>
          <Button
            ghost={!showPast}
            type="primary"
            onClick={handleToggleShowPast}
          >
            {showPast ? "Don't show past events" : "Show past events"}
          </Button>
        </Space>
      </div>
      {getPagination()}
      <MagicCalendarInner
        zeroResults={zeroResults}
        eventsAndStartDates={eventsAndStartDates}
        eventsList={eventsList}
        showHidden={showHidden}
        handleUpdateHiddenStatus={handleUpdateHiddenStatus}
      />
      {getPagination()}
    </>
  );
};

export default MagicCalendar;
