import React from "react";
import { Modal } from "antd";

const WorkingRequestCaptureModal = ({ open, onCancel, captureData }) => {
  return (
    <Modal open={open} title="Capture Data" footer={false} onCancel={onCancel}>
      <>
        {captureData ? (
          <pre>
            <code>{JSON.stringify(captureData, null, 2)}</code>
          </pre>
        ) : (
          <></>
        )}
      </>
    </Modal>
  );
};

export default WorkingRequestCaptureModal;
