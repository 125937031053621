import React from "react";
import { Alert, Typography } from "antd";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";

const { Text } = Typography;

const NoMessagesProcessedAlert = () => {
  const { currentUser } = useTldrPageContext();

  const getEmailSubmissionInstructions = () => {
    if (!currentUser) {
      return <></>;
    }

    if (currentUser?.has_processed_message === true) {
      return <></>;
    }
    return (
      <Alert
        showIcon={true}
        icon={<LightbulbOutlinedIcon />}
        message={
          <>
            Hi {currentUser?.given_name}! You haven't forwarded a message yet.
            Try forwarding any email to{" "}
            <Text strong={true}>
              {process.env.REACT_APP_FORWARD_EMAIL_ADDRESS}
            </Text>
            . We'll take if from there.
          </>
        }
        style={{ marginTop: 5, marginBottom: 12 }}
        type={"warning"}
      />
    );
  };

  return getEmailSubmissionInstructions();
};

export default NoMessagesProcessedAlert;
