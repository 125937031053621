import React from "react";
import { Button, Col, Row, Space, Typography } from "antd";
import {
  getDateForDisplayForISO8601,
  getDayJsDatetimeFromStringsGivenTimezones,
} from "../../../utils/dayJsUtils";
import FamilyMemberAvatarIcon from "../../familyMembers/familyMemberAvatarIcon";
import FamilyMemberName from "../../familyMembers/familyMemberName";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import FormattedDateTimeRange from "../../common/generic/formattedDateTimeRange";
import { getOpenGoogleCalendarToDateUrl } from "../../../utils/utils";

const GoogleEventCard = ({ savedObject, onClickEdit, onClickDelete }) => {
  const { familyId, currentFamily } = useTldrPageContext();
  const startDate = getDayJsDatetimeFromStringsGivenTimezones(
    savedObject?.start_date,
    savedObject?.start_time,
    "UTC",
    currentFamily?.timezone || "UTC",
  );
  const urlToGoogleCalendar = getOpenGoogleCalendarToDateUrl(
    startDate.format("YYYY-MM-DD"),
  );

  return (
    <Row wrap={false} style={{ width: "100%" }}>
      <Col>
        <div style={{ marginTop: 8, marginBottom: 5 }}>
          <div style={{ marginBottom: 10 }}>
            <Typography.Text ellipsis={true}>
              <Typography.Text strong={true}>Name: </Typography.Text>{" "}
              {savedObject?.name}
            </Typography.Text>
          </div>
          {savedObject?.details && (
            <div style={{ marginBottom: 10 }}>
              <Typography.Text ellipsis={true}>
                <Typography.Text strong={true}>Details: </Typography.Text>{" "}
                <Typography.Text type={"secondary"}>
                  {savedObject?.details}
                </Typography.Text>
              </Typography.Text>
            </div>
          )}
          {savedObject?.location && (
            <div style={{ marginBottom: 10 }}>
              <Typography.Text ellipsis={true}>
                <Typography.Text strong={true}>Location: </Typography.Text>{" "}
                <Typography.Text type={"secondary"}>
                  {savedObject?.location}
                </Typography.Text>
              </Typography.Text>
            </div>
          )}
          <div style={{ marginBottom: 10 }}>
            <Typography.Text strong={true}>Date: </Typography.Text>{" "}
            <Typography.Text type={"secondary"}>
              <FormattedDateTimeRange
                startDateAsString={savedObject?.start_date}
                startTimeAsString={savedObject?.start_time}
                endDateAsString={savedObject?.end_date}
                endTimeAsString={savedObject?.end_time}
                timezoneInterpret={"UTC"}
                timezoneDisplay={currentFamily?.timezone || "UTC"}
              />
            </Typography.Text>
          </div>
          <div style={{ marginBottom: 10 }}>
            <Typography.Text strong={true}>Added on: </Typography.Text>
            <Typography.Text type={"secondary"}>
              {getDateForDisplayForISO8601(savedObject?.created)}
            </Typography.Text>
          </div>
          <div style={{ marginBottom: 10 }}>
            <Typography.Text strong={true}>Added by: </Typography.Text>
            <FamilyMemberAvatarIcon
              familyId={familyId}
              userId={savedObject?.creator_id}
            />{" "}
            <Typography.Text type={"secondary"}>
              <FamilyMemberName
                familyId={familyId}
                userId={savedObject?.creator_id}
              />
            </Typography.Text>
          </div>
          <Space>
            {onClickEdit && (
              <Button
                type={"primary"}
                ghost={true}
                onClick={() => onClickEdit()}
              >
                Edit
              </Button>
            )}
            <Button
              type={"primary"}
              onClick={() => {
                window.open(urlToGoogleCalendar, "_blank");
              }}
            >
              Open Google Calendar
            </Button>
            <Button
              type={"primary"}
              ghost={true}
              danger={true}
              onClick={() => onClickDelete()}
            >
              Delete from TLDR Parents
            </Button>
          </Space>
        </div>
      </Col>
    </Row>
  );
};

export default GoogleEventCard;
