import React, { useEffect, useState } from "react";
import { getOpenGoogleCalendarToDateUrl } from "../../../utils/utils";
import dayjs from "dayjs";
import { Typography } from "antd";

const { Text } = Typography;

const DateMiniCard = ({ dayJsDate }) => {
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState("");

  const urlToGoogleCalendar = getOpenGoogleCalendarToDateUrl(
    dayJsDate.format("YYYY-MM-DD"),
  );

  const showYear = dayjs().format("YYYY") !== dayJsDate.format("YYYY");

  useEffect(() => {
    setMonth(dayJsDate.format("MMM"));
    setDate(dayJsDate.format("D"));
    setDayOfWeek(dayJsDate.format("ddd"));
  }, [dayJsDate]);

  return (
    <>
      <div
        style={{
          cursor: "pointer",
          backgroundColor: "#fff",
          width: 80,
          textAlign: "center",
          borderRadius: 12,
          border: "1px solid #e6e6e6",
        }}
        onClick={() => {
          window.open(urlToGoogleCalendar, "_blank");
        }}
      >
        <div
          style={{
            fontSize: 14,
            backgroundColor: "#e6e6e6",
            borderBottom: "1px solid #e6e6e6",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            textTransform: "uppercase",
            padding: 2,
            fontWeight: 500,
          }}
        >
          {month}
        </div>
        <div
          style={{
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1.35,
          }}
        >
          {date}
        </div>
        <div
          style={{
            fontSize: 13,
            fontWeight: 400,
            textTransform: "uppercase",
            paddingBottom: 2,
            lineHeight: 1.3,
          }}
        >
          {dayOfWeek}
        </div>
      </div>
      {showYear && (
        <div style={{ textAlign: "center" }}>
          <Text type={"secondary"}>{dayJsDate.format("YYYY")}</Text>
        </div>
      )}
    </>
  );
};

export default DateMiniCard;
