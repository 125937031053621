import React from "react";
import DetailPanelSkeleton from "./detailPanelParts/detailPanelSkeleton";
import DetailRow from "./detailPanelParts/detailRow";
import EmailAddressCardCover from "../cardCovers/emailAddressCardCover";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";

const EmailAddressDetailPanel = ({ ecObject, actionMenu }) => {
  const position1 = (
    <TldrCardWithCoverComponent
      coverComponent={<EmailAddressCardCover ecObject={ecObject} />}
      width={"auto"}
      coverHeight={300}
      hoverable={false}
      onClick={null}
    />
  );

  const displayNamesAsString = ecObject?.display_names?.join(", ") || "";

  return (
    <DetailPanelSkeleton
      position1={position1}
      position2={!!actionMenu && actionMenu}
      position3={
        <>
          <DetailRow label={"Display Name"} value={displayNamesAsString} />
          <DetailRow label={"Email Address"} value={ecObject?.email} />
        </>
      }
    />
  );
};

export default EmailAddressDetailPanel;
