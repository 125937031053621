import { Avatar } from "antd";
import PersonIcon from "@mui/icons-material/Person";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";

const HeaderAvatar = () => {
  const { user, isLoading } = useAuth0(Auth0UniversalProviderContext);
  if (isLoading) {
    return <Avatar size={32} icon={<PersonIcon sx={{ fontSize: 30 }} />} />;
  }
  return <Avatar size={32} src={user.picture} alt={user.name} />;
};

export default HeaderAvatar;
