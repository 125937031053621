import React, { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import LayoutChromeAuthenticated from "./layoutChromeAuthenticated";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import AbsoluteSpin from "../common/generic/absoluteSpin";
import UsageLimitAlert from "./usageLimitAlert";
import TldrBreadcrumbRibbon from "./tldrBreadcrumbRibbon";
import ResponsiveCard from "../common/generic/responsiveCard";
import NoMessagesProcessedAlert from "./noMessagesProcessedAlert";
import TimezoneMismatchAlert from "./timezoneMismatchAlert";

const FamilyOutlet = () => {
  const navigate = useNavigate();
  const { familyId: familyIdFromParams } = useParams();
  const { familyId, currentUser, currentUserEducationSubjects } =
    useTldrPageContext();

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.families || currentUser.families.length === 0) {
        navigate("/");
      }
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (currentUser && familyIdFromParams) {
      const familyId = parseInt(familyIdFromParams);
      let userIsPartOfFamily = false;
      for (const i in currentUser.families) {
        if (currentUser.families[i].id === familyId) {
          userIsPartOfFamily = true;
          break;
        }
      }
      if (!userIsPartOfFamily) {
        navigate("/");
      }
    }
  }, [currentUser, familyIdFromParams, navigate]);

  useEffect(() => {
    if (currentUserEducationSubjects?.hasFinishedOnboarding === false) {
      navigate(`/families/${familyId}/onboarding`);
    }
  }, [familyId, currentUserEducationSubjects, navigate]);

  const getJsx = () => {
    if (currentUserEducationSubjects?.hasFinishedOnboarding === false) {
      return <Outlet />;
    } else if (currentUserEducationSubjects?.hasFinishedOnboarding === true) {
      return (
        <LayoutChromeAuthenticated isMeMode={false}>
          <ResponsiveCard className="main-container-card">
            <NoMessagesProcessedAlert />
            <UsageLimitAlert />
            <TimezoneMismatchAlert />
            <TldrBreadcrumbRibbon />
            <Outlet />
          </ResponsiveCard>
        </LayoutChromeAuthenticated>
      );
    } else {
      return <AbsoluteSpin />;
    }
  };

  return getJsx();
};

export default FamilyOutlet;
