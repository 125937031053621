import React from "react";
import { Alert } from "antd";
import CreateFreeAccountButton from "./createFreeAccountButton";

const InboundForwardCreateAccountAlert = () => {
  const getCreateAccountAlert = () => {
    const description = (
      <>
        {/*<ul>*/}
        {/*  <li>View events from all messages on a single screen</li>*/}
        {/*  <li>Hide events you don't care about</li>*/}
        {/*  <li>Collaborate with a partner</li>*/}
        {/*  <li>Create and track to-dos</li>*/}
        {/*  <li>*/}
        {/*    View links and attachments from all messages (de-duped) on a single*/}
        {/*    screen*/}
        {/*  </li>*/}
        {/*  <li>Pin links and attachments to boards</li>*/}
        {/*</ul>*/}
        <CreateFreeAccountButton />
      </>
    );
    return (
      <Alert
        closable={true}
        type={"warning"}
        message={
          <h1>
            This is only the beginning. Create your free account now to explore
            more. 🚀
          </h1>
        }
        description={description}
        style={{ marginBottom: 16 }}
      />
    );
  };

  return getCreateAccountAlert();
};

export default InboundForwardCreateAccountAlert;
