import { useEffect, useRef, useState } from "react";
import useListOrchestrateObjects from "../hooks/orchestrate/useListOrchestrateObjects";
import {
  O_SERVICE_BOARDS_CREATE,
  O_SERVICE_BOARDS_LIST,
} from "../serviceUrls/serviceUrlsOrchestrate";
import useCreateOrchestrateObject from "../hooks/orchestrate/useCreateOrchestrateObject";
import useInvalidateQuery from "../hooks/utils/useInvalidateQuery";

const useBoardSelector2 = (value, onChange) => {
  const { data: boardsFromQuery } = useListOrchestrateObjects(
    O_SERVICE_BOARDS_LIST,
    true,
  );

  const { invalidateQueryWhereKeyContains } = useInvalidateQuery();
  const [selectedBoardId, setSelectedBoardId] = useState(value);
  const [boardsAsOptions, setBoardsAsOptions] = useState(null);
  const [allBoardIds, setAllBoardIds] = useState(null);
  const [allBoardNames, setAllBoardNames] = useState(null);
  const [newBoardName, setNewBoardName] = useState("");
  const newBoardInputRef = useRef(null);

  useEffect(() => {
    if (boardsFromQuery) {
      if (boardsFromQuery.length > 0) {
        const boardIds = [];
        const boardNames = [];
        const options = boardsFromQuery.map((board) => {
          boardIds.push(board.id);
          boardNames.push(board.name);
          return { value: board.id, label: board.name };
        });
        options.sort((a, b) => {
          if (a.label > b.label) return 1;
          if (a.label < b.label) return -1;
          return 0;
        });
        setBoardsAsOptions(options);
        setAllBoardIds(boardIds);
        setAllBoardNames(boardNames);
      } else if (boardsFromQuery.length === 0) {
        setBoardsAsOptions([]);
      }
    }
  }, [boardsFromQuery]);

  useEffect(() => {
    if (boardsAsOptions && boardsAsOptions.length > 0) {
      if (!selectedBoardId || !allBoardIds.includes(selectedBoardId)) {
        onChange(boardsAsOptions[0].value);
      }
    }
  }, [boardsAsOptions, allBoardIds, selectedBoardId, onChange]);

  useEffect(() => {
    if (value && value !== selectedBoardId) {
      setSelectedBoardId(value);
    }
  }, [value, selectedBoardId]);

  const { mutate: createBoard } = useCreateOrchestrateObject(
    O_SERVICE_BOARDS_CREATE,
    null,
    () => {},
    (item) => {
      invalidateQueryWhereKeyContains("boards");
      onChange(item.id);
      setNewBoardName("");
    },
    () => {},
  );

  const handleCreateNewBoardSubmitClicked = () => {
    const boardNameLocal = newBoardInputRef.current.input.value.trim();
    if (boardNameLocal === "") {
      setNewBoardName("");
      return;
    }
    if (allBoardNames?.includes(boardNameLocal)) {
      setNewBoardName("");
      return;
    }
    createBoard({ name: boardNameLocal });
  };

  const handleChangeBoardRadioSelection = (value) => {
    onChange(value);
  };

  const getImageUrlForBoardId = (boarId) => {
    for (const i in boardsFromQuery) {
      if (boardsFromQuery[i].id === boarId) {
        return boardsFromQuery[i].cover_image_url;
      }
    }
  };

  return {
    newBoardInputRef,
    newBoardName,
    setNewBoardName,
    boardsAsOptions,
    selectedBoardId,
    handleChangeBoardRadioSelection,
    handleCreateNewBoardSubmitClicked,
    getImageUrlForBoardId,
  };
};

export default useBoardSelector2;
