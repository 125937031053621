import React from "react";
import UserEducationModal from "../../userEducation/userEducationModal";
import { Modal, Typography } from "antd";

const { Text } = Typography;

const TodoListEducationModal = ({ forced, open, onClose }) => {
  const title = "To-dos ✅";
  const inner = (
    <>
      <p>
        We've revolutionized the standard to-do list with a couple of stellar
        enhancements:
      </p>
      <ol>
        <li>
          <Text strong={true}>Create To-dos from Email Content:</Text> Easily
          convert parts of email messages—like attachments, images, and
          links—into actionable to-dos.
        </li>
        <li>
          <Text strong={true}>Source Links:</Text> Every to-do in TLDR Parents
          includes a link back to the original message, right down to the
          specific link or topic. This ensures you have all the details at your
          fingertips when its time to tackle the task!
        </li>
      </ol>
      <h3>Workflow Example 🔧</h3>
      <p>
        Valentine's Day is coming up, and your daughter's third-grade class will
        be swapping cards. Her teacher sent an attachment with the list of all
        the kids so no one gets left out. Simply click to create a to-do in TLDR
        Parents from the attachment. When it's time to make the Valentines,
        you'll have the list easily accessible, saving you time and hassle!
      </p>
      <p>
        And the best part? You won't need to keep the original email around to
        clutter up your inbox!
      </p>
    </>
  );

  if (!!forced) {
    return (
      <UserEducationModal title={title} subjects={["EXPLAIN_TODOS"]}>
        {inner}
      </UserEducationModal>
    );
  } else {
    return (
      <Modal
        title={title}
        open={open}
        closable={true}
        onCancel={() => onClose()}
        onOk={null}
        footer={null}
      >
        {inner}
      </Modal>
    );
  }
};

export default TodoListEducationModal;
