import React, { useEffect } from "react";
import { AppContextClippingScroll } from "../../ecObjectViewers/actionMappers/appContextsToActions";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import LoadingEmptyOrContent from "../../common/generic/loadingEmptyOrContent";
import EcObjectCardsContainer from "../../ecObjectViewers/ecObjectCardsContainer";
import useClippingScroll from "./hooks/useClippingScroll";
import ClippingScrollEducationModal from "./clippingScrollEducationModal";
import HelpIconAndModal from "../../helpIconAndModal";
import { Button } from "antd";

const ClippingScroll = () => {
  const {
    setBreadcrumbs,
    isLoading: isTldrPageContextLoading,
    setBreadcrumbExtra,
  } = useTldrPageContext();
  const {
    clippings,
    isLoadingClippings,
    isLoadingClippingData,
    ecArtifacts,
    removeDuplicates,
  } = useClippingScroll();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Workbench",
      },
    ]);
    setBreadcrumbExtra(
      <HelpIconAndModal educationModalClass={ClippingScrollEducationModal} />,
    );
  }, [setBreadcrumbs, setBreadcrumbExtra]);

  const getJsx = () => {
    return (
      <>
        <ClippingScrollEducationModal forced={true} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
            marginBottom: 10,
          }}
        >
          <Button
            ghost={true}
            type={"primary"}
            onClick={() => removeDuplicates()}
          >
            Remove Duplicates
          </Button>
        </div>
        <LoadingEmptyOrContent
          isLoading={
            isTldrPageContextLoading ||
            isLoadingClippings ||
            isLoadingClippingData
          }
          isEmpty={!isLoadingClippings && clippings.length === 0}
        >
          <EcObjectCardsContainer
            ecObjects={ecArtifacts}
            correspondingSavedObjectsIfAny={clippings}
            appContext={AppContextClippingScroll}
            cardProperties={null}
            showClickDetailsTag={true}
            scrollToFirstHighlight={false}
          />
        </LoadingEmptyOrContent>
      </>
    );
  };

  return <>{getJsx()}</>;
};

export default ClippingScroll;
