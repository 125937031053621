import React, { useEffect, useState } from "react";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import { Checkbox, Col, Row, Space } from "antd";
import FamilyMemberAvatarIcon from "../../familyMembers/familyMemberAvatarIcon";

const UserCheckboxes = ({ excludeSelf, checkedIds, onChange }) => {
  const { currentUser, currentFamily } = useTldrPageContext();
  const [checkboxOptons, setCheckboxOptons] = useState([]);
  const [familyMembersById, setFamilyMembersById] = useState([]);

  if (!checkedIds) checkedIds = [];

  useEffect(() => {
    if (currentFamily) {
      const localFamilyMembersById = {};
      let members = currentFamily?.family_members.map((familyMember) => {
        localFamilyMembersById[familyMember.member.id] = familyMember.member;
        return familyMember.member;
      });

      setFamilyMembersById(localFamilyMembersById);

      if (excludeSelf) {
        members = members.filter((item) => item.id !== currentUser.id);
      }

      members.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });

      const optionsLocal = members.map((item) => {
        return {
          label: item.name,
          value: item.id,
          defaultChecked: checkedIds.includes(item.id),
        };
      });
      setCheckboxOptons(optionsLocal);
    }
  }, [currentUser, currentFamily, excludeSelf, checkedIds]);

  const handleChange = (selectedIds) => {
    const response = selectedIds.map((selectedId) => {
      return familyMembersById[selectedId];
    });
    return onChange(response);
  };

  return (
    <Checkbox.Group defaultValue={checkedIds || []} onChange={handleChange}>
      <Row gutter={[8, 8]}>
        {checkboxOptons?.map((option, index) => {
          return (
            <Col key={index} span={24}>
              <Checkbox value={option.value}>
                <Space>
                  <FamilyMemberAvatarIcon userId={option.value} />
                  {option.label}
                </Space>
              </Checkbox>
            </Col>
          );
        })}
      </Row>
    </Checkbox.Group>
  );
};

export default UserCheckboxes;
