import React, { useEffect } from "react";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import LoadingEmptyOrContent from "../../common/generic/loadingEmptyOrContent";
import { AppContextBoard } from "../../ecObjectViewers/actionMappers/appContextsToActions";
import EcObjectCardsContainer from "../../ecObjectViewers/ecObjectCardsContainer";
import useBoard from "./hooks/useBoard";

const Board = () => {
  const {
    familyId,
    setBreadcrumbs,
    clearBreadcrumbExtra,
    isLoading: isTldrPageContextLoading,
  } = useTldrPageContext();

  const { board, tiles, ecArtifacts } = useBoard();

  useEffect(() => {
    if (board) {
      setBreadcrumbs([
        {
          link: `/families/${familyId}/boards`,
          label: "Boards",
        },
        { label: board.name },
      ]);
    } else {
      setBreadcrumbs([
        {
          label: "Boards",
        },
      ]);
    }
    clearBreadcrumbExtra();
  }, [familyId, setBreadcrumbs, clearBreadcrumbExtra, board]);

  const getJsx = () => {
    return (
      <>
        {/*<div*/}
        {/*  style={{ display: "flex", justifyContent: "right", marginBottom: 10 }}*/}
        {/*>*/}
        {/*  <Space>*/}
        {/*    Public*/}
        {/*    <Switch*/}
        {/*      loading={isSettingPublicState}*/}
        {/*      checked={board?.is_public}*/}
        {/*      onChange={(value) => {*/}
        {/*        updateBoard({ id: board.id, is_public: value });*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Space>*/}
        {/*</div>*/}
        {/*{board && board.is_public && (*/}
        {/*  <div*/}
        {/*    style={{ display: "flex", justifyContent: "right", marginTop: 5 }}*/}
        {/*  >*/}
        {/*    <Paragraph*/}
        {/*      style={{ textAlign: "right" }}*/}
        {/*      copyable={{*/}
        {/*        text: `${process.env.REACT_APP_BASE_URL}/public/families/${familyId}/boards/${board.board_uuid}`,*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <Link*/}
        {/*        target="_blank"*/}
        {/*        to={`/public/families/${familyId}/boards/${board.board_uuid}`}*/}
        {/*      >*/}
        {/*        Sharable link*/}
        {/*      </Link>*/}
        {/*    </Paragraph>*/}
        {/*  </div>*/}
        {/*)}*/}

        <LoadingEmptyOrContent
          isLoading={isTldrPageContextLoading || !ecArtifacts}
          isEmpty={tiles && tiles.length === 0}
        >
          <EcObjectCardsContainer
            ecObjects={ecArtifacts}
            correspondingSavedObjectsIfAny={tiles}
            appContext={AppContextBoard}
            cardProperties={null}
            showClickDetailsTag={true}
            scrollToFirstHighlight={false}
          />
        </LoadingEmptyOrContent>
      </>
    );
  };

  return <>{getJsx()}</>;
};

export default Board;
