import useQueryGenericGet from "../../framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../serviceUrls/baseUrls";
import { EC_SERVICE_MESSAGES_LIST } from "../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../useEmailCollectorServiceUrlHelper";

const useListMessages = (archived, limit, offset, orderBy) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_MESSAGES_LIST,
    {},
  );
  const queryParams = { limit: limit, offset: offset, order_by: orderBy };
  if (archived) {
    queryParams.archived = "true";
  }

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    queryParams,
    !!currentFamily,
  );
};

export default useListMessages;
