import React from "react";
import { Button, Form, Input } from "antd";
import useInvitePartnerForm from "../hooks/useInvitePartnerForm";

const InvitePartnerForm = ({ onSuccess, onSkip }) => {
  const { formInvite, createInviteHook, isSubmitting, setIsSubmitting } =
    useInvitePartnerForm(() => onSuccess());

  return (
    <Form
      form={formInvite}
      name="inviteForm"
      layout="vertical"
      size="large"
      onFinish={(values) => {
        setIsSubmitting(true);
        const emailAddress = (values.emailAddress || "").trim();
        if (emailAddress) {
          createInviteHook.mutate({
            invitee_email: emailAddress,
          });
        } else {
          onSkip();
        }
      }}
    >
      <Form.Item
        name="emailAddress"
        rules={[
          ({ getFieldValue }) => ({
            message: "Enter a valid email",
            validator(_, value) {
              if (value === undefined) return Promise.resolve();
              const emailAddress = value.trim();
              const emailRegex =
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              const match = emailAddress.match(emailRegex);
              if (
                (match !== null && match[0] === emailAddress) ||
                emailAddress === ""
              ) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error("Invalid email"));
              }
            },
          }),
        ]}
      >
        <Input
          maxLength={50}
          placeholder="helpful.partner@gmail.com"
          disabled={isSubmitting}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          loading={isSubmitting}
          disabled={isSubmitting}
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Continue
        </Button>
      </Form.Item>
    </Form>
  );
};

export default InvitePartnerForm;
