import useQueryGenericGet from "../../../../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../../../../serviceUrls/baseUrls";
import { EC_SERVICE_TOPICS } from "../../../../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../../../../hooks/emailCollector/useEmailCollectorServiceUrlHelper";

const useListMessageTldrTopics = (uniqueMessageId) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_TOPICS,
    { uniqueMessageId: uniqueMessageId },
  );

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    { unique_message_id: uniqueMessageId },
    !!(uniqueMessageId && currentFamily),
  );
};

export default useListMessageTldrTopics;
