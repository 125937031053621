import React from "react";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import MiniCardTitleRow from "../miniCardParts/miniCardTitleRow";
import MessageCardCover from "../cardCovers/messageCardCover";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const MessageMiniCard = ({ ecObject, appContext, onClick }) => {
  const cover = <MessageCardCover ecObject={ecObject} isMini={true} />;

  return (
    <TldrCardWithCoverComponent
      coverComponent={cover}
      width={250}
      coverHeight={150}
      hoverable={false}
      onClick={onClick}
      bodyPadding={8}
    >
      <MiniCardTitleRow
        titleText={ecObject?.subject}
        tooltipText={"Message"}
        icon={<EmailOutlinedIcon sx={{ color: "#ff4081" }} />}
      />
    </TldrCardWithCoverComponent>
  );
};

export default MessageMiniCard;
