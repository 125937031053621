import React, { useRef } from "react";
import useTldrViewMessageContext from "../hooks/useTldrViewMessageContext";
import LoadingEmptyOrContent from "../../../common/generic/loadingEmptyOrContent";
import { useOutletContext } from "react-router-dom";
import { Space } from "antd";
import useOrganizedMessageArtifacts from "../hooks/useOrganizedMessageArtifacts";
import { getIconForContentType } from "../../../../utils/contentTypeUtils";
import HtmlStringToReactComponent from "../../../common/generic/htmlStringToReactComponent";

const MessageFullTextView = () => {
  const {
    messageObject: [messageObject],
  } = useOutletContext();

  const {
    uniqueMessageId,
    messageHtmlForDisplayAsString,
    isLoadingMessageContent,
  } = useTldrViewMessageContext();
  const { attachments } = useOrganizedMessageArtifacts(uniqueMessageId, false);
  const messageContainerRef = useRef(null);
  // const [isRead, setIsRead] = useState(undefined);

  // const updateMessageReadHook = useUpdateMessages(
  //   () => {},
  //   () => {},
  //   () => {},
  // );

  // useEffect(() => {
  //   if (isRead === false) {
  //     updateMessageReadHook.mutate({
  //       unique_message_ids: [uniqueMessageId],
  //       updates: { read_status: 1 },
  //     });
  //     setIsRead(true);
  //   }
  // }, [isRead, uniqueMessageId, updateMessageReadHook]);

  // useEffect(() => {
  //   if (messageObject) {
  //     setIsRead(messageObject.is_read);
  //   }
  // }, [messageObject]);

  const getAttachmentsRow = () => {
    if (attachments && attachments.length) {
      return (
        <Space style={{ marginTop: 10 }} direction={"vertical"}>
          {attachments.map((attachment) => (
            <a
              key={attachment.unique_filename}
              target="_blank"
              rel="noreferrer"
              href={attachment.signed_url}
            >
              <Space>
                {getIconForContentType(attachment.mime_type)}
                {attachment.filename && attachment.filename !== ""
                  ? attachment.filename
                  : "[no filename]"}
              </Space>
            </a>
          ))}
        </Space>
      );
    }
  };

  return (
    <>
      {getAttachmentsRow()}
      <LoadingEmptyOrContent
        isLoading={isLoadingMessageContent}
        isEmpty={false}
      >
        <HtmlStringToReactComponent
          htmlAsString={messageHtmlForDisplayAsString || ""}
          tlecIndexesForHighlighting={null}
          onComponentDidMount={() => {}}
          attachments={attachments}
          ref={messageContainerRef}
        />
      </LoadingEmptyOrContent>
    </>
  );
};

export default MessageFullTextView;
