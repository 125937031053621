import React from "react";
import OrchestrateObjectFormOverlays from "./orchestrateObjectFormOverlays";

const AnyOrchestrateObjectFormOverlay = ({
  classId,
  initialFormValues,
  mergeValues,
  queryKey,
  overlayOpen,
  onCancel,
  onSubmit,
  onPersist,
  ecArtifact,
}) => {
  return (
    <OrchestrateObjectFormOverlays
      todoInitialFormValues={classId === "todo" ? initialFormValues : null}
      todoMergeValues={classId === "todo" ? mergeValues : null}
      todosQueryKey={classId === "todo" ? queryKey : null}
      todoOverlayOpen={classId === "todo" ? overlayOpen : null}
      onCancelTodo={classId === "todo" ? onCancel : null}
      onSubmitTodo={classId === "todo" ? onSubmit : null}
      onPersistTodo={classId === "todo" ? onPersist : null}
      clippingInitialFormValues={
        classId === "clipping" ? initialFormValues : null
      }
      clippingMergeValues={classId === "clipping" ? mergeValues : null}
      clippingsQueryKey={classId === "clipping" ? queryKey : null}
      clippingOverlayOpen={classId === "clipping" ? overlayOpen : null}
      onCancelClipping={classId === "clipping" ? onCancel : null}
      onSubmitClipping={classId === "clipping" ? onSubmit : null}
      onPersistClipping={classId === "clipping" ? onPersist : null}
      tileInitialFormValues={classId === "tile" ? initialFormValues : null}
      tileMergeValues={classId === "tile" ? mergeValues : null}
      tilesQueryKey={classId === "tile" ? queryKey : null}
      tileOverlayOpen={classId === "tile" ? overlayOpen : null}
      onCancelTile={classId === "tile" ? onCancel : null}
      onSubmitTile={classId === "tile" ? onSubmit : null}
      onPersistTile={classId === "tile" ? onPersist : null}
      ecObjectActedUpon={ecArtifact}
    />
  );
};

export default AnyOrchestrateObjectFormOverlay;
