import React, { useEffect, useState } from "react";
import { Col, Row, Tag } from "antd";
import EmbeddedDatepicker from "./embeddedDatepicker";
import { getCurrentDateString } from "./dateTimePickerUtils";

const DatePickerWithPresetsPanel = ({
  value,
  width,
  onChange,
  presets,
  allowEmptyDate,
}) => {
  if (allowEmptyDate === undefined) {
    allowEmptyDate = false;
  }
  const [selectedValueAsString, setSelectedValueAsString] = useState(undefined);
  const [presetsState, setPresetsState] = useState([]);

  useEffect(() => {
    if (value) {
      setSelectedValueAsString(value);
    } else {
      if (!allowEmptyDate) {
        setSelectedValueAsString(getCurrentDateString());
      }
    }
  }, [value, allowEmptyDate]);

  useEffect(() => {
    if (presets) {
      if (allowEmptyDate) {
        setPresetsState([
          {
            label: "No date",
            value: null,
            codeString: "",
          },
          ...presets,
        ]);
      } else {
        setPresetsState([...presets]);
      }
    }
  }, [presets, allowEmptyDate]);

  return (
    <div
      style={{
        width: width,
      }}
    >
      <Row>
        {presetsState.map((preset, index) => {
          return (
            <Col
              key={index}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ padding: 5 }}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedValueAsString(preset.codeString);
                  onChange(preset.codeString);
                }}
              >
                <Tag color="#3478f6">{preset.label}</Tag>
              </a>
            </Col>
          );
        })}
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 5,
        }}
      >
        <EmbeddedDatepicker
          value={selectedValueAsString}
          onChange={(value) => {
            setSelectedValueAsString(value);
            onChange(value);
          }}
        />
      </div>
    </div>
  );
};

export default DatePickerWithPresetsPanel;
