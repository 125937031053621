import React from "react";
import { Alert, Col, Divider, Row, Space, Typography } from "antd";
import InboundForwardEventDetails from "./inboundForwardEventDetails";
import ResponsiveCard from "../common/generic/responsiveCard";
import useOrganizeEventsForInboundForward from "./hooks/useOrganizeEventsForInboundForward";
import dayjs from "dayjs";
import DateMiniCard from "../common/generic/dateMiniCard";
import DividerExceptOnLast from "../common/generic/dividerExceptOnLast";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const InboundForwardEvents = ({
  processingTimezone,
  tenantTimezone,
  events,
  onClickViewInMessage,
  onClickHelpIcon,
}) => {
  const { startDates, eventsByStartDate, showCardTitles } =
    useOrganizeEventsForInboundForward(
      events,
      processingTimezone,
      tenantTimezone,
    );

  const getDay = (startDateAsString) => {
    const eventsForDay = eventsByStartDate?.[startDateAsString] || [];

    let props = {};
    if (showCardTitles) {
      props = {
        styles: { header: { background: "#168dff", color: "#ffffff" } },
        title: <>{dayjs(startDateAsString).format("ddd, MMM D, YYYY")}</>,
      };
    }

    return (
      <>
        <Row style={{ paddingBottom: 20 }} wrap={false} key={startDateAsString}>
          <Col flex="none" className={"hide-on-small-screen"}>
            <DateMiniCard dayJsDate={dayjs(startDateAsString)} />
          </Col>
          <Col flex={"auto"} style={{ marginLeft: showCardTitles ? 0 : 8 }}>
            <ResponsiveCard {...props}>
              {eventsForDay?.map((eventPair, index) => {
                return (
                  <div key={index}>
                    <InboundForwardEventDetails
                      eventPair={eventPair}
                      onClickViewInMessage={(event) =>
                        onClickViewInMessage(event)
                      }
                    />
                    <DividerExceptOnLast
                      list={eventsForDay}
                      currentItem={eventPair}
                    />
                  </div>
                );
              })}
            </ResponsiveCard>
          </Col>
        </Row>
      </>
    );
  };

  const getJsx = () => {
    if (startDates && startDates.length > 0) {
      return (
        <>
          <Space style={{ marginBottom: 5 }}>
            <h2>Events</h2>
            <HelpOutlineIcon
              sx={{ fontSize: 25, color: "#ff4081", cursor: "pointer" }}
              onClick={() => onClickHelpIcon("events")}
            />
          </Space>
          <Alert
            message={
              <Typography.Text strong={true}>
                Wanna see all your events from all forwarded messages in a
                single list? <a href={"/login"}>Sign up</a> and achieve
                organizational bliss! 📅
              </Typography.Text>
            }
            type={"warning"}
            style={{ marginBottom: 10 }}
          />
          {startDates.map((startDate, index) => {
            return <div key={index}>{getDay(startDate)}</div>;
          })}
          <Divider />
        </>
      );
    }
  };

  return <>{getJsx()}</>;
};

export default InboundForwardEvents;
