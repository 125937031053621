import { useState } from "react";
import useGetAndSetSelectedFamilyId from "../../../../hooks/orchestrate/common/useGetAndSetSelectedFamilyId";
import useUpdateableMessage from "../../../../hooks/utils/useUpdateableMessage";
import useInvalidateQuery from "../../../../hooks/utils/useInvalidateQuery";
import useCreateFamily from "./useCreateFamily";
import { Form } from "antd";
import useGetCurrentUser from "../../../../hooks/orchestrate/user/useGetCurrentUser";

const useFamilyWorkspaceForm = (onSuccess) => {
  const [formFamily] = Form.useForm();
  const { data: currentUser } = useGetCurrentUser();
  const { setSelectedFamily } = useGetAndSetSelectedFamilyId();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { openLoadingMessage, openSuccessMessage, openErrorMessage } =
    useUpdateableMessage();
  const { invalidateQueryWhereKeyContains } = useInvalidateQuery();

  const createFamilyHook = useCreateFamily(
    () => {
      openLoadingMessage("createFamilyWorkspace", "Creating family workspace");
      setIsSubmitting(true);
    },
    async (savedFamily) => {
      openSuccessMessage("createFamilyWorkspace", "Family workspace created");
      setSelectedFamily(savedFamily.id);
      await invalidateQueryWhereKeyContains("current-user");
      setIsSubmitting(false);
      onSuccess(savedFamily);
    },
    () => {
      openErrorMessage(
        "createFamilyWorkspace",
        "Error creating family workspace",
      );
      setIsSubmitting(false);
    },
  );

  return {
    currentUser,
    formFamily,
    createFamilyHook,
    isSubmitting,
    setIsSubmitting,
  };
};

export default useFamilyWorkspaceForm;
