import { useQueryClient } from "@tanstack/react-query";

const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  const invalidateQueryWhereKeyIs = (queryKey) => {
    queryClient.invalidateQueries({ queryKey: queryKey }).then();
  };

  const invalidateQueryWhereKeyContains = (queryKeyIncludes) => {
    const queryCache = queryClient.getQueryCache();
    queryCache.getAll().map((queryObject) => {
      const queryKey = queryObject.queryKey;
      if (queryKey) {
        if (queryKey.includes(queryKeyIncludes)) {
          queryClient.invalidateQueries({ queryKey: queryKey }).then();
        }
      }
    });
  };

  return {
    invalidateQueryWhereKeyIs: invalidateQueryWhereKeyIs,
    invalidateQueryWhereKeyContains: invalidateQueryWhereKeyContains,
  };
};

export default useInvalidateQuery;
