import React from "react";
import { Button, Form, Input } from "antd";
import useFamilyWorkspaceForm from "../hooks/useFamilyWorkspaceForm";

const FamilyWorkspaceForm = ({ onSuccess }) => {
  const {
    currentUser,
    formFamily,
    createFamilyHook,
    isSubmitting,
    setIsSubmitting,
  } = useFamilyWorkspaceForm((savedFamily) => onSuccess(savedFamily));

  return (
    <Form
      form={formFamily}
      name="familyForm"
      layout="vertical"
      size="large"
      onFinish={async (values) => {
        setIsSubmitting(true);
        values.image_filename = "";
        values.image_url = "";
        createFamilyHook.mutate(values);
      }}
    >
      <Form.Item
        name="name"
        initialValue={
          currentUser?.family_name
            ? `The ${currentUser.family_name} Family`
            : ""
        }
        rules={[
          {
            required: true,
            message: "Come on. You know the drill. We need a name.",
          },
        ]}
      >
        <Input
          maxLength={100}
          placeholder="The Smith Family"
          disabled={isSubmitting}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          loading={isSubmitting}
          disabled={isSubmitting}
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Continue
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FamilyWorkspaceForm;
