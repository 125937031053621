import React from "react";
import { ALL_CLASSES_BY_CLASS_ID } from "../actionMappers/classesToActions";

const AnyDetailPanel = ({ ecObject, actionMenu }) => {
  const getDetailPanel = () => {
    if (ecObject) {
      const DetailPanelClass =
        ALL_CLASSES_BY_CLASS_ID[ecObject.class_id].detailPanelClass;
      return <DetailPanelClass ecObject={ecObject} actionMenu={actionMenu} />;
    } else {
      return <></>;
    }
  };

  return <>{getDetailPanel()}</>;
};

export default AnyDetailPanel;
