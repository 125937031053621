import React from "react";
import { Modal } from "antd";
import useGetTextByFingerprint from "./hooks/useGetTextByFingerprint";

const ImageTextModal = ({ fingerprint, open, onClose }) => {
  const { data: textByFingerprint } = useGetTextByFingerprint(fingerprint);

  return (
    <Modal open={open} title="" footer={null} onCancel={() => onClose()}>
      <p>{textByFingerprint?.fingerprint}</p>
      {textByFingerprint?.primary_text_lines?.map((item) => {
        return <div>{item}</div>;
      })}
    </Modal>
  );
};

export default ImageTextModal;
