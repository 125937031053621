import React from "react";
import { Typography } from "antd";
import OnboardingPageFrame from "../onboardingPageFrame";
import FamilyWorkspaceForm from "./forms/familyWorkspaceForm";
import UsersGivenName from "../../common/commonTldr/usersGivenName";
import useOnboardingPart1 from "./hooks/useOnboardingPart1";
import AbsoluteSpin from "../../common/generic/absoluteSpin";

const { Text } = Typography;

const FamilyWorkspaceCreation = () => {
  const { isLoadingOnboardingInfo } = useOnboardingPart1();

  const getStepJsx = () => {
    if (isLoadingOnboardingInfo) {
      return <AbsoluteSpin />;
    }

    return (
      <OnboardingPageFrame
        numSteps={0}
        currentStep={0}
        title={
          <>
            🎉 <UsersGivenName fallbackGivenName={"Friend"} />, it's time to
            name your family workspace!
          </>
        }
      >
        <div style={{ marginBottom: 15 }}>
          <Text type="secondary">Stay calm. You can change it later!</Text>
        </div>
        <FamilyWorkspaceForm onSuccess={(savedFamily) => {}} />
      </OnboardingPageFrame>
    );
  };

  return getStepJsx();
};

export default FamilyWorkspaceCreation;
