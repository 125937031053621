import React from "react";
import OverlayFormWrapper from "../../../common/formComponents/overlayFormWrapper";
import EditTileForm from "./editTileForm";

const EditTileOverlay = ({
  open,
  onOk,
  onCancel,
  initialValues,
  confirmLoading,
  formMergeValues,
  ecObject,
}) => {
  const titleText = "Edit tile";
  const okText = "Save";
  const cancelText = "Cancel";

  const handleOk = (values) => {
    const response = { ...values, ...formMergeValues };
    onOk(response);
  };

  return (
    <OverlayFormWrapper
      open={open}
      title={titleText}
      onOk={handleOk}
      okText={okText}
      onCancel={onCancel}
      cancelText={cancelText}
      confirmLoading={confirmLoading}
      initialValues={initialValues}
      formMergeValues={formMergeValues}
      formClass={EditTileForm}
      ecObject={ecObject}
      onValuesChange={() => {}}
      onValidationError={() => {}}
    />
  );
};

export default EditTileOverlay;
