import React from "react";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import EmailAddressCardCover from "../cardCovers/emailAddressCardCover";
import MiniCardTitleRow from "../miniCardParts/miniCardTitleRow";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";

const EmailAddressMiniCard = ({ ecObject, appContext, onClick }) => {
  return (
    <TldrCardWithCoverComponent
      coverComponent={<EmailAddressCardCover ecObject={ecObject} />}
      width={250}
      coverHeight={150}
      hoverable={false}
      onClick={onClick}
    >
      <MiniCardTitleRow
        titleText={""}
        tooltipText={"Email Address"}
        icon={<AlternateEmailOutlinedIcon sx={{ color: "#ff4081" }} />}
      />
    </TldrCardWithCoverComponent>
  );
};

export default EmailAddressMiniCard;
