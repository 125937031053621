import useQueryGenericGet from "../../../hooks/framework/useQueryGenericGet";
import { EC_SERVICE_INBOUND_FORWARDS_GET } from "../../../serviceUrls/serviceUrlsEmailCollector";
import { replacePlaceholdersInTemplate } from "../../../serviceUrls/serviceUrlHelpers";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR_NOAUTH } from "../../../serviceUrls/baseUrls";

const useGetInboundForward = (inboundForwardId) => {
  const endpointPath = replacePlaceholdersInTemplate(
    EC_SERVICE_INBOUND_FORWARDS_GET,
    {
      inboundForwardId,
    },
  );

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR_NOAUTH,
    endpointPath,
    false,
    [],
    {},
    !!inboundForwardId,
  );
};

export default useGetInboundForward;
