import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";
import { useState } from "react";
import useListOrchestrateObjects from "../../../../hooks/orchestrate/useListOrchestrateObjects";
import {
  O_SERVICE_FAMILY_MEMBERS,
  O_SERVICE_INVITES_CREATE,
} from "../../../../serviceUrls/serviceUrlsOrchestrate";
import useCreateOrchestrateObject from "../../../../hooks/orchestrate/useCreateOrchestrateObject";

const useSettingsFamilyMembers = () => {
  const {
    openLoadingMessage,
    openSuccessMessage,
    openErrorMessage,
    invalidateQueryWhereKeyContains,
  } = useTldrPageContext();

  const [modalOpen, setModalOpen] = useState(false);

  const { data: familyMembers, isLoading: isLoadingFamilyMembers } =
    useListOrchestrateObjects(O_SERVICE_FAMILY_MEMBERS, true);

  const createInviteHook = useCreateOrchestrateObject(
    O_SERVICE_INVITES_CREATE,
    null,
    () => {
      setModalOpen(false);
      openLoadingMessage("sendInvite", "Sending invite");
    },
    () => {
      openSuccessMessage("sendInvite", "📬 Invite dispatched!");
      invalidateQueryWhereKeyContains("invites");
    },
    (error) => {
      openErrorMessage("sendInvite", "Error sending invite");
      invalidateQueryWhereKeyContains("invites");
    },
  );

  const handleOk = (form) => {
    const formValues = form.getFieldsValue();
    createInviteHook.mutate({
      invitee_email: formValues.emailAddress,
    });
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  return {
    modalOpen,
    setModalOpen,
    isLoadingFamilyMembers,
    familyMembers,
    handleOk,
    handleCancel,
  };
};

export default useSettingsFamilyMembers;
