import { useEffect, useState } from "react";

function useGetCurrentUserInboxId(familyId, currentUser) {
  const [currentUserInboxId, setCurrentUserInboxId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (familyId && currentUser) {
      for (const i in currentUser.families) {
        const family = currentUser.families[i];
        if (family.id === parseInt(familyId)) {
          for (const j in family.inboxes) {
            const inbox = family.inboxes[j];
            if (inbox.owner_id === currentUser.id) {
              setCurrentUserInboxId(inbox.id);
            }
          }
        }
      }
      setIsLoading(false);
    }
  }, [familyId, currentUser]);

  return { currentUserInboxId, isLoading };
}

export default useGetCurrentUserInboxId;
