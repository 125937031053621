import React, { useEffect, useState } from "react";
import useGetCurrentUser from "../../../hooks/orchestrate/user/useGetCurrentUser";

const UsersGivenName = ({ fallbackGivenName }) => {
  const { data: currentUser } = useGetCurrentUser();
  const [givenName, setGivenName] = useState(fallbackGivenName);

  useEffect(() => {
    if (currentUser) {
      setGivenName(currentUser.given_name || fallbackGivenName);
    }
  }, [currentUser, fallbackGivenName]);

  return <>{givenName}</>;
};

export default UsersGivenName;
