import React from "react";
import TldrCardWithCoverComponent from "../../common/generic/tldrCardWithCoverComponent";
import TopicCardCover from "../cardCovers/topicCardCover";
import MiniCardTitleRow from "../miniCardParts/miniCardTitleRow";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";

const TopicMiniCard = ({ ecObject, appContext, onClick }) => {
  const cover = <TopicCardCover ecObject={ecObject} />;

  return (
    <TldrCardWithCoverComponent
      coverComponent={cover}
      width={250}
      coverHeight={150}
      hoverable={false}
      onClick={onClick}
      bodyPadding={8}
    >
      <MiniCardTitleRow
        titleText={ecObject.title}
        tooltipText={"Event"}
        icon={<TagOutlinedIcon sx={{ color: "#ff4081" }} />}
      />
    </TldrCardWithCoverComponent>
  );
};

export default TopicMiniCard;
