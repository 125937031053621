import React from "react";
import FamilyMemberAvatarIcon from "../../../familyMembers/familyMemberAvatarIcon";
import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";

const TodoAvatarPart = ({ todoItem }) => {
  const { familyId } = useTldrPageContext();

  const getAvatar = () => {
    const userId = todoItem.completed_at
      ? todoItem.completed_by_id
      : todoItem.assigned_to_id;
    return <FamilyMemberAvatarIcon familyId={familyId} userId={userId} />;
  };

  return getAvatar();
};

export default TodoAvatarPart;
