import React from "react";
import TldrCardWithCoverComponent from "./tldrCardWithCoverComponent";
import ArtifactCardInner from "./artifactCardInner";

const ArtifactCard = ({ cover, position1, position2, position3 }) => {
  return (
    <TldrCardWithCoverComponent
      coverComponent={cover}
      width={"auto"}
      coverHeight={300}
      hoverable={false}
      onClick={null}
      bodyPadding={8}
    >
      <ArtifactCardInner
        position1={position1}
        position2={position2}
        position3={position3}
      />
    </TldrCardWithCoverComponent>
  );
};

export default ArtifactCard;
