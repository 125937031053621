import React, { useEffect, useRef, useState } from "react";
import HtmlStringToReactComponent from "../common/generic/htmlStringToReactComponent";
import MessageInfoForSimpleMessageViewer from "../inboxesAndMessages/message/simpleViewer/messageInfoForSimpleMessageViewer";
import { Button, Col, Divider, Row } from "antd";
import FullScreenOverlay from "../common/generic/fullScreenOverlay";

const InboundForwardMessageViewer = ({
  open,
  onClose,
  messageObject,
  htmlAsString,
  tlecIndexesToHighlight,
  scrollToFirstHighlight,
}) => {
  if (!scrollToFirstHighlight) {
    scrollToFirstHighlight = true;
  }
  const refContainer = useRef(null);
  const [tlecIndexesToHighlightState, setTlecIndexesToHighlightState] =
    useState([]);

  useEffect(() => {
    if (tlecIndexesToHighlight) {
      setTlecIndexesToHighlightState(
        tlecIndexesToHighlight.filter((item) => {
          return item > 1;
        }),
      );
    }
  }, [tlecIndexesToHighlight]);

  const applyHighlights = () => {
    const containedIndexes = [];
    tlecIndexesToHighlightState.forEach((index) => {
      const element = document.querySelector(`[tlec-index="${index}"]`);
      tlecIndexesToHighlightState.forEach((innerIndex) => {
        if (index !== innerIndex) {
          const innerElement = document.querySelector(
            `[tlec-index="${innerIndex}"]`,
          );
          if (element && element.contains(innerElement)) {
            containedIndexes.push(innerIndex);
          }
        }
      });
    });

    tlecIndexesToHighlightState.forEach((index) => {
      if (!containedIndexes.includes(index)) {
        const element = document.querySelector(`[tlec-index="${index}"]`);
        if (element) {
          element.classList.add("tldr-message-highlight");
          const children = element.querySelectorAll("*");
          children.forEach((child) => {
            child.classList.add("tldr-message-highlight-child");
          });
        }
      }
    });
  };

  const scrollToElementWithId = () => {
    if (refContainer.current) {
      refContainer.current
        ?.querySelector(".tldr-message-highlight")
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <FullScreenOverlay title={"Original Message"} open={open} onClose={onClose}>
      <Row justify={"space-between"} gutter={[16, 16]}>
        <Col>
          <MessageInfoForSimpleMessageViewer messageObject={messageObject} />
        </Col>
        {tlecIndexesToHighlightState?.length > 0 && (
          <Col>
            <Button type={"primary"} onClick={() => scrollToElementWithId()}>
              Scroll to highlight
            </Button>
          </Col>
        )}
      </Row>

      <Divider />
      <HtmlStringToReactComponent
        htmlAsString={htmlAsString}
        onComponentDidMount={() => {
          applyHighlights();
          if (scrollToFirstHighlight) {
            scrollToElementWithId();
          }
        }}
        attachments={messageObject?.attachments}
        ref={refContainer}
      />
    </FullScreenOverlay>
  );
};

export default InboundForwardMessageViewer;
