import React from "react";
import OverlayFormWrapper from "../../common/formComponents/overlayFormWrapper";
import CreateFamilyForm from "./createFamilyForm";

const CreateFamilyOverlay = ({
  open,
  onOk,
  onCancel,
  initialValues,
  confirmLoading,
  formMergeValues,
}) => {
  const titleText = "Create family workspace";
  const okText = "Create";
  const cancelText = "Cancel";

  const handleOk = (values) => {
    const response = { ...values, ...formMergeValues };
    onOk(response);
  };

  return (
    <OverlayFormWrapper
      open={open}
      title={titleText}
      onOk={handleOk}
      okText={okText}
      onCancel={onCancel}
      cancelText={cancelText}
      confirmLoading={confirmLoading}
      initialValues={initialValues}
      formMergeValues={formMergeValues}
      formClass={CreateFamilyForm}
      ecObject={null}
      onValuesChange={() => {}}
      onValidationError={() => {}}
    />
  );
};

export default CreateFamilyOverlay;
