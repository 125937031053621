import React from "react";
import {
  FileExcelOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
} from "@ant-design/icons";

export const getIconForContentType = (contentType) => {
  if (contentType.startsWith("application/pdf")) {
    return <FilePdfOutlined />;
  } else if (
    contentType.startsWith("application/vnd.ms-excel") ||
    contentType.startsWith(
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    )
  ) {
    return <FileExcelOutlined />;
  } else if (contentType.startsWith("image/gif")) {
    return <FileGifOutlined />;
  } else if (contentType.startsWith("image/jpeg")) {
    return <FileJpgOutlined />;
  } else if (
    contentType.startsWith("application/vnd.ms-powerpoint") ||
    contentType.startsWith(
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    )
  ) {
    return <FilePptOutlined />;
  } else if (
    contentType.startsWith("application/gzip") ||
    contentType.startsWith("application/x-bzip,application/x-bzip2") ||
    contentType.startsWith("application/zip")
  ) {
    return <FileZipOutlined />;
  } else if (
    contentType.startsWith("application/msword") ||
    contentType.startsWith(
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    )
  ) {
    return <FileWordOutlined />;
  } else if (contentType.startsWith("text/")) {
    return <FileTextOutlined />;
  } else if (contentType.startsWith("image/")) {
    return <FileImageOutlined />;
  } else {
    return <FileOutlined />;
  }
};
