import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "../framework/apiClient";
import useOrchestrateServiceUrlHelper from "./useOrchestrateServiceUrlHelper";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../serviceUrls/baseUrls";
import { replacePlaceholdersInTemplate } from "../../serviceUrls/serviceUrlHelpers";

const useUpdateOrchestrateObject = (
  endpointTemplateUrl,
  queryKey,
  onSubmit,
  onSuccess,
  onError,
) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);
  const queryClient = useQueryClient();
  const { endpointPath } = useOrchestrateServiceUrlHelper(
    endpointTemplateUrl,
    {},
  );

  return useMutation({
    mutationFn: async (data) => {
      const { id, ...restOfData } = data;
      const token = await getAccessTokenSilently();
      const finalEndpoint = replacePlaceholdersInTemplate(endpointPath, {
        objectId: id,
      });
      return getApiClientWithBaseUrl(
        BACKEND_API_BASE_URL_ORCHESTRATE,
        finalEndpoint,
        token,
      ).patch(restOfData);
    },

    onMutate: (unsaved) => {
      if (queryKey) {
        const previousItems = queryClient.getQueryData(queryKey) || [];
        const updatedItems = previousItems.map((item) => {
          if (item.id === unsaved.id) {
            return { ...item, ...unsaved };
          } else {
            return item;
          }
        });
        queryClient.setQueryData(queryKey, updatedItems);
        if (onSubmit) onSubmit(unsaved);
        return { previousItems };
      } else {
        if (onSubmit) onSubmit(unsaved);
      }
    },

    onSuccess: (saved, submitted) => {
      queryClient.invalidateQueries({ queryKey: queryKey }).then();
      if (onSuccess) onSuccess(saved);
    },

    onError: (error, unsaved, context) => {
      if (context) {
        queryClient.setQueryData(queryKey, context.previousItems);
      }
      queryClient.invalidateQueries({ queryKey: queryKey }).then();
      if (onError) onError(error, unsaved);
    },
  });
};

export default useUpdateOrchestrateObject;
