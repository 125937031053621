import React, { useState } from "react";
import { Button, Card, Space } from "antd";
import useGetCurrentUser from "../../../hooks/orchestrate/user/useGetCurrentUser";
import { useNavigate } from "react-router-dom";
import useTldrMePageContext from "../hooks/useTldrMePageContext";
import { UsergroupAddOutlined } from "@ant-design/icons";
import CreateFamilyOverlay from "./createFamilyOverlay";
import useCreateFamily from "../../onboarding/createWorkspace/hooks/useCreateFamily";

const EMPTY_FORM = { name: "" };

const MyFamilies = () => {
  const { openLoadingMessage, openSuccessMessage, openErrorMessage } =
    useTldrMePageContext();
  const navigate = useNavigate();

  const [modalCreateFamilyOpen, setModalCreateFamilyOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [formFieldValues, setFormValues] = useState(EMPTY_FORM);
  const { data: currentUser, refetch: refetchCurrentUser } =
    useGetCurrentUser(false);

  const createFamilyHook = useCreateFamily(
    () => {
      openLoadingMessage("creatingFamily", "Creating family workspace");
      setModalCreateFamilyOpen(false);
    },
    (savedFamily) => {
      openSuccessMessage("creatingFamily", "Family workspace created");
      setConfirmLoading(false);
      setFormValues(EMPTY_FORM);
      refetchCurrentUser().then();
    },
    (error) => {
      openErrorMessage("creatingFamily", "Error creating family workspace");
      setModalCreateFamilyOpen(false);
      setConfirmLoading(false);
      setFormValues(EMPTY_FORM);
    },
  );

  const handleOk = (values) => {
    setConfirmLoading(true);
    values.id = "";
    values.image_filename = "";
    values.image_url = "";
    createFamilyHook.mutate(values);
  };

  const handleCancel = () => {
    setModalCreateFamilyOpen(false);
    setConfirmLoading(false);
    setFormValues(EMPTY_FORM);
  };

  const getFamilyCards = () => {
    if (currentUser) {
      return currentUser.families.map((family, index) => {
        // const queryInfo = ecQueryInfoByFamilyId[family.id] || null;
        return (
          <Card key={index} title={family.name} style={{ marginBottom: 20 }}>
            <Space direction={"vertical"}>
              <Button
                onClick={() => navigate(`/families/${family.id}`)}
                style={{ width: 240 }}
              >
                Family Workspace
              </Button>
              <Button
                onClick={() =>
                  navigate(`/families/${family.id}/settings/members`)
                }
                style={{ width: 240 }}
              >
                Family Settings
              </Button>
            </Space>
          </Card>
        );
      });
    }
  };

  const getJsx = () => {
    return (
      <>
        <CreateFamilyOverlay
          open={modalCreateFamilyOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          initialValues={formFieldValues}
          confirmLoading={confirmLoading}
          formMergeValues={null}
        />
        <div style={{ marginBottom: 20, textAlign: "right" }}>
          <Button
            type={"primary"}
            ghost={true}
            onClick={() => {
              setModalCreateFamilyOpen(true);
            }}
            icon={<UsergroupAddOutlined />}
          >
            Create Family
          </Button>
        </div>
        {getFamilyCards()}
      </>
    );
  };

  return getJsx();
};

export default MyFamilies;
