import React from "react";
import { Typography } from "antd";
import CardCenteredTextCover from "../../common/generic/cardCenteredTextCover";

const TopicCardCover = ({ ecObject }) => {
  return (
    <CardCenteredTextCover>
      <Typography.Title level={3} ellipsis={true}>
        <span style={{ fontSize: 25 }}>{ecObject.emoji}</span> {ecObject.title}
      </Typography.Title>
      {ecObject.description && (
        <Typography.Paragraph ellipsis={{ rows: 4 }}>
          {ecObject.description}
        </Typography.Paragraph>
      )}
    </CardCenteredTextCover>
  );
};

export default TopicCardCover;
