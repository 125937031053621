import React from "react";
import ArtifactCard from "../../common/generic/artifactCard";
import ArtifactCardWithCoverImage from "../../common/generic/artifactCardWithCoverImage";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { Tooltip } from "antd";
import AttachmentTextCover from "../cardCovers/attachmentTextCover";

const AttachmentCard = ({
  ecObject,
  cardProperties,
  onClickViewEcObjectDetails,
  sourceMessageInfo,
  actionMenu,
}) => {
  const isImage = ecObject?.mime_type.startsWith("image/");
  const hasPdfPreview = !!ecObject?.preview_signed_url;

  const getAttachmentIconAndTooltip = () => {
    return (
      <Tooltip title={"Attachment"}>
        <AttachFileOutlinedIcon sx={{ color: "#ff4081" }} />
      </Tooltip>
    );
  };

  const getStandardCardWithTextDescription = () => {
    const onClick = () => {
      window.open(ecObject.signed_url, "_blank");
    };

    return (
      <ArtifactCard
        cover={<AttachmentTextCover onClick={onClick} ecObject={ecObject} />}
        position1={sourceMessageInfo}
        position2={getAttachmentIconAndTooltip()}
        position3={actionMenu}
      />
    );
  };

  const getCardCapableOfPreview = () => {
    return (
      <ArtifactCardWithCoverImage
        imageSrc={
          hasPdfPreview ? ecObject.preview_signed_url : ecObject.signed_url
        }
        imageSize={null}
        alt={ecObject?.filename || ""}
        preview={true}
        position1={sourceMessageInfo}
        position2={getAttachmentIconAndTooltip()}
        position3={actionMenu}
      />
    );
  };

  const getCard = () => {
    if (isImage || hasPdfPreview) {
      return getCardCapableOfPreview();
    } else {
      return getStandardCardWithTextDescription();
    }
  };

  return getCard();
};

export default AttachmentCard;
