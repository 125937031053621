import React, { useEffect, useState } from "react";
import NavButton from "./navButton";
import { Divider } from "antd";

const NavButtonRow = ({
  buttonHeight,
  navButtonItems,
  bottomSpacer,
  sectionTitle,
}) => {
  const [widthPercentString, setWidthPercentString] = useState(undefined);

  useEffect(() => {
    if (navButtonItems) {
      const percent = 100 / navButtonItems.length;
      setWidthPercentString(`${Math.floor(percent).toString()}%`);
    }
  }, [navButtonItems]);

  const getSectionTitle = (sectionTitle) => {
    if (sectionTitle === "") {
      return <Divider />;
    } else if (!sectionTitle) {
      return <></>;
    } else {
      return <Divider orientation="left">{sectionTitle}</Divider>;
    }
  };

  return (
    <>
      {widthPercentString ? (
        <>
          {getSectionTitle(sectionTitle)}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {navButtonItems.map((item, index) => (
              <NavButton
                key={index}
                width={widthPercentString}
                height={buttonHeight}
                text={item.text}
                icon={item.icon}
                onClick={item.onClick}
              />
            ))}
          </div>
          {bottomSpacer ? <div style={{ height: bottomSpacer }} /> : <></>}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default NavButtonRow;
