import useQueryGenericGet from "../hooks/framework/useQueryGenericGet";
import { BACKEND_API_BASE_URL_EMAIL_COLLECTOR } from "../serviceUrls/baseUrls";
import { EC_SERVICE_UNROLL_ANY_ARTIFACT_GET } from "../serviceUrls/serviceUrlsEmailCollector";
import useEmailCollectorServiceUrlHelper from "../hooks/emailCollector/useEmailCollectorServiceUrlHelper";

const useUnrollAnyArtifact = (classId, hashKey, sortKey) => {
  const { endpointPath, currentFamily } = useEmailCollectorServiceUrlHelper(
    EC_SERVICE_UNROLL_ANY_ARTIFACT_GET,
    { classId, hashKey, sortKey },
  );

  return useQueryGenericGet(
    BACKEND_API_BASE_URL_EMAIL_COLLECTOR,
    endpointPath,
    true,
    [],
    {},
    !!(classId && currentFamily),
  );
};

export default useUnrollAnyArtifact;
