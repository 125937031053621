import { useNavigate, useParams } from "react-router-dom";
import useGetCurrentUser from "../../../hooks/orchestrate/user/useGetCurrentUser";
import useInvalidateQuery from "../../../hooks/utils/useInvalidateQuery";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../../context/auth0UniversalProviderContext";
import useGetInviteGivenCode from "./useGetInviteGivenCode";
import { useEffect } from "react";

const useViewInvitationDetailsFlow = () => {
  const navigate = useNavigate();

  const { data: currentUser } = useGetCurrentUser();

  let { inviteCode } = useParams();
  const { data: invite } = useGetInviteGivenCode(inviteCode);

  const { loginWithPopup, isAuth0Loading, isAuthenticated } = useAuth0(
    Auth0UniversalProviderContext,
  );
  const { invalidateQueryWhereKeyContains } = useInvalidateQuery();

  useEffect(() => {
    if (isAuthenticated && currentUser && invite) {
      navigate("/confirm-invites");
    }
  }, [isAuthenticated, currentUser, invite, navigate]);

  return {
    invite,
    loginWithPopup,
    invalidateQueryWhereKeyContains,
    isAuth0Loading,
    isAuthenticated,
  };
};

export default useViewInvitationDetailsFlow;
