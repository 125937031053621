import React, { useEffect, useState } from "react";
import {
  DISPLAY_DATE_FORMAT_LONG,
  DISPLAY_TIME_FORMAT,
  DUMMY_DATE_STRING,
  DUMMY_TIME_STRING,
  getDayJsDatetimeFromStrings,
} from "../../../utils/dayJsUtils";
import {
  getCurrentDateString,
  getCurrentTimeStringFlooredToHour,
} from "./dateTimePickerUtils";
import CollapsibleContentArea from "./collapsibleContentArea";
import DatePickerWithPresetsPanel from "./datePickerWithPresetsPanel";
import TimeSelectorWithPresetsPanel from "./timeSelectorWithPresetsPanel";

const DatetimePickerWithPresets = ({
  value,
  onChange,
  allowDateChoice,
  allowTimeChoice,
  datePresets,
  timePresets,
  editPanelOpen,
  onEditPanelOpenChange,
}) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [isEditMode, setIsEditMode] = useState(editPanelOpen);

  useEffect(() => {
    setIsEditMode(editPanelOpen);
  }, [editPanelOpen]);

  useEffect(() => {
    if (value?.date) {
      setSelectedDate(value.date);
      if (value?.time) {
        setSelectedTime(value.time);
      } else {
        if (!allowTimeChoice) {
          setSelectedTime(getCurrentTimeStringFlooredToHour());
        } else {
          setSelectedTime("");
        }
      }
    } else {
      if (!allowDateChoice) {
        setSelectedDate(getCurrentDateString());
      }
    }
  }, [value, allowDateChoice, allowTimeChoice]);

  const reportOnChangeToParent = (date, time) => {
    const data = { date: date };
    if (time) {
      data.time = time;
    }
    onChange(data);
  };

  const handleTimePickerChange = (value) => {
    if (!selectedDate && value !== "") {
      const newDate = getCurrentDateString();
      reportOnChangeToParent(newDate, value);
      setSelectedDate(newDate);
    } else {
      reportOnChangeToParent(selectedDate, value);
    }
    setSelectedTime(value);
    setIsEditMode(false);
  };

  const handleDatePickerChange = (value) => {
    if (value === "") {
      reportOnChangeToParent(value, "");
      setSelectedTime("");
    } else {
      reportOnChangeToParent(value, selectedTime);
    }
    setSelectedDate(value);
    setIsEditMode(false);
  };

  const getContentItemsForCollapse = () => {
    const contentItems = [
      {
        buttonText: selectedDate
          ? getDayJsDatetimeFromStrings(selectedDate, DUMMY_TIME_STRING).format(
              DISPLAY_DATE_FORMAT_LONG,
            )
          : "[No date]",
        content: (
          <DatePickerWithPresetsPanel
            value={selectedDate}
            width={300}
            onChange={(value) => handleDatePickerChange(value)}
            presets={datePresets}
            allowEmptyDate={allowDateChoice}
          />
        ),
      },
    ];

    contentItems.push({
      buttonText: selectedTime
        ? getDayJsDatetimeFromStrings(DUMMY_DATE_STRING, selectedTime).format(
            DISPLAY_TIME_FORMAT,
          )
        : "[No time]",
      content: (
        <TimeSelectorWithPresetsPanel
          value={selectedTime}
          width={300}
          onChange={(value) => {
            handleTimePickerChange(value);
          }}
          presets={timePresets}
          allowEmptyTime={allowTimeChoice}
        />
      ),
    });

    return contentItems;
  };

  return (
    <CollapsibleContentArea
      open={isEditMode}
      width={310}
      items={getContentItemsForCollapse()}
      onOpenChange={(value) => {
        setIsEditMode(value);
        onEditPanelOpenChange?.(value);
      }}
    />
  );
};

export default DatetimePickerWithPresets;
