import useTldrPageContext from "../../../../hooks/orchestrate/common/useTldrPageContext";
import useListOrchestrateObjects from "../../../../hooks/orchestrate/useListOrchestrateObjects";
import {
  O_SERVICE_INVITES_DELETE,
  O_SERVICE_INVITES_LIST,
} from "../../../../serviceUrls/serviceUrlsOrchestrate";
import useResendInviteEmail from "../../../invites/hooks/useResendInviteEmail";
import useDeleteOrchestrateObject from "../../../../hooks/orchestrate/useDeleteOrchestrateObject";

const useSettingsInvites = () => {
  const { openLoadingMessage, openSuccessMessage, openErrorMessage } =
    useTldrPageContext();
  const { data: invites, queryKey } = useListOrchestrateObjects(
    O_SERVICE_INVITES_LIST,
    true,
  );

  const resendInviteEmailHook = useResendInviteEmail(
    () => openLoadingMessage("resendInvite", "Re-sending invite"),
    () => {
      openSuccessMessage("resendInvite", "Invite re-sent");
    },
    () => {
      openErrorMessage("resendInvite", "Error re-sending invite");
    },
  );

  const deleteInviteHook = useDeleteOrchestrateObject(
    O_SERVICE_INVITES_DELETE,
    queryKey,
    () => {
      openLoadingMessage("deleteInvite", "Deleting invite");
    },
    () => {
      openSuccessMessage("deleteInvite", "Invite deleted");
    },
    () => {
      openErrorMessage("deleteInvite", "Error deleting invite");
    },
  );

  const handleDeleteInvite = async (inviteId) => {
    deleteInviteHook.mutate({ objectId: inviteId });
  };

  const handleResendInvite = async (inviteId) => {
    resendInviteEmailHook.mutate(inviteId);
  };

  return { invites, handleDeleteInvite, handleResendInvite };
};

export default useSettingsInvites;
