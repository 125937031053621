import React from "react";
import { Card, Divider } from "antd";

const TldrCardWithCoverComponent = ({
  coverComponent,
  width,
  coverHeight,
  hoverable,
  onClick,
  bodyPadding,
  children,
}) => {
  if (!width) {
    width = "auto";
  }

  if (!bodyPadding) {
    bodyPadding = 8;
  }

  return (
    <Card
      hoverable={hoverable}
      style={{ width: width, cursor: !!onClick && "pointer" }}
      styles={{ body: { padding: 0 } }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <div
        style={{
          width: "100%",
          height: coverHeight,
          overflow: "clip",
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
        }}
      >
        {coverComponent}
      </div>
      {!!children && (
        <>
          <Divider style={{ margin: 0 }} />
          <div style={{ width: "100%", padding: bodyPadding }}>{children}</div>
        </>
      )}
    </Card>
  );
};

export default TldrCardWithCoverComponent;
