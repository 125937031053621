import React, { useEffect, useState } from "react";
import useListOrchestrateObjects from "../../../hooks/orchestrate/useListOrchestrateObjects";
import { O_SERVICE_GOOGLE_EVENTS_LIST } from "../../../serviceUrls/serviceUrlsOrchestrate";
import useBatchGetArtifacts from "../../../hooks/emailCollector/useBatchGetArtifacts";
import useTldrUniversalMutateContext from "../../../hooks/utils/useTldrUniversalMutateContext";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import { List } from "antd";
import SavedObjectDetailsOverlay from "../savedObjectDetailsOverlay";
import LoadingEmptyOrContent from "../../common/generic/loadingEmptyOrContent";
import GoogleEventListItem from "./googleEventListItem";
import HelpIconAndModal from "../../helpIconAndModal";
import GoogleEventsEducationModal from "./googleEventsEducationModal";

const GoogleEvents = () => {
  const { setBreadcrumbs, showDeleteConfirm, setBreadcrumbExtra } =
    useTldrPageContext();

  const {
    queryKey: googleEventQueryKey,
    data: googleEvents,
    isLoading: isLoadingGoogleEvents,
  } = useListOrchestrateObjects(O_SERVICE_GOOGLE_EVENTS_LIST, true, {});

  const { data: googleEventData } = useBatchGetArtifacts(googleEvents);

  const [detailsOverlayOpen, setDetailsOverlayOpen] = useState(false);
  const [dataForDetailsOverlay, setDataForDetailsOverlay] = useState(null);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Added to your Google Calendar",
      },
    ]);
    setBreadcrumbExtra(
      <HelpIconAndModal educationModalClass={GoogleEventsEducationModal} />,
    );
  }, [setBreadcrumbs, setBreadcrumbExtra]);

  const { deleteGoogleEvent } = useTldrUniversalMutateContext(
    null,
    googleEventQueryKey,
    null,
    null,
    null,
    null,
  );

  const handleClickViewDetails = (googleEvent, googleEventData) => {
    setDataForDetailsOverlay({
      savedObject: googleEvent,
      savedObjectData: googleEventData,
    });
    setDetailsOverlayOpen(true);
  };

  const handleClickDelete = (googleEvent) => {
    showDeleteConfirm(
      "Delete Google Calendar Event from TLDR Parents?",
      "Note: This will not delete the event from Google Calendar.",
      () => deleteGoogleEvent(googleEvent.id),
    );
  };

  const getListItem = (googleEvent) => {
    return (
      <GoogleEventListItem
        googleEvent={googleEvent}
        googleEventData={googleEventData}
        onClickViewDetails={handleClickViewDetails}
        onClickDelete={() => handleClickDelete(googleEvent)}
      />
    );
  };

  const getJsx = () => {
    return (
      <>
        <SavedObjectDetailsOverlay
          open={detailsOverlayOpen}
          onClose={() => {
            setDetailsOverlayOpen(false);
            setDataForDetailsOverlay(null);
          }}
          savedObject={dataForDetailsOverlay?.savedObject}
          savedObjectData={dataForDetailsOverlay?.savedObjectData}
        />
        <GoogleEventsEducationModal forced={true} />
        <LoadingEmptyOrContent
          isLoading={isLoadingGoogleEvents}
          isEmpty={!googleEvents || googleEvents.length === 0}
        >
          <List
            itemLayout="horizontal"
            dataSource={googleEvents}
            renderItem={(googleEvent) => {
              return getListItem(googleEvent);
            }}
          />
        </LoadingEmptyOrContent>
      </>
    );
  };

  return <>{getJsx()}</>;
};

export default GoogleEvents;
