import React from "react";
import ConfirmInvitesList from "../invites/confirmInvitesList";
import useListInvitesForCurrentUser from "../invites/hooks/useListInvitesForCurrentUser";

const MyInvites = () => {
  const { data: invites } = useListInvitesForCurrentUser();

  return <ConfirmInvitesList invites={invites} />;
};

export default MyInvites;
