import React, { forwardRef, useEffect, useRef, useState } from "react";
import parse from "html-react-parser";

const HtmlStringToReactComponentInner = (
  { htmlAsString, parseOptions, onComponentDidMount },
  ref,
) => {
  const [htmlAsParsedComponent, setHtmlAsParsedComponent] = useState(<></>);
  const refComponentDidMount = useRef(false);

  useEffect(() => {
    if (refComponentDidMount.current) {
      onComponentDidMount();
    } else {
      refComponentDidMount.current = true;
    }
  });

  useEffect(() => {
    if (htmlAsString) {
      setHtmlAsParsedComponent(parse(htmlAsString, parseOptions));
    }
  }, [htmlAsString, parseOptions]);

  return <div ref={ref}>{htmlAsParsedComponent}</div>;
};

export default forwardRef(HtmlStringToReactComponentInner);
