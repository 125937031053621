import React from "react";
import UserEducationModal from "../userEducation/userEducationModal";
import { Modal } from "antd";

const EventSuggestionsEducationModal = ({ forced, open, onClose }) => {
  const title = "Event Suggestions 🪄";
  const inner = (
    <>
      <p>
        Keeping track of all your family’s events can be overwhelming,
        especially when they're buried in countless emails. That's where Event
        Suggestions comes in! Event Suggestions magically extracts and displays
        all your upcoming events from your emails in one easy-to-view place.
      </p>
      <p>
        With Event Suggestions, you no longer need to sift through individual
        messages to find important dates and activities. Simply visit Event
        Suggestions to see a chronological list of all events, helping you stay
        organized and on top of your family's schedule with ease.
      </p>
      <h3>Workflow Tips 🔧</h3>
      <ul>
        <li>
          Feeling swamped by emails? Let Event Suggestions show you what's on
          the horizon!
        </li>
        <li>
          Curious about the details? Click through to see each event in the
          original email.
        </li>
        <li>
          Not interested in certain events? Easily hide them. Psyched about an
          event? Add it to your Google Calendar with a single click!
        </li>
      </ul>
    </>
  );

  if (!!forced) {
    return (
      <UserEducationModal title={title} subjects={["EXPLAIN_MAGIC_CALENDAR"]}>
        {inner}
      </UserEducationModal>
    );
  } else {
    return (
      <Modal
        title={title}
        open={open}
        closable={true}
        onCancel={() => onClose()}
        onOk={null}
        footer={null}
      >
        {inner}
      </Modal>
    );
  }
};

export default EventSuggestionsEducationModal;
