import React from "react";
import { Modal } from "antd";

const TrialEducationModal = ({ title, open, children, onGotIt }) => {
  return (
    <Modal
      title={title || ""}
      open={open}
      onCancel={onGotIt}
      onOk={onGotIt}
      footer={null}
    >
      {children}
    </Modal>
  );
};

export default TrialEducationModal;
