import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useSearchParamPagination from "../../../../hooks/utils/useSearchParamPagination";
import useGetNextMessageIds from "./useGetNextMessageIds";
import useGetPrevMessageIds from "./useGetPrevMessageIds";

const PAGE_SIZE = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);

const useMessageHeaderOutlet = (archived) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { uniqueMessageId: currentUniqueMessageId } = useParams();

  const [startingPointUniqueMessageId, setStartingPointUniqueMessageId] =
    useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [position, setPosition] = useState(null);
  const [messageIds, setMessageIds] = useState([]);
  const [hasNext, setHasNext] = useState(null);
  const [hasPrev, setHasPrev] = useState(null);

  const { orderBy } = useSearchParamPagination({
    order: "message_date",
  });

  useEffect(() => {
    if (!startingPointUniqueMessageId) {
      setStartingPointUniqueMessageId(currentUniqueMessageId);
    }
  }, [startingPointUniqueMessageId, currentUniqueMessageId]);

  const { data: nextIds } = useGetNextMessageIds(
    startingPointUniqueMessageId,
    archived,
    PAGE_SIZE,
    orderBy,
  );

  const { data: prevIds } = useGetPrevMessageIds(
    startingPointUniqueMessageId,
    archived,
    PAGE_SIZE,
    orderBy,
  );

  useEffect(() => {
    if (prevIds && nextIds && startingPointUniqueMessageId) {
      setTotalCount(prevIds.found + nextIds.found + 1);
      const prevIdsLocal = prevIds.items;
      prevIdsLocal.reverse();
      setMessageIds([
        ...prevIdsLocal,
        startingPointUniqueMessageId,
        ...nextIds.items,
      ]);
    } else {
      setMessageIds([]);
    }
  }, [prevIds, startingPointUniqueMessageId, nextIds]);

  useEffect(() => {
    if (currentUniqueMessageId && nextIds && messageIds?.length > 0) {
      const index = messageIds.findIndex((x) => x === currentUniqueMessageId);
      if (index === messageIds.length - 1) {
        if (nextIds.found > nextIds.items.length) {
          setStartingPointUniqueMessageId(null);
          setHasNext(null);
        } else {
          setHasNext(false);
        }
      } else {
        setHasNext(true);
      }
    }
  }, [currentUniqueMessageId, nextIds, messageIds]);

  useEffect(() => {
    if (currentUniqueMessageId && prevIds && messageIds?.length > 0) {
      const index = messageIds.findIndex((x) => x === currentUniqueMessageId);
      if (index === 0) {
        if (prevIds.found > prevIds.items.length) {
          setStartingPointUniqueMessageId(null);
          setHasPrev(null);
        } else {
          setHasPrev(false);
        }
      } else {
        setHasPrev(true);
      }
    }
  }, [currentUniqueMessageId, prevIds, messageIds]);

  useEffect(() => {
    if (prevIds && currentUniqueMessageId && messageIds?.length > 0) {
      const index = messageIds.findIndex((x) => x === currentUniqueMessageId);
      setPosition(index - prevIds.items.length + prevIds.found + 1);
    }
  }, [currentUniqueMessageId, prevIds, messageIds]);

  const handleClickNext = () => {
    if (hasNext) {
      const index = messageIds.findIndex((x) => x === currentUniqueMessageId);
      const newPathname = location.pathname.replace(
        currentUniqueMessageId,
        messageIds[index + 1],
      );
      navigate({
        pathname: newPathname,
        search: searchParams.toString(),
      });
    }
  };

  const handleClickPrev = () => {
    if (hasPrev) {
      const index = messageIds.findIndex((x) => x === currentUniqueMessageId);
      const newPathname = location.pathname.replace(
        currentUniqueMessageId,
        messageIds[index - 1],
      );
      navigate({
        pathname: newPathname,
        search: searchParams.toString(),
      });
    }
  };

  return {
    currentUniqueMessageId,
    totalCount,
    position,
    messageIds,
    hasNext,
    hasPrev,
    handleClickPrev,
    handleClickNext,
  };
};

export default useMessageHeaderOutlet;
