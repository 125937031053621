import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Select } from "antd";

dayjs.extend(utc);
dayjs.extend(timezone);

const TIMEZONE_MAPPING = {
  "America/New_York": "US Eastern",
  "America/Chicago": "US Central",
  "America/Denver": "US Mountain",
  "America/Phoenix": "US Arizona",
  "America/Los_Angeles": "US Pacific",
  "America/Anchorage": "US Alaska",
  "Pacific/Honolulu": "US Hawaii-Aleutian",
};

const TimeZoneSelector = ({ initiallySelectedTimezone, onChange }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(undefined);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [allTimezoneOptions, setAllTimezoneOptions] = useState([]);

  const getAdditionalLabelForTimezone = (tz) => {
    const additionalInfo = TIMEZONE_MAPPING[tz];
    return additionalInfo ? ` (${additionalInfo})` : "";
  };

  useEffect(() => {
    const usersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const supportedTimezones = Intl.supportedValuesOf("timeZone");
    const now = dayjs();
    let verifiedBrowserTimeZone = undefined;
    let verifiedInitiallySelectedTimeZone = undefined;
    const timezoneOptionsLocal = supportedTimezones.map((tz) => {
      const localTime = now.tz(tz);
      if (tz === usersTimezone) {
        verifiedBrowserTimeZone = tz;
      }
      if (tz === initiallySelectedTimezone) {
        verifiedInitiallySelectedTimeZone = tz;
      }
      const splitTz = tz.split("/");
      const secondPart = splitTz[1] || splitTz[0];
      let tzForDisplay = `${secondPart.replace("_", " ")}, ${splitTz[0]}`;
      tzForDisplay = `${tzForDisplay}${getAdditionalLabelForTimezone(tz)}`;
      return {
        value: tz,
        searchableValue: tzForDisplay.toLowerCase(),
        label: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{tzForDisplay}</span>
            <span style={{ color: "#1890ff" }}>
              {localTime.format("h:mma")}
            </span>
          </div>
        ),
      };
    });
    if (verifiedInitiallySelectedTimeZone) {
      setSelectedTimezone(verifiedInitiallySelectedTimeZone);
    } else if (verifiedBrowserTimeZone) {
      setSelectedTimezone(verifiedBrowserTimeZone);
    }
    setAllTimezoneOptions(timezoneOptionsLocal);
    setTimezoneOptions(timezoneOptionsLocal);
  }, [initiallySelectedTimezone]);

  useEffect(() => {
    if (initiallySelectedTimezone) {
      for (const i in timezoneOptions) {
        if (timezoneOptions[i].value === initiallySelectedTimezone) {
          setSelectedTimezone(initiallySelectedTimezone);
          return;
        }
      }
    }
  }, [initiallySelectedTimezone, timezoneOptions]);

  const handleSearch = (searchText) => {
    searchText = searchText.toLowerCase();
    setTimezoneOptions(
      allTimezoneOptions.filter((option) => {
        return option.searchableValue.includes(searchText);
      }),
    );
  };

  const handleChange = (newValue) => {
    setSelectedTimezone(newValue);
    onChange(newValue);
  };

  return (
    <Select
      style={{ width: "100%", maxWidth: 300 }}
      showSearch
      filterOption={false}
      value={selectedTimezone}
      onSearch={handleSearch}
      onChange={handleChange}
      options={timezoneOptions}
    />
  );
};

export default TimeZoneSelector;
