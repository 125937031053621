export const replacePlaceholdersInTemplate = (
  template,
  templateReplacements,
) => {
  let response = template;
  for (const key in templateReplacements) {
    const value = templateReplacements[key];
    if (value) {
      response = response.replace(`{${key}}`, value);
    } else {
      response = response.replace(`/{${key}}`, "");
    }
  }
  return response;
};
