import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0UniversalProviderContext from "../../context/auth0UniversalProviderContext";
import { getApiClientWithBaseUrl } from "./apiClient";

const useQueryGenericPost = (
  baseUrl,
  endpoint,
  queryKey,
  onSubmit,
  onSuccess,
  onError,
  enabled,
) => {
  const { getAccessTokenSilently } = useAuth0(Auth0UniversalProviderContext);
  const queryClient = useQueryClient();

  return useMutation({
    enabled: enabled !== false,
    mutationFn: async (data) => {
      const token = await getAccessTokenSilently();
      const client = getApiClientWithBaseUrl(baseUrl, endpoint, token);
      return client.post(data);
    },

    onMutate: (unsaved) => {
      if (queryKey) {
        const previousItems = queryClient.getQueryData(queryKey) || [];
        queryClient.setQueryData(queryKey, [unsaved, ...previousItems]);
        if (onSubmit) onSubmit(unsaved);
        return { previousItems };
      }

      if (onSubmit) onSubmit(unsaved);
    },

    onSuccess: (saved, submitted) => {
      if (onSuccess) onSuccess(saved, submitted);
    },

    onError: (error, unsaved, context) => {
      if (queryKey) {
        queryClient.setQueryData(queryKey, context.previousItems);
        queryClient.invalidateQueries({ queryKey: queryKey }).then();
      }
      if (onError) onError(error);
    },
  });
};

export default useQueryGenericPost;
