import React from "react";
import { Col, Divider, Row } from "antd";
import DateMiniCard from "../../common/generic/dateMiniCard";
import dayjs from "dayjs";
import ResponsiveCard from "../../common/generic/responsiveCard";
import MagicEventDetails from "./../parts/magicEventDetails";
import { ALL_CLASSES_BY_CLASS_ID } from "../../ecObjectViewers/actionMappers/classesToActions";
import { Actions } from "../../ecObjectViewers/actionMappers/actions";
import { AppContextMagicCalendar } from "../../ecObjectViewers/actionMappers/appContextsToActions";

const MagicCalendarDay = ({
  startDateAsString,
  eventsAndStartDates,
  savedObjectsByEcArtifactIds,
  showHidden,
  handleUpdateHiddenStatus,
  showCardTitles,
  onClickCardAction,
  onClickFoundInMessages,
}) => {
  const getSavedObjectUsagesForEvent = (eventObject) => {
    return savedObjectsByEcArtifactIds?.[eventObject?.class_id]?.[
      eventObject?.hash_key
    ]?.[eventObject?.sort_key];
  };

  const getDay = () => {
    const eventsForDay =
      eventsAndStartDates?.eventsByStartDate?.[startDateAsString] || [];

    const cleanedEventsForDay = [];
    for (const i in eventsForDay) {
      const eventObject = eventsForDay[i];
      let showItem =
        eventObject && (eventObject.is_hidden === false || showHidden === true);
      if (showItem) {
        cleanedEventsForDay.push(eventObject);
      }
    }
    if (cleanedEventsForDay.length === 0) {
      return <></>;
    }

    let props = {};
    if (showCardTitles) {
      props = {
        styles: { header: { background: "#e0f5ff" } },
        title: <>{dayjs(startDateAsString).format("ddd, MMM D, YYYY")}</>,
      };
    }

    return (
      <Row style={{ paddingBottom: 20 }} wrap={false}>
        <Col flex="none" className={"hide-on-small-screen"}>
          <DateMiniCard dayJsDate={dayjs(startDateAsString)} />
        </Col>
        <Col flex={"auto"} style={{ marginLeft: showCardTitles ? 0 : 8 }}>
          <ResponsiveCard {...props}>
            {cleanedEventsForDay?.map((eventObject, index) => {
              let showItem =
                eventObject &&
                (eventObject.is_hidden === false || showHidden === true);

              return showItem ? (
                <div key={index}>
                  <MagicEventDetails
                    eventObject={eventObject}
                    savedObjectUsages={getSavedObjectUsagesForEvent(
                      eventObject,
                    )}
                    onClickFoundInMessages={onClickFoundInMessages}
                    showHideButton={true}
                    onClickAddToGoogleCalendar={(event) => {
                      const clazz = ALL_CLASSES_BY_CLASS_ID[event.class_id];
                      onClickCardAction(
                        {
                          classAction: Actions.createEventAction.name,
                          sourceInfo: clazz.getSourceInfo(event),
                          appContext: AppContextMagicCalendar,
                          cardProperties: null,
                          savedObject: null,
                        },
                        event,
                      );
                    }}
                    onClickCardAction={onClickCardAction}
                    onUpdateHiddenStatus={handleUpdateHiddenStatus}
                  />
                  {index !== cleanedEventsForDay.length - 1 && <Divider />}
                </div>
              ) : (
                <div key={index} />
              );
            })}
          </ResponsiveCard>
        </Col>
      </Row>
    );
  };

  return <>{getDay()}</>;
};

export default MagicCalendarDay;
