import React, { useEffect } from "react";
import useTldrPageContext from "../../hooks/orchestrate/common/useTldrPageContext";
import AbsoluteSpin from "../common/generic/absoluteSpin";
import { Collapse, Divider, Typography } from "antd";
import ResponsiveCard from "../common/generic/responsiveCard";
import AnchorNoPropagation from "../common/generic/anchorNoPropagation";
import { useNavigate } from "react-router-dom";

const HomeSuggestedWorkflow = () => {
  const {
    familyId,
    clearBreadcrumbs,
    clearBreadcrumbExtra,
    currentUserEducationSubjects,
  } = useTldrPageContext();
  const navigate = useNavigate();

  useEffect(() => {
    clearBreadcrumbs();
    clearBreadcrumbExtra();
  }, [clearBreadcrumbs, clearBreadcrumbExtra]);

  const getForwardCard = () => {
    const items = [
      {
        key: "1",
        label: "Productivity Philosophy",
        children: (
          <>
            <p style={{ fontSize: "1.5rem" }}>
              We all know this, but a reminder can be helpful: Your inbox is not
              a to-do list. Treating it as one can distract you from focusing on
              more important tasks and may lead to things slipping through the
              cracks. In{" "}
              <a
                href={"https://gettingthingsdone.com/books/"}
                target="_blank"
                rel="noreferrer"
                style={{ fontStyle: "italic" }}
              >
                Getting Things Done
              </a>
              , David Allen introduces the two-minute rule: "If an action will
              take less than two minutes, it should be done at the moment it’s
              defined." For anything that takes longer, it should enter a proper
              workflow.
            </p>
            <p style={{ fontSize: "1.5rem" }}>
              If a school email contains a lot of information and requires some
              time to process, send it to us to clear your inbox. TLDR Parents
              offers a range of features to make these tasks easier and more
              enjoyable. When you use TLDR Parents, it should be a focused
              session dedicated to organizing your kid-related activities.
            </p>
          </>
        ),
      },
      {
        key: "2",
        label: "Shared Inbox",
        children: (
          <p style={{ fontSize: "1.5rem" }}>
            One significant advantage of forwarding messages to TLDR Parents is
            that they become accessible to any partners sharing the workspace.
            So, if you receive emails from gymnastics but your partner doesn't,
            no problem—just forward them to TLDR Parents.
          </p>
        ),
      },
      {
        key: "3",
        label: "Setting up Automatic Forwarding",
        children: (
          <p style={{ fontSize: "1.5rem" }}>
            You can even automate this process by setting up rules in your email
            settings to automatically forward specific messages. For Gmail
            users, we've created a video guide to walk you through the steps.
            Check out our{" "}
            <a
              href={"https://tldrparents.com/tips"}
              target="_blank"
              rel="noreferrer"
            >
              tips page
            </a>{" "}
            for details.
          </p>
        ),
      },
    ];

    return (
      <ResponsiveCard style={{ borderColor: "#168dff" }}>
        <h2>Send emails to {process.env.REACT_APP_FORWARD_EMAIL_ADDRESS}</h2>
        <Collapse items={items} accordion={true} />
      </ResponsiveCard>
    );
  };

  const getFilterEventsCard = () => {
    return (
      <ResponsiveCard style={{ borderColor: "rgb(0, 204, 68)" }}>
        <Typography.Text
          type={"secondary"}
          style={{ textTransform: "uppercase" }}
        >
          Step 1
        </Typography.Text>
        <h2>Filter Event Suggestions</h2>
        <p style={{ fontSize: "1.5rem" }}>
          Visit the{" "}
          <AnchorNoPropagation
            onClick={() => {
              navigate(`/families/${familyId}/event-suggestions`);
            }}
          >
            Event Suggestions
          </AnchorNoPropagation>{" "}
          page to see potential events extracted from your messages, arranged in
          chronological order. If an event seems relevant, click{" "}
          <Typography.Text strong={true}>Copy to Workbench</Typography.Text>.{" "}
          For events that aren't relevant, click{" "}
          <Typography.Text strong={true}>Dismiss</Typography.Text>.
        </p>
      </ResponsiveCard>
    );
  };

  const getLinkAlbumCard = () => {
    return (
      <ResponsiveCard style={{ borderColor: "rgb(0, 204, 68)" }}>
        <Typography.Text
          type={"secondary"}
          style={{ textTransform: "uppercase" }}
        >
          Step 2
        </Typography.Text>
        <h2>Review the Link Gallery</h2>
        <p style={{ fontSize: "1.5rem" }}>
          Head over to the{" "}
          <AnchorNoPropagation
            onClick={() => {
              navigate(`/families/${familyId}/key-links-files`);
            }}
          >
            Link Gallery
          </AnchorNoPropagation>
          , where you'll find important links and attachments pulled from your
          messages—like Google Docs, maps, forms, and surveys. Explore items
          from your most recent messages; we recommend reviewing the past week
          or so. If you spot something you need to act on or save for later,
          simply click the{" "}
          <Typography.Text strong={true}>Copy to Workbench</Typography.Text>{" "}
          button.
        </p>
      </ResponsiveCard>
    );
  };

  const getProcessSmartInboxCard = () => {
    return (
      <ResponsiveCard style={{ borderColor: "rgb(0, 204, 68)" }}>
        <Typography.Text
          type={"secondary"}
          style={{ textTransform: "uppercase" }}
        >
          Step 3
        </Typography.Text>
        <h2>Process the Smart Inbox</h2>
        <p style={{ fontSize: "1.5rem" }}>
          Get over to the{" "}
          <AnchorNoPropagation
            onClick={() => {
              navigate(`/families/${familyId}/smart-inbox`);
            }}
          >
            Smart Inbox
          </AnchorNoPropagation>
          . Page through each message. You can view each message in{" "}
          <Typography.Text strong={true}>TLDR mode</Typography.Text> or
          <Typography.Text strong={true}>full-text mode</Typography.Text>. In
          TLDR mode, you'll get a streamlined overview featuring 1){" "}
          <Typography.Text strong={true}>Topics at a Glance</Typography.Text>,
          2) <Typography.Text strong={true}>Event Suggestions</Typography.Text>,
          and 3) <Typography.Text strong={true}>Key Links</Typography.Text>.
          With Suggested Events and Links already handled, take a moment to scan
          through each topic to ensure nothing critical slips through the
          cracks. If a topic catches your eye, click on it to see the full
          context within the original message. If it's something you haven’t yet
          addressed, add it to your{" "}
          <Typography.Text strong={true}>Workbench</Typography.Text> with a
          click.
        </p>
      </ResponsiveCard>
    );
  };

  const getFamiliarWithTheWorkbenchCard = () => {
    return (
      <ResponsiveCard style={{ borderColor: "rgb(156, 39, 176)" }}>
        <Typography.Text
          type={"secondary"}
          style={{ textTransform: "uppercase" }}
        >
          Step 1
        </Typography.Text>
        <h2>Get familiar with the Workbench</h2>
        <p style={{ fontSize: "1.5rem" }}>
          The{" "}
          <AnchorNoPropagation
            onClick={() => {
              navigate(`/families/${familyId}/workbench`);
            }}
          >
            Workbench
          </AnchorNoPropagation>{" "}
          displays all the items you collected during the previous steps. You'll
          see cards for everything you flagged--Suggested Events, Links, Images,
          Attachments, Topics, etc. In short, it's a list of everything you need
          to address. If you think you added anything twice by accident, click
          the <Typography.Text strong={true}>Remove Duplicates</Typography.Text>{" "}
          button.
        </p>
      </ResponsiveCard>
    );
  };

  const getProcessWorkbenchCardsCard = () => {
    return (
      <ResponsiveCard style={{ borderColor: "rgb(156, 39, 176)" }}>
        <Typography.Text
          type={"secondary"}
          style={{ textTransform: "uppercase" }}
        >
          Step 2
        </Typography.Text>
        <h2>Process each card on your Workbench</h2>
        <p style={{ fontSize: "1.5rem" }}>
          For each card, you've got three power moves at your disposal:
        </p>
        <ul>
          <li>
            <Typography.Text strong={true}>Add to Calendar:</Typography.Text>{" "}
            Put Suggested Events onto your Google Calendar.
          </li>
          <li>
            <Typography.Text strong={true}>Create To-Do:</Typography.Text>{" "}
            Convert links, images, topics, attachments, etc. into trackable
            tasks.
          </li>
          <li>
            <Typography.Text strong={true}>Pin to Board:</Typography.Text> Store
            important info where it’s easy for anyone in your family workspace
            find. Put things here that you'll need to retrieve throughout the
            school year or sports season.
          </li>
        </ul>
        <p style={{ fontSize: "1.5rem" }}>
          Once you've crafted your calendar events, set up your to-dos, and
          pinned your key items, go ahead and clear the clutter by deleting the
          cards from your Workbench!
        </p>
      </ResponsiveCard>
    );
  };

  const getJsx = () => {
    //   This is here to prevent a flash of the home screen while onboarding part 2 is still taking place
    if (currentUserEducationSubjects?.hasFinishedOnboarding !== true) {
      return <AbsoluteSpin />;
    }

    return (
      <>
        <div style={{ height: 30 }} />
        <ResponsiveCard
          style={{ borderColor: "#e6f7ff" }}
          styles={{ body: { backgroundColor: "#e6f7ff" } }}
        >
          <span style={{ fontSize: "1.5rem" }}>
            At TLDR Parents, we've found the following workflow to be
            uber-effective.
          </span>
        </ResponsiveCard>
        <div style={{ height: 30 }} />

        <h2 style={{ color: "#168dff", marginBottom: 10 }}>
          From your email program, forward school / extracurricular emails as
          they come in
        </h2>
        {getForwardCard()}
        <div style={{ height: 30 }} />
        <Divider />

        <h2 style={{ color: "rgb(0, 204, 68)", marginBottom: 10 }}>
          Extract the stuff that matters to your family
        </h2>
        {getFilterEventsCard()}
        <div style={{ height: 30 }} />
        {getLinkAlbumCard()}
        <div style={{ height: 30 }} />
        {getProcessSmartInboxCard()}
        <div style={{ height: 30 }} />
        <Divider />

        <h2 style={{ color: "rgb(156, 39, 176)", marginBottom: 10 }}>
          Get organized using the Workbench
        </h2>
        {getFamiliarWithTheWorkbenchCard()}
        <div style={{ height: 30 }} />
        {getProcessWorkbenchCardsCard()}
        <div style={{ height: 30 }} />
      </>
    );
  };

  return getJsx();
};

export default HomeSuggestedWorkflow;
