import React, { useEffect } from "react";
import { Button, Col, Row, Skeleton } from "antd";
import useTldrPageContext from "../../../hooks/orchestrate/common/useTldrPageContext";
import BoardListItem from "./parts/boardListItem";
import OrchestrateObjectFormOverlays from "../orchestrateObjectFormOverlays";
import { O_SERVICE_BOARDS_LIST } from "../../../serviceUrls/serviceUrlsOrchestrate";
import useListOrchestrateObjects from "../../../hooks/orchestrate/useListOrchestrateObjects";
import LoadingEmptyOrContent2 from "../../common/generic/loadingEmptyOrContent2";
import BoardsEmpty from "./boardsEmpty";
import useCreateOrEditOrchestrateObject from "../../../hooks/orchestrate/common/useCreateOrEditOrchestrateObject";
import useTldrUniversalMutateContext from "../../../hooks/utils/useTldrUniversalMutateContext";
import BoardsUserEducationModal from "../boardsUserEducationModal";
import HelpIconAndModal from "../../helpIconAndModal";

const Boards = () => {
  const { setBreadcrumbs, setBreadcrumbExtra } = useTldrPageContext();

  const {
    queryKey: boardsQueryKey,
    data: boards,
    isLoading: isLoadingBoards,
  } = useListOrchestrateObjects(O_SERVICE_BOARDS_LIST, true);

  const {
    handleCreateBoard,
    closeBoardOverlay,
    boardOverlayOpen,
    boardFormValues,
    boardMergeValues,
  } = useCreateOrEditOrchestrateObject();

  const { deleteBoard } = useTldrUniversalMutateContext();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Boards",
      },
    ]);
    setBreadcrumbExtra(
      <HelpIconAndModal educationModalClass={BoardsUserEducationModal} />,
    );
  }, [setBreadcrumbs, setBreadcrumbExtra]);

  const getListItem = (board, index) => {
    return (
      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6} key={index}>
        <BoardListItem
          key={board.id}
          board={board}
          onEdit={() => {
            handleCreateBoard(
              {
                name: board.name,
              },
              { id: board.id },
            );
          }}
          onDelete={() => {
            deleteBoard({ objectId: board.id });
          }}
        />
      </Col>
    );
  };

  const getJsx = () => {
    return (
      <>
        <BoardsUserEducationModal forced={true} />
        <OrchestrateObjectFormOverlays
          boardInitialFormValues={boardFormValues}
          boardMergeValues={boardMergeValues}
          boardsQueryKey={boardsQueryKey}
          boardOverlayOpen={boardOverlayOpen}
          onCancelBoard={() => closeBoardOverlay()}
          onSubmitBoard={() => closeBoardOverlay()}
          onPersistBoard={(savedItem) => console.log(savedItem)}
        />
        <div
          style={{ display: "flex", justifyContent: "right", marginBottom: 10 }}
        >
          <Button
            onClick={() => {
              handleCreateBoard({}, {});
            }}
            type={"primary"}
          >
            Create Board
          </Button>
        </div>
        <LoadingEmptyOrContent2
          isLoading={isLoadingBoards}
          loadingComponent={<Skeleton active={true} />}
          isEmpty={boards?.length === 0}
          emptyComponent={<BoardsEmpty />}
        >
          <Row gutter={[12, 12]}>
            {boards ? (
              boards.map((board, index) => {
                return getListItem(board, index);
              })
            ) : (
              <></>
            )}
          </Row>
        </LoadingEmptyOrContent2>
      </>
    );
  };

  return <>{getJsx()}</>;
};

export default Boards;
