import { useEffect, useState } from "react";
import useQueryGenericPost from "../framework/useQueryGenericPost";
import { BACKEND_API_BASE_URL_ORCHESTRATE } from "../../serviceUrls/baseUrls";
import { O_SERVICE_AUTH_TRACK_USER_VISIT } from "../../serviceUrls/serviceUrlsOrchestrate";

export const useTrackUserVisit = () => {
  const lastVisitStringFromLocalStorage = localStorage.getItem(
    "tldrParents.lastVisit",
  );
  const [lastVisitState, setLastVisitState] = useState(null);

  const { mutate: callTrackVisitOnServer } = useQueryGenericPost(
    BACKEND_API_BASE_URL_ORCHESTRATE,
    O_SERVICE_AUTH_TRACK_USER_VISIT,
    null,
    null,
    null,
    null,
    true,
  );

  useEffect(() => {
    if (lastVisitStringFromLocalStorage) {
      setLastVisitState({
        isSet: true,
        value: parseInt(lastVisitStringFromLocalStorage),
      });
    } else {
      setLastVisitState({
        isSet: false,
        value: 0,
      });
    }
  }, [lastVisitStringFromLocalStorage]);

  useEffect(() => {
    if (lastVisitState) {
      const isSet = lastVisitState.isSet;
      let requiresUpdate = false;
      if (isSet) {
        const now = Math.floor(Date.now());
        const value = lastVisitState.value;
        const delta = now - value;
        if (delta > 15 * 60 * 1000) {
          requiresUpdate = true;
        }
      } else {
        requiresUpdate = true;
      }
      if (requiresUpdate) {
        const now = Math.floor(Date.now());
        localStorage.setItem("tldrParents.lastVisit", now.toString());
        callTrackVisitOnServer({ lastVisit: now });
      }
    }
  }, [lastVisitState, callTrackVisitOnServer]);
};

export default useTrackUserVisit;
